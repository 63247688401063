import { action } from 'typesafe-actions'
import { LayoutTypes } from './types'

export const toggleAction = (data: string) => action(LayoutTypes.TOGGLE_ACTION, { toggleState: data })

export const changeToggle = (data: boolean) => action(LayoutTypes.CHANGE_TOGGLE, { toggle: data })

export const changeUsername = (data: string) => action(LayoutTypes.CHANGE_USERNAME, { username: data })

export const changeCollpase = (data: boolean) => action(LayoutTypes.CHANGE_COLLAPSE, { collapse: data })

export const changeUpdateAvailable = (
    updateAvailable: boolean,
    registration: ServiceWorkerRegistration | undefined
) => action(
    LayoutTypes.UPDATE_AVAILABLE,
    { updateAvailable, registration }
)
