import { action } from 'typesafe-actions'
import { DisbursementReportBalanceObservation } from '../../application/models/disbursement/disbursement.report.balance.observation'
import { DisbursementReportBalanceObservationActionTypes } from './types'

/* Actions for Reset State */
export const reset = () => action(DisbursementReportBalanceObservationActionTypes.RESET)

/* Actions for Create Disbursement Report Config */

export const changeCreateModal = (dialog: boolean, data: DisbursementReportBalanceObservation) => action(
    DisbursementReportBalanceObservationActionTypes.CHANGE_CREATE_DIALOG, { dialog, data }
)

export const createRequest = (data: DisbursementReportBalanceObservation) => action(
    DisbursementReportBalanceObservationActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: DisbursementReportBalanceObservation) => action(
    DisbursementReportBalanceObservationActionTypes.CREATE_SUCCESS,
    { data }
)

export const createFailure = () => action(DisbursementReportBalanceObservationActionTypes.CREATE_FAILURE)

/* Actions for Load Disbursement Report Config */
export const loadRequest = (date: string) => action(
    DisbursementReportBalanceObservationActionTypes.LOAD_REQUEST,
    { date }
)

export const loadSuccess = (data: DisbursementReportBalanceObservation[]) => action(
    DisbursementReportBalanceObservationActionTypes.LOAD_SUCCESS,
    { data }
)

export const loadFailure = () => action(DisbursementReportBalanceObservationActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement Report Config */

export const changeRemoveModal = (dialog: boolean, id: string) => action(
    DisbursementReportBalanceObservationActionTypes.CHANGE_REMOVE_DIALOG, { dialog, id }
)

export const removeRequest = (date: string, id: string) => action(
    DisbursementReportBalanceObservationActionTypes.REMOVE_REQUEST,
    { date, id }
)

export const removeSuccess = (id: string) => action(
    DisbursementReportBalanceObservationActionTypes.REMOVE_SUCCESS,
    { id }
)

export const removeFailure = () => action(DisbursementReportBalanceObservationActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement Report Config */
export const updateRequest = (data: DisbursementReportBalanceObservation) => action(
    DisbursementReportBalanceObservationActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: DisbursementReportBalanceObservation) => action(
    DisbursementReportBalanceObservationActionTypes.UPDATE_SUCCESS,
    { data }
)

export const updateFailure = () => action(DisbursementReportBalanceObservationActionTypes.UPDATE_FAILURE)
