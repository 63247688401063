import { JsonUtils } from '../../utils/json.util'

export default class AuditResponse {
    private _code?: string
    private _body?: any

    constructor() {
        this._code = ''
    }

    get code(): string | undefined {
        return this._code
    }

    get body(): any | undefined {
        return this._body
    }

    get bodyIsEmpty(): boolean {
        return !this.body || JSON.stringify(this.body) === '{}'
    }

    public fromJSON(json: any): AuditResponse {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.code !== undefined) {
            this._code = json.code
        }

        if (json.body !== undefined) {
            this._body = json.body
        }

        return this
    }

    public toJSON(): any {
        return {
            code: this.code ? this.code : undefined,
            body: this.body ? this.body : undefined
        }
    }
}
