import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { IActionType, IAxiosResponse } from '../root.types'
import {
    IActionLoad,
    IActionLoadAllScenarios,
    IActionLoadBudgets,
    IActionLoadDuodecimos,
    IActionLoadSupplementaryBudgets,
    ScenarioActionTypes
} from './types'
import {
    createFailure,
    createSuccess,
    loadBalanceFailure,
    loadBalanceSuccess,
    loadBudgetsFailure,
    loadBudgetsSuccess,
    loadCostingFailure,
    loadCostingSuccess,
    loadDebtFailure,
    loadDebtSuccess,
    loadDuodecimosFailure,
    loadDuodecimosSuccess,
    loadEventFailure,
    loadEventSuccess,
    loadExpenseFailure,
    loadExpenseSuccess,
    loadInvestmentFailure,
    loadInvestmentSuccess,
    loadPrevRevenueFailure,
    loadPrevRevenueSuccess,
    loadRevenueFailure,
    loadRevenueSuccess,
    loadScenariosFailure,
    loadScenariosSuccess,
    loadSupplementaryBudgetsFailure,
    loadSupplementaryBudgetsSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { TransactionService } from '../../../services/transaction'
import balanceService from '../../../services/balance.summary'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import CostAnnualSummary from '../../application/models/transactions/costing/cost.annual.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'
import scenarioService from '../../../services/scenarios'
import payeeService from '../../../services/payee'
import budgetService from '../../../services/budget'
import supplementaryBudgetService from '../../../services/supplementary.budget'
import ScenarioDataModel from '../../application/models/scenario/scenario.data.model'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import Budget from '../../application/models/budget/budget'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'
import Payee from '../../application/models/financial.agents/payee/payee'
import BalanceSummary from '../../application/models/balance/balance.summary'
import { Toast } from '../../../services/toast'

const transactionService = new TransactionService()

const toastService = Toast.getInstance()

function* load(action: IActionType<IActionLoad>) {
    const { date, paginator } = action.payload

    try {
        const responseBalance: IAxiosResponse<BalanceSummary> = yield apply(
            balanceService,
            balanceService.getMonthly,
            [
                `${date?.substr(0, 4)}-01`,
                paginator
            ]
        )
        yield put(loadBalanceSuccess({ monthlyBalance: responseBalance.data }))
    } catch (err) {
        yield put(loadBalanceFailure(err))
    }

    try {
        const responseRevenue: IAxiosResponse<RevenueAnnualSummary> = yield apply(
            transactionService,
            transactionService.getAnnual,
            [
                TransactionTypes.REVENUE,
                date ? date : `${new Date().getFullYear()}`,
                paginator
            ]
        )
        const month = new Date().getMonth()
        /**
         * If the current month is December, it is not necessary to load the previous year
         * 11 because getMonth returns a value between [0-11]
         */
        if (month !== 11) {
            const prevDate = parseInt(date ? date : `${new Date().getFullYear()}`, 10) - 1

            try {
                const responsePrevRevenue: IAxiosResponse<RevenueAnnualSummary> = yield apply(
                    transactionService,
                    transactionService.getAnnual,
                    [
                        TransactionTypes.REVENUE,
                        `${prevDate}`,
                        paginator
                    ]
                )

                yield put(loadPrevRevenueSuccess({ annualRevenue: responsePrevRevenue.data }))
            } catch (err) {
                yield put(loadPrevRevenueFailure(err))
            }
        }

        yield put(loadRevenueSuccess({ annualRevenue: responseRevenue.data }))
    } catch (err) {
        yield put(loadRevenueFailure(err))
    }

    try {
        const responseCosting: IAxiosResponse<CostAnnualSummary> = yield apply(
            transactionService,
            transactionService.getAnnual, [
                TransactionTypes.COST,
                date ? date : `${new Date().getFullYear()}`,
                paginator
            ]
        )
        yield put(loadCostingSuccess({ annualCosting: responseCosting.data }))
    } catch (err) {
        yield put(loadCostingFailure(err))
    }

    try {
        const januaryDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-01` : `${new Date().getFullYear()}-01`,
                paginator
            ]
        )

        const februaryDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-02` : `${new Date().getFullYear()}-02`,
                paginator
            ]
        )

        const marchDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-03` : `${new Date().getFullYear()}-03`,
                paginator
            ]
        )

        const aprilDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-04` : `${new Date().getFullYear()}-04`,
                paginator
            ]
        )

        const mayDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-05` : `${new Date().getFullYear()}-05`,
                paginator
            ]
        )

        const juneDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-06` : `${new Date().getFullYear()}-06`,
                paginator
            ]
        )

        const julyDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-07` : `${new Date().getFullYear()}-07`,
                paginator
            ]
        )

        const aguDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-08` : `${new Date().getFullYear()}-08`,
                paginator
            ]
        )

        const setDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-09` : `${new Date().getFullYear()}-09`,
                paginator
            ]
        )

        const octDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-10` : `${new Date().getFullYear()}-10`,
                paginator
            ]
        )

        const novDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-11` : `${new Date().getFullYear()}-11`,
                paginator
            ]
        )

        const decDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? `${date}-12` : `${new Date().getFullYear()}-12`,
                paginator
            ]
        )

        const annualDebt = {
            january: januaryDebt.data,
            february: februaryDebt.data,
            march: marchDebt.data,
            april: aprilDebt.data,
            may: mayDebt.data,
            june: juneDebt.data,
            july: julyDebt.data,
            august: aguDebt.data,
            september: setDebt.data,
            october: octDebt.data,
            november: novDebt.data,
            december: decDebt.data
        }

        yield put(loadDebtSuccess({ annualDebt }))
    } catch (err) {
        yield put(loadDebtFailure(err))
    }

    try {
        const responseEvent: IAxiosResponse<EventAnnualSummary> = yield apply(
            transactionService,
            transactionService.getAnnual, [
                TransactionTypes.EVENT,
                date ? date : `${new Date().getFullYear()}`,
                paginator
            ])
        yield put(loadEventSuccess({ annualEvent: responseEvent.data }))
    } catch (err) {
        yield put(loadEventFailure(err))
    }

    try {
        const responseExpense: IAxiosResponse<ExpenseAnnualSummary> = yield apply(
            transactionService,
            transactionService.getAnnual, [
                TransactionTypes.EXPENSE,
                date ? date : `${new Date().getFullYear()}`,
                paginator
            ]
        )
        yield put(loadExpenseSuccess({ annualExpense: responseExpense.data }))
    } catch (err) {
        yield put(loadExpenseFailure(err))
    }

    try {
        const januaryDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-01` : `${new Date().getFullYear()}-01`,
                paginator
            ]
        )

        const februaryDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-02` : `${new Date().getFullYear()}-02`,
                paginator
            ]
        )

        const marchDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-03` : `${new Date().getFullYear()}-03`,
                paginator
            ]
        )

        const aprilDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-04` : `${new Date().getFullYear()}-04`,
                paginator
            ]
        )

        const mayDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-05` : `${new Date().getFullYear()}-05`,
                paginator
            ]
        )

        const juneDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-06` : `${new Date().getFullYear()}-06`,
                paginator
            ]
        )

        const julyDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-07` : `${new Date().getFullYear()}-07`,
                paginator
            ]
        )

        const aguDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-08` : `${new Date().getFullYear()}-08`,
                paginator
            ]
        )

        const setDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-09` : `${new Date().getFullYear()}-09`,
                paginator
            ]
        )

        const octDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-10` : `${new Date().getFullYear()}-10`,
                paginator
            ]
        )

        const novDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-11` : `${new Date().getFullYear()}-11`,
                paginator
            ]
        )

        const decDebt: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? `${date}-12` : `${new Date().getFullYear()}-12`,
                paginator
            ]
        )

        const annualInvestment = {
            january: januaryDebt.data,
            february: februaryDebt.data,
            march: marchDebt.data,
            april: aprilDebt.data,
            may: mayDebt.data,
            june: juneDebt.data,
            july: julyDebt.data,
            august: aguDebt.data,
            september: setDebt.data,
            october: octDebt.data,
            november: novDebt.data,
            december: decDebt.data
        }

        yield put(loadInvestmentSuccess({ annualInvestment }))
    } catch (err) {
        yield put(loadInvestmentFailure(err))
    }
}

function* loadAllScenarios(action: IActionType<IActionLoadAllScenarios>) {
    const { exerciseId, paginator } = action.payload
    try {
        const response: IAxiosResponse<ScenarioDataModel[]> = yield apply(
            scenarioService,
            scenarioService.getAll, [exerciseId,paginator]
        )
        yield put(loadScenariosSuccess(response.data))
    } catch (err) {
        yield put(loadScenariosFailure(err))
    }
}

function* create(action: IActionType) {
    const { scenario } = action.payload
    try {
        const response: IAxiosResponse<ScenarioDataModel> = yield apply(
            scenarioService,
            scenarioService.create, [scenario]
        )
        yield put(createSuccess(response.data))
        toastService.show('success', 'Cenário criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err))
    }
}

function* update(action: IActionType) {
    const { scenario } = action.payload
    try {
        const response: IAxiosResponse<ScenarioDataModel> = yield apply(
            scenarioService,
            scenarioService.update, [scenario]
        )
        yield put(updateSuccess(response.data))
        toastService.show('success', 'Cenário atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err))
    }
}

function* remove(action: IActionType) {
    const { exerciseId, scenarioId } = action.payload
    try {
        yield apply(scenarioService, scenarioService.remove, [exerciseId, scenarioId])
        yield put<any>(removeSuccess(exerciseId))
        toastService.show('success', 'Cenário removido com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* getAllDuodecimos(action: IActionType<IActionLoadDuodecimos>) {
    const { paginator, exerciseSelected } = action.payload
    try {
        const response: IAxiosResponse<Payee[]> = yield apply(
            payeeService,
            payeeService.getDuodecimos,
            [paginator]
        )
        yield put<any>(loadDuodecimosSuccess(response.data, exerciseSelected))
    } catch (err) {
        yield put(loadDuodecimosFailure(err))
    }
}

function* getManyBudgets(action: IActionType<IActionLoadBudgets>) {
    const { payees, exerciseId } = action.payload
    try {
        const response: Map<string, Budget> = yield apply(
            budgetService,
            budgetService.getManyByExercise,
            [payees, exerciseId]
        )
        yield put<any>(loadBudgetsSuccess(response))
    } catch (err) {
        yield put(loadBudgetsFailure(err))
    }
}

function* getManySupplementaryBudgets(action: IActionType<IActionLoadSupplementaryBudgets>) {
    const { payees, date } = action.payload
    try {
        const response: Map<string, SupplementaryBudget[]> = yield apply(
            supplementaryBudgetService,
            supplementaryBudgetService.getManyByExercise,
            [payees, date]
        )
        yield put<any>(loadSupplementaryBudgetsSuccess(response))
    } catch (err) {
        yield put(loadSupplementaryBudgetsFailure(err))
    }
}

export default function* cashFlowSaga() {
    return yield all([
        takeLatest(ScenarioActionTypes.LOAD_REQUEST, load),
        takeLatest(ScenarioActionTypes.LOAD_ALL_REQUEST, loadAllScenarios),
        takeLatest(ScenarioActionTypes.CREATE_REQUEST, create),
        takeLatest(ScenarioActionTypes.UPDATE_REQUEST, update),
        takeLatest(ScenarioActionTypes.REMOVE_REQUEST, remove),
        takeLatest(ScenarioActionTypes.LOAD_DUODECIMOS_REQUEST, getAllDuodecimos),
        takeLatest(ScenarioActionTypes.LOAD_BUDGETS_REQUEST, getManyBudgets),
        takeLatest(ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_REQUEST, getManySupplementaryBudgets)
    ])
}
