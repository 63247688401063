import React, { Component } from 'react'
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Navbar from '../../components/navbar/navbar'
import Sidebar from '../../components/sidebar/sidebar'
import Footer from '../../components/footer/footer'
import { RouteWithSubRoutes } from '../../routes/routes'
import { IApplicationState } from '../../store'
import * as LayoutActions from '../../store/ducks/layout/actions'
import ErrorBoundary from '../../components/error.boundary/error.boundary'
import ScenarioDataModel from '../../store/application/models/scenario/scenario.data.model'
import PrevisionRemove from '../prevision/prevision.remove'
import CreatePrevision from '../prevision/create'
import ExerciseDialogBlocked from '../exercise/dialog.blocked'
import UpdateAvailable from '../../components/update.available'

interface IState {
    readonly username: string,
    readonly collapse: boolean
    readonly toggle: boolean
    readonly toggleState: string
    readonly scenarios: ScenarioDataModel[]
}

interface IDispatchProps extends RouteComponentProps<any> {
    changeCollpase(data: boolean): void

    toggleAction(data: string): void

    changeToggle(data: boolean): void

    changeUsername(data: string): void

    changeUpdateAvailable(
        updateAvailable: boolean,
        registration: ServiceWorkerRegistration | undefined
    ): void
}

interface IOwnProps extends RouteComponentProps<any> {
    routes: []
}

type Props = IState & IDispatchProps & IOwnProps

class Layout extends Component<Props> {
    private static updateFrozenTable(): void {
        const firstTable: Element | undefined = document.getElementsByClassName('p-treetable-scrollable-body-table')[0]
        const secondTable: Element | undefined = document.getElementsByClassName('p-treetable-scrollable-body-table')[1]
        if (firstTable && secondTable) {
            secondTable.setAttribute('style', `height:${firstTable.clientHeight}px`)
        }
    }

    constructor(props: Props) {
        super(props)
        Layout.updateFrozenTable = Layout.updateFrozenTable.bind(this)
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
        Layout.updateFrozenTable()
    }

    public componentDidMount() {
        window.addEventListener('resize', Layout.updateFrozenTable)
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', Layout.updateFrozenTable)
    }

    public render() {
        const {
            username,
            collapse,
            changeCollpase,
            toggleState,
            toggle,
            toggleAction,
            changeToggle,
            changeUsername,
            history,
            location,
            match,
            scenarios
        } = this.props
        return (
            <div id="wrapper">

                <Navbar
                    username={username}
                    collapse={collapse}
                    toggle={toggle}
                    toggleState={toggleState}
                    changeCollpase={changeCollpase}
                    toggleAction={toggleAction}
                    changeToggle={changeToggle}
                    changeUsername={changeUsername}
                    history={history}
                    location={location}
                    match={match}/>

                <Sidebar
                    collapse={collapse}
                    toggleState={toggleState}
                    changeCollpase={changeCollpase}
                    toggleAction={toggleAction}
                    history={history}
                    location={location}
                    match={match}
                    scenarios={scenarios}/>

                <UpdateAvailable/>

                <div id="page-wrapper" className={this.props.collapse ? 'closed' : ''}>
                    {/*Prevision exclusion confirmation dialog*/}
                    <PrevisionRemove/>
                    {/*Prevision create and update  dialog*/}
                    <CreatePrevision/>
                    {/*Dialog blocked create exercise */}
                    <ExerciseDialogBlocked/>
                    <ErrorBoundary>
                        <div style={{ padding: '10px', marginBottom: '50px', height: '100%' }}>
                            <Switch>
                                {this.props.routes.map((route: any, i: number) => (
                                    <RouteWithSubRoutes key={i} {...route} />
                                ))}
                            </Switch>
                        </div>
                    </ErrorBoundary>
                </div>

                <Footer collapse={collapse}/>

            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    username: state.layout.username,
    collapse: state.layout.collapse,
    toggle: state.layout.toggle,
    toggleState: state.layout.toggleState,
    scenarios: state.scenario.listScenarios.scenarios
})


const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(LayoutActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
