import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import $ from 'jquery'
import MetisMenu from 'metismenujs'
import 'metismenujs/dist/metismenujs.css'

import './sidebar.scss'
import { LogicalStrategy } from '../../services/auth'
import MenuWithSubMenus, { IMenu } from '../menu/menuWithSubmenu'
import Exercise from '../../containers/exercise/exercise'
import ScenarioDataModel from '../../store/application/models/scenario/scenario.data.model'
import { UserTypes } from '../../store/application/models/auth/user'
import { VerifyUserType } from '../verify.user.type/verify.user.types'
import { UnregisterCallback } from 'history'

/*
{
        exact: true,
        to: '/app/home',
        icon: 'fa-home',
        description: 'Página Inicial',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        strict: true,
        to: '/app/users',
        icon: 'fa-users',
        scopes: ['op:ra', 'vi:ra'],
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN],
        description: 'Usuários',
        submenus: [
            {
                exact: true,
                to: '/app/users/admins',
                description: 'Administradores',
                scopes: ['ad:ra']
            },
            {
                exact: true,
                to: '/app/users/operators',
                description: 'Operadores',
                scopes: ['op:ra']
            },
            {
                exact: true,
                to: '/app/users/visitors',
                description: 'Visitantes',
                scopes: ['vi:ra']
            },
            {
                exact: true,
                to: '/app/users/disbursements',
                description: 'Desembolso',
                scopes: ['di:ra']
            }
        ]
    },
    {
        exact: true,
        to: '/app/audits',
        icon: 'fa-search',
        scopes: ['al:ra'],
        users: [UserTypes.ADMIN],
        logicalStrategy: LogicalStrategy.OR,
        description: 'Auditoria'
    },
    {
        exact: true,
        to: '/app/cashflow',
        icon: 'fa-random',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Fluxo de Caixa',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/availablefinancial',
        icon: 'fa fa-money',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Disponível Financeiro',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        strict: true,
        to: '/app/scenarios',
        icon: 'fa-signal',
        scopes: ['sc:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR,
        description: 'Cenários',
        submenus: []
    },

    {
        separator: true,
        to: '',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Relatórios',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/export',
        icon: 'fa fa-files-o',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Exportar Dados',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        separator: true,
        to: '',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Gerenciamento',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/balanceagents',
        icon: 'fa fa-file-text',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Dados Bancários',
        scopes: ['ba:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/duodecimos',
        icon: 'fa fa-hospital-o',
        description: 'Duodécimos',
        scopes: ['fa:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/institutions',
        icon: 'fa fa-building-o',
        description: 'Órgãos & Fundos',
        scopes: ['fa:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/payees',
        icon: 'fa fa-paperclip',
        description: 'Anexo A1',
        scopes: ['fa:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: false,
        to: '/app/payers',
        icon: 'fa fa-dollar',
        description: 'Ingressos',
        scopes: ['fa:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        separator: true,
        to: '',
        description: '',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        separator: true,
        to: '',
        description: 'Saldos & Ingressos',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        strict: true,
        to: '/app/balances',
        icon: 'fa fa fa-file-text-o',
        scopes: ['as:r', 'ms:r'],
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Histórico Bancário',
        submenus: [
            {
                exact: true,
                to: '/app/balances/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/balances/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },
    {
        strict: true,
        to: '/app/revenues',
        icon: 'fa fa-dollar',
        scopes: ['as:r', 'ms:r'],
        description: 'Receita',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/revenues/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/revenues/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },
    {
        separator: true,
        to: '',
        description: '',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        separator: true,
        to: '',
        description: 'Saídas',
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        strict: true,
        to: '/app/expenses',
        icon: 'fa fa-scissors',
        scopes: ['as:r', 'ms:r'],
        description: 'Gastos',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/expenses/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/expenses/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },
    {
        strict: true,
        to: '/app/costing',
        icon: 'fa-dollar',
        scopes: ['as:r', 'ms:r'],
        description: 'Custeio',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/costing/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/costing/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },
    {
        strict: true,
        to: '/app/debts',
        icon: 'fa-files-o',
        scopes: ['as:r', 'ms:r'],
        description: 'Dívidas',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/debts/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/debts/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },
    {
        strict: true,
        to: '/app/events',
        icon: 'fa-calendar',
        scopes: ['as:r', 'ms:r'],
        description: 'Eventos',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/events/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/events/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]

    },
    {
        strict: true,
        to: '/app/investments',
        icon: 'fa fa-money',
        scopes: ['as:r', 'ms:r'],
        description: 'Investimentos',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        submenus: [
            {
                exact: true,
                to: '/app/investments/annual',
                description: 'Anual',
                scopes: ['as:r']
            },
            {
                exact: true,
                to: '/app/investments/monthly',
                description: 'Mensal',
                scopes: ['ms:r']
            }
        ]
    },

{
        strict: true,
        to: '/app/users',
        icon: 'fa-users',
        scopes: ['op:ra', 'vi:ra'],
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN],
        description: 'Usuários',
        submenus: [
            {
                exact: true,
                to: '/app/users/admins',
                description: 'Administradores',
                scopes: ['ad:ra']
            },
            {
                exact: true,
                to: '/app/users/operators',
                description: 'Operadores',
                scopes: ['op:ra']
            },
            {
                exact: true,
                to: '/app/users/visitors',
                description: 'Visitantes',
                scopes: ['vi:ra']
            },
            {
                exact: true,
                to: '/app/users/disbursements',
                description: 'Desembolso',
                scopes: ['di:ra']
            }
        ]
    },
    {
        exact: true,
        to: '/app/audits',
        icon: 'fa-search',
        scopes: ['al:ra'],
        users: [UserTypes.ADMIN],
        logicalStrategy: LogicalStrategy.OR,
        description: 'Auditoria'
    },
    {
        strict: true,
        to: '/app/exercises',
        icon: 'fa fa-cogs',
        scopes: ['ex:ra'],
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR],
        description: 'Configurar Exercícios',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        separator: true,
        to: '',
        description: '',
        logicalStrategy: LogicalStrategy.OR
    }

*/

const menus: IMenu[] = [
    {
        separator: true,
        to: '',
        description: 'Fluxo de Caixa',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR]
    },
    // {
    //     strict: true,
    //     to: '/app/committed_balances',
    //     icon: 'fa fa-file-text-o',
    //     description: 'Saldo Comprometido',
    //     submenus: [
    //         {
    //             exact: true,
    //             to: '/app/committed_balances/daily',
    //             description: 'Diários'
    //         },
    //         {
    //             exact: true,
    //             to: '/app/committed_balances/monthly',
    //             description: 'Mensais'
    //         }
    //     ]
    // },
    // {
    //     strict: true,
    //     to: '/app/liberations',
    //     icon: 'fa-dollar',
    //     description: 'Liberações',
    //     submenus: [
    //         {
    //             exact: true,
    //             to: '/app/liberations/daily',
    //             description: 'Diárias'
    //         },
    //         {
    //             exact: true,
    //             to: '/app/liberations/monthly',
    //             description: 'Mensais'
    //         }
    //     ]
    // },
    {
        strict: true,
        to: '/app/management',
        icon: 'fa fa-bars',
        description: 'Gerenciamento',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR]
    },
    {
        strict: true,
        to: '/app/settings',
        icon: 'fa fa-cog',
        description: 'Configurações',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR]
    },
    {
        separator: true,
        to: '',
        description: '',
        logicalStrategy: LogicalStrategy.OR,
        users: [UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR]
    },
    {
        separator: true,
        to: '',
        description: 'Desembolso',
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/conference',
        icon: 'fa fa-tasks',
        description: 'Conferência',
        scopes: ['in:ra', 'ou:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/inputs',
        icon: 'fa-dollar',
        description: 'Entradas',
        scopes: ['in:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/inputs_transfers',
        icon: 'fa-exchange',
        description: 'Transferências',
        scopes: ['in:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/outputs',
        icon: 'fa-dollar',
        description: 'Saídas',
        scopes: ['ou:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/balances',
        icon: 'fa fa-money',
        description: 'Saldos',
        scopes: ['ou:ra'],
        logicalStrategy: LogicalStrategy.OR
    },
    {
        exact: true,
        to: '/app/disbursement/reports',
        icon: 'fa fa-bar-chart',
        description: 'Relatórios',
        scopes: ['in:ra', 'ou:ra'],
        logicalStrategy: LogicalStrategy.OR,
        submenus: [
            {
                exact: true,
                icon: 'fa fa-file-text',
                to: '/app/disbursement/reports/main',
                description: 'Diário',
                scopes: []
            },
            {
                exact: true,
                icon: 'fa fa-file-text',
                to: '/app/disbursement/reports/input_output',
                description: 'Entradas/Saídas',
                scopes: []
            }
        ]
    },
    {
        separator: true,
        to: '',
        description: '',
        logicalStrategy: LogicalStrategy.OR
    }
]

interface IState {
    readonly collapse: boolean
    readonly toggleState: string
    readonly scenarios: ScenarioDataModel[]
}

interface IDispatchProps extends RouteComponentProps<any> {
    changeCollpase(data: boolean): void

    toggleAction(data: string): void
}

type Props = IState & IDispatchProps

class Sidebar extends Component<Props> {
    private el: any
    private $el: any
    private mm: any
    private removeListener: UnregisterCallback | undefined

    constructor(props: any) {
        super(props)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        this.updateScenarios = this.updateScenarios.bind(this)
        this.registerListener = this.registerListener.bind(this)
    }

    public componentDidMount() {
        this.$el = this.el
        this.mm = new MetisMenu(this.$el, { toggle: false })
        window.addEventListener('resize', this.updateWindowDimensions)
        this.updateWindowDimensions()
        this.removeListener = this.registerListener()
    }

    public updateScenarios(): void {
        const { scenarios } = this.props
        menus.forEach((menu: IMenu) => {
            if (menu.description === 'Cenários') {
                const submenus: any[] = scenarios
                    .sort((prev: ScenarioDataModel, current: ScenarioDataModel) => {
                        return (prev.percentage || 0) > (current.percentage || 0) ? 1 : -1
                    })
                    .map(scenario => {
                        return {
                            exact: true,
                            to: `/app/scenarios/${scenario.percentage}`,
                            description: `Cenário com ${scenario.percentage}%`,
                            scopes: ['sc:ra']
                        }
                    })
                submenus.push({
                    exact: true,
                    icon: 'fa fa-cog',
                    to: `/app/scenarios/management`,
                    description: `Gerenciar Cenário`,
                    scopes: ['sc:ra']
                })
                menu.submenus = submenus
            }
        })
    }

    public componentDidUpdate(
        prevProps: Readonly<IState & IDispatchProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        const { collapse, toggleState } = this.props
        if (collapse) {
            $('#page-wrapper').animate({ 'margin-left': '0px' })
            if (toggleState === 'show') {
                $('html').css({ overflow: 'hidden' })
                $('.navbar-side').animate({ left: '0px' })
                $('#backdrop').animate({ display: 'flex' })
                $('#div-sidebar').animate({ display: 'flex' })
            } else {
                $('html').css({ overflow: 'auto' })
                $('.navbar-side').animate({ left: '-260px' })
                $('#backdrop').animate({ display: 'none' })
                $('#div-sidebar').animate({ display: 'none' })
            }
        } else {
            $('#page-wrapper').animate({ 'margin-left': '260px' })
            $('.navbar-side').animate({ left: '0px' })
        }
    }

    public updateWindowDimensions() {
        const { changeCollpase, collapse, toggleState } = this.props
        if (!collapse || toggleState === 'show') {
            changeCollpase(window.innerWidth < 783)
        }
    }

    public componentWillUnmount() {
        if (this.removeListener) {
            this.removeListener()
        }
    }

    public render() {
        const { collapse, toggleState, toggleAction } = this.props

        this.updateScenarios()

        return <React.Fragment>

            <div
                id="backdrop"
                style={{
                    display: toggleState === 'show' && window.innerWidth < 783 ?
                        'flex'
                        : 'none',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    zIndex: 1
                }}
                onClick={() => toggleAction('hidden')}/>

            <div id="div-sidebar" className={`${collapse ? 'collapse' : ''} ${toggleState}`}>
                <nav className="navbar-default navbar-side scrollbar" role="navigation" id="style-2">
                    <div
                        className={`sidebar-collapse ${collapse ? 'collapse' : ''} ${toggleState}`}
                        id="menuCollapse">
                        <ul className="nav" ref={(el: any) => (this.el = el)}>
                            <MenuWithSubMenus menus={menus}/>
                        </ul>
                    </div>
                </nav>
                <VerifyUserType
                    usersType={[UserTypes.ADMIN, UserTypes.OPERATOR, UserTypes.VISITOR]}
                    logicalStrategy={LogicalStrategy.OR}>
                    <div className={`sidebar-collapse ${collapse ? 'collapse' : ''} ${toggleState}`}>
                        <Exercise/>
                    </div>
                </VerifyUserType>
            </div>
        </React.Fragment>
    }

    private registerListener(): UnregisterCallback {
        const { history, toggleAction } = this.props
        return history.listen(() => {
            const { toggleState } = this.props
            if (toggleState === 'show' && window.innerWidth < 783) {
                toggleAction('hidden')
            }
        })
    }
}

export default Sidebar
