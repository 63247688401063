import { JsonUtils } from '../../utils/json.util'
import { LiberationSummaryItem } from './liberation.summary.item'

/**
 * Implementation of the Daily Liberation Summary entity.
 *
 */
export class LiberationSummary {
    private _from_disbursement: LiberationSummaryItem[] | undefined
    private _financial_agents: LiberationSummaryItem[] | undefined

    get financial_agents(): LiberationSummaryItem[] | undefined {
        return this._financial_agents
    }

    set financial_agents(value: LiberationSummaryItem[] | undefined) {
        this._financial_agents = value
    }

    get from_disbursement(): LiberationSummaryItem[] | undefined {
        return this._from_disbursement
    }

    set from_disbursement(value: LiberationSummaryItem[] | undefined) {
        this._from_disbursement = value
    }

    public fromJSON(json: any): LiberationSummary {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.financial_agents instanceof Array && json.financial_agents.length) {
            this.financial_agents = json
                ?.financial_agents
                ?.map((institutionItem: any) => new LiberationSummaryItem().fromJSON(institutionItem)) || []
        }
        if (json.from_disbursement instanceof Array && json.from_disbursement.length) {
            this.from_disbursement = json
                ?.from_disbursement
                ?.map((institutionItem: any) => new LiberationSummaryItem().fromJSON(institutionItem)) || []
        }

        return this
    }

    public toJSON(): any {
        return {
            financial_agents: this.financial_agents?.length ?
                this.financial_agents.map((item: LiberationSummaryItem) => item.toJSON())
                : [],
            from_disbursement: this.from_disbursement?.length ?
                this.from_disbursement.map((item: LiberationSummaryItem) => item.toJSON())
                : []
        }
    }
}
