import { Reducer } from 'redux'

import { IScenarioState, ScenarioActionTypes } from './types'
import CostAnnualSummary from '../../application/models/transactions/costing/cost.annual.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'
import ScenarioDataModel from '../../application/models/scenario/scenario.data.model'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import Budget from '../../application/models/budget/budget'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'
import BalanceSummary from '../../application/models/balance/balance.summary'

export const INITIAL_STATE: IScenarioState = {
    create: {
        scenario: new ScenarioDataModel(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listScenarios: {
        scenarios: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        exerciseId: '',
        scenarioId: '',
        dialog: false,
        loading: false,
        error: false,
        success: false
    },
    previousExercise: {
        revenue: {
            annualRevenue: undefined,
            loading: false,
            error: false,
            success: false
        }
    },
    currentExercise: {
        costing: {
            annualCosting: undefined,
            loading: false,
            error: false,
            success: false
        },
        debt: {
            january: undefined,
            february: undefined,
            march: undefined,
            april: undefined,
            may: undefined,
            june: undefined,
            july: undefined,
            august: undefined,
            september: undefined,
            october: undefined,
            november: undefined,
            december: undefined,
            loading: false,
            error: false,
            success: false
        },
        event: {
            annualEvent: undefined,
            loading: false,
            error: false,
            success: false
        },
        investment: {
            january: undefined,
            february: undefined,
            march: undefined,
            april: undefined,
            may: undefined,
            june: undefined,
            july: undefined,
            august: undefined,
            september: undefined,
            october: undefined,
            november: undefined,
            december: undefined,
            loading: false,
            error: false,
            success: false
        },
        expense: {
            annualExpense: undefined,
            loading: false,
            error: false,
            success: false
        },
        revenue: {
            annualRevenue: undefined,
            loading: false,
            error: false,
            success: false
        },
        balance: {
            monthlyBalance: undefined,
            loading: false,
            error: false,
            success: false
        }
    },
    duodecimos: {
        payees: [],
        budgets: new Map<string, Budget>(),
        supplementaries: new Map<string, SupplementaryBudget[]>(),
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IScenarioState> = (state: IScenarioState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case ScenarioActionTypes.RESET:
            return {
                ...state,
                create: INITIAL_STATE.create
            }

        case ScenarioActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    dialog
                }
            }

        case ScenarioActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listScenarios: {
                    ...state.listScenarios,
                    paginator
                }
            }

        case ScenarioActionTypes.CHANGE_SCENARIO:
            const { scenario: changeScenario } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    scenario: changeScenario
                }
            }

        case ScenarioActionTypes.CHANGE_REMOVE_MODAL:
            const { dialog: removeModal, idForRemove } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    scenarioId: idForRemove,
                    dialog: removeModal
                }
            }

        case ScenarioActionTypes.CREATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case ScenarioActionTypes.CREATE_SUCCESS:
            const { scenario } = action.payload
            const newScenario = new ScenarioDataModel().fromJSON(scenario)
            return {
                ...state,
                create: {
                    ...state.create,
                    scenario: newScenario,
                    loading: false,
                    error: false,
                    success: true,
                    dialog: false
                },
                listScenarios: {
                    ...state.listScenarios,
                    scenarios: state.listScenarios.scenarios.concat(newScenario),
                    loading: false
                }
            }

        case ScenarioActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: true
                }
            }

        case ScenarioActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case ScenarioActionTypes.UPDATE_SUCCESS:
            const { scenario: updateScenario } = action.payload
            const updatedScenario = new ScenarioDataModel().fromJSON(updateScenario)
            const listScenarios = state
                .listScenarios
                .scenarios
                .filter(scenarioElement => scenarioElement.id !== updatedScenario.id)
            return {
                ...state,
                create: {
                    ...state.create,
                    scenario: updatedScenario,
                    loading: false,
                    error: false,
                    success: true
                },
                listScenarios: {
                    ...state.listScenarios,
                    scenarios: listScenarios.concat(updatedScenario),
                    loading: false
                }
            }

        case ScenarioActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: true
                }
            }

        case ScenarioActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    dialog: false
                }
            }

        case ScenarioActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: false,
                    success: true,
                    exerciseId: '',
                    scenarioId: ''
                }
            }

        case ScenarioActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: true,
                    dialog: true
                }
            }

        case ScenarioActionTypes.LOAD_ALL_REQUEST:
            return {
                ...state,
                listScenarios: {
                    ...state.listScenarios,
                    loading: true
                }
            }

        case ScenarioActionTypes.LOAD_ALL_SUCCESS:
            const { scenarios } = action.payload
            return {
                ...state,
                listScenarios: {
                    ...state.listScenarios,
                    scenarios: scenarios.map(elementScenario => new ScenarioDataModel().fromJSON(elementScenario)),
                    loading: false
                }
            }

        case ScenarioActionTypes.LOAD_ALL_FAILURE:
            const { error } = action.payload
            return {
                ...state,
                listScenarios: {
                    ...state.listScenarios,
                    error,
                    loading: false
                }
            }

        case ScenarioActionTypes.LOAD_REQUEST:
            const month = new Date().getMonth()
            return {
                ...state,
                previousExercise: {
                    ...state.previousExercise,
                    revenue: {
                        ...state.previousExercise.revenue,
                        loading: month !== 11
                    }
                },
                currentExercise: {
                    costing: {
                        ...state.currentExercise.costing,
                        loading: true
                    },
                    debt: {
                        ...state.currentExercise.debt,
                        loading: true
                    },
                    event: {
                        ...state.currentExercise.event,
                        loading: true
                    },
                    investment: {
                        ...state.currentExercise.investment,
                        loading: true
                    },
                    expense: {
                        ...state.currentExercise.expense,
                        loading: true
                    },
                    revenue: {
                        ...state.currentExercise.revenue,
                        loading: true
                    },
                    balance: {
                        ...state.currentExercise.balance,
                        loading: true
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_COSTING_SUCCESS:
            const { annualCosting } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    costing: {
                        annualCosting: annualCosting ?
                            new CostAnnualSummary().fromJSON(annualCosting) : state.currentExercise.costing.annualCosting,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_COSTING_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    costing: {
                        ...state.currentExercise.costing,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_DEBT_SUCCESS:
            const {
                january: januaryDebt,
                february: februaryDebt,
                march: marchDebt,
                april: aprilDebt,
                may: mayDebt,
                june: juneDebt,
                july: julyDebt,
                august: augustDebt,
                september: septemberDebt,
                october: octoberDebt,
                november: novemberDebt,
                december: decemberDebt
            } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    debt: {
                        january: januaryDebt ? new DebtMonthlySummary().fromJSON(januaryDebt) : undefined,
                        february: februaryDebt ? new DebtMonthlySummary().fromJSON(februaryDebt) : undefined,
                        march: marchDebt ? new DebtMonthlySummary().fromJSON(marchDebt) : undefined,
                        april: aprilDebt ? new DebtMonthlySummary().fromJSON(aprilDebt) : undefined,
                        may: mayDebt ? new DebtMonthlySummary().fromJSON(mayDebt) : undefined,
                        june: juneDebt ? new DebtMonthlySummary().fromJSON(juneDebt) : undefined,
                        july: julyDebt ? new DebtMonthlySummary().fromJSON(julyDebt) : undefined,
                        august: augustDebt ? new DebtMonthlySummary().fromJSON(augustDebt) : undefined,
                        september: septemberDebt ? new DebtMonthlySummary().fromJSON(septemberDebt) : undefined,
                        october: octoberDebt ? new DebtMonthlySummary().fromJSON(octoberDebt) : undefined,
                        november: novemberDebt ? new DebtMonthlySummary().fromJSON(novemberDebt) : undefined,
                        december: decemberDebt ? new DebtMonthlySummary().fromJSON(decemberDebt) : undefined,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_DEBT_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    debt: {
                        ...state.currentExercise.debt,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_EVENT_SUCCESS:
            const { annualEvent } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    event: {
                        annualEvent: annualEvent ?
                            new EventAnnualSummary().fromJSON(annualEvent) : state.currentExercise.event.annualEvent,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_EVENT_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    event: {
                        ...state.currentExercise.event,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_INVESTMENT_SUCCESS:
            const {
                january: januaryInvest,
                february: februaryInvest,
                march: marchInvest,
                april: aprilInvest,
                may: mayInvest,
                june: juneInvest,
                july: julyInvest,
                august: augustInvest,
                september: septemberInvest,
                october: octoberInvest,
                november: novemberInvest,
                december: decemberInvest
            } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    investment: {
                        january: januaryInvest ? new InvestmentsMonthlySummary().fromJSON(januaryInvest) : undefined,
                        february: februaryInvest ? new InvestmentsMonthlySummary().fromJSON(februaryInvest) : undefined,
                        march: marchInvest ? new InvestmentsMonthlySummary().fromJSON(marchInvest) : undefined,
                        april: aprilInvest ? new InvestmentsMonthlySummary().fromJSON(aprilInvest) : undefined,
                        may: mayInvest ? new InvestmentsMonthlySummary().fromJSON(mayInvest) : undefined,
                        june: juneInvest ? new InvestmentsMonthlySummary().fromJSON(juneInvest) : undefined,
                        july: julyInvest ? new InvestmentsMonthlySummary().fromJSON(julyInvest) : undefined,
                        august: augustInvest ? new InvestmentsMonthlySummary().fromJSON(augustInvest) : undefined,
                        september: septemberInvest ? new InvestmentsMonthlySummary().fromJSON(septemberInvest) : undefined,
                        october: octoberInvest ? new InvestmentsMonthlySummary().fromJSON(octoberInvest) : undefined,
                        november: novemberInvest ? new InvestmentsMonthlySummary().fromJSON(novemberInvest) : undefined,
                        december: decemberInvest ? new InvestmentsMonthlySummary().fromJSON(decemberInvest) : undefined,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_INVESTMENT_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    investment: {
                        ...state.currentExercise.investment,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_EXPENSE_SUCCESS:
            const { annualExpense } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    expense: {
                        annualExpense: annualExpense ?
                            new ExpenseAnnualSummary().fromJSON(annualExpense)
                            : state.currentExercise.expense.annualExpense,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_EXPENSE_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    expense: {
                        ...state.currentExercise.expense,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_BALANCE_SUCCESS:
            const { monthlyBalance } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    balance: {
                        monthlyBalance: monthlyBalance ?
                            new BalanceSummary().fromJSON(monthlyBalance) : state.currentExercise.balance.monthlyBalance,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_BALANCE_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    balance: {
                        ...state.currentExercise.balance,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_PREV_REVENUE_SUCCESS:
            const { annualRevenue: prevAnnualRevenue } = action.payload

            return {
                ...state,
                previousExercise: {
                    ...state.previousExercise,
                    revenue: {
                        annualRevenue: prevAnnualRevenue ?
                            new RevenueAnnualSummary().fromJSON(prevAnnualRevenue)
                            : state.previousExercise.revenue.annualRevenue,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_PREV_REVENUE_FAILURE:
            return {
                ...state,
                previousExercise: {
                    ...state.previousExercise,
                    revenue: {
                        ...state.previousExercise.revenue,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_REVENUE_SUCCESS:
            const { annualRevenue } = action.payload

            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    revenue: {
                        annualRevenue: annualRevenue ?
                            new RevenueAnnualSummary().fromJSON(annualRevenue)
                            : state.currentExercise.revenue.annualRevenue,
                        loading: false,
                        success: true,
                        error: false
                    }
                }
            }

        case ScenarioActionTypes.LOAD_CURRENT_REVENUE_FAILURE:
            return {
                ...state,
                currentExercise: {
                    ...state.currentExercise,
                    revenue: {
                        ...state.currentExercise.revenue,
                        loading: false,
                        error: true,
                        data: action.payload.error
                    }
                }
            }

        case ScenarioActionTypes.LOAD_DUODECIMOS_REQUEST:
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: true
                }
            }

        case ScenarioActionTypes.LOAD_DUODECIMOS_SUCCESS:
            const { payees } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    payees
                }
            }

        case ScenarioActionTypes.LOAD_DUODECIMOS_FAILURE:
            const { error: payeesError } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    error: true,
                    data: payeesError
                }
            }

        case ScenarioActionTypes.LOAD_BUDGETS_REQUEST:
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: true
                }
            }

        case ScenarioActionTypes.LOAD_BUDGETS_SUCCESS:
            const { budgets } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    budgets
                }
            }

        case ScenarioActionTypes.LOAD_BUDGETS_FAILURE:
            const { error: budgetError } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    error: true,
                    data: budgetError
                }
            }

        case ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_REQUEST:
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: true
                }
            }

        case ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_SUCCESS:
            const { supplementaries } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    supplementaries
                }
            }

        case ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_FAILURE:
            const { error: supplementariesError } = action.payload
            return {
                ...state,
                duodecimos: {
                    ...state.duodecimos,
                    loading: false,
                    error: true,
                    data: supplementariesError
                }
            }

        default:
            return state
    }
}

export default reducer
