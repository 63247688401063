/**
 * Action types
 */

import { IComponentState } from '../root.types'
import { DisbursementReportType } from '../../application/utils/disbursement.report.type'
import { DisbursementIOReportItem } from '../../application/models/disbursement/disbursement.io.report.item'

export enum DisbursementIOReportActionTypes {
    RESET = '@disbursement.report.io/RESET',

    LOAD_REQUEST = '@disbursement.report.io/LOAD_REQUEST',

    LOAD_INPUT_SUCCESS = '@disbursement.report.io/LOAD_INPUT_SUCCESS',
    LOAD_INPUT_FAILURE = '@disbursement.report.io/LOAD_INPUT_FAILURE',

    LOAD_OUTPUT_SUCCESS = '@disbursement.report.io/LOAD_OUTPUT_SUCCESS',
    LOAD_OUTPUT_FAILURE = '@disbursement.report.io/LOAD_OUTPUT_FAILURE'
}

/* Actions  */
export interface IActionLoad {
    readonly startDate: string
    readonly endDate: string
    readonly reportType: DisbursementReportType
    readonly transactionCategory?: string
    readonly institutionsIds?: string[]
}

/* States */
interface IReportState extends IComponentState {
    readonly data: DisbursementIOReportItem[]
}

/**
 * State type
 */
export interface IDisbursementIOReportState {
    readonly input: IReportState
    readonly output: IReportState
}
