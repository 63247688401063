import { lazy } from 'react'

const ExportManagement = lazy(() => import('../containers/export/management'))

export const EXPORT_ROUTES = [
    {
        path: '/app/export',
        component: ExportManagement,
        exact: true,
        private: true
    }
]
