import React, { Component } from 'react'
import { Column } from 'primereact/column'
import Currency from '../currency/currency'
import { DataTable } from 'primereact/datatable'
import Cell from '../tree.table/cell'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import UtilDatePicker from '../date.picker/utils'

export interface IColumn {
    field: string
    header: string
}

interface IProps {

    initialValue: number | undefined

    create: boolean

    header: string

    previsions: any[]

    columns: IColumn[]

    loading?: boolean

    onChange(field: string, value: number): void

    onClickAdd(date: string): void

    onClickUpdate(date: string): void

    onClickRemove(date: string): void
}

const NOT_VISIBLE = 'not_visible'

class PrevisionTable extends Component<IProps> {
    constructor(props: IProps) {
        super(props)

        /* Bind Context */
        this.onEditorValueChange = this.onEditorValueChange.bind(this)
        this.editor = this.editor.bind(this)
        this.isEditable = this.isEditable.bind(this)
    }

    public render() {
        const {
            previsions,
            columns,
            header,
            loading,
            initialValue,
            onClickAdd,
            onClickUpdate,
            onClickRemove,
            create
        } = this.props

        const headerGroup1 = <ColumnGroup>
            <Row>
                <Column header="JANEIRO" colSpan={2}/>
                <Column header="FEVERREIRO" colSpan={2}/>
                <Column header="MARÇO" colSpan={2}/>
            </Row>
            <Row>
                <Column header="EXECUTADO" field="04exec"/>
                <Column header="PREVISTO" field="04prev"/>

                <Column header="EXECUTADO" field="05exec"/>
                <Column header="PREVISTO" field="05prev"/>

                <Column header="EXECUTADO" field="06exec"/>
                <Column header="PREVISTO" field="06prev"/>
            </Row>
        </ColumnGroup>

        const headerGroup2 = <ColumnGroup>
            <Row>
                <Column header="ABRIL" colSpan={2}/>
                <Column header="MAIO" colSpan={2}/>
                <Column header="JUNHO" colSpan={2}/>
            </Row>
            <Row>
                <Column header="EXECUTADO" field="04exec"/>
                <Column header="PREVISTO" field="04prev"/>

                <Column header="EXECUTADO" field="05exec"/>
                <Column header="PREVISTO" field="05prev"/>

                <Column header="EXECUTADO" field="06exec"/>
                <Column header="PREVISTO" field="06prev"/>
            </Row>
        </ColumnGroup>

        const headerGroup3 = <ColumnGroup>
            <Row>
                <Column header="JULHO" colSpan={2}/>
                <Column header="AGOSTO" colSpan={2}/>
                <Column header="SETEMBRO" colSpan={2}/>
            </Row>
            <Row>
                <Column header="EXECUTADO" field="07exec"/>
                <Column header="PREVISTO" field="07prev"/>

                <Column header="EXECUTADO" field="08exec"/>
                <Column header="PREVISTO" field="08prev"/>

                <Column header="EXECUTADO" field="09exec"/>
                <Column header="PREVISTO" field="09prev"/>
            </Row>
        </ColumnGroup>

        const headerGroup4 = <ColumnGroup>
            <Row>
                <Column header="OUTUBRO" colSpan={2}/>
                <Column header="NOVEMBRO" colSpan={2}/>
                <Column header="DEZEMBRO" colSpan={2}/>
            </Row>
            <Row>
                <Column header="EXECUTADO" field="10exec"/>
                <Column header="PREVISTO" field="10prev"/>

                <Column header="EXECUTADO" field="11exec"/>
                <Column header="PREVISTO" field="11prev"/>

                <Column header="EXECUTADO" field="12exec"/>
                <Column header="PREVISTO" field="12prev"/>
            </Row>
        </ColumnGroup>

        const mapQuarterToHeaderGroup = {
            '1° Trimestre': headerGroup1,
            '2° Trimestre': headerGroup2,
            '3° Trimestre': headerGroup3,
            '4° Trimestre': headerGroup4
        }

        return (
            <DataTable
                value={previsions}
                header={header}
                responsive={true}
                lazy={true}
                loading={loading}
                headerColumnGroup={mapQuarterToHeaderGroup[header]}
                editMode="cell">
                {
                    columns.map(element => {
                        const editable = this.isEditable(element.field.substr(0, 7))
                        const isPrevision = element?.field.replace(/['exec']/g, '').length > 8
                        return <Column
                            key={element.field}
                            field={element.field}
                            header={element.header}
                            className="center"
                            editor={editable ? this.editor : undefined}
                            body={(rowData: any) => {
                                const cellElement: any = { value: undefined }
                                if (initialValue && isPrevision) {
                                    cellElement.value = initialValue
                                } else if (rowData[element.field]) {
                                    cellElement.value = rowData[element.field]
                                }
                                return <Cell
                                    hasOccurred={editable}
                                    element={cellElement}
                                    scopesCreate={isPrevision && !create ? ['pr:c'] : [NOT_VISIBLE]}
                                    onClickAdd={() => {
                                        onClickAdd(element?.field?.substr(0, 7))
                                    }}
                                    scopesEdit={isPrevision && !create ? ['pr:u'] : [NOT_VISIBLE]}
                                    onClickUpdate={() => {
                                        onClickUpdate(element?.field?.substr(0, 7))
                                    }}
                                    scopesRemove={isPrevision && !create ? ['pr:d'] : [NOT_VISIBLE]}
                                    onClickRemove={() => {
                                        onClickRemove(element?.field?.substr(0, 7))
                                    }}
                                />
                            }}
                        />
                    })
                }
            </DataTable>
        )
    }

    private onEditorValueChange(props, value) {
        const { onChange } = this.props
        const updated = [...props.value]
        const oldValue = updated[props.rowIndex][props.field]
        updated[props.rowIndex][props.field] = value
        if (oldValue !== value) {
            onChange(props.field, value)
        }
    }

    private editor(selected: any) {
        const { field, rowData } = selected
        const editable = this.isEditable(field)
        return <Currency
            value={rowData[field]}
            readOnly={!editable}
            enterPressed={editable ? (value: any) => this.onEditorValueChange(selected, value) : undefined}/>
    }

    private isEditable(field: string): boolean {
        return UtilDatePicker.hasOccurred(field)
    }
}

export default PrevisionTable
