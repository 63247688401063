import { Reducer } from 'redux'
import { IInstitutionState, InstitutionActionTypes } from './types' // { IEvent }
import Institution from '../../application/models/financial.agents/institution/institution'

export const INITIAL_STATE: IInstitutionState = {
    create: {
        institution: new Institution(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    remove: {
        loading: false,
        error: false,
        success: false,
        removeVisibilityModal: false,
        idForRemove: ''
    },
    list: {
        institutions: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 20,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

const reducer: Reducer<IInstitutionState> = (
    state: IInstitutionState = INITIAL_STATE,
    action: any
) => {
    switch (action.type) {

        case InstitutionActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, loading: true, paginator } }

        case InstitutionActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }

        case InstitutionActionTypes.CHANGE_INSTITUTION:
            const createInstitution: Institution = action.payload.institution
            return {
                ...state,
                create: { ...state.create, institution: createInstitution }
            }

        case InstitutionActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case InstitutionActionTypes.CREATE_REQUEST:
            const { institution } = action.payload
            return { ...state, create: { ...state.create, institution, loading: true } }

        case InstitutionActionTypes.CREATE_SUCCESS:
            return { ...state, create: { ...INITIAL_STATE.create, success: true, dialog: true } }

        case InstitutionActionTypes.CREATE_FAILURE:
            const createError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: createError
                }
            }

        case InstitutionActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case InstitutionActionTypes.LOAD_SUCCESS:
            const { institutions, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    institutions: institutions.map(institutionJson => new Institution().fromJSON(institutionJson)),
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case InstitutionActionTypes.LOAD_FAILURE:
            const loadError = action.payload.error
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case InstitutionActionTypes.RESET_CREATE_INSTITUTION:
            return { ...state, create: INITIAL_STATE.create }

        case InstitutionActionTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    removeVisibilityModal: visibilityModal,
                    idForRemove
                }
            }

        case InstitutionActionTypes.FIND_REQUEST:
            const { institutionId } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    institution: new Institution().fromJSON({ id: institutionId })
                }
            }

        case InstitutionActionTypes.FIND_SUCCESS:
            const findInstitution = action.payload.institution
            return {
                ...state,
                create: {
                    ...state.create,
                    institution: new Institution().fromJSON(findInstitution),
                    loading: false,
                    success: true
                }
            }

        case InstitutionActionTypes.FIND_FAILURE:
            const findError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    institution: new Institution(),
                    loading: false,
                    error: findError
                }
            }

        case InstitutionActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    removeVisibilityModal: false
                }
            }

        case InstitutionActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: { ...state.remove, loading: false, success: true }
            }

        case InstitutionActionTypes.REMOVE_FAILURE:
            const removeError = action.payload.error
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: false,
                    error: true,
                    data: removeError
                }
            }

        default:
            return state
    }
}

export default reducer
