import { InstitutionalExpense } from '../institutional.expense'
import { ExpenseStatus } from '../transaction'

export enum InvestmentsType {
    COVENANTS = 'covenants',
    DIRECT_INVESTMENTS = 'direct_investments',
    GENERAL_FINANCE_CHARGES = 'general_finance_charges',
    FUNDS = 'funds',
    PROGRAMS = 'programs'
}

export enum DescriptionEmpty {
    EMPTY = 'empty'
}

export default class Investments extends InstitutionalExpense {
    private _investment_type: InvestmentsType
    private _status: ExpenseStatus

    constructor() {
        super();
        this.id = ''
        this.created_at = ''
        this.description = ''
        this.date = ''
        this._investment_type = '' as InvestmentsType
        this._status = '' as ExpenseStatus
        this.institution_id = ''
    }

    get investment_type(): InvestmentsType {
        return this._investment_type
    }

    set investments_type(value: InvestmentsType) {
        this._investment_type = value
    }

    get status(): ExpenseStatus {
        return this._status
    }

    set status(value: ExpenseStatus) {
        this._status = value
    }

    public fromJSON(json: any): Investments {
        super.fromJSON(json)

        if (json?.investment_type !== undefined) {
            this._investment_type = json.investment_type
        }

        if (json?.status !== undefined) {
            this._status = json.status
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            investment_type: this.investment_type ? this.investment_type : undefined,
            status: this.status ? this.status : undefined
        }
    }
}
