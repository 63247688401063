import { IComponentState, IPaginator } from '../root.types'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'

export enum SupplementaryBudgetTypes {
    RESET = '@supplementary.budget/RESET',

    CHANGE_SUPPLEMENTARY = '@supplementary.budget/CHANGE_SUPPLEMENTARY',
    CHANGE_PAGINATOR = '@supplementary.budget/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@supplementary.budget/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@supplementary.budget/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_CREATE = '@supplementary.budget/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@supplementary.budget/CREATE_REQUEST',
    CREATE_SUCCESS = '@supplementary.budget/CREATE_SUCCESS',
    CREATE_FAILURE = '@supplementary.budget/CREATE_FAILURE',

    LOAD_REQUEST = '@supplementary.budget/LOAD_REQUEST',
    LOAD_SUCCESS = '@supplementary.budget/LOAD_SUCCESS',
    LOAD_FAILURE = '@supplementary.budget/LOAD_FAILURE',

    FIND_REQUEST = '@supplementary.budget/FIND_REQUEST',
    FIND_SUCCESS = '@supplementary.budget/FIND_SUCCESS',
    FIND_FAILURE = '@supplementary.budget/FIND_FAILURE',

    UPDATE_REQUEST = '@supplementary.budget/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@supplementary.budget/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@supplementary.budget/UPDATE_FAILURE',

    REMOVE_REQUEST = '@supplementary.budget/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@supplementary.budget/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@supplementary.budget/REMOVE_FAILURE'
}

/* Interface Actions */
export interface IActionCreate {
    readonly supplementary: SupplementaryBudget
    readonly paginator: IPaginator
}

export interface IActionLoad {
    readonly payeeId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly payeeId: string
    readonly supplementaryId: string
}

export interface IActionRemove {
    readonly payeeId: string
    readonly supplementaryId: string
    readonly paginator: IPaginator
}

/* Interfaces Component State */
export interface ICreateState extends IComponentState {
    readonly supplementary: SupplementaryBudget
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly supplementaries: SupplementaryBudget[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly payeeId: string
    readonly supplementaryId: string
    readonly visibilityModal: boolean
}

/* State Type */
export interface ISupplementaryBudgetState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
