import httpClient from './config.axios'
import { AxiosResponse } from 'axios'
import User, { UserTypes } from '../store/application/models/auth/user'
import localStorageService from './local.storage'
import authService from './auth'
import { IPaginator } from '../store/ducks/root.types'


class UserService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(newUser: User) {
        return httpClient.post(`${this.apiVersion}/${newUser.type}s`, newUser.toJSON())
    }

    public remove(userId: string) {
        return httpClient.delete(`${this.apiVersion}/users/${userId}`)
    }

    public updateAccess(user: User) {
        const body = { new_access_exp_date: user.access_exp_date }
        return httpClient.put(`${this.apiVersion}/users/${user.id}/update-access`, body)
    }

    public update(user: User) {
        const body = user.toJSON()
        delete body.access_exp_date
        return httpClient
            .patch(`${this.apiVersion}/${user.type}s/${user.id}`, body)
    }

    public getUser(type: UserTypes, userId: string) {
        return httpClient.get(`${this.apiVersion}/${type}s/${userId}`)
            .then((response: AxiosResponse) => response.data)
    }

    public getAll(type: UserTypes, paginator?: IPaginator) {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (paginator?.search?.key && paginator?.search?.value) {
                if (paginator.search.key === 'name') {
                    params.append(paginator.search.key, `*${paginator.search.value}*`)
                }
                if (paginator.search.key === 'cpf') {
                    const cpf = paginator.search.value
                        .replace(/[.]/g, '')
                        .replace(/[-]/g, '')
                        .replace(/[_]/g, '')
                    params.append(paginator.search.key, `*${cpf}*`)
                }
            }
        }

        return httpClient.get(`${this.apiVersion}/${type}s`, { params })
            .then((response: AxiosResponse) => {
                return { data: response.data, headers: response.headers }
            })
    }

    public async getUserName(): Promise<string> {
        const localUserString: string = localStorageService.getItem('user')
        if (localUserString) {
            const localUserObject: User = JSON.parse(localUserString)
            return localUserObject.name ? localUserObject.name : ''
        }
        const localToken = authService.decodeToken()
        if (localToken && localToken.sub) {
            const userLogged = await this.getUser(localToken.sub_type, localToken.sub)
            localStorageService.setItem('user', JSON.stringify(userLogged))
            return userLogged.name
        }
        throw Error('Não foi possivel buscar nome do usuário!')
    }

}

export default new UserService()

