import { all, apply, put, takeLatest } from 'redux-saga/effects'

import {
    createFailure,
    createSuccess,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateSuccess
} from './actions'
import { IActionType, IAxiosResponse, IPaginator } from '../root.types'
import { Toast } from '../../../services/toast'
import { ConferenceRevenueActionTypes, IActionCreate, IActionLoad, IActionRemove } from './types'
import conferenceService from '../../../services/conference.transaction'
import { ConferenceTransactionType } from '../../application/utils/conference.transaction.type'
import { ConferenceItem } from '../../application/models/disbursement/conference.item'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: ConferenceItem = yield apply(
            conferenceService,
            conferenceService.create,
            [data, ConferenceTransactionType.REVENUE]
        )
        yield put<any>(createSuccess(response))
        toastService.show('success', 'Conferência salva com sucesso', '')
    } catch (err) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { year, paginator } = action.payload
        let totalItems: number = 0
        let totalLength: number = 0
        let index: number = 0
        do {
            const currentPaginator: IPaginator = {
                ...paginator,
                page: index
            }
            const response: IAxiosResponse<ConferenceItem[]> = yield apply(
                conferenceService,
                conferenceService.getAllByYear,
                [ConferenceTransactionType.REVENUE, year, currentPaginator]
            )
            totalLength += response?.data?.length || 0
            totalItems = parseInt(response.headers['x-total-count'], 10)
            yield put(
                loadSuccess(
                    response,
                    index === 0,
                    totalLength >= totalItems
                )
            )
            index++
        } while (totalItems > totalLength)
    } catch (err) {
        yield put(loadFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { ids } = action.payload
        for (const id of ids) {
            yield apply(conferenceService, conferenceService.remove, [ConferenceTransactionType.REVENUE, id])
        }
        yield put<any>(removeSuccess(ids))
        toastService.show('success', 'Conferência removida com sucesso', '')
    } catch (err) {
        yield put(removeFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: ConferenceItem = yield apply(
            conferenceService,
            conferenceService.update,
            [data, ConferenceTransactionType.REVENUE]
        )
        yield put<any>(updateSuccess(response))
        toastService.show('success', 'Conferência salva com sucesso', '')
    } catch (err) {
        yield put(createFailure())
    }
}

export default function* conferenceRevenueSaga() {
    return yield all([
        takeLatest(ConferenceRevenueActionTypes.CREATE_REQUEST, create),
        takeLatest(ConferenceRevenueActionTypes.LOAD_REQUEST, getAll),
        takeLatest(ConferenceRevenueActionTypes.REMOVE_REQUEST, remove),
        takeLatest(ConferenceRevenueActionTypes.UPDATE_REQUEST, update)
    ])
}
