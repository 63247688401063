import { action } from 'typesafe-actions'

import { IActionLoadResponse, ScenarioActionTypes } from './types'
import { IPaginator } from '../root.types'
import ScenarioDataModel from '../../application/models/scenario/scenario.data.model'
import Budget from '../../application/models/budget/budget'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'
import Exercise from '../../application/models/exercise/exercise'
import Payee from '../../application/models/financial.agents/payee/payee'

const PAGINATOR: IPaginator = {
    first: 0,
    rows: 100,
    page: 0,
    pageCount: 0,
    totalRecords: 0,
    search: { key: '', value: '' }
}

export const reset = () => {
    return action(ScenarioActionTypes.RESET, {})
}

export const changeDialogCreate = (dialog: boolean) => {
    return action(ScenarioActionTypes.CHANGE_DIALOG, { dialog })
}

export const changeScenario = (scenario: ScenarioDataModel) => {
    return action(ScenarioActionTypes.CHANGE_SCENARIO, { scenario })
}

export const changePaginator = (exerciseId: string, paginator: IPaginator) => [
    action(ScenarioActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadScenarios(exerciseId, paginator)
]

/* Create Scenario */
export const create = (scenario: ScenarioDataModel) => {
    return action(ScenarioActionTypes.CREATE_REQUEST, { scenario })
}

export const createSuccess = (scenario: ScenarioDataModel) => {
    return action(ScenarioActionTypes.CREATE_SUCCESS, { scenario })
}

export const createFailure = (error: ErrorEvent) => {
    return action(ScenarioActionTypes.CREATE_FAILURE, { error })
}

/* Update Scenario */
export const update = (scenario: ScenarioDataModel) => {
    return action(ScenarioActionTypes.UPDATE_REQUEST, { scenario })
}

export const updateSuccess = (scenario: ScenarioDataModel) => {
    return action(ScenarioActionTypes.UPDATE_SUCCESS, { scenario })
}

export const updateFailure = (error: ErrorEvent) => {
    return action(ScenarioActionTypes.UPDATE_FAILURE, { error })
}

/* Remove Scenario */
export const changeRemoveModal = (dialog: boolean, idForRemove: string) => {
    return action(ScenarioActionTypes.CHANGE_REMOVE_MODAL, { dialog, idForRemove })
}

export const remove = (exerciseId: string, scenarioId: string) => {
    return action(ScenarioActionTypes.REMOVE_REQUEST, { exerciseId, scenarioId })
}

export const removeSuccess = (exerciseId: string) => {
    return [
        action(ScenarioActionTypes.REMOVE_SUCCESS, {}),
        loadScenarios(exerciseId)
    ]
}

export const removeFailure = (error: ErrorEvent) => {
    return action(ScenarioActionTypes.REMOVE_FAILURE, { error })
}

/* Load All Scenarios */
export const loadScenarios = (exerciseId: string, paginator?: IPaginator) => action(
    ScenarioActionTypes.LOAD_ALL_REQUEST,
    { exerciseId, paginator }
)


export const loadScenariosSuccess = (scenarios: ScenarioDataModel[]) => {
    return action(ScenarioActionTypes.LOAD_ALL_SUCCESS, { scenarios })
}

export const loadScenariosFailure = (error: ErrorEvent) => {
    return action(ScenarioActionTypes.LOAD_ALL_FAILURE, { error })
}

export const load = (date: string) => {
    return action(ScenarioActionTypes.LOAD_REQUEST, { date, paginator: PAGINATOR })
}

/* Load Annual Costing */
export const loadCostingSuccess = (response: IActionLoadResponse) => {
    const { annualCosting } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_COSTING_SUCCESS, { annualCosting })
}

export const loadCostingFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_COSTING_FAILURE,
    { error }
)

/* Load Annual Debt */
export const loadDebtSuccess = (response: IActionLoadResponse) => {
    const { annualDebt } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_DEBT_SUCCESS, { ...annualDebt })
}

export const loadDebtFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_DEBT_FAILURE,
    { error }
)

/* Load Annual Event */
export const loadEventSuccess = (response: IActionLoadResponse) => {
    const { annualEvent } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_EVENT_SUCCESS, { annualEvent })
}

export const loadEventFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_EVENT_FAILURE,
    { error }
)

/* Load Annual Investment */
export const loadInvestmentSuccess = (response: IActionLoadResponse) => {
    const { annualInvestment } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_INVESTMENT_SUCCESS, { ...annualInvestment })
}

export const loadInvestmentFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_INVESTMENT_FAILURE,
    { error }
)

/* Load Annual Expense */
export const loadExpenseSuccess = (response: IActionLoadResponse) => {
    const { annualExpense } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_EXPENSE_SUCCESS, { annualExpense })
}

export const loadExpenseFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_EXPENSE_FAILURE,
    { error }
)

/* Load Annual Revenue */
export const loadRevenueSuccess = (response: IActionLoadResponse) => {
    const { annualRevenue } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_REVENUE_SUCCESS, { annualRevenue })
}

export const loadPrevRevenueSuccess = (response: IActionLoadResponse) => {
    const { annualRevenue } = response
    return action(ScenarioActionTypes.LOAD_PREV_REVENUE_SUCCESS, { annualRevenue })
}

export const loadRevenueFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_REVENUE_FAILURE,
    { error }
)

/* Load Monthly Balance */
export const loadBalanceSuccess = (response: IActionLoadResponse) => {
    const { monthlyBalance } = response
    return action(ScenarioActionTypes.LOAD_CURRENT_BALANCE_SUCCESS, { monthlyBalance })
}

export const loadBalanceFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_CURRENT_BALANCE_FAILURE,
    { error }
)


export const loadPrevRevenueFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_PREV_REVENUE_FAILURE,
    { error }
)

/* Load Balance Agents for Duodecimos */
export const loadDuodecimos = (exerciseSelected: Exercise) => action(
    ScenarioActionTypes.LOAD_DUODECIMOS_REQUEST,
    { paginator: PAGINATOR, exerciseSelected }
)


export const loadDuodecimosSuccess = (payees: Payee[], exerciseSelected: Exercise) => [
    action(ScenarioActionTypes.LOAD_DUODECIMOS_SUCCESS, { payees }),
    loadBudgets(payees, exerciseSelected?.id || ''),
    loadSupplementaryBudgets(payees, exerciseSelected?.date || '')
]

export const loadDuodecimosFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_DUODECIMOS_FAILURE,
    { error }
)

/* Load Budget for Duodecimos */
export const loadBudgets = (payees: Payee[], exerciseId: string) => action(
    ScenarioActionTypes.LOAD_BUDGETS_REQUEST,
    { payees, exerciseId }
)


export const loadBudgetsSuccess = (budgets: Map<string, Budget>) => {
    return action(ScenarioActionTypes.LOAD_BUDGETS_SUCCESS, { budgets })
}

export const loadBudgetsFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_BUDGETS_FAILURE,
    { error }
)

/* Load SupplementaryBudget for Duodecimos */
export const loadSupplementaryBudgets = (payees: Payee[], date: string) => action(
    ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_REQUEST,
    { payees, date }
)

export const loadSupplementaryBudgetsSuccess = (supplementaries: Map<string, SupplementaryBudget[]>) => {
    return action(ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_SUCCESS, { supplementaries })
}

export const loadSupplementaryBudgetsFailure = (error: ErrorEvent) => action(
    ScenarioActionTypes.LOAD_SUPPLEMENTARY_BUDGETS_FAILURE,
    { error }
)
