import { Reducer } from 'redux'
import { BalanceTypes, IBalancesState } from './types'
import Balance from '../../application/models/balance/balance'
import BalanceSummary from '../../application/models/balance/balance.summary'

export const INITIAL_STATE: IBalancesState = {
    createBalance: {
        balance: new Balance(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listBalances: {
        balances: [],
        data: new ErrorEvent(''),
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        balanceAgents: undefined
    },
    removeBalance: {
        idForRemove: '',
        balanceAgentId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    dailyBalances: {
        summary: new BalanceSummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    monthlyBalances: {
        monthlyBalances: new BalanceSummary(),
        data: new ErrorEvent(''),
        lastSync: undefined,
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annualBalances: {
        annualBalances: new BalanceSummary(),
        data: new ErrorEvent(''),
        lastSync: undefined,
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

const reducer: Reducer<IBalancesState> = (state: IBalancesState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case BalanceTypes.RESET_BALANCE: {
            return {
                ...state,
                createBalance: INITIAL_STATE.createBalance
            }
        }

        case BalanceTypes.RESET_MONTHLY_PAGINATOR: {
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    paginator: INITIAL_STATE.monthlyBalances.paginator
                }
            }
        }

        case BalanceTypes.RESET_ANNUAL_PAGINATOR: {
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    paginator: INITIAL_STATE.annualBalances.paginator
                }
            }
        }

        case BalanceTypes.CHANGE_BALANCE:
            const { balance: changeBalance, dialog: changeDialog } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    loading: false,
                    error: false,
                    success: false,
                    balance: new Balance().fromJSON(changeBalance),
                    dialog: changeDialog
                }
            }

        case BalanceTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, createBalance: { ...state.createBalance, dialog } }

        case BalanceTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    paginator
                }
            }

        case BalanceTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    paginator: {
                        ...state.listBalances.paginator,
                        search
                    }
                }
            }

        case BalanceTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, balanceAgentId } = action.payload
            return {
                ...state,
                removeBalance: {
                    ...state.removeBalance,
                    visibilityModal,
                    idForRemove,
                    balanceAgentId
                }
            }

        case BalanceTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    paginator: monthlyPaginator
                }
            }

        case BalanceTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    paginator: annualPaginator
                }
            }

        case BalanceTypes.CREATE_REQUEST:
            const { balance: dataForCreate } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    balance: dataForCreate,
                    loading: true
                }
            }

        case BalanceTypes.CREATE_SUCCESS:
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    balance: new Balance(),
                    success: false,
                    loading: false,
                    dialog: false
                }
            }

        case BalanceTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    loading: false,
                    success: false,
                    error: true,
                    data: createError
                }
            }

        case BalanceTypes.LOAD_REQUEST:
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    loading: true
                }
            }

        case BalanceTypes.LOAD_SUCCESS:
            const { balances: loadBalances, headers } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    loading: false,
                    success: false,
                    balances: loadBalances,
                    paginator: {
                        ...state.listBalances.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case BalanceTypes.FIND_REQUEST:
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    loading: true
                }
            }

        case BalanceTypes.FIND_SUCCESS:
            const { balance: balanceFound } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    balance: new Balance().fromJSON(balanceFound),
                    loading: false
                }
            }

        case BalanceTypes.FIND_FAILURE:
            const { error: findError } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    loading: false,
                    success: false,
                    error: true,
                    data: findError
                }
            }

        case BalanceTypes.UPDATE_REQUEST:
            const { balance: balanceUpdate } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    balance: balanceUpdate,
                    loading: true
                }
            }

        case BalanceTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    balance: new Balance(),
                    loading: false
                }
            }

        case BalanceTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                createBalance: {
                    ...state.createBalance,
                    loading: false,
                    success: false,
                    error: true,
                    data: updateError
                }
            }

        case BalanceTypes.REMOVE_REQUEST:
            return {
                ...state,
                removeBalance: {
                    ...state.removeBalance,
                    loading: true,
                    visibilityModal: false
                }
            }

        case BalanceTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeBalance: {
                    ...state.removeBalance,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case BalanceTypes.REMOVE_FAILURE:
            const { error: removeError } = action.payload
            return {
                ...state,
                removeBalance: {
                    ...state.removeBalance,
                    loading: false,
                    success: false,
                    visibilityModal: true,
                    error: true,
                    data: removeError
                }
            }

        case BalanceTypes.FIND_BALANCE_AGENT_REQUEST:
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    balanceAgents: undefined
                }
            }

        case BalanceTypes.FIND_BALANCE_AGENT_SUCCESS:
            const { balanceAgents } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    balanceAgents
                }
            }

        case BalanceTypes.FIND_BALANCE_AGENT_FAILURE:
            const { error: findBankAccountsError } = action.payload
            return {
                ...state,
                listBalances: {
                    ...state.listBalances,
                    error: findBankAccountsError
                }
            }

        case BalanceTypes.LOAD_DAILY_REQUEST:
            const { paginator: dailyPaginator } = action.payload
            return {
                ...state,
                dailyBalances: {
                    ...state.dailyBalances,
                    loading: true,
                    paginator: dailyPaginator
                }
            }

        case BalanceTypes.LOAD_DAILY_SUCCESS_RESET:
            const { headers: dailyHeadersReset, summary: dailyBalancesReset } = action.payload
            return {
                ...state,
                dailyBalances: {
                    ...state.dailyBalances,
                    loading: false,
                    success: false,
                    error: false,
                    summary: new BalanceSummary().fromJSON(dailyBalancesReset),
                    lastSync: new Date(),
                    paginator: {
                        ...state.dailyBalances.paginator,
                        totalRecords: parseInt(dailyHeadersReset['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_DAILY_SUCCESS_CONCAT:
            const { headers: dailyHeadersConcat, summary: dailyBalancesConcat } = action.payload
            return {
                ...state,
                dailyBalances: {
                    ...state.dailyBalances,
                    loading: false,
                    success: false,
                    error: false,
                    summary: state.dailyBalances.summary.concatFromJSON(dailyBalancesConcat),
                    paginator: {
                        ...state.dailyBalances.paginator,
                        totalRecords: parseInt(dailyHeadersConcat['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_DAILY_FAILURE:
            const { error: dailyError } = action.payload
            return {
                ...state,
                dailyBalances: {
                    ...state.dailyBalances,
                    summary: INITIAL_STATE.dailyBalances.summary,
                    loading: false,
                    error: true,
                    data: dailyError
                }
            }

        case BalanceTypes.LOAD_MONTHLY_REQUEST:
            const { paginator: monthlyRequestPaginator } = action.payload
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    loading: true,
                    paginator: monthlyRequestPaginator
                }
            }

        case BalanceTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyHeadersReset, monthlyBalances: monthlyBalancesReset } = action.payload
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    loading: false,
                    success: false,
                    error: false,
                    monthlyBalances: new BalanceSummary().fromJSON(monthlyBalancesReset),
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyBalances.paginator,
                        totalRecords: parseInt(monthlyHeadersReset['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyHeadersConcat, monthlyBalances: monthlyBalancesConcat } = action.payload
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    loading: false,
                    success: false,
                    error: false,
                    monthlyBalances: state.monthlyBalances.monthlyBalances.concatFromJSON(monthlyBalancesConcat),
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyBalances.paginator,
                        totalRecords: parseInt(monthlyHeadersConcat['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthlyBalances: {
                    ...state.monthlyBalances,
                    monthlyBalances: INITIAL_STATE.monthlyBalances.monthlyBalances,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case BalanceTypes.LOAD_ANNUAL_REQUEST:
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    loading: true
                }
            }

        case BalanceTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const { headers: annualHeadersReset, annualBalances: annualBalancesReset } = action.payload
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    loading: false,
                    success: false,
                    error: false,
                    annualBalances: new BalanceSummary().fromJSON(annualBalancesReset),
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualBalances.paginator,
                        totalRecords: parseInt(annualHeadersReset['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const { headers: annualHeadersConcat, annualBalances: annualBalancesConcat } = action.payload
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    loading: false,
                    success: false,
                    error: false,
                    annualBalances: state.annualBalances.annualBalances.concatFromJSON(annualBalancesConcat),
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualBalances.paginator,
                        totalRecords: parseInt(annualHeadersConcat['x-total-count'], 10)
                    }
                }
            }

        case BalanceTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annualBalances: {
                    ...state.annualBalances,
                    annualBalances: INITIAL_STATE.annualBalances.annualBalances,
                    loading: false,
                    success: false,
                    error: true,
                    data: annualError
                }
            }

        default:
            return state
    }
}

export default reducer
