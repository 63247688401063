/**
 * Action types
 */
import Institution from '../../application/models/financial.agents/institution/institution'
import { IPaginator } from '../root.types'

export enum InstitutionActionTypes {
    RESET_CREATE_INSTITUTION = '@institutions/RESET_CREATE_INSTITUTION',
    CHANGE_INSTITUTION = '@institutions/CHANGE_INSTITUTION',
    CHANGE_DIALOG = '@institutions/CHANGE_DIALOG',
    CHANGE_REMOVE_MODAL = '@institutions/CHANGE_REMOVE_MODAL',
    CHANGE_PAGINATOR = '@institutions/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@institutions/CHANGE_SEARCH_PAGINATOR',

    CREATE_REQUEST = '@institutions/CREATE_REQUEST',
    CREATE_SUCCESS = '@institutions/CREATE_SUCCESS',
    CREATE_FAILURE = '@institutions/CREATE_FAILURE',

    FIND_REQUEST = '@institutions/FIND_REQUEST',
    FIND_SUCCESS = '@institutions/FIND_SUCCESS',
    FIND_FAILURE = '@institutions/FIND_FAILURE',

    UPDATE_REQUEST = '@institutions/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@institutions/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@institutions/UPDATE_FAILURE',

    REMOVE_REQUEST = '@institutions/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@institutions/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@institutions/REMOVE_FAILURE',

    LOAD_REQUEST = '@institutions/LOAD_REQUEST',
    LOAD_SUCCESS = '@institutions/LOAD_SUCCESS',
    LOAD_FAILURE = '@institutions/LOAD_FAILURE',
}

interface IComponentState {
    readonly loading: boolean
    readonly error: boolean,
    readonly success: boolean
}

interface ICreateState extends IComponentState {
    readonly institution: Institution
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly institutions: Institution[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly removeVisibilityModal: boolean
    readonly idForRemove: string
}

/**
 * State type
 */
export interface IInstitutionState {
    readonly create: ICreateState
    readonly remove: IRemoveState
    readonly list: IListState
}
