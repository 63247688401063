import { lazy } from 'react'

const DailySummary = lazy(() => import('../containers/committed.balances/daily'))
const DaySelected = lazy(() => import('../containers/committed.balances/day.selected'))
const MonthlySummary = lazy(() => import('../containers/committed.balances/monthly'))

export const COMMITTED_BALANCES_ROUTES = [
    {
        path: '/app/committed_balances/daily',
        component: DailySummary,
        exact: true,
        private: true
    },
    {
        path: '/app/committed_balances/daily/:date',
        component: DaySelected,
        exact: true,
        private: true
    },
    {
        path: '/app/committed_balances/monthly',
        component: MonthlySummary,
        exact: true,
        private: true
    }
]
