import { JsonUtils } from '../../utils/json.util'
import { DisbursementType } from '../../utils/disbursement.type'

/**
 * Implementation of the Liberation Transaction entity.
 * @category Application
 * @subcategory Model
 *
 * @param {string} [description] Disbursement transaction description.
 * @param {string} [date] Disbursement transaction date.
 * @param {string} [value] Disbursement transaction value.
 * @param {string} [institution_id] Institution identifier.
 * @param {string} [financial_agent_id] Institution identifier.
 * @param {string} [institution_id] Institution identifier.
 * @param {DisbursementType} [type] Type liberation identifier.
 */
export class LiberationTransaction {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _description: string | undefined
    private _date: string | undefined
    private _value: number | undefined
    private _institution_id: string | undefined
    private _financial_agent_id: string | undefined
    private _type: DisbursementType | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get description(): string | undefined {
        return this._description
    }

    set description(value: string | undefined) {
        this._description = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get institution_id(): string | undefined {
        return this._institution_id
    }

    set institution_id(value: string | undefined) {
        this._institution_id = value
    }

    get financial_agent_id(): string | undefined {
        return this._financial_agent_id
    }

    set financial_agent_id(value: string | undefined) {
        this._financial_agent_id = value
    }

    get type(): DisbursementType | undefined {
        return this._type
    }

    set type(value: DisbursementType | undefined) {
        this._type = value
    }

    /**
     * Method use for receive a item from JSON, and pass for the attributes this value.
     * @function
     * @param {json} json Format the item is JSON
     * @returns {LiberationTransaction}
     */
    public fromJSON(json: any): LiberationTransaction {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }
        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }
        if (json.description !== undefined) {
            this.description = json.description
        }
        if (json.date !== undefined) {
            this.date = json.date
        }
        if (json.value !== undefined) {
            this.value = json.value
        }
        if (json.institution_id !== undefined) {
            this.institution_id = json.institution_id
        }
        if (json.financial_agent_id !== undefined) {
            this.financial_agent_id = json.financial_agent_id
        }
        if (json.type !== undefined) {
            this.type = json.type
        }

        return this
    }

    /**
     * Method use for return an Object {@link LiberationTransaction} to JSON.
     * @function
     * @returns {any}
     */
    public toJSON(): any {
        return {
            id: this.id,
            created_at: this.created_at,
            updated_at: this.updated_at,
            description: this.description,
            date: this.date,
            value: this.value,
            type: this.type,
            institution_id: this.institution_id,
            financial_agent_id: this.financial_agent_id
        }
    }
}
