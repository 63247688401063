import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Conference Disbursement Item entity.
 *
 */
export class DisbursementConferenceItem {
    private _date: string | undefined
    private _revenue: number | undefined
    private _expense: number | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get revenue(): number | undefined {
        return this._revenue
    }

    set revenue(value: number | undefined) {
        this._revenue = value
    }

    get expense(): number | undefined {
        return this._expense
    }

    set expense(value: number | undefined) {
        this._expense = value
    }

    public fromJSON(json: any): DisbursementConferenceItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.date !== undefined) {
            this.date = json.date
        }
        if (json.revenue !== undefined) {
            this.revenue = json.revenue
        }
        if (json.expense !== undefined) {
            this.expense = json.expense
        }

        return this
    }

    public toJSON(): any {
        return {
            date: this.date,
            revenue: this.revenue,
            expense: this.expense
        }
    }
}
