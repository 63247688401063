import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { IPaginator } from '../store/ducks/costing/types'
import Prevision from '../store/application/models/prevision/prevision'
import { MultiStatus } from '../store/application/models/multi.status/multi.status'
import { PrevisionTransactionTypes } from '../store/application/utils/prevision.transaction.types'

class PrevisionService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(financialAgentId: string, transactionType: PrevisionTransactionTypes, previsions: Prevision[]):
        Promise<IAxiosResponse<MultiStatus<Prevision>>> {
        const body = previsions.map(element => element.toJSON())
        const url = `${this.apiVersion}/financialagents/${financialAgentId}/previsions/${transactionType}`
        return httpClient.post(url, body)
    }

    public getAll(financialAgentId: string, transactionType: PrevisionTransactionTypes, paginator?: IPaginator):
        Promise<IAxiosResponse<Prevision[]>> {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (paginator?.search?.key && paginator?.search?.value) {
                params.append(paginator.search.key, `gte:${paginator.search.value}`)
                params.append(paginator.search.key, `lt:${Number(paginator.search.value) + 1}`)
            }
        }

        const url = `${this.apiVersion}/financialagents/${financialAgentId}/previsions/${transactionType}`
        return httpClient.get(url, { params })
    }

    public update(prevision: Prevision): Promise<IAxiosResponse<Prevision>> {
        const body = prevision.toJSON()
        delete body.date
        delete body.debt_type
        delete body.investment_type
        const url = `${this.apiVersion}/financialagents/${prevision.financialagent_id}/previsions/${prevision.id}`
        return httpClient.patch(url, body)
    }

    public async updateMany(previsions: Prevision[]): Promise<IAxiosResponse<MultiStatus<Prevision>>> {
        const multistatus = new MultiStatus()
        for (const prevision of previsions) {
            const body = prevision.toJSON()
            delete body.date
            delete body.debt_type
            delete body.investment_type
            const url = `${this.apiVersion}/financialagents/${prevision.financialagent_id}/previsions/${prevision.id}`
            await httpClient.patch(url, body)
        }
        // tslint:disable-next-line:no-object-literal-type-assertion
        return { data: multistatus, headers: undefined } as IAxiosResponse
    }

    public remove(financialAgentId: string, previsionId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/financialagents/${financialAgentId}/previsions/${previsionId}`)
    }
}

export default new PrevisionService()

