import { Reducer } from 'redux'

import { CashFlowActionTypes, ICashFlowState } from './types'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import UtilDatePicker from '../../../components/date.picker/utils'
import BalanceSummary from '../../application/models/balance/balance.summary'

export const INITIAL_STATE: ICashFlowState = {
    month: UtilDatePicker.getCurrentMonth(),
    costing: {
        monthlyCosting: undefined,
        loading: false,
        error: false,
        success: false
    },
    debt: {
        monthlyDebt: undefined,
        loading: false,
        error: false,
        success: false
    },
    event: {
        monthlyEvent: undefined,
        loading: false,
        error: false,
        success: false
    },
    investment: {
        monthlyInvestment: undefined,
        loading: false,
        error: false,
        success: false
    },
    expense: {
        monthlyExpense: undefined,
        loading: false,
        error: false,
        success: false
    },
    revenue: {
        monthlyRevenue: undefined,
        loading: false,
        error: false,
        success: false
    },
    balance: {
        monthlyBalance: undefined,
        loading: false,
        error: false,
        success: false
    },
    paginator: {
        first: 0,
        rows: 100,
        page: 0,
        pageCount: 0,
        totalRecords: 0,
        search: {
            key: '',
            value: ''
        }
    }
}

const reducer: Reducer<ICashFlowState> = (state: ICashFlowState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case CashFlowActionTypes.CHANGE_MONTH:
            const { month } = action.payload
            return { ...state, month }

        case CashFlowActionTypes.LOAD_REQUEST:
            return {
                ...state,
                costing: {
                    ...state.costing,
                    loading: true
                },
                debt: {
                    ...state.debt,
                    loading: true
                },
                event: {
                    ...state.event,
                    loading: true
                },
                investment: {
                    ...state.investment,
                    loading: true
                },
                expense: {
                    ...state.expense,
                    loading: true
                },
                revenue: {
                    ...state.revenue,
                    loading: true
                },
                balance: {
                    ...state.balance,
                    loading: true
                }
            }

        case CashFlowActionTypes.LOAD_COSTING_SUCCESS:
            const { monthlyCosting } = action.payload

            return {
                ...state,
                costing: {
                    monthlyCosting: monthlyCosting ?
                        new CostMonthlySummary().fromJSON(monthlyCosting) : state.costing.monthlyCosting,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_COSTING_FAILURE:
            return {
                ...state,
                costing: {
                    ...state.costing,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_DEBT_SUCCESS:
            const { monthlyDebt } = action.payload

            return {
                ...state,
                debt: {
                    monthlyDebt: monthlyDebt ?
                        new DebtMonthlySummary().fromJSON(monthlyDebt) : state.debt.monthlyDebt,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_DEBT_FAILURE:
            return {
                ...state,
                debt: {
                    ...state.debt,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_EVENT_SUCCESS:
            const { monthlyEvent } = action.payload

            return {
                ...state,
                event: {
                    monthlyEvent: monthlyEvent ?
                        new EventMonthlySummary().fromJSON(monthlyEvent) : state.event.monthlyEvent,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_EVENT_FAILURE:
            return {
                ...state,
                event: {
                    ...state.event,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_INVESTMENT_SUCCESS:
            const { monthlyInvestment } = action.payload

            return {
                ...state,
                investment: {
                    monthlyInvestment: monthlyInvestment ?
                        new InvestmentsMonthlySummary().fromJSON(monthlyInvestment) : state.investment.monthlyInvestment,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_INVESTMENT_FAILURE:
            return {
                ...state,
                investment: {
                    ...state.investment,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_EXPENSE_SUCCESS:
            const { monthlyExpense } = action.payload

            return {
                ...state,
                expense: {
                    monthlyExpense: monthlyExpense ?
                        new ExpenseMonthlySummary().fromJSON(monthlyExpense) : state.expense.monthlyExpense,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_EXPENSE_FAILURE:
            return {
                ...state,
                expense: {
                    ...state.expense,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_REVENUE_SUCCESS:
            const { monthlyRevenue } = action.payload

            return {
                ...state,
                revenue: {
                    monthlyRevenue: monthlyRevenue ?
                        new RevenueMonthlySummary().fromJSON(monthlyRevenue) : state.revenue.monthlyRevenue,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_REVENUE_FAILURE:
            return {
                ...state,
                revenue: {
                    ...state.revenue,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        case CashFlowActionTypes.LOAD_BALANCE_SUCCESS:
            const { monthlyBalance } = action.payload

            return {
                ...state,
                balance: {
                    monthlyBalance: monthlyBalance ?
                        new BalanceSummary().fromJSON(monthlyBalance) : state.balance.monthlyBalance,
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case CashFlowActionTypes.LOAD_BALANCE_FAILURE:
            return {
                ...state,
                balance: {
                    ...state.balance,
                    loading: false,
                    error: true,
                    data: action.payload.error
                }
            }

        default:
            return state
    }
}

export default reducer
