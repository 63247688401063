import { all, apply, call, put, takeLatest } from 'redux-saga/effects'
import { Toast } from '../../../services/toast'
import transactionService from '../../../services/transaction'

import {
    createRevenueFailure,
    createRevenueSuccess,
    findPayerFailure,
    findPayerSuccess,
    findRevenueFailure,
    findRevenueSuccess,
    loadAnnualExecutedFailure,
    loadAnnualExecutedSuccess,
    loadRevenueAnnualConcat,
    loadRevenueAnnualFailure,
    loadRevenueAnnualReset,
    loadRevenueFailure,
    loadRevenueMonthly,
    loadRevenueMonthlyFailure,
    loadRevenueMonthlySuccessConcat,
    loadRevenueMonthlySuccessReset,
    loadRevenues,
    loadRevenueSuccess,
    removeRevenueFailure,
    removeRevenueSuccess,
    updateRevenueFailure,
    updateRevenueSuccess
} from './actions'

import { IActionAnnual, IActionMonthly, IActionType } from '../root.types'
import revenueService from '../../../services/revenue'
import {
    IActionAnnualExecuted,
    IActionCreate,
    IActionFind,
    IActionFindPayer,
    IActionLoad,
    IActionRemove,
    RevenueLocation,
    RevenuesActionsTypes
} from './types'
import payerService from '../../../services/payer'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import UtilDatePicker from '../../../components/date.picker/utils'
import { IPaginator } from '../costing/types'
import { PrevisionTransactionTypes } from '../../application/utils/prevision.transaction.types'

const toastService = Toast.getInstance()

function* refreshByLocation(location: RevenueLocation, payerId: string, date: string, paginator?: IPaginator) {
    location === RevenueLocation.BY_PAYER ?
        yield put(loadRevenues(payerId, paginator))
        : yield put(loadRevenueMonthly(date, paginator))
}

function* create(action: IActionType<IActionCreate>) {
    const { revenue, location } = action.payload
    try {
        const response = yield apply(revenueService, revenueService.create, [revenue])
        yield put<any>(createRevenueSuccess(response?.data))
        const payerId = revenue?.payer_id || ''
        const date = revenue.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, payerId, date)
        toastService.show('success', 'Receita criada com sucesso', '')
    } catch (err) {
        yield put(createRevenueFailure(err, revenue))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { payerId, revenueId } = action.payload
        const response = yield apply(revenueService, revenueService.getById, [payerId, revenueId])
        yield put(findRevenueSuccess(response))
    } catch (err) {
        yield put(findRevenueFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { payerId, paginator } = action.payload
        const response = yield apply(revenueService, revenueService.getAll, [payerId, paginator])
        yield put(loadRevenueSuccess(response))
    } catch (err) {
        yield put(loadRevenueFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { revenue, location } = action.payload
    try {
        const response = yield apply(revenueService, revenueService.update, [revenue])
        yield put<any>(updateRevenueSuccess(response.data))
        const payerId = revenue?.payer_id || ''
        const date = revenue.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, payerId, date)
        toastService.show('success', 'Receita atualizada com sucesso', '')
    } catch (err) {
        yield put(updateRevenueFailure(err, revenue))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { payerId, revenueId, paginator, date, location } = action.payload
        yield apply(revenueService, revenueService.remove, [payerId, revenueId])
        yield put(removeRevenueSuccess())
        yield call(refreshByLocation, location, payerId, date || '', paginator)
        toastService.show('success', 'Receita excluída com sucesso', '')
    } catch (err) {
        yield put(removeRevenueFailure(err))
    }
}

function* getPayer(action: IActionType<IActionFindPayer>) {
    const { payerId } = action.payload
    try {
        const response = yield apply(payerService, payerService.getById, [payerId])
        yield put(findPayerSuccess(response))
    } catch (err) {
        yield put(findPayerFailure(err))
    }
}

function* getMonthlyRevenue(action: IActionType<IActionMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getMonthly,
            [
                TransactionTypes.REVENUE,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadRevenueMonthlySuccessConcat(response))
            : yield put(loadRevenueMonthlySuccessReset(response))
    } catch (err) {
        yield put(loadRevenueMonthlyFailure(err))
    }
}

function* getAnnualRevenue(action: IActionType<IActionAnnual>) {
    const { year, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnual,
            [TransactionTypes.REVENUE, year, paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadRevenueAnnualConcat(response))
            : yield put(loadRevenueAnnualReset(response))
    } catch (err) {
        yield put(loadRevenueAnnualFailure(err))
    }
}

function* getAnnualExecuted(action: IActionType<IActionAnnualExecuted>) {
    const { year, payerId } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnualByInstitutionId,
            [
                payerId, PrevisionTransactionTypes.REVENUE, year
            ]
        )
        yield put(loadAnnualExecutedSuccess(response))
    } catch (err) {
        yield put(loadAnnualExecutedFailure(err))
    }
}

export default function* revenueSaga() {
    return yield all([
        takeLatest(RevenuesActionsTypes.CREATE_REQUEST, create),
        takeLatest(RevenuesActionsTypes.LOAD_REQUEST, getAll),
        takeLatest(RevenuesActionsTypes.FIND_REQUEST, getById),
        takeLatest(RevenuesActionsTypes.UPDATE_REQUEST, update),
        takeLatest(RevenuesActionsTypes.REMOVE_REQUEST, remove),
        takeLatest(RevenuesActionsTypes.FIND_PAYER_REQUEST, getPayer),
        takeLatest(RevenuesActionsTypes.LOAD_MONTHLY_REQUEST, getMonthlyRevenue),
        takeLatest(RevenuesActionsTypes.LOAD_ANNUAL_REQUEST, getAnnualRevenue),
        takeLatest(RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_REQUEST, getAnnualExecuted)
    ])
}
