import { JsonUtils } from '../../utils/json.util'
import { LiberationTransaction } from './liberation.transaction'
import Prevision from '../prevision/prevision'

/**
 * Implementation of the Daily Liberation Summary Item entity.
 *
 */
export class LiberationSummaryItem {
    private _id: string | undefined
    private _name: string | undefined
    private _code: string | undefined
    private _transactions: LiberationTransaction[] | undefined
    private _previsions: Prevision[] | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get code(): string | undefined {
        return this._code
    }

    set code(value: string | undefined) {
        this._code = value
    }

    get transactions(): LiberationTransaction[] | undefined {
        return this._transactions
    }

    set transactions(value: LiberationTransaction[] | undefined) {
        this._transactions = value
    }

    get previsions(): Prevision[] | undefined {
        return this._previsions
    }

    set previsions(value: Prevision[] | undefined) {
        this._previsions = value
    }

    public fromJSON(json: any): LiberationSummaryItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.name !== undefined) {
            this.name = json.name
        }
        if (json.code !== undefined) {
            this.code = json.code
        }
        if (json.transactions instanceof Array && json.transactions.length) {
            this.transactions = json
                ?.transactions
                ?.map((transactionItem: any) =>
                    new LiberationTransaction().fromJSON(transactionItem)
                ) || []
        }
        if (json.previsions instanceof Array && json.previsions.length) {
            this.previsions = json
                ?.previsions
                ?.map((previsionItem: any) =>
                    new Prevision().fromJSON(previsionItem)
                ) || []
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id,
            name: this.name,
            code: this.code,
            transactions: this.transactions?.length ?
                this.transactions.map((item: LiberationTransaction) => item.toJSON())
                : [],
            previsions: this.previsions?.length ?
                this.previsions.map((item: Prevision) => item.toJSON())
                : []
        }
    }
}
