import React, { Component } from 'react'
import './spinners.scss'

interface IProps {
    readonly message?: string
    readonly description?: string
}

class Spinner extends Component <IProps> {

    public render() {
        const { message, description } = this.props
        return (
            <div id="externa">

                <div id="interna">

                    <div className="d-flex flex-direction-column">

                        <div className="d-flex justify-content-center align-items-center">
                            <div className="lds-spinner">
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div>

                            {
                                !!message && (
                                    <div className="p-15">
                                        <p className="text-center">{message}</p>
                                    </div>
                                )
                            }
                        </div>

                        {
                            !!description && (
                                <div className="p-15">
                                    <p className="text-center">{description}</p>
                                </div>
                            )
                        }

                    </div>

                </div>

            </div>
        )
    }
}

export default Spinner

