import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { Toast } from '../../../services/toast'
import { IActionType, IAxiosResponse } from '../root.types'
import {
    createFailure,
    createSuccess,
    loadFailure,
    loadLastFailure,
    loadLastSuccess,
    loadSuccess,
    removeFailure,
    removeSuccess,
    load, loadLast
} from './actions'
import { ExerciseTypes, IActionCreate, IActionLoad, IActionRemove } from './types'
import exerciseService from '../../../services/exercise'
import Exercise from '../../application/models/exercise/exercise'

const toastService = Toast.getInstance()

function* createExercise(action: IActionType<IActionCreate>) {
    try {
        const { exercise } = action.payload
        const response: IAxiosResponse<Exercise> = yield apply(
            exerciseService,
            exerciseService.create, [exercise]
        )
        yield put<any>(createSuccess(response.data))
        toastService.show('success', 'Exercício criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<Exercise[]> = yield apply(exerciseService, exerciseService.getAll, [paginator])
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getLast() {
    try {
        const response = yield apply(exerciseService, exerciseService.getAll, [])
        yield put<any>(loadLastSuccess(response.data))
    } catch (err) {
        yield put(loadLastFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { exerciseId, paginator } = action.payload
        yield apply(exerciseService, exerciseService.remove, [exerciseId])
        yield put(removeSuccess())
        yield put(load(paginator))
        yield put(loadLast())
    } catch (err) {
        yield put(removeFailure(err))
    }
}

export default function* exerciseSaga() {
    return yield all([
        takeLatest(ExerciseTypes.CREATE_REQUEST, createExercise),
        takeLatest(ExerciseTypes.LOAD_REQUEST, getAll),
        takeLatest(ExerciseTypes.LOAD_LAST_REQUEST, getLast),
        takeLatest(ExerciseTypes.REMOVE_REQUEST, remove)
    ])
}
