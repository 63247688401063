import { JsonUtils } from '../../utils/json.util'
import Request from './audit.request';
import Response from './audit.response';
import User from '../auth/user'

export enum SuccessType {
    YES = 'yes',
    NO = 'no'
}

export default class Audit {
    private _id?: string
    private _created_at: string
    private _date?: string
    private _user?: User
    private _success?: SuccessType
    private _request?: Request
    private _response?: Response


    constructor() {
        this._id = ''
        this._created_at = ''
        this._date = ''
        this._user = new User()
        this._success = '' as SuccessType
        this._request = new Request()
        this._response = new Response()

    }

    get id(): string | undefined {
        return this._id
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get user(): User | undefined {
        return this._user
    }

    get success(): SuccessType | undefined {
        return this._success
    }

    get request(): Request | undefined {
        return this._request
    }

    get response(): Response | undefined {
        return this._response
    }

    public fromJSON(json: any): Audit {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this._id = json.id
        }

        if (json.created_at !== undefined) {
            this._created_at = json.created_at
        }

        if (json.date !== undefined) {
            this._date = json.date
        }

        if (json.user !== undefined) {
            this._user = new User().fromJSON(json.user)
        }

        if (json.success !== undefined) {
            this._success = json.success
        }

        if (json.request !== undefined) {
            this._request = new Request().fromJSON(json.request)
        }

        if (json.response !== undefined) {
            this._response = new Response().fromJSON(json.response)
        }


        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            date: this.date ? this.date : '',
            user: this._user ? this._user : undefined,
            success: this.success ? this.success : undefined,
            request: this._request ? this._request.toJSON() : undefined,
            response: this._response ? this._response.toJSON() : undefined
        }
    }
}
