import { all, apply, call, put, takeLatest } from 'redux-saga/effects'

import {
    createFailure,
    createSuccess,
    findFailure,
    findInstitutionFailure,
    findInstitutionSuccess,
    findSuccess,
    load,
    loadAnnualFailure,
    loadAnnualSuccessConcat,
    loadAnnualSuccessReset,
    loadCostingFailure,
    loadCostingSuccess,
    loadMonthly,
    loadMonthlyFailure,
    loadMonthlySuccessConcat,
    loadMonthlySuccessReset,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionType } from '../root.types'
import { Toast } from '../../../services/toast'
import {
    CostingActionTypes,
    IActionCreate,
    IActionFind,
    IActionFindInstitution,
    IActionLoad,
    IActionLoadAnnual,
    IActionLoadMonthly,
    IActionRemove,
    IPaginator,
    TransactionLocation
} from './types'
import costingService from '../../../services/costing'
import { TransactionService } from '../../../services/transaction'
import institutionService from '../../../services/institutions'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import Costing from '../../application/models/transactions/costing/costing'
import UtilDatePicker from '../../../components/date.picker/utils'
import { AnnualInstitution } from '../../application/models/financial.agents/institution/annual.institution'

const toastService = Toast.getInstance()

const transactionService = new TransactionService<AnnualInstitution, Costing>()

function* refreshByLocation(location: TransactionLocation, institutionId: string, date: string, paginator?: IPaginator) {
    location === TransactionLocation.BY_INSTITUTION ?
        yield put(load(institutionId, paginator))
        : yield put(loadMonthly(date, paginator))
}

function* create(action: IActionType<IActionCreate>) {
    const { costing, location } = action.payload
    try {
        const response = yield apply(costingService, costingService.create, [costing])
        yield put<any>(createSuccess(response?.data))
        const institutionId = costing?.institution_id || ''
        const date = costing.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, date)
        toastService.show('success', 'Custeio criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err, costing))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { institutionId, paginator } = action.payload
        const response = yield apply(costingService, costingService.getAll, [institutionId, paginator])
        yield put(loadCostingSuccess(response))
    } catch (err) {
        yield put(loadCostingFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { institutionId, costingId } = action.payload
        const response = yield apply(costingService, costingService.getById, [institutionId, costingId])
        yield put(findSuccess(response))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { institutionId, costingId, paginator, date, location } = action.payload
        yield apply(costingService, costingService.remove, [institutionId, costingId])
        yield put(removeSuccess())
        yield call(refreshByLocation, location, institutionId, date || '', paginator)
        toastService.show('success', 'Custeio removido com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { costing, location } = action.payload
    try {
        const response = yield apply(costingService, costingService.update, [costing])
        yield put<any>(updateSuccess(response.data))
        const institutionId = costing?.institution_id || ''
        const date = costing.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, date)
        toastService.show('success', 'Custeio atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err, costing))
    }
}

function* getInstitution(action: IActionType<IActionFindInstitution>) {
    const { institutionId } = action.payload
    try {
        const institution = yield apply(institutionService, institutionService.getById, [institutionId])
        const response: any = { data: institution, headers: {} }
        yield put(findInstitutionSuccess(response))
    } catch (err) {
        yield put(findInstitutionFailure(err))
    }
}

function* getMonthlyCosting(action: IActionType<IActionLoadMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.COST,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadMonthlySuccessConcat(response))
            : yield put(loadMonthlySuccessReset(response))
    } catch (err) {
        yield put(loadMonthlyFailure(err))
    }
}

function* getAnnualCosting(action: IActionType<IActionLoadAnnual>) {
    const { year, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnual, [TransactionTypes.COST, year, paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadAnnualSuccessConcat(response))
            : yield put(loadAnnualSuccessReset(response))
    } catch (err) {
        yield put(loadAnnualFailure(err))
    }
}

export default function* costingSaga() {
    return yield all([
        takeLatest(CostingActionTypes.CREATE_REQUEST, create),
        takeLatest(CostingActionTypes.LOAD_REQUEST, getAll),
        takeLatest(CostingActionTypes.FIND_REQUEST, getById),
        takeLatest(CostingActionTypes.REMOVE_REQUEST, remove),
        takeLatest(CostingActionTypes.UPDATE_REQUEST, update),
        takeLatest(CostingActionTypes.FIND_INSTITUTION, getInstitution),
        takeLatest(CostingActionTypes.LOAD_MONTHLY, getMonthlyCosting),
        takeLatest(CostingActionTypes.LOAD_ANNUAL, getAnnualCosting)
    ])
}
