import { action } from 'typesafe-actions'

import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { ExpenseActionTypes, ExpenseLocation } from './types'
import Expense from '../../application/models/transactions/expense/expense'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import Payee from '../../application/models/financial.agents/payee/payee'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'

/* Actions for Reset State */
export const resetCreateExpense = () => action(ExpenseActionTypes.RESET_EXPENSE, {})

/* Actions for Change */
export const changeExpense = (expense: Expense) => action(ExpenseActionTypes.CHANGE_EXPENSE, { expense })

export const changeDialog = (dialog: boolean) => action(ExpenseActionTypes.CHANGE_DIALOG, { dialog })

export const changePaginator = (payeeId: string, paginator: IPaginator) => [
    action(ExpenseActionTypes.CHANGE_PAGINATOR, { paginator }),
    load(payeeId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    ExpenseActionTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, payeeId: string) => action(
    ExpenseActionTypes.CHANGE_REMOVE_MODAL, { visibilityModal, idForRemove, payeeId }
)

/* Actions for Create Expense */
export const create = (expense: Expense, location: ExpenseLocation) => action(
    ExpenseActionTypes.CREATE_REQUEST,
    { expense, location }
)

export const createSuccess = (expense: Expense) => action(ExpenseActionTypes.CREATE_SUCCESS, { expense } )

export const createFailure = (error: ErrorEvent, expense: Expense) => action(ExpenseActionTypes.CREATE_FAILURE, {
    error,
    expense
})

/* Actions for Load Expense */
export const load = (payeeId: string, paginator?: IPaginator) => action(
    ExpenseActionTypes.LOAD_REQUEST, { payeeId, paginator }
)

export const loadSuccess = (response: IAxiosResponse<Expense[]>) => action(
    ExpenseActionTypes.LOAD_SUCCESS,
    { expenses: response.data, headers: response.headers }
)

export const loadFailure = (error: ErrorEvent) => action(ExpenseActionTypes.LOAD_FAILURE, { error })

/* Actions for Find Expense */
export const find = (payeeId: string, expenseId: string) => action(
    ExpenseActionTypes.FIND_REQUEST, { payeeId, expenseId }
)

export const findSuccess = (response: IAxiosResponse<Expense[]>) => action(
    ExpenseActionTypes.FIND_SUCCESS,
    { expense: response.data, headers: response.headers }
)

export const findFailure = (error: ErrorEvent) => action(ExpenseActionTypes.FIND_FAILURE, { error })

/* Actions for Remove Expense */
export const remove = (payeeId: string, expenseId: string, location: ExpenseLocation, paginator: IPaginator, date?: string) => action(
    ExpenseActionTypes.REMOVE_REQUEST, {
        payeeId,
        expenseId,
        location,
        paginator,
        date
    }
)

export const removeSuccess = () => action(ExpenseActionTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(ExpenseActionTypes.REMOVE_FAILURE, { error })

/* Actions for Update Expense */
export const update = (expense: Expense, location: ExpenseLocation) => action(
    ExpenseActionTypes.UPDATE_REQUEST,
    { expense, location }
)

export const updateSuccess = (expense: Expense) => action(ExpenseActionTypes.UPDATE_SUCCESS, { expense })

export const updateFailure = (error: ErrorEvent, expense: Expense) => action(ExpenseActionTypes.UPDATE_FAILURE, {
    error,
    expense
})

/* Actions for get Payee */
export const findPayee = (payeeId: string) => action(
    ExpenseActionTypes.FIND_PAYEE,
    { payeeId }
)

export const findPayeeSuccess = (response: IAxiosResponse<Payee>) => action(
    ExpenseActionTypes.FIND_PAYEE_SUCCESS,
    { payee: response.data, headers: response.headers }
)

export const findPayeeFailure = (error: ErrorEvent) => action(
    ExpenseActionTypes.FIND_PAYEE_FAILURE,
    { error }
)

/* Actions for Load Monthly Expense */
export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(ExpenseActionTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(ExpenseActionTypes.RESET_MONTHLY_PAGINATOR)


export const loadMonthly = (date?: string, paginator?: IPaginator) => action(
    ExpenseActionTypes.LOAD_MONTHLY, { date, paginator }
)

export const loadMonthlySuccessReset = (response: IAxiosResponse<ExpenseMonthlySummary>) => action(
    ExpenseActionTypes.LOAD_MONTHLY_SUCCESS_RESET,
    { monthlyExpense: response.data, headers: response.headers }
)

export const loadMonthlySuccessConcat = (response: IAxiosResponse<ExpenseMonthlySummary>) => action(
    ExpenseActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT,
    { monthlyExpense: response.data, headers: response.headers }
)

export const loadMonthlyFailure = (error: ErrorEvent) => action(
    ExpenseActionTypes.LOAD_MONTHLY_FAILURE,
    { error }
)

/* Actions for Load Annual Expense */
export const changeAnnualPaginator = (year: string, paginator?: IPaginator) => [
    action(ExpenseActionTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(ExpenseActionTypes.RESET_ANNUAL_PAGINATOR)


export const loadAnnual = (year: string, paginator?: IPaginator) => action(
    ExpenseActionTypes.LOAD_ANNUAL, { year, paginator }
)

export const loadAnnualSuccessReset = (response: IAxiosResponse<ExpenseAnnualSummary>) => action(
    ExpenseActionTypes.LOAD_ANNUAL_SUCCESS_RESET,
    { annualExpense: response.data, headers: response.headers }
)

export const loadAnnualSuccessConcat = (response: IAxiosResponse<ExpenseAnnualSummary>) => action(
    ExpenseActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT,
    { annualExpense: response.data, headers: response.headers }
)

export const loadAnnualFailure = (error: ErrorEvent) => action(
    ExpenseActionTypes.LOAD_ANNUAL_FAILURE,
    { error }
)
