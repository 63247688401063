import { Reducer } from 'redux'
import { ConferenceRevenueActionTypes, IConferenceRevenueState } from './types'
import { ConferenceItem } from '../../application/models/disbursement/conference.item'

export const INITIAL_STATE: IConferenceRevenueState = {
    create: {
        data: new ConferenceItem(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IConferenceRevenueState> = (state: IConferenceRevenueState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case ConferenceRevenueActionTypes.CHANGE_CREATE_DIALOG:
            const { ids, dialog, data: changedData } = action.payload
            return {
                ...state,
                create: { ...state.create, dialog, ids, data: changedData }
            }

        case ConferenceRevenueActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data }
            }

        case ConferenceRevenueActionTypes.CREATE_SUCCESS:
            const { data: newData } = action.payload
            const resultData: ConferenceItem[] = state.list.data.concat([newData])
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: newData
                },
                list: {
                    ...state.list,
                    data: resultData
                }
            }

        case ConferenceRevenueActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case ConferenceRevenueActionTypes.RESET:
            return INITIAL_STATE

        case ConferenceRevenueActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case ConferenceRevenueActionTypes.LOAD_SUCCESS:
            const {
                data: listData,
                headers: listHeaders,
                isFirst,
                isLast
            } = action.payload
            const totalRecords = parseInt(listHeaders['x-total-count'], 10)
            const paginator = {
                ...state.list.paginator,
                totalRecords
            }
            const resultListData = state.list.data.length ?
                state.list.data.concat(listData)
                : listData

            if (isFirst && isLast) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        loading: false,
                        success: true,
                        error: false,
                        data: listData,
                        paginator
                    }
                }
            }

            if (isFirst) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: resultListData,
                        paginator
                    }
                }
            }

            if (isLast) {
                return {
                    ...state,
                    list: {
                        ...state.list,
                        loading: false,
                        success: true,
                        error: false,
                        data: resultListData,
                        paginator
                    }
                }
            }

            return {
                ...state,
                list: {
                    ...state.list,
                    data: resultListData,
                    paginator
                }
            }

        case ConferenceRevenueActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case ConferenceRevenueActionTypes.CHANGE_REMOVE_DIALOG:
            const { ids: removeIds, dialog: removeDialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog: removeDialog, ids: removeIds }
            }

        case ConferenceRevenueActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case ConferenceRevenueActionTypes.REMOVE_SUCCESS:
            const { ids: removedIds } = action.payload
            const resultRemoveData: ConferenceItem[] = state.list.data
                ?.filter((item: ConferenceItem) => !removedIds.include(`${item?.id}`)) || []
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    ids: []
                },
                list: {
                    ...state.list,
                    data: resultRemoveData
                }
            }

        case ConferenceRevenueActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case ConferenceRevenueActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case ConferenceRevenueActionTypes.UPDATE_SUCCESS:
            const { data: updatedData } = action.payload
            const resultUpdateData: ConferenceItem[] = state.list.data
                ?.map((item: ConferenceItem) => {
                    if (item?.id === updatedData?.id) {
                        return updatedData
                    }
                    return item
                }) || []
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: updatedData
                },
                list: {
                    ...state.list,
                    data: resultUpdateData
                }
            }

        case ConferenceRevenueActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state;
    }
}

export default reducer
