import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Debt from '../store/application/models/transactions/debt/debt'
import { AxiosResponse } from 'axios'
import { ApiService } from './api'

class DebtService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(debt: Debt): Promise<IAxiosResponse<Debt>> {
        return httpClient.post(`${this.apiVersion}/institutions/${debt.institution_id}/debts`, debt.toJSON())
    }

    public createMany(debts: Debt[]): Promise<Array<AxiosResponse<Debt>>> {
        const promises = debts.map(debt => {
            return httpClient.post(`${this.apiVersion}/institutions/${debt.institution_id}/debts`, debt.toJSON())
        })
        return Promise.all(promises)
    }

    public getById(institutionId: string, debtId: string): Promise<IAxiosResponse<Debt>> {
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/debts/${debtId}`)
    }

    public getAll(institutionId: string, paginator?: IPaginator): Promise<IAxiosResponse<Debt[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/debts`, { params })
    }

    public update(debt: Debt): Promise<IAxiosResponse<Debt>> {
        const body = debt.toJSON()
        delete body.date
        delete body.installments
        const url = `${this.apiVersion}/institutions/${debt.institution_id}/debts/${debt.id}`
        return httpClient.patch(url, body)
    }

    public remove(institutionId: string, debtId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/institutions/${institutionId}/debts/${debtId}`)
    }
}

export default new DebtService()

