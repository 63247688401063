import Investments from './investments'
import MonthlySummary from '../../summary/monthly.summary'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'

export default class InvestmentsMonthlySummary extends MonthlySummary<MonthlyInstitution<Investments>, Investments> {
    private _total_covenants: number | undefined
    private _total_direct_investments: number | undefined
    private _total_finance_charges: number | undefined
    private _total_funds: number | undefined
    private _total_programs: number | undefined

    get total_covenants(): number | undefined {
        return this._total_covenants
    }

    set total_covenants(value: number | undefined) {
        this._total_covenants = value
    }

    get total_direct_investments(): number | undefined {
        return this._total_direct_investments
    }

    set total_direct_investments(value: number | undefined) {
        this._total_direct_investments = value
    }

    get total_finance_charges(): number | undefined {
        return this._total_finance_charges
    }

    set total_finance_charges(value: number | undefined) {
        this._total_finance_charges = value
    }

    get total_funds(): number | undefined {
        return this._total_funds
    }

    set total_funds(value: number | undefined) {
        this._total_funds = value
    }

    get total_programs(): number | undefined {
        return this._total_programs
    }

    set total_programs(value: number | undefined) {
        this._total_programs = value
    }

    public fromJSON(json: any): InvestmentsMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Investments>().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_covenants !== undefined) {
            this._total_covenants = json.total_covenants
        }

        if (json.total_direct_investments !== undefined) {
            this._total_direct_investments = json.total_direct_investments
        }

        if (json.total_finance_charges !== undefined) {
            this._total_finance_charges = json.total_finance_charges
        }

        if (json.total_funds !== undefined) {
            this._total_funds = json.total_funds
        }

        if (json.total_programs !== undefined) {
            this._total_programs = json.total_programs
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_covenants: this.total_covenants ? this.total_covenants : undefined,
            total_direct_investments: this.total_direct_investments ? this.total_direct_investments : undefined,
            total_finance_charges: this.total_finance_charges ? this.total_finance_charges : undefined,
            total_funds: this.total_funds ? this.total_funds : undefined,
            total_programs: this.total_programs ? this.total_programs : undefined
        }
    }

    public concatFromJSON(json: any): InvestmentsMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Investments>().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_covenants !== undefined) {
            this._total_covenants += json.total_covenants
        }

        if (json.total_direct_investments !== undefined) {
            this._total_direct_investments += json.total_direct_investments
        }

        if (json.total_finance_charges !== undefined) {
            this._total_finance_charges += json.total_finance_charges
        }

        if (json.total_funds !== undefined) {
            this._total_funds += json.total_funds
        }

        if (json.total_programs !== undefined) {
            this._total_programs += json.total_programs
        }

        return this
    }
}
