import React, { Component } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

import '../container.style.scss'
import { IApplicationState } from '../../store'
import * as exerciseActions from '../../store/ducks/exercise/actions'
import Spinner from '../../components/spinner/spinner'
import Exercise from '../../store/application/models/exercise/exercise'
import UtilDatePicker from '../../components/date.picker/utils'
import { MONTH_ENABLE_NEW_EXERCISE } from './dialog.blocked'
import localStorageService from '../../services/local.storage'

interface IProps {
    readonly exercises: Exercise[]
    readonly loading: boolean
    readonly error: boolean
    readonly success: boolean
    readonly exerciseSelected: Exercise
}

interface IDispatchProps extends RouteComponentProps<any> {
    change(exerciseId: string): void

    loadLast(): void

    changeDialogBlocked(dialog: boolean): void
}

type IUnionProps = IProps & IDispatchProps

export interface IExerciseItem {
    label?: string
    value: number
}

class ContainerExercise extends Component<IUnionProps> {
    private static dropdownTemplate(option) {
        if (!option.value) {
            const month = UtilDatePicker.getCurrentMonth()?.substr(5, 2)
            const title = month !== MONTH_ENABLE_NEW_EXERCISE ?
                'Funcionalidade temporariamente bloqueada'
                : 'Clique para cadastrar um novo exercício'
            const cursor = month !== MONTH_ENABLE_NEW_EXERCISE ? 'not-allowed' : 'pointer'
            return <div
                style={{ padding: '0.429em 0.857em', fontSize: '11px', cursor }}
                title={title}>
                <Button
                    disabled={month !== MONTH_ENABLE_NEW_EXERCISE}
                    className="p-button-addon p-button-small"
                    icon="pi pi-plus"/>
                Novo Exercício
            </div>
        } else {
            return <div className="center" style={{ padding: '0.429em 0.857em' }}>
                {option.label}
            </div>
        }
    }

    constructor(props: IUnionProps) {
        super(props)
        /* Bind Context */
        ContainerExercise.dropdownTemplate = ContainerExercise.dropdownTemplate.bind(this)
        this.getExercisesFormatted = this.getExercisesFormatted.bind(this)
        this.verifyLocalExerciseSelected = this.verifyLocalExerciseSelected.bind(this)
    }

    public componentDidMount() {
        this.props.loadLast()
    }

    public render() {
        const {
            exercises,
            change,
            history,
            loading,
            exerciseSelected,
            changeDialogBlocked
        } = this.props

        const exercisesFormatted = this.getExercisesFormatted(exercises)

        this.verifyLocalExerciseSelected()

        return <React.Fragment>
            {
                loading && <div style={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    zIndex: 1500
                }}><Spinner/></div>
            }

            <div className="exercise-content">
                <label className="label-bold" style={{ color: '#333333', padding: '5px' }}>
                    Exercício:
                </label>
                <Dropdown
                    value={exerciseSelected?.id}
                    options={exercisesFormatted}
                    onChange={(e) => {
                        if (e?.value) {
                            change(e.value)
                        } else {
                            const month = UtilDatePicker.getCurrentMonth()?.substr(5, 2)
                            month === MONTH_ENABLE_NEW_EXERCISE ?
                                history.push(`/app/exercises/new`)
                                : changeDialogBlocked(true)
                        }
                    }}
                    placeholder="Selecione o Exercício"
                    autoWidth={false}
                    style={{ width: '150px' }}
                    itemTemplate={ContainerExercise.dropdownTemplate}
                />
            </div>
        </React.Fragment>
    }

    private getExercisesFormatted(exercises: Exercise[]): IExerciseItem[] {
        const first: any[] = [{ value: 0 }]
        const exercisesFormatted = exercises.map((exercise: Exercise) => {
            const year = parseInt(`${exercise.date}`, 10)
            return { label: `Ano de ${year}`, value: exercise.id }
        })
        return first.concat(exercisesFormatted)
    }

    private verifyLocalExerciseSelected(): void {
        const { exerciseSelected, change } = this.props
        const exerciseSelectedId: string = localStorageService.getItem('exerciseSelected')
        if (exerciseSelectedId && exerciseSelected?.id !== exerciseSelectedId) {
            window.setTimeout(() => change(exerciseSelectedId), 100)
        }
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    exercises: state.exercise.exercises,
    exerciseSelected: state.exercise.exerciseSelected,
    loading: state.exercise.loading,
    error: state.exercise.error,
    success: state.exercise.success
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(exerciseActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerExercise))
