import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementReportBalance } from '../store/application/models/disbursement/disbursement.report.balance'
import { DisbursementTransactionType } from '../store/application/utils/disbursement.transaction.type'
import { DisbursementReportType } from '../store/application/utils/disbursement.report.type'
import { DisbursementIOReportItem } from '../store/application/models/disbursement/disbursement.io.report.item'

class DisbursementIOReportService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public getByPeriod(
        startDate: string,
        endDate: string,
        transactionType: DisbursementTransactionType,
        reportType: DisbursementReportType,
        transactionCategory?: string,
        institutionsIds?: string[]
    ): Promise<DisbursementReportBalance[]> {
        const params = new URLSearchParams()
        if (transactionCategory) {
            params.append(`type`, `${transactionCategory}`)
        }
        if (institutionsIds?.length) {
            params.append(`institution_id`, `${institutionsIds.join(',')}`)
        }
        return httpClient
            .get(
                `${this.apiVersion}/disbursement/reports/date/${startDate}/${endDate}/${transactionType}/${reportType}`,
                { params }
            )
            .then((response: IAxiosResponse<any>) =>
                response?.data?.map((item: any) => new DisbursementIOReportItem().fromJSON(item)) || []
            )
    }
}

export default new DisbursementIOReportService()

