import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { IActionType, IAxiosResponse } from '../root.types'
import { CashFlowActionTypes, IActionLoad } from './types'
import {
    loadBalanceFailure,
    loadBalanceSuccess,
    loadCostingFailure,
    loadCostingSuccess,
    loadDebtFailure,
    loadDebtSuccess,
    loadEventFailure,
    loadEventSuccess,
    loadExpenseFailure,
    loadExpenseSuccess,
    loadInvestmentFailure,
    loadInvestmentSuccess,
    loadRevenueFailure,
    loadRevenueSuccess
} from './actions'
import { TransactionService } from '../../../services/transaction'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import UtilDatePicker from '../../../components/date.picker/utils'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import BalanceSummary from '../../application/models/balance/balance.summary'
import balanceService from '../../../services/balance.summary'

const transactionService = new TransactionService()

function* load(action: IActionType<IActionLoad>) {
    const { date, paginator } = action.payload
    try {
        const responseCosting: IAxiosResponse<CostMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.COST,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        yield put(loadCostingSuccess({ monthlyCosting: responseCosting.data }))
    } catch (err) {
        yield put(loadCostingFailure(err))
    }

    try {
        const responseDebt: IAxiosResponse<DebtMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        yield put(loadDebtSuccess({ monthlyDebt: responseDebt.data }))
    } catch (err) {
        yield put(loadDebtFailure(err))
    }

    try {
        const responseEvent: IAxiosResponse<EventMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.EVENT,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ])
        yield put(loadEventSuccess({ monthlyEvent: responseEvent.data }))
    } catch (err) {
        yield put(loadEventFailure(err))
    }

    try {
        const responseExpense: IAxiosResponse<ExpenseMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.EXPENSE,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        yield put(loadExpenseSuccess({ monthlyExpense: responseExpense.data }))
    } catch (err) {
        yield put(loadExpenseFailure(err))
    }

    try {
        const responseInvestment: IAxiosResponse<InvestmentsMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        yield put(loadInvestmentSuccess({ monthlyInvestment: responseInvestment.data }))
    } catch (err) {
        yield put(loadInvestmentFailure(err))
    }

    try {
        const responseRevenue: IAxiosResponse<RevenueMonthlySummary> = yield apply(
            transactionService,
            transactionService.getMonthly,
            [
                TransactionTypes.REVENUE,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )

        yield put(loadRevenueSuccess({ monthlyRevenue: responseRevenue.data }))
    } catch (err) {
        yield put(loadRevenueFailure(err))
    }

    try {
        const responseBalance: IAxiosResponse<BalanceSummary> = yield apply(
            balanceService,
            balanceService.getMonthly,
            [
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )

        yield put(loadBalanceSuccess({ monthlyBalance: responseBalance.data }))
    } catch (err) {
        yield put(loadBalanceFailure(err))
    }
}

export default function* cashFlowSaga() {
    return yield all([
        takeLatest(CashFlowActionTypes.LOAD_REQUEST, load)
    ])
}
