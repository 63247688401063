import { action } from 'typesafe-actions'
import { LiberationActionTypes } from './types'
import { IAxiosResponse, IPaginator } from '../root.types'
import { Liberation } from '../../application/models/liberation/liberation'
import { LiberationSummary } from '../../application/models/liberation/liberation.summary'

/* Actions for Reset State */
export const reset = () => action(LiberationActionTypes.RESET)

export const changeCreateDialog = (dialog: boolean, data: Liberation) => action(
    LiberationActionTypes.CHANGE_CREATE_DIALOG, { dialog, data }
)

export const changeRemoveModal = (dialog: boolean, ids: string[]) => action(
    LiberationActionTypes.CHANGE_REMOVE_MODAL, { dialog, ids }
)

/* Actions for Create Liberation */
export const create = (data: Liberation, location: 'daily' | 'monthly') => action(
    LiberationActionTypes.CREATE_REQUEST,
    { data, location }
)

export const createSuccess = (data: Liberation, location: 'daily' | 'monthly') => [
    action(LiberationActionTypes.CREATE_SUCCESS, { data }),
    load(location, location === 'monthly' ? `${data.date?.substr(0, 7)}` : data.date!)
]

export const createFailure = () => action(LiberationActionTypes.CREATE_FAILURE)

/* Actions for Load Liberation transaction */
export const findRequest = (id: string) => action(LiberationActionTypes.FIND_REQUEST, { id })

export const findSuccess = (data: Liberation) => action(LiberationActionTypes.FIND_SUCCESS, { data })

export const findFailure = () => action(LiberationActionTypes.FIND_FAILURE)

/* Actions for Load Liberation transaction */
export const load = (type: 'daily' | 'monthly' | 'annual', date: string, paginator?: IPaginator) => action(
    LiberationActionTypes.LOAD_REQUEST,
    { type, date, paginator }
)

export const loadSuccess = (response: IAxiosResponse<LiberationSummary>) => action(
    LiberationActionTypes.LOAD_SUCCESS,
    { data: response.data, headers: response.headers }
)

export const loadFailure = () => action(LiberationActionTypes.LOAD_FAILURE)

/* Actions for Remove Liberation */
export const remove = (ids: string[], date: string) => action(
    LiberationActionTypes.REMOVE_REQUEST,
    { ids, date }
)

export const removeSuccess = (date: string) => [
    action(LiberationActionTypes.REMOVE_SUCCESS),
    load('daily', date)
]

export const removeFailure = () => action(LiberationActionTypes.REMOVE_FAILURE)

/* Actions for Update Liberation transaction */
export const update = (data: Liberation, location: 'daily' | 'monthly') => action(
    LiberationActionTypes.UPDATE_REQUEST,
    { data, location }
)

export const updateSuccess = (data: Liberation, location: 'daily' | 'monthly') => [
    action(LiberationActionTypes.UPDATE_SUCCESS, { data }),
    load(location, location === 'monthly' ? `${data.date?.substr(0, 7)}` : data.date!)
]

export const updateFailure = () => action(LiberationActionTypes.UPDATE_FAILURE)
