/**
 * Action types
 */

import { IComponentState, IPaginator } from '../root.types'
import { Liberation } from '../../application/models/liberation/liberation'
import { LiberationSummary } from '../../application/models/liberation/liberation.summary'

export enum LiberationActionTypes {
    RESET = '@liberation/RESET',
    CHANGE_CREATE_DIALOG = '@liberation/CHANGE_CREATE_DIALOG',
    CHANGE_REMOVE_MODAL = '@liberation/CHANGE_REMOVE_MODAL',

    CREATE_REQUEST = '@liberation/CREATE_REQUEST',
    CREATE_SUCCESS = '@liberation/CREATE_SUCCESS',
    CREATE_FAILURE = '@liberation/CREATE_FAILURE',

    LOAD_REQUEST = '@liberation/LOAD_REQUEST',
    LOAD_SUCCESS = '@liberation/LOAD_SUCCESS',
    LOAD_FAILURE = '@liberation/LOAD_FAILURE',

    FIND_REQUEST = '@liberation/FIND_REQUEST',
    FIND_SUCCESS = '@liberation/FIND_SUCCESS',
    FIND_FAILURE = '@liberation/FIND_FAILURE',

    UPDATE_REQUEST = '@liberation/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@liberation/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@liberation/UPDATE_FAILURE',

    REMOVE_REQUEST = '@liberation/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@liberation/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@liberation/REMOVE_FAILURE'
}

/* Actions  */

export interface IActionCreate {
    readonly data: Liberation
    readonly location: 'daily' | 'monthly'
}

export interface IActionFind {
    readonly id: string
}

export interface IActionLoad {
    readonly type: 'daily' | 'monthly' | 'annual'
    readonly date: string
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly ids: string[]
    readonly date: string
}

/* States */
interface ICreateState extends IComponentState {
    readonly data: Liberation
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly data: LiberationSummary
    readonly paginator: IPaginator
    readonly sync: boolean
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly ids: string[]
}

/**
 * State type
 */
export interface ILiberationState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
