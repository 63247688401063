import { IComponentState, IPaginator } from '../root.types'
import BalanceAgent from '../../application/models/balance/balance.agent'

export enum BalanceAgentTypes {
    RESET_BALANCE_AGENT = '@balance_agent/RESET_BALANCE_AGENT',

    CHANGE_BALANCE_AGENT = '@balance_agent/CHANGE_BALANCE_AGENT',
    CHANGE_PAGINATOR = '@balance_agent/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@balance_agent/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@balance_agent/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_CREATE = '@balance_agent/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@balance_agent/CREATE_REQUEST',
    CREATE_SUCCESS = '@balance_agent/CREATE_SUCCESS',
    CREATE_FAILURE = '@balance_agent/CREATE_FAILURE',

    LOAD_REQUEST = '@balance_agent/LOAD_REQUEST',
    LOAD_SUCCESS = '@balance_agent/LOAD_SUCCESS',
    LOAD_FAILURE = '@balance_agent/LOAD_FAILURE',

    FIND_REQUEST = '@balance_agent/FIND_REQUEST',
    FIND_SUCCESS = '@balance_agent/FIND_SUCCESS',
    FIND_FAILURE = '@balance_agent/FIND_FAILURE',

    UPDATE_REQUEST = '@balance_agent/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@balance_agent/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@balance_agent/UPDATE_FAILURE',

    REMOVE_REQUEST = '@balance_agent/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@balance_agent/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@balance_agent/REMOVE_FAILURE'
}

/*
* Interface Actions
* */
export interface IActionCreate {
    balanceAgent: BalanceAgent
}

export interface IActionLoad {
    balanceAgentId: string
    paginator?: IPaginator
}

export interface IActionFind {
    balanceAgentId: string
}

export interface IActionRemove {
    idForRemove: string
    paginator: IPaginator
}

/*
* Interface Component State
* */
interface ICreateState extends IComponentState {
    readonly balanceAgent: BalanceAgent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly balanceAgents: BalanceAgent[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly removeVisibilityModal: boolean
}

/* State Type */
export interface IBalanceAgentState {
    readonly createBalanceAgent: ICreateState
    readonly listBalanceAgents: IListState
    readonly removeBalanceAgent: IRemoveState
}

