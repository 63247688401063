import httpClient from './config.axios'
import { ApiService } from './api'
import BalanceAgent from '../store/application/models/balance/balance.agent'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'

class BalanceAgentService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(balanceAgent: BalanceAgent): Promise<IAxiosResponse<BalanceAgent>> {
        return httpClient.post(`${this.apiVersion}/balanceagents`, balanceAgent.toJSON())
    }

    public getById(balanceAgentId: string): Promise<IAxiosResponse<BalanceAgent>> {
        return httpClient.get(`${this.apiVersion}/balanceagents/${balanceAgentId}`)
    }

    public async getAll(paginator?: IPaginator): Promise<IAxiosResponse<BalanceAgent[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/balanceagents`, { params })
    }

    public update(balanceAgent: BalanceAgent): Promise<IAxiosResponse<BalanceAgent>> {
        return httpClient.patch(`${this.apiVersion}/balanceagents/${balanceAgent.id}`, balanceAgent.toJSON())
    }

    public remove(balanceAgentId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/balanceagents/${balanceAgentId}`)
    }
}

export default new BalanceAgentService()
