import { JsonUtils } from '../../utils/json.util'
import { BalanceType } from './balance'

export enum AgentCategory {
    FUNDEB = 'fundeb',
    BB = 'bb',
    BB_F = 'bb_f',
    CEF = 'cef',
    SANTANDER = 'santander',
}

export default class BalanceAgent {
    private _id: string | undefined
    private _created_at: string | undefined
    private _name: string | undefined
    private _balance_category: BalanceType | undefined
    private _agent_category: AgentCategory | undefined
    private _siaf_code: number | undefined
    private _account_number: string | undefined
    private _management_unit_number: number | undefined

    constructor() {
        this._id = ''
        this._created_at = ''
        this._name = ''
        this._balance_category = '' as BalanceType
        this._agent_category = '' as AgentCategory
        this._siaf_code = 0
        this._account_number = ''
    }

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get balance_category(): BalanceType | undefined {
        return this._balance_category
    }

    set balance_category(value: BalanceType | undefined) {
        this._balance_category = value
    }

    get agent_category(): AgentCategory | undefined {
        return this._agent_category
    }

    set agent_category(value: AgentCategory | undefined) {
        this._agent_category = value
    }

    get siaf_code(): number | undefined {
        return this._siaf_code
    }

    set siaf_code(value: number | undefined) {
        this._siaf_code = value
    }

    get account_number(): string | undefined {
        return this._account_number
    }

    set account_number(value: string | undefined) {
        this._account_number = value
    }

    get management_unit_number(): number | undefined {
        return this._management_unit_number
    }

    set management_unit_number(value: number | undefined) {
        this._management_unit_number = value
    }

    public fromJSON(json: any): BalanceAgent {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this._id = json.id
        }

        if (json.created_at !== undefined) {
            this._created_at = json.created_at
        }

        if (json.name !== undefined) {
            this._name = json.name
        }

        if (json.balance_category !== undefined) {
            this._balance_category = json.balance_category
        }

        if (json.agent_category !== undefined) {
            this._agent_category = json.agent_category
        }

        if (json.siaf_code !== undefined) {
            this._siaf_code = Number(json.siaf_code)
        }

        if (json.account_number !== undefined) {
            this._account_number = json.account_number
        }

        if (json.management_unit_number !== undefined) {
            this._management_unit_number = json.management_unit_number
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            name: this.name ? this.name : '',
            balance_category: this.balance_category ? this.balance_category : undefined,
            agent_category: this.agent_category ? this.agent_category : undefined,
            siaf_code: this.siaf_code ? this.siaf_code : undefined,
            account_number: this.account_number ? this.account_number : undefined,
            management_unit_number: this.management_unit_number ? this.management_unit_number : undefined
        }
    }
}
