/**
 * Action types
 */
import Audit from '../../application/models/audits/audit'
import { IPaginator } from '../root.types'
import User from '../../application/models/auth/user'

export enum AuditActionTypes {
    RESET_CREATE_AUDIT = '@audits/RESET_CREATE_AUDIT',
    CHANGE_AUDIT = '@audits/CHANGE_AUDIT',
    CHANGE_REMOVE_MODAL = '@audits/CHANGE_REMOVE_MODAL',
    CHANGE_PAGINATOR = '@audits/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@audits/CHANGE_SEARCH_PAGINATOR',

    FIND_REQUEST = '@audits/FIND_REQUEST',
    FIND_SUCCESS = '@audits/FIND_SUCCESS',
    FIND_FAILURE = '@audits/FIND_FAILURE',

    LOAD_REQUEST = '@audits/LOAD_REQUEST',
    LOAD_SUCCESS = '@audits/LOAD_SUCCESS',
    LOAD_FAILURE = '@audits/LOAD_FAILURE',

    LOAD_USER_REQUEST = '@audits/LOAD_USER_REQUEST',
    LOAD_USER_SUCCESS = '@audits/LOAD_USER_SUCCESS',
    LOAD_USER_FAILURE = '@audits/LOAD_USER_FAILURE',
}

interface IComponentState {
    readonly loading: boolean
    readonly error: boolean,
    readonly success: boolean
}

interface IListState extends IComponentState {
    readonly audits: Audit[]
    readonly paginator: IPaginator
}

interface IListUserState extends IComponentState {
    readonly users: User[]
}

/**
 * State type
 */
export interface IAuditState {
    readonly list: IListState
    readonly listUsers: IListUserState
}
