import { JsonUtils } from '../../utils/json.util'

export enum BalanceType {
    COMPROMISED = 'compromised',
    OTHERS = 'others',
    RESERVE = 'reserve'
}

export default class Balance {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _value: number | undefined
    private _date: string | undefined
    private _balanceagent_id: string | undefined

    constructor() {
        this._id = ''
        this._created_at = ''
        this._updated_at = ''
        this._date = ''
        this._balanceagent_id = ''
    }

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get balanceagent_id(): string | undefined {
        return this._balanceagent_id
    }

    set balanceagent_id(value: string | undefined) {
        this._balanceagent_id = value
    }

    public fromJSON(json: any): Balance {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this._id = json.id
        }

        if (json.created_at !== undefined) {
            this._created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this._updated_at = json.updated_at
        }

        if (json.value !== undefined) {
            this._value = json.value
        }

        if (json.date !== undefined) {
            this._date = json.date
        }

        if (json.balanceagent_id !== undefined) {
            this._balanceagent_id = json.balanceagent_id
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            value: this.value ? this.value : undefined,
            date: this.date ? this.date : undefined,
            balanceagent_id: this.balanceagent_id ? this.balanceagent_id : undefined
        }
    }
}
