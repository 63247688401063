import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Year Disbursement Summary Item entity.
 *
 */
export class YearDisbursementSummaryTransactionItem {
    private _input: number | undefined
    private _output: number | undefined
    private _balance: number | undefined

    get input(): number | undefined {
        return this._input
    }

    set input(value: number | undefined) {
        this._input = value
    }

    get output(): number | undefined {
        return this._output
    }

    set output(value: number | undefined) {
        this._output = value
    }

    get balance(): number | undefined {
        return this._balance
    }

    set balance(value: number | undefined) {
        this._balance = value
    }

    public fromJSON(json: any): YearDisbursementSummaryTransactionItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.input !== undefined) {
            this.input = json.input
        }
        if (json.output !== undefined) {
            this.output = json.output
        }
        if (json.balance !== undefined) {
            this.balance = json.balance
        }

        return this
    }

    public toJSON(): any {
        return {
            input: this.input,
            output: this.output,
            balance: this.balance
        }
    }
}
