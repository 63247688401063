import { all, apply, put, takeLatest } from 'redux-saga/effects'

import DisbursementReportBalanceObservationService from '../../../services/disbursement.report.balance.observation'
import { Toast } from '../../../services/toast'
import { DisbursementReportBalanceObservation } from '../../application/models/disbursement/disbursement.report.balance.observation'
import { IActionType } from '../root.types'
import {
    createFailure,
    createSuccess,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateSuccess
} from './actions'
import { DisbursementReportBalanceObservationActionTypes, IActionCreate, IActionLoad, IActionRemove } from './types'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: DisbursementReportBalanceObservation = yield apply(
            DisbursementReportBalanceObservationService,
            DisbursementReportBalanceObservationService.create,
            [data]
        )
        yield put<any>(createSuccess(response))
        toastService.show('success', 'Observação salva com sucesso!', '')
    } catch (err) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { date } = action.payload
        const response: DisbursementReportBalanceObservation[] = yield apply(
            DisbursementReportBalanceObservationService,
            DisbursementReportBalanceObservationService.getByDate,
            [date]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { date, id } = action.payload
        yield apply(
            DisbursementReportBalanceObservationService,
            DisbursementReportBalanceObservationService.remove,
            [date, id]
        )
        yield put<any>(removeSuccess(id))
        toastService.show('success', 'Observação removida com sucesso!', '')
    } catch (err) {
        yield put(removeFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: DisbursementReportBalanceObservation = yield apply(
            DisbursementReportBalanceObservationService,
            DisbursementReportBalanceObservationService.update,
            [data]
        )
        yield put<any>(updateSuccess(response))
        toastService.show('success', 'Observação atualizada com sucesso!', '')
    } catch (err) {
        yield put(createFailure())
    }
}

export default function* DisbursementReportBalanceObservationSaga() {
    return yield all([
        takeLatest(DisbursementReportBalanceObservationActionTypes.CREATE_REQUEST, create),
        takeLatest(DisbursementReportBalanceObservationActionTypes.LOAD_REQUEST, getAll),
        takeLatest(DisbursementReportBalanceObservationActionTypes.REMOVE_REQUEST, remove),
        takeLatest(DisbursementReportBalanceObservationActionTypes.UPDATE_REQUEST, update)
    ])
}
