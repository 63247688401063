import { DisbursementTransaction } from './disbursement.transaction'
import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the DisbursementInputTransferLog entity.
 *
 * @category Application
 * @subcategory Model
 *
 *
 * @param {string} [id] Automatically generated ID when a resource is created.
 * @param {string} [created_at] Date automatically generated when a resource is created.
 * @param {string} [updated_at] Date automatically generated when a resource is updated.
 * @param {string} [description] Transaction description.
 * @param {string} [date] Transaction date.
 * @param {number} [value] Transaction value.
 * @param {string} [origin_institution_id] Institution origin identifier.
 * @param {string} [origin_type] Disbursement transaction type origin identifier.
 * @param {string} [destiny_institution_id] Institution destiny identifier.
 * @param {string} [destiny_type] Disbursement transaction type destiny identifier.
 * @param {DisbursementTransaction} [origin] Institution identifier origin of transfer.
 * @param {DisbursementTransaction} [destiny] Institution identifier destiny of transfer.
 */
export class DisbursementInputTransferLog {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _description: string | undefined
    private _date: string | undefined
    private _value: number | undefined
    private _origin_institution_id: string | undefined
    private _origin_type: string | undefined
    private _destiny_institution_id: string | undefined
    private _destiny_type: string | undefined
    private _origin: DisbursementTransaction | undefined
    private _destiny: DisbursementTransaction | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get description(): string | undefined {
        return this._description
    }

    set description(value: string | undefined) {
        this._description = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get origin_institution_id(): string | undefined {
        return this._origin_institution_id
    }

    set origin_institution_id(value: string | undefined) {
        this._origin_institution_id = value
    }

    get origin_type(): string | undefined {
        return this._origin_type
    }

    set origin_type(value: string | undefined) {
        this._origin_type = value
    }

    get destiny_institution_id(): string | undefined {
        return this._destiny_institution_id
    }

    set destiny_institution_id(value: string | undefined) {
        this._destiny_institution_id = value
    }

    get destiny_type(): string | undefined {
        return this._destiny_type
    }

    set destiny_type(value: string | undefined) {
        this._destiny_type = value
    }

    get origin(): DisbursementTransaction | undefined {
        return this._origin
    }

    set origin(value: DisbursementTransaction | undefined) {
        this._origin = value
    }

    get destiny(): DisbursementTransaction | undefined {
        return this._destiny
    }

    set destiny(value: DisbursementTransaction | undefined) {
        this._destiny = value
    }

    /**
     * Method use for receive an item from JSON, and pass for the atribbutes this value.
     * @function
     * @param {json} json Format the item is JSON
     * @returns {DisbursementInputTransferLog}
     */
    public fromJSON(json: any): DisbursementInputTransferLog {

        if (!json) {
            return this
        }

        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }
        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }
        if (json.description !== undefined) {
            this.description = json.description
        }
        if (json.date !== undefined) {
            this.date = json.date
        }
        if (json.value !== undefined) {
            this.value = json.value
        }
        if (json.origin_institution_id !== undefined) {
            this.origin_institution_id = json.origin_institution_id
        }
        if (json.origin_type !== undefined) {
            this.origin_type = json.origin_type
        }
        if (json.destiny_institution_id !== undefined) {
            this.destiny_institution_id = json.destiny_institution_id
        }
        if (json.destiny_type !== undefined) {
            this.destiny_type = json.destiny_type
        }
        if (json.origin !== undefined) {
            this.origin = new DisbursementTransaction().fromJSON(json.origin)
        }
        if (json.destiny !== undefined) {
            this.destiny = new DisbursementTransaction().fromJSON(json.destiny)
        }

        return this
    }

    /**
     * Method use for return an Object {@link DisbursementInputTransferLog} to JSON.
     * @function
     * @returns {any}
     */
    public toJSON(): any {
        return {
            id: this.id,
            created_at: this.created_at,
            updated_at: this.updated_at,
            description: this.description,
            date: this.date,
            value: this.value,
            origin_institution_id: this.origin_institution_id,
            origin_type: this.origin_type,
            destiny_institution_id: this.destiny_institution_id,
            destiny_type: this.destiny_type,
            origin: this.origin?.toJSON() || undefined,
            destiny: this.destiny?.toJSON() || undefined
        }
    }
}
