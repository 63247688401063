import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import authService from '../../services/auth'

const UserDropdown = (props: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [profileActive, setProfileActive] = useState('')
    const [settingsActive, setSettingsActive] = useState('')

    const { history } = props

    useEffect(() => history.listen(() => {
        const { location: { pathname } } = history
        setProfileActive('')
        setSettingsActive('')
        if (pathname.match('my_profile')) {
            setProfileActive('active')
        }
        if (pathname.match('settings')) {
            setSettingsActive('active')
        }
    }), [history])

    const toggle = () => setDropdownOpen(prevState => !prevState)

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="right">
            <DropdownToggle id="buttonDropdom" title={props.username}>
                <span className="dropdown-button waves-effect waves-dark">
                    <i className="fa fa-user fa-fw"/>
                    <b>{props.username}</b>
                    <i className="material-icons right">arrow_drop_down</i>
                 </span>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header={true}>Editar/Visualizar</DropdownItem>

                <DropdownItem className={profileActive} onClick={() => {
                    history.push('/app/my_profile')
                }}>
                    <i className="fa fa-user fa-fw"/> Meus Dados
                </DropdownItem>

                <DropdownItem header={true}>Opções</DropdownItem>

                <DropdownItem className={settingsActive} onClick={() => {
                    history.push('/app/settings')
                }}>
                    <i className="fa fa-gear fa-fw"/> Configurações
                </DropdownItem>

                <DropdownItem onClick={() => {
                    authService.logout()
                    history.push('/')
                }}>
                    <p><i className="fa fa-sign-out fa-fw"/> Sair</p>
                </DropdownItem>

            </DropdownMenu>
        </Dropdown>
    )
}

export default withRouter(UserDropdown)
