import React, { Component } from 'react'
import { Button } from 'primereact/button'
import { VerifyScopes } from '../verify.scopes/verify.scopes'

interface IProps {

    titleEdit: string

    titleDelete: string

    titleAuxiliary?: string

    disableEdit?: any

    disableAuxiliary?: boolean

    scopesEdit?: string[]

    scopesRemove?: string[]

    scopesAuxiliary?: string[]

    editAction(event: any): void

    deleteAction(event: any): void

    auxiliaryAction?(event: any): void
}

class ListActionsButtons extends Component<IProps> {

    public render() {
        const {
            titleEdit,
            titleDelete,
            titleAuxiliary,
            disableEdit,
            disableAuxiliary,
            editAction,
            deleteAction,
            auxiliaryAction,
            scopesEdit,
            scopesRemove,
            scopesAuxiliary
        } = this.props
        return (
            <div className="d-flex justify-content-around buttons-actions">
                {/* Edit */}
                <VerifyScopes scopes={scopesEdit?.length ? scopesEdit : []}>
                    <Button
                        tooltip={titleEdit}
                        tooltipOptions={{ position: 'top' }}
                        className="p-button-primary left"
                        icon="pi pi-pencil"
                        disabled={disableEdit}
                        onClick={editAction} />
                </VerifyScopes>
                {/* Auxiliary */}
                {!disableAuxiliary
                    ? <VerifyScopes scopes={scopesAuxiliary?.length ? scopesAuxiliary : []}>
                        <Button
                            tooltip={titleAuxiliary}
                            tooltipOptions={{ position: 'top' }}
                            className="p-button-primary"
                            icon="pi pi-external-link"
                            onClick={auxiliaryAction} />
                    </VerifyScopes>
                    : ''}
                {/* Delete */}
                <VerifyScopes scopes={scopesRemove?.length ? scopesRemove : []}>
                    <Button
                        tooltip={titleDelete}
                        tooltipOptions={{ position: 'top' }}
                        className="p-button-primary right"
                        icon="pi pi-trash"
                        onClick={deleteAction} />
                </VerifyScopes>
            </div>
        )
    }
}

export default ListActionsButtons
