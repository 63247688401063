import { Reducer } from 'redux'
import { IPayersState, PayerActionsTypes } from './types'
import Payer from '../../application/models/financial.agents/payer/payer'

export const INITIAL_STATE: IPayersState = {
    createPayer: {
        payer: new Payer(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listPayers: {
        payers: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 20,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    removePayer: {
        loading: false,
        error: false,
        success: false,
        idForRemove: '',
        removeVisibilityModal: false
    }
}

const reducer: Reducer<IPayersState> = (state: IPayersState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case PayerActionsTypes.RESET_PAYER:
            return {
                ...state,
                createPayer: INITIAL_STATE.createPayer
            }

        case PayerActionsTypes.CHANGE_PAYER:
            const { payer: changePayer } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: false,
                    error: false,
                    success: false,
                    payer: changePayer
                }
            }

        case PayerActionsTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listPayers: {
                    ...state.listPayers,
                    paginator
                }
            }

        case PayerActionsTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listPayers: {
                    ...state.listPayers,
                    paginator: {
                        ...state.listPayers.paginator,
                        search
                    }
                }
            }

        case PayerActionsTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                removePayer: {
                    ...state.removePayer,
                    removeVisibilityModal: visibilityModal,
                    idForRemove
                }
            }

        case PayerActionsTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return { ...state, createPayer: { ...state.createPayer, dialog } }

        case PayerActionsTypes.CREATE_REQUEST:
            const { payer } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    payer,
                    loading: true
                }
            }

        case PayerActionsTypes.CREATE_SUCCESS:
            return {
                ...state,
                createPayer: {
                    ...INITIAL_STATE.createPayer,
                    success: true
                }
            }

        case PayerActionsTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: false,
                    error: true,
                    success: false,
                    data: createError
                }
            }

        case PayerActionsTypes.LOAD_REQUEST:
            return {
                ...state,
                listPayers: {
                    ...state.listPayers,
                    loading: true
                }
            }

        case PayerActionsTypes.LOAD_SUCCESS:
            const { payers, headers } = action.payload
            return {
                ...state,
                listPayers: {
                    ...state.listPayers,
                    loading: false,
                    success: false,
                    error: false,
                    payers: payers.map(payerJson => new Payer().fromJSON(payerJson)),
                    paginator: {
                        ...state.listPayers.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case PayerActionsTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                listPayers: {
                    ...state.listPayers,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case PayerActionsTypes.FIND_REQUEST:
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: true
                }
            }

        case PayerActionsTypes.FIND_SUCCESS:
            const { payer: payerFound } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    payer: new Payer().fromJSON(payerFound),
                    loading: false
                }
            }

        case PayerActionsTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: false,
                    error: true,
                    success: false,
                    data: errorFound
                }
            }

        case PayerActionsTypes.UPDATE_REQUEST:
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: true
                }
            }

        case PayerActionsTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: false
                }
            }

        case PayerActionsTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                createPayer: {
                    ...state.createPayer,
                    loading: false,
                    success: false,
                    error: true,
                    data: updateError
                }
            }

        case PayerActionsTypes.REMOVE_REQUEST:
            return {
                ...state,
                removePayer: {
                    ...state.removePayer,
                    loading: true,
                    removeVisibilityModal: false
                }
            }

        case PayerActionsTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removePayer: {
                    ...state.removePayer,
                    loading: false,
                    success: true
                }
            }

        case PayerActionsTypes.REMOVE_FAILURE:
            const removeError = action.payload.error
            return {
                ...state,
                removePayer: {
                    ...state.removePayer,
                    loading: false,
                    success: false,
                    error: true,
                    data: removeError
                }
            }

        default:
            return state
    } // End Switch
}

export default reducer
