import Costing from './costing'
import MonthlySummary from '../../summary/monthly.summary'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'

export default class CostMonthlySummary extends MonthlySummary<MonthlyInstitution<Costing>, Costing> {
    private _total_direct_adm: number | undefined
    private _total_indirect_adm: number | undefined

    get total_direct_adm(): number | undefined {
        return this._total_direct_adm
    }

    set total_direct_adm(value: number | undefined) {
        this._total_direct_adm = value
    }

    get total_indirect_adm(): number | undefined {
        return this._total_indirect_adm
    }

    set total_indirect_adm(value: number | undefined) {
        this._total_indirect_adm = value
    }

    public fromJSON(json: any): CostMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Costing>().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_direct_adm !== undefined) {
            this.total_direct_adm = json.total_direct_adm
        }
        if (json.total_indirect_adm !== undefined) {
            this._total_indirect_adm = json.total_indirect_adm
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_direct_adm: this.total_direct_adm ? this.total_direct_adm : undefined,
            total_indirect_adm: this.total_indirect_adm ? this.total_indirect_adm : undefined
        }
    }

    public concatFromJSON(json: any): CostMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Costing>().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_direct_adm !== undefined) {
            this._total_direct_adm += json.total_direct_adm
        }
        if (json.total_indirect_adm !== undefined) {
            this._total_indirect_adm += json.total_indirect_adm
        }

        return this
    }
}
