import { IComponentState, IPaginator } from '../root.types'
import Balance from '../../application/models/balance/balance'
import BalanceSummary from '../../application/models/balance/balance.summary'

export enum BalanceTypes {
    RESET_BALANCE = '@balance/RESET_BALANCE',
    RESET_MONTHLY_PAGINATOR = '@balance/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@balance/RESET_ANNUAL_PAGINATOR',

    CHANGE_BALANCE = '@balance/CHANGE_BALANCE',
    CHANGE_DIALOG = '@balance/CHANGE_DIALOG',
    CHANGE_PAGINATOR = '@balance/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@balance/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@balance/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@balance/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@balance/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@balance/CREATE_REQUEST',
    CREATE_SUCCESS = '@balance/CREATE_SUCCESS',
    CREATE_FAILURE = '@balance/CREATE_FAILURE',

    LOAD_REQUEST = '@balance/LOAD_REQUEST',
    LOAD_SUCCESS = '@balance/LOAD_SUCCESS',
    LOAD_FAILURE = '@balance/LOAD_FAILURE',

    FIND_REQUEST = '@balance/FIND_REQUEST',
    FIND_SUCCESS = '@balance/FIND_SUCCESS',
    FIND_FAILURE = '@balance/FIND_FAILURE',

    UPDATE_REQUEST = '@balance/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@balance/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@balance/UPDATE_FAILURE',

    REMOVE_REQUEST = '@balance/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@balance/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@balance/REMOVE_FAILURE',

    FIND_BALANCE_AGENT_REQUEST = '@balance/FIND_BALANCE_AGENT_REQUEST',
    FIND_BALANCE_AGENT_SUCCESS = '@balance/FIND_BALANCE_AGENT_SUCCESS',
    FIND_BALANCE_AGENT_FAILURE = '@balance/FIND_BALANCE_AGENT_FAILURE',

    LOAD_DAILY_REQUEST = '@balance/LOAD_DAILY_REQUEST',
    LOAD_DAILY_SUCCESS_RESET = '@balance/LOAD_DAILY_SUCCESS_RESET',
    LOAD_DAILY_SUCCESS_CONCAT = '@balance/LOAD_DAILY_SUCCESS_CONCAT',
    LOAD_DAILY_FAILURE = '@balance/LOAD_DAILY_FAILURE',

    LOAD_MONTHLY_REQUEST = '@balance/LOAD_MONTHLY_REQUEST',
    LOAD_MONTHLY_SUCCESS_RESET = '@balance/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@balance/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@balance/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL_REQUEST = '@balance/LOAD_ANNUAL_REQUEST',
    LOAD_ANNUAL_SUCCESS_RESET = '@balance/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@balance/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@balance/LOAD_ANNUAL_FAILURE'
}

export enum BalanceLocation {
    BY_AGENT = 'by_agent',
    DAILY = 'daily',
    MONTHLY = 'monthly'
}

/* Interface Actions */
export interface IActionCreate {
    readonly balance: Balance
    readonly location: BalanceLocation
}

export interface IActionLoad {
    readonly balanceAgentId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly balanceAgentId: string
    readonly balanceId: string
}

export interface IActionRemove {
    readonly balanceAgentId: string
    readonly balanceId: string
    readonly paginator: IPaginator
    readonly date?: string
    readonly location: BalanceLocation
}

export interface IActionFindBalanceAgent {
    readonly balanceAgentId: string
}

/* Interfaces Component State */
interface ICreateState extends IComponentState {
    readonly balance: Balance
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly balances: Balance[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly balanceAgents: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly balanceAgentId: string
    readonly visibilityModal: boolean
}

interface IMonthlyState extends IComponentState {
    readonly monthlyBalances: BalanceSummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IDailyState extends IComponentState {
    readonly summary: BalanceSummary
    readonly data: ErrorEvent
    readonly paginator: IPaginator
}

interface IAnnualState extends IComponentState {
    readonly annualBalances: BalanceSummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

/* State Type */
export interface IBalancesState {
    readonly createBalance: ICreateState
    readonly listBalances: IListState
    readonly removeBalance: IRemoveState
    readonly dailyBalances: IDailyState
    readonly monthlyBalances: IMonthlyState
    readonly annualBalances: IAnnualState
}
