import { DisbursementTransaction } from './disbursement.transaction'
import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Daily Disbursement Summary Item entity.
 *
 */
export class DailyDisbursementSummaryItem {
    private _id: string | undefined
    private _name: string | undefined
    private _code: string | undefined
    private _transactions: DisbursementTransaction[] | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get code(): string | undefined {
        return this._code
    }

    set code(value: string | undefined) {
        this._code = value
    }

    get transactions(): DisbursementTransaction[] | undefined {
        return this._transactions
    }

    set transactions(value: DisbursementTransaction[] | undefined) {
        this._transactions = value
    }

    public fromJSON(json: any): DailyDisbursementSummaryItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.name !== undefined) {
            this.name = json.name
        }
        if (json.code !== undefined) {
            this.code = json.code
        }
        if (json.transactions instanceof Array && json.transactions.length) {
            this.transactions = json
                ?.transactions
                ?.map((transactionItem: any) =>
                    new DisbursementTransaction().fromJSON(transactionItem)
                ) || []
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id,
            name: this.name,
            code: this.code,
            transactions: this.transactions?.length ?
                this.transactions.map((item: DisbursementTransaction) => item.toJSON())
                : []
        }
    }
}
