import { Reducer } from 'redux'

import { ISuggestionState, SuggestionActionTypes } from './types'

export const INITIAL_STATE: ISuggestionState = {
    loading: false,
    error: false,
    success: false,
    suggestions: [],
    typeRespectively: []
}

const reducer: Reducer<ISuggestionState> = (state: ISuggestionState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case SuggestionActionTypes.RESET:
            return INITIAL_STATE

        case SuggestionActionTypes.LOAD_SUGGESTIONS:
            return { ...state, loading: true }

        case SuggestionActionTypes.LOAD_SUGGESTIONS_SUCCESS:
            const { suggestions, typeRespectively } = action.payload
            return { ...state, loading: false, suggestions, error: false, typeRespectively }

        case SuggestionActionTypes.LOAD_SUGGESTIONS_FAILURE:
            const { error: errorSuggestions } = action.payload
            return {
                ...state,
                loading: false,
                error: true,
                data: errorSuggestions
            }

        default:
            return state
    }
}

export default reducer
