import { all, apply, put, takeLatest } from 'redux-saga/effects'

import {
    change,
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    loadBalanceFailure,
    loadBalanceSuccess,
    loadConferenceFailure,
    loadConferenceSuccess,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    reportFailure,
    reportSuccess,
    syncFailure,
    syncSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionType, IAxiosResponse, IPaginator } from '../root.types'
import { Toast } from '../../../services/toast'
import {
    DisbursementActionTypes,
    IActionCreate,
    IActionFind,
    IActionLoad,
    IActionLoadBalance,
    IActionLoadReport,
    IActionRemove
} from './types'
import disbursementTransaction from '../../../services/disbursement.transaction'
import disbursementTransferTransaction from '../../../services/disbursement.transfer.log'
import { DisbursementTransactionType } from '../../application/utils/disbursement.transaction.type'
import { DisbursementTransaction } from '../../application/models/disbursement/disbursement.transaction'
import { DailyDisbursementSummary } from '../../application/models/disbursement/daily.disbursement.summary'
import { YearDisbursementSummaryItem } from '../../application/models/disbursement/year.disbursement.summary.item'
import { DisbursementConferenceItem } from '../../application/models/disbursement/conference.disbursement.item'
import { DisbursementReport } from '../../application/models/disbursement/disbursement.report'
import { DisbursementInputTransferLog } from '../../application/models/disbursement/disbursement.input.transfer.log'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        if (data instanceof DisbursementTransaction) {
            const response: DisbursementTransaction = yield apply(
                disbursementTransaction,
                disbursementTransaction.create,
                [data]
            )
            yield put<any>(createSuccess(response))
            data.transaction_type === DisbursementTransactionType.INPUT ?
                toastService.show('success', 'Entrada cadastrada com sucesso', '')
                : toastService.show('success', 'Saída cadastrada com sucesso', '')
        }
        if (data instanceof DisbursementInputTransferLog) {
            const response: DisbursementInputTransferLog = yield apply(
                disbursementTransferTransaction,
                disbursementTransferTransaction.create,
                [data]
            )
            yield put<any>(createSuccess(response))
            toastService.show('success', 'Transferência cadastrada com sucesso', '')
        }
    } catch (err) {
        yield put(createFailure())
    }
}

function* find(action: IActionType<IActionFind>) {
    const { type, id } = action.payload
    try {
        if (type !== DisbursementTransactionType.INPUT_TRANSFER) {
            const response: DisbursementTransaction = yield apply(
                disbursementTransaction,
                disbursementTransaction.getById,
                [type, id]
            )
            yield put<any>(change(response))
        }
        if (type === DisbursementTransactionType.INPUT_TRANSFER) {
            const response: DisbursementInputTransferLog = yield apply(
                disbursementTransferTransaction,
                disbursementTransferTransaction.getById,
                [id]
            )
            yield put<any>(findSuccess(response))
        }
    } catch (err) {
        yield put(findFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { type, date, paginator } = action.payload
        const response: IAxiosResponse<DailyDisbursementSummary> = yield apply(
            disbursementTransaction,
            disbursementTransaction.getDailyDisbursementSummary,
            [type, date, paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure())
    }
}

function* syncData(action: IActionType<IActionLoad>) {
    try {
        const { date } = action.payload
        const response: IAxiosResponse<DailyDisbursementSummary> = yield apply(
            disbursementTransaction,
            disbursementTransaction.syncDailyDisbursementSummary,
            [date]
        )
        yield put(syncSuccess(response))
        toastService.show('success', 'Dados atualizados com sucesso', '')
    } catch (err) {
        yield put(syncFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { type, ids, date } = action.payload
        if (type !== DisbursementTransactionType.INPUT_TRANSFER) {
            for (const id of ids) {
                yield apply(disbursementTransaction, disbursementTransaction.remove, [type, id])
            }
            yield put<any>(removeSuccess(type, date))
            type === DisbursementTransactionType.INPUT ?
                toastService.show('success', 'Entrada removida com sucesso', '')
                : toastService.show('success', 'Saída removida com sucesso', '')
        } else {
            for (const id of ids) {
                yield apply(disbursementTransferTransaction, disbursementTransferTransaction.remove, [id])
            }
            yield put<any>(removeSuccess(type, date))
            toastService.show('success', 'Transferência removida com sucesso', '')
        }
    } catch (err) {
        yield put(removeFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        if (data instanceof DisbursementTransaction) {
            const response: DisbursementTransaction = yield apply(
                disbursementTransaction,
                disbursementTransaction.update,
                [data]
            )
            yield put<any>(updateSuccess(response))
            data.transaction_type === DisbursementTransactionType.INPUT ?
                toastService.show('success', 'Entrada atualizada com sucesso', '')
                : toastService.show('success', 'Saída atualizada com sucesso', '')
        }
        if (data instanceof DisbursementInputTransferLog) {
            const response: DisbursementInputTransferLog = yield apply(
                disbursementTransferTransaction,
                disbursementTransferTransaction.update,
                [data]
            )
            yield put<any>(updateSuccess(response))
            toastService.show('success', 'Transferência atualizada com sucesso', '')
        }
    } catch (err) {
        yield put(updateFailure())
    }
}

function* getAllBalance(action: IActionType<IActionLoadBalance>) {
    try {
        const { year, paginator, startDate, endDate } = action.payload
        let totalItems: number = 0
        let totalLength: number = 0
        let index: number = 0
        do {
            const currentPaginator: IPaginator = {
                ...paginator,
                page: index
            }
            const response: IAxiosResponse<YearDisbursementSummaryItem[]> = yield apply(
                disbursementTransaction,
                disbursementTransaction.getYearDisbursementSummary,
                [year, currentPaginator, startDate, endDate]
            )
            totalLength += response?.data?.length || 0
            totalItems = parseInt(response.headers['x-total-count'], 10)
            yield put(
                loadBalanceSuccess(
                    response,
                    index === 0,
                    totalLength >= totalItems
                )
            )
            index++
        } while (totalItems > totalLength)
    } catch (err) {
        yield put(loadBalanceFailure())
    }
}

function* getConference(action: IActionType<IActionLoadBalance>) {
    try {
        const { year, paginator } = action.payload
        let totalItems: number = 0
        let totalLength: number = 0
        let index: number = 0
        do {
            const currentPaginator: IPaginator = {
                ...paginator,
                page: index
            }
            const response: IAxiosResponse<DisbursementConferenceItem[]> = yield apply(
                disbursementTransaction,
                disbursementTransaction.getConference,
                [year, currentPaginator]
            )
            totalLength += response?.data?.length || 0
            totalItems = parseInt(response.headers['x-total-count'], 10)
            yield put(
                loadConferenceSuccess(
                    response,
                    index === 0,
                    totalLength >= totalItems
                )
            )
            index++
        } while (totalItems > totalLength)
    } catch (err) {
        yield put(loadConferenceFailure())
    }
}

function* getReport(action: IActionType<IActionLoadReport>) {
    try {
        const { date } = action.payload
        const response: DisbursementReport = yield apply(
            disbursementTransaction,
            disbursementTransaction.getReport,
            [date]
        )
        yield put(reportSuccess(response))
    } catch (err) {
        yield put(reportFailure())
    }
}

export default function* disbursementSaga() {
    return yield all([
        takeLatest(DisbursementActionTypes.CREATE_REQUEST, create),
        takeLatest(DisbursementActionTypes.FIND_REQUEST, find),
        takeLatest(DisbursementActionTypes.LOAD_REQUEST, getAll),
        takeLatest(DisbursementActionTypes.SYNC_REQUEST, syncData),
        takeLatest(DisbursementActionTypes.REMOVE_REQUEST, remove),
        takeLatest(DisbursementActionTypes.UPDATE_REQUEST, update),
        takeLatest(DisbursementActionTypes.LOAD_BALANCE_REQUEST, getAllBalance),
        takeLatest(DisbursementActionTypes.LOAD_CONFERENCE_REQUEST, getConference),
        takeLatest(DisbursementActionTypes.REPORT_REQUEST, getReport)
    ])
}
