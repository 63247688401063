import Event from './event'
import MonthlySummary from '../../summary/monthly.summary'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'

export default class EventMonthlySummary extends MonthlySummary<MonthlyInstitution<Event>, Event> {
    private _total_realized: number | undefined
    private _total_unrealized: number | undefined

    get total_realized(): number | undefined {
        return this._total_realized
    }

    set total_realized(value: number | undefined) {
        this._total_realized = value
    }

    get total_unrealized(): number | undefined {
        return this._total_unrealized
    }

    set total_unrealized(value: number | undefined) {
        this._total_unrealized = value
    }

    public fromJSON(json: any): EventMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Event>().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_realized !== undefined) {
            this._total_realized = json.total_realized
        }

        if (json.total_unrealized !== undefined) {
            this._total_unrealized = json.total_unrealized
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_realized: this.total_realized ? this.total_realized : undefined,
            total_unrealized: this.total_unrealized ? this.total_unrealized : undefined
        }
    }

    public concatFromJSON(json: any): EventMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Event>().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_realized !== undefined) {
            this._total_realized += json.total_realized
        }

        if (json.total_unrealized !== undefined) {
            this._total_unrealized += json.total_unrealized
        }

        return this
    }
}
