import React from 'react'
import { NavLink } from 'react-router-dom'

import 'metismenujs/dist/metismenujs.css'
import { LogicalStrategy } from '../../services/auth'
import { VerifyScopesUtil } from '../../store/application/utils/verify.scopes.util'
import { UserTypes } from '../../store/application/models/auth/user'
import { VerifyUsersUtil } from '../../store/application/utils/verify.users.util'

export interface IMenu {
    exact?: boolean
    strict?: boolean
    separator?: boolean
    to: string
    icon?: string
    description: string
    scopes?: string[],
    users?: UserTypes[],
    logicalStrategy?: LogicalStrategy
    submenus?: IMenu[]
}

export const MenuWithSubMenus: React.FunctionComponent<{ menus: IMenu[] }> = (props) => {
    return (
        <React.Fragment>
            {
                props.menus.map((itemMenu: IMenu, index: number) => {

                    return (
                        <React.Fragment key={itemMenu.description ? `${itemMenu.description}_${index}` : Math.random()}>
                            {
                                itemMenu.separator ?
                                    VerifyUsersUtil.verify(itemMenu.users, itemMenu.logicalStrategy) && (
                                        <li className="separator">
                                            <div className="separator-content">{itemMenu.description}</div>
                                        </li>
                                    )
                                    :
                                    VerifyScopesUtil.verify(itemMenu.scopes, itemMenu.logicalStrategy) &&
                                    VerifyUsersUtil.verify(itemMenu.users, itemMenu.logicalStrategy) && (
                                        <li>
                                            <React.Fragment>
                                                <NavLink
                                                    exact={itemMenu.exact}
                                                    strict={itemMenu.strict}
                                                    to={itemMenu.to}
                                                    activeClassName="active-menu">
                                                    <i className={`fa ${itemMenu.icon}`}/> {itemMenu.description}
                                                    {itemMenu.submenus ? <span className="fa arrow"/> : undefined}
                                                </NavLink>

                                                {
                                                    itemMenu.submenus &&
                                                    <ul className="nav nav-second-level">
                                                        {itemMenu.submenus.map((submenu: IMenu) => {
                                                            return (
                                                                <React.Fragment key={submenu.description}>
                                                                    {
                                                                        VerifyScopesUtil.verify(submenu.scopes, submenu.logicalStrategy) &&
                                                                        <li>
                                                                            <NavLink exact={submenu.exact}
                                                                                     to={submenu.to}
                                                                                     className="waves-effect waves-dark"
                                                                                     activeClassName="active-menu">
                                                                                {submenu.icon &&
                                                                                <i className={`fa ${submenu.icon} icon-submenu`}/>}
                                                                                {submenu.description}
                                                                            </NavLink>
                                                                        </li>
                                                                    }
                                                                </React.Fragment>)
                                                        })}
                                                    </ul>
                                                }
                                            </React.Fragment>
                                        </li>
                                    )

                            }
                        </React.Fragment>
                    )
                })
            }

        </React.Fragment>
    )
}

export default MenuWithSubMenus
