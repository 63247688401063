/**
 * Action types
 */
import { IPaginator } from '../root.types'
import Payee from '../../application/models/financial.agents/payee/payee'

export enum PayeeTypes {
    RESET_CREATE_PAYEE = '@payees/RESET_CREATE_PAYEE',

    CHANGE_PAYEE = '@payees/CHANGE_PAYEE',
    CHANGE_REMOVE_MODAL = '@payees/CHANGE_REMOVE_MODAL',
    CHANGE_PAGINATOR = '@payees/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@payees/CHANGE_SEARCH_PAGINATOR',
    CHANGE_DIALOG_CREATE = '@payees/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@payees/CREATE_REQUEST',
    CREATE_SUCCESS = '@payees/CREATE_SUCCESS',
    CREATE_FAILURE = '@payees/CREATE_FAILURE',

    FIND_REQUEST = '@payees/FIND_REQUEST',
    FIND_SUCCESS = '@payees/FIND_SUCCESS',
    FIND_FAILURE = '@payees/FIND_FAILURE',

    UPDATE_REQUEST = '@payees/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@payees/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@payees/UPDATE_FAILURE',

    REMOVE_REQUEST = '@payees/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@payees/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@payees/REMOVE_FAILURE',

    LOAD_REQUEST = '@payees/LOAD_REQUEST',
    LOAD_SUCCESS = '@payees/LOAD_SUCCESS',
    LOAD_FAILURE = '@payees/LOAD_FAILURE',

    LOAD_DUODECIMOS = '@payees/LOAD_DUODECIMOS',
    LOAD_DUODECIMOS_SUCCESS = '@payees/LOAD_DUODECIMOS_SUCCESS',
    LOAD_DUODECIMOS_FAILURE = '@payees/LOAD_DUODECIMOS_FAILURE'
}

/**
 *  Interfaces Actions
 */
export interface IActionCreate {
    readonly payee: Payee
    readonly location: 'list' | 'duodecimos'
}

export interface IActionLoad {
    payeeId: string
    paginator?: IPaginator
}

export interface IActionFind {
    payeeId: string
}

export interface IActionRemove {
    idForRemove: string
    paginator: IPaginator
}

/**
 *  Interfaces States Components
 */
interface IComponentState {
    readonly loading: boolean
    readonly error: boolean,
    readonly success: boolean
}

interface ICreateState extends IComponentState {
    readonly payee: Payee
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly payees: Payee[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly removeVisibilityModal: boolean
    readonly idForRemove: string
}

/**
 * State type
 */
export interface IPayeeState {
    readonly create: ICreateState
    readonly remove: IRemoveState
    readonly list: IListState
}
