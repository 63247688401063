import { lazy } from 'react'

const HomeDisbursement = lazy(() => import('../containers/disbursement/home.disbursement'))
const Conference = lazy(() => import('../containers/disbursement/conference'))
const Inputs = lazy(() => import('../containers/disbursement/inputs'))
const InputsTransfers = lazy(() => import('../containers/disbursement/inputs.transfers'))
const Outputs = lazy(() => import('../containers/disbursement/outputs'))
const Balances = lazy(() => import('../containers/disbursement/balances'))
const ReportsMain = lazy(() => import('../containers/disbursement/reports'))
const ReportsInputOutput = lazy(() => import('../containers/disbursement/reports/input.output'))

export const DISBURSEMENT_ROUTES = [
    {
        path: '/app/disbursement',
        component: HomeDisbursement,
        scopes: ['in:ra', 'ou:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/conference',
        component: Conference,
        scopes: ['in:ra', 'ou:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/inputs',
        component: Inputs,
        scopes: ['in:ra'],
        exact: true,
        private: true
    }, {
        path: '/app/disbursement/inputs_transfers',
        component: InputsTransfers,
        scopes: ['in:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/outputs',
        component: Outputs,
        scopes: ['ou:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/balances',
        component: Balances,
        scopes: ['in:ra', 'ou:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/reports/main',
        component: ReportsMain,
        scopes: ['dr:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/disbursement/reports/input_output',
        component: ReportsInputOutput,
        scopes: ['in:ra', 'ou:ra'],
        exact: true,
        private: true
    }
]
