import MonthlySummary from '../../summary/monthly.summary'
import Debt from './debt'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'

export default class DebtMonthlySummary extends MonthlySummary<MonthlyInstitution<Debt>, Debt> {
    private _total_direct_debts: number | undefined
    private _total_indirect_debts: number | undefined
    private _total_jda: number | undefined

    get total_direct_debts(): number | undefined {
        return this._total_direct_debts
    }

    set total_direct_debts(value: number | undefined) {
        this._total_direct_debts = value
    }

    get total_indirect_debts(): number | undefined {
        return this._total_indirect_debts
    }

    set total_indirect_debts(value: number | undefined) {
        this._total_indirect_debts = value
    }

    get total_jda(): number | undefined {
        return this._total_jda
    }

    set total_jda(value: number | undefined) {
        this._total_jda = value
    }

    public fromJSON(json: any): DebtMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Debt>().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_direct_debts !== undefined) {
            this._total_direct_debts = json.total_direct_debts
        }
        if (json.total_indirect_debts !== undefined) {
            this._total_indirect_debts = json.total_indirect_debts
        }
        if (json.total_jda !== undefined) {
            this._total_jda = json.total_jda
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_direct_debts: this.total_direct_debts ? this.total_direct_debts : undefined,
            total_indirect_debts: this.total_indirect_debts ? this.total_indirect_debts : undefined,
            total_jda: this.total_jda ? this.total_jda : undefined
        }
    }

    public concatFromJSON(json: any): DebtMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyInstitution<Debt>().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_direct_debts !== undefined) {
            this._total_direct_debts += json.total_direct_debts
        }
        if (json.total_indirect_debts !== undefined) {
            this._total_indirect_debts += json.total_indirect_debts
        }
        if (json.total_jda !== undefined) {
            this._total_jda += json.total_jda
        }

        return this
    }

}
