import { ApiService } from './api'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import SupplementaryBudget from '../store/application/models/budget/supplementary.budget'
import Payee from '../store/application/models/financial.agents/payee/payee'

class SupplementaryBudgetService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(supplementary: SupplementaryBudget): Promise<IAxiosResponse<SupplementaryBudget>> {
        const url = `${this.apiVersion}/payees/${supplementary.payee_id}/supplementarybudgets`
        return httpClient.post(url, supplementary.toJSON())
    }

    public getById(payeeId: string, supplementaryId: string): Promise<IAxiosResponse<SupplementaryBudget>> {
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/supplementarybudgets/${supplementaryId}`)
    }

    public getAll(payeeId: string, paginator?: IPaginator): Promise<IAxiosResponse<SupplementaryBudget[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        if (paginator?.search.key) {
            params.delete(paginator?.search.key)
            const year = parseInt(`${paginator?.search.value}`, 10)
            params.append('date', `gte:${year}`)
            params.append('date', `lt:${year + 1}`)
        }
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/supplementarybudgets`, { params })
    }

    public update(supplementary: SupplementaryBudget): Promise<IAxiosResponse<SupplementaryBudget>> {
        const body = supplementary.toJSON()
        delete body.date
        const url = `${this.apiVersion}/payees/${supplementary.payee_id}/supplementarybudgets/${supplementary.id}`
        return httpClient.patch(url, body)
    }

    public remove(payeeId: string, supplementaryId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payees/${payeeId}/supplementarybudgets/${supplementaryId}`)
    }

    public async getManyByExercise(payees: Payee[], date: string): Promise<Map<string, SupplementaryBudget[]>> {
        const paginator: IPaginator = {
            first: 0,
            rows: 12,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: 'date',
                value: date
            }
        }

        const result: Map<string, SupplementaryBudget[]> = new Map<string, SupplementaryBudget[]>()

        for (const payee of payees) {
            const payeeId = payee?.id || ''
            const response: IAxiosResponse<SupplementaryBudget[]> = await this.getAll(payeeId, paginator)
            if (response?.data?.length) {
                result.set(payeeId, response.data)
            }
        }
        return result
    }

}

export default new SupplementaryBudgetService()
