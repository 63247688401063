import Prevision from '../../application/models/prevision/prevision'
import { IComponentState, IPaginator } from '../root.types'
import { AnnualTransaction } from '../../application/models/transactions/annual.transaction'
import { PrevisionTransactionTypes } from '../../application/utils/prevision.transaction.types'

/**
 * Action types
 */
export enum PrevisionActionTypes {
    RESET = '@prevision/RESET',

    CHANGE_PREVISION = '@prevision/CHANGE_PREVISION',
    CHANGE_INITIAL_VALUE = '@prevision/CHANGE_INITIAL_VALUE',
    CHANGE_REMOVE_MODAL = '@prevision/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_CREATE = '@prevision/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@prevision/CREATE_REQUEST',
    CREATE_SUCCESS = '@prevision/CREATE_SUCCESS',
    CREATE_FAILURE = '@prevision/CREATE_FAILURE',

    LOAD_REQUEST = '@prevision/LOAD_REQUEST',
    LOAD_SUCCESS = '@prevision/LOAD_SUCCESS',
    LOAD_FAILURE = '@prevision/LOAD_FAILURE',

    UPDATE_REQUEST = '@prevision/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@prevision/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@prevision/UPDATE_FAILURE',

    UPDATE_MANY_REQUEST = '@prevision/UPDATE_MANY_REQUEST',
    UPDATE_MANY_SUCCESS = '@prevision/UPDATE_MANY_SUCCESS',
    UPDATE_MANY_FAILURE = '@prevision/UPDATE_MANY_FAILURE',

    LOAD_ANNUAL_REQUEST = '@prevision/LOAD_ANNUAL_REQUEST',
    LOAD_ANNUAL_SUCCESS = '@prevision/LOAD_ANNUAL_SUCCESS',
    LOAD_ANNUAL_FAILURE = '@prevision/LOAD_ANNUAL_FAILURE',

    REMOVE_REQUEST = '@prevision/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@prevision/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@prevision/REMOVE_FAILURE'

}

export interface IActionCreate {
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
    readonly previsions: Prevision[]
    readonly paginator?: IPaginator
}

export interface IActionLoad {
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
    readonly paginator?: IPaginator
}

export interface IActionAnnualPrevision {
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
    readonly year: number
}

export interface IActionUpdate {
    readonly prevision: Prevision
    readonly transactionType: PrevisionTransactionTypes
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly financialAgentId: string
    readonly idForRemove: string
    readonly transactionType: PrevisionTransactionTypes
    readonly paginator?: IPaginator
}

export interface IActionUpdateMany {
    readonly previsions: Prevision[]
    readonly transactionType: PrevisionTransactionTypes
    readonly paginator: IPaginator
}

export interface IAnnualPrevisionState extends IComponentState {
    readonly annualPrevision: AnnualTransaction[]
    readonly data: ErrorEvent
}


export interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly removeVisibilityModal: boolean
    readonly paginator?: IPaginator
}

export interface ICreateState extends IComponentState {
    readonly prevision: Prevision
    readonly dialog: boolean
}

/**
 * State type
 */
export interface IPrevisionState extends IComponentState {
    readonly initialValue: number | undefined
    readonly previsions: Prevision[] | undefined
    readonly previsionsForUpdate: Prevision[] | undefined
    readonly data: ErrorEvent
    readonly paginator: IPaginator

    readonly annual: IAnnualPrevisionState
    readonly remove: IRemoveState
    readonly create: ICreateState

    readonly description?: string
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
}
