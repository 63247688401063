import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { BudgetTypes } from './types'
import Budget from '../../application/models/budget/budget'
import Payee from '../../application/models/financial.agents/payee/payee'
import { addChecked } from '../exercise/actions'

/* Actions for Reset State */
export const reset = () => action(BudgetTypes.RESET, {})

export const resetAll = () => action(BudgetTypes.RESET_ALL, {})

/* Actions for Change */
export const changeBudget = (budget: Budget) => action(BudgetTypes.CHANGE_BUDGET, { budget })

export const changePaginator = (payeeId: string, paginator: IPaginator) => [
    action(BudgetTypes.CHANGE_PAGINATOR, { paginator }),
    load(payeeId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(BudgetTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, budgetId: string) => action(
    BudgetTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        budgetId
    }
)

export const changeDialogCreate = (dialog: boolean) => action(BudgetTypes.CHANGE_DIALOG_CREATE, { dialog })

/* Actions for Create Budget */
export const create = (budget: Budget) => action(BudgetTypes.CREATE_REQUEST, { budget })

export const createSuccess = (budget: Budget) => [
    action(BudgetTypes.CREATE_SUCCESS, { budget }),
    load(budget?.payee_id || ''),
    addChecked(budget?.payee_id || '', 'DUODECIMOS')
]

export const createFailure = (error: ErrorEvent, budget: Budget) => action(
    BudgetTypes.CREATE_FAILURE, {
        error,
        budget
    }
)

/* Actions for Load Budgets */
export const load = (payeeId: string, paginator?: IPaginator) => action(
    BudgetTypes.LOAD_REQUEST, {
        payeeId,
        paginator
    })

export const loadSuccess = (response: IAxiosResponse<Budget[]>) => action(
    BudgetTypes.LOAD_SUCCESS, {
        budgets: response.data,
        headers: response.headers
    }
)

export const loadFailure = (error: ErrorEvent) => action(BudgetTypes.LOAD_FAILURE, { error })

/* Actions for Find Budget */
export const find = (payeeId: string, budgetId: string) => action(
    BudgetTypes.FIND_REQUEST, {
        payeeId,
        budgetId
    })

export const findSuccess = (budget: Budget) => action(BudgetTypes.FIND_SUCCESS, { budget })

export const findFailure = (error: ErrorEvent) => action(BudgetTypes.FIND_FAILURE, { error })

/* Actions for Update Budget */
export const update = (budget: Budget) => action(BudgetTypes.UPDATE_REQUEST, { budget })

export const updateSuccess = (budget: Budget) => [
    action(BudgetTypes.UPDATE_SUCCESS, { budget }),
    load(budget?.payee_id || '')
]

export const updateFailure = (error: ErrorEvent, budget: Budget) => action(
    BudgetTypes.UPDATE_FAILURE, {
        error,
        budget
    }
)

/* Actions for Remove Budget */
export const remove = (payeeId: string, budgetId: string, paginator: IPaginator) => action(
    BudgetTypes.REMOVE_REQUEST, {
        payeeId,
        budgetId,
        paginator
    }
)

export const removeSuccess = () => action(BudgetTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(BudgetTypes.REMOVE_FAILURE, { error })

/* Actions for Find Payee */
export const findPayee = (payeeId: string) => action(
    BudgetTypes.FIND_PAYEE_REQUEST, { payeeId }
)

export const findPayeeSuccess = (payee: Payee) => action(BudgetTypes.FIND_PAYEE_SUCCESS, { payee })

export const findPayeeFailure = (error: ErrorEvent) => action(
    BudgetTypes.FIND_PAYEE_FAILURE, { error }
)
