import { Reducer } from 'redux'

import MultiStatusItem from '../../application/models/multi.status/multi.status.item'
import { IPrevisionState, PrevisionActionTypes } from './types'
import Prevision from '../../application/models/prevision/prevision'
import { PrevisionTransactionTypes } from '../../application/utils/prevision.transaction.types'

export const INITIAL_STATE: IPrevisionState = {
    initialValue: undefined,
    previsions: [],
    previsionsForUpdate: [],
    data: new ErrorEvent(''),
    loading: false,
    error: false,
    success: false,
    paginator: {
        first: 0,
        rows: 12,
        page: 0,
        pageCount: 0,
        totalRecords: 0,
        search: {
            key: '',
            value: ''
        }
    },
    annual: {
        annualPrevision: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false
    },
    remove: {

        idForRemove: '',
        loading: false,
        error: false,
        success: false,
        removeVisibilityModal: false,
        paginator: {
            first: 0,
            rows: 12,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    create: {
        prevision: new Prevision(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    description: '',
    financialAgentId: '',
    transactionType: '' as PrevisionTransactionTypes
}

const reducer: Reducer<IPrevisionState> = (state: IPrevisionState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case PrevisionActionTypes.CHANGE_REMOVE_MODAL:
            const {
                visibilityModal,
                idForRemove,
                financialAgentId,
                transactionType,
                paginator,
                description
            } = action.payload
            return {
                ...state,
                financialAgentId,
                transactionType,
                description,
                remove: {
                    ...state.remove,
                    removeVisibilityModal: visibilityModal,
                    idForRemove,
                    paginator
                }
            }

        case PrevisionActionTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case PrevisionActionTypes.CHANGE_PREVISION:
            const { prevision } = action.payload
            const typeOfTransaction = prevision?.transaction_type || state.transactionType
            return {
                ...state,
                transactionType: typeOfTransaction,
                create: { ...state.create, prevision: new Prevision().fromJSON(prevision) }
            }

        case PrevisionActionTypes.CHANGE_INITIAL_VALUE:
            const { initialValue } = action.payload
            return { ...state, initialValue }

        case PrevisionActionTypes.RESET:
            return INITIAL_STATE

        case PrevisionActionTypes.CREATE_REQUEST:
            return { ...state, loading: true, create: { ...state.create, loading: true } }

        case PrevisionActionTypes.CREATE_SUCCESS:
            const { success } = action?.payload
            const previsionsFormatted = formatterCreateReturn(success)
            const resultingPrevisions = previsionsFormatted?.length === 12 ? previsionsFormatted
                : state.previsions?.concat(previsionsFormatted)
            return {
                ...state,
                previsions: resultingPrevisions,
                success: true,
                loading: false,
                create: { ...state.create, loading: false, success: true }
            }

        case PrevisionActionTypes.CREATE_FAILURE:
            const previsionError = action.payload.error
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                data: previsionError,
                create: { ...state.create, loading: false, success: false, error: true }
            }

        case PrevisionActionTypes.LOAD_REQUEST:
            const {
                financialAgentId: loadFinancialAgentId,
                transactionType: loadTransactionType,
                description: loadDescription,
                paginator: loadPaginator
            } = action.payload
            return {
                ...state,
                previsions: [],
                initialValue: undefined,
                loading: true,
                financialAgentId: loadFinancialAgentId,
                transactionType: loadTransactionType,
                description: loadDescription,
                paginator: loadPaginator
            }

        case PrevisionActionTypes.LOAD_SUCCESS:
            const { previsions: previsionsSaved, headers: previsionsHeaders } = action.payload
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                previsions: previsionsSaved.map(previsionSaved => new Prevision().fromJSON(previsionSaved)),
                paginator: {
                    ...state.paginator, totalRecords: parseInt(previsionsHeaders['x-total-count'], 10)
                }
            }

        case PrevisionActionTypes.LOAD_FAILURE:
            return { ...state, loading: false, error: true, data: action.payload.error }

        case PrevisionActionTypes.LOAD_ANNUAL_REQUEST:
            return { ...state, annual: { ...state.annual, loading: true } }

        case PrevisionActionTypes.LOAD_ANNUAL_SUCCESS:
            const { annualPrevision } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    loading: false,
                    annualPrevision
                }
            }

        case PrevisionActionTypes.LOAD_ANNUAL_FAILURE:
            return { ...state, annual: { ...state.annual, loading: false, error: true, data: action.payload.error } }

        case PrevisionActionTypes.UPDATE_MANY_REQUEST:
            const { previsions: previsionsForUpdate } = action.payload
            return { ...state, loading: true, previsionsForUpdate }

        case PrevisionActionTypes.UPDATE_MANY_SUCCESS:
            return { ...state, previsionsForUpdate: [], success: true, loading: false }

        case PrevisionActionTypes.UPDATE_MANY_FAILURE:
            const updateError = action.payload.error
            return { ...state, loading: false, error: true, success: false, data: updateError }

        case PrevisionActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    removeVisibilityModal: false
                }
            }

        case PrevisionActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: true
                }
            }

        case PrevisionActionTypes.REMOVE_FAILURE:
            const removeError = action.payload.error
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: false,
                    error: true,
                    data: removeError
                }
            }

        case PrevisionActionTypes.UPDATE_REQUEST:
            return { ...state, create: { ...state.create, loading: true } }

        case PrevisionActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: true
                }
            }

        case PrevisionActionTypes.UPDATE_FAILURE:
            const updatePrevisionError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: updatePrevisionError
                }
            }

        default:
            return state
    }
}

const formatterCreateReturn = (success: Array<MultiStatusItem<Prevision>> | undefined): Prevision[] => {
    const previsions: any = success?.length ?
        success?.map((element: MultiStatusItem<Prevision>) => element.item)
        : false
    return previsions ? previsions : []
}

export default reducer
