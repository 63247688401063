/**
 * Action types
 */

import { IComponentState, IPaginator } from '../root.types'
import { DisbursementBalanceConfig } from '../../application/models/disbursement/disbursement.balance.config'

export enum DisbursementBalanceConfigActionTypes {
    RESET = '@disbursement.balance.config/RESET',

    CHANGE_PAGINATOR = '@disbursement.balance.config/CHANGE_PAGINATOR',
    CHANGE_CREATE_DIALOG = '@disbursement.balance.config/CHANGE_CREATE_DIALOG',

    CREATE_REQUEST = '@disbursement.balance.config/CREATE_REQUEST',
    CREATE_SUCCESS = '@disbursement.balance.config/CREATE_SUCCESS',
    CREATE_FAILURE = '@disbursement.balance.config/CREATE_FAILURE',

    LOAD_REQUEST = '@disbursement.balance.config/LOAD_REQUEST',
    LOAD_SUCCESS = '@disbursement.balance.config/LOAD_SUCCESS',
    LOAD_FAILURE = '@disbursement.balance.config/LOAD_FAILURE',

    UPDATE_REQUEST = '@disbursement.balance.config/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@disbursement.balance.config/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@disbursement.balance.config/UPDATE_FAILURE',

    CHANGE_REMOVE_DIALOG = '@disbursement.balance.config/CHANGE_REMOVE_DIALOG',

    REMOVE_REQUEST = '@disbursement.balance.config/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@disbursement.balance.config/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@disbursement.balance.config/REMOVE_FAILURE'
}

/* Actions  */

export interface IActionCreate {
    readonly data: DisbursementBalanceConfig
}

export interface IActionLoad {
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}

/* States */
interface ICreateState extends IComponentState {
    readonly data: DisbursementBalanceConfig
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly data: DisbursementBalanceConfig[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
}

/**
 * State type
 */
export interface IDisbursementBalanceConfigState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
