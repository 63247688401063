import { Reducer } from 'redux'
import { BalanceAgentTypes, IBalanceAgentState } from './types'
import BalanceAgent from '../../application/models/balance/balance.agent'

export const INITIAL_STATE: IBalanceAgentState = {
    createBalanceAgent: {
        balanceAgent: new BalanceAgent(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listBalanceAgents: {
        balanceAgents: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 20,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    removeBalanceAgent: {
        idForRemove: '',
        removeVisibilityModal: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IBalanceAgentState> = (state: IBalanceAgentState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case BalanceAgentTypes.RESET_BALANCE_AGENT:
            return {
                ...state,
                createBalanceAgent: INITIAL_STATE.createBalanceAgent
            }

        case BalanceAgentTypes.CHANGE_BALANCE_AGENT:
            const { balanceAgent: changeBalanceAgent } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: false,
                    error: false,
                    success: false,
                    balanceAgent: changeBalanceAgent
                }
            }

        case BalanceAgentTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listBalanceAgents: {
                    ...state.listBalanceAgents,
                    paginator
                }
            }

        case BalanceAgentTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listBalanceAgents: {
                    ...state.listBalanceAgents,
                    paginator: {
                        ...state.listBalanceAgents.paginator,
                        search
                    }
                }
            }

        case BalanceAgentTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                removeBalanceAgent: {
                    ...state.removeBalanceAgent,
                    removeVisibilityModal: visibilityModal,
                    idForRemove
                }
            }

        case BalanceAgentTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    dialog
                }
            }

        case BalanceAgentTypes.CREATE_REQUEST:
            const { balanceAgent: newBalanceAgent } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    balanceAgent: newBalanceAgent,
                    loading: true
                }
            }

        case BalanceAgentTypes.CREATE_SUCCESS:
            return {
                ...state,
                createBalanceAgent: {
                    ...INITIAL_STATE.createBalanceAgent,
                    success: true
                }
            }

        case BalanceAgentTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: false,
                    error: true,
                    success: false,
                    data: createError
                }
            }

        case BalanceAgentTypes.LOAD_REQUEST:
            return {
                ...state,
                listBalanceAgents: {
                    ...state.listBalanceAgents,
                    loading: true
                }
            }

        case BalanceAgentTypes.LOAD_SUCCESS:
            const { balanceAgents, headers } = action.payload
            return {
                ...state,
                listBalanceAgents: {
                    ...state.listBalanceAgents,
                    loading: false,
                    success: false,
                    error: false,
                    balanceAgents,
                    paginator: {
                        ...state.listBalanceAgents.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case BalanceAgentTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                listBalanceAgents: {
                    ...state.listBalanceAgents,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case BalanceAgentTypes.FIND_REQUEST:
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: true
                }
            }

        case BalanceAgentTypes.FIND_SUCCESS:
            const { balanceAgent: balanceAgentFound } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    balanceAgent: new BalanceAgent().fromJSON(balanceAgentFound),
                    loading: false
                }
            }

        case BalanceAgentTypes.FIND_FAILURE:
            const { error: findError } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: false,
                    error: true,
                    success: false,
                    data: findError
                }
            }

        case BalanceAgentTypes.UPDATE_REQUEST:
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: true
                }
            }

        case BalanceAgentTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: false
                }
            }

        case BalanceAgentTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                createBalanceAgent: {
                    ...state.createBalanceAgent,
                    loading: false,
                    success: false,
                    error: true,
                    data: updateError
                }
            }

        case BalanceAgentTypes.REMOVE_REQUEST:
            return {
                ...state,
                removeBalanceAgent: {
                    ...state.removeBalanceAgent,
                    load: true,
                    removeVisibilityModal: false
                }
            }

        case BalanceAgentTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeBalanceAgent: {
                    ...state.removeBalanceAgent,
                    loading: false,
                    success: true
                }
            }

        case BalanceAgentTypes.REMOVE_FAILURE:
            const { error: removeError } = action.payload
            return {
                ...state,
                removeBalanceAgent: {
                    ...state.removeBalanceAgent,
                    loading: false,
                    success: false,
                    error: true,
                    data: removeError
                }
            }

        default:
            return state
    }
}

export default reducer
