import { action } from 'typesafe-actions'
import { InvestmentsActionsTypes } from './types'
import Investments from '../../application/models/transactions/investments/investments'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import InvestmentsAnnualSummary from '../../application/models/transactions/investments/investments.annual.summary'
import { TransactionLocation } from '../costing/types'

/* Action for Reset State */
export const resetInvestments = () => action(InvestmentsActionsTypes.RESET_INVESTMENTS, {})

/* Actions for Change */
export const changeInvestments = (investment: Investments) => action(
    InvestmentsActionsTypes.CHANGE_INVESTMENTS,
    { investment }
)

export const changeDialog = (dialog: boolean) => action(InvestmentsActionsTypes.CHANGE_DIALOG, { dialog })

export const changeDate = (date: Date) => action(InvestmentsActionsTypes.CHANGE_DATE, { date })

export const changePaginator = (institutionId: string, paginator: IPaginator) => [
    action(InvestmentsActionsTypes.CHANGE_PAGINATOR, { paginator }),
    loadInvestments(institutionId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(InvestmentsActionsTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, institutionId: string) => action(
    InvestmentsActionsTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove,
        institutionId
    }
)

export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(InvestmentsActionsTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadInvestmentsMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(InvestmentsActionsTypes.RESET_MONTHLY_PAGINATOR, {})

export const changeAnnualPaginator = (year: string, paginator?: IPaginator) => [
    action(InvestmentsActionsTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadInvestmentsAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(InvestmentsActionsTypes.RESET_ANNUAL_PAGINATOR, {})


/* Actions for Create Investments */
export const createInvestments = (investments: Investments, location: TransactionLocation) => action(
    InvestmentsActionsTypes.CREATE_REQUEST,
    { investments, location }
)

export const createInvestmentsSuccess = (investments: Investments) => action(
    InvestmentsActionsTypes.CREATE_SUCCESS,
    { investments }
)

export const createInvestmentsFailure = (error: ErrorEvent, investments: Investments) => action(
    InvestmentsActionsTypes.CREATE_FAILURE, {
        error,
        investments
    }
)

/* Actions for Load Investments */
export const loadInvestments = (institutionId: string, paginator?: IPaginator) => action(
    InvestmentsActionsTypes.LOAD_REQUEST, {
        institutionId,
        paginator
    }
)

export const loadInvestmentsSuccess = (response: IAxiosResponse<Investments[]>) => action(
    InvestmentsActionsTypes.LOAD_SUCCESS, {
        investments: response.data,
        headers: response.headers
    }
)

export const loadInvestmentsFailure = (error: ErrorEvent) => action(InvestmentsActionsTypes.LOAD_FAILURE, { error })

/* Actions for Find Investments */
export const findInvestments = (institutionId: string, investmentsId: string) => action(
    InvestmentsActionsTypes.FIND_REQUEST, {
        institutionId,
        investmentsId
    }
)

export const findInvestmentsSuccess = (response: IAxiosResponse<Investments[]>) => action(
    InvestmentsActionsTypes.FIND_SUCCESS, {
        investment: response.data,
        headers: response.headers
    }
)

export const findInvestmentsFailure = (error: ErrorEvent) => action(InvestmentsActionsTypes.FIND_FAILURE, { error })

/* Actions for Update Investments */
export const updateInvestments = (investments: Investments, location: TransactionLocation) => action(
    InvestmentsActionsTypes.UPDATE_REQUEST,
    { investments, location }
)

export const updateInvestmentsSuccess = (investments: Investments) => action(
    InvestmentsActionsTypes.UPDATE_SUCCESS,
    { investments }
)

export const updateInvestmentsFailure = (error: ErrorEvent, investment: Investments) => action(InvestmentsActionsTypes.UPDATE_FAILURE, {
    error,
    investment
})

/* Actions for Remove Investments */
export const removeInvestments = (institutionId: string, investmentsId: string, location: TransactionLocation, paginator: IPaginator, date?: string) => action(
    InvestmentsActionsTypes.REMOVE_REQUEST, {
        institutionId,
        investmentsId,
        location,
        paginator,
        date
    })

export const removeInvestmentsSuccess = () => action(InvestmentsActionsTypes.REMOVE_SUCCESS, {})

export const removeInvestmentsFailure = (error: ErrorEvent) => action(InvestmentsActionsTypes.REMOVE_FAILURE, { error })

/* Actions for Find Institutions */
export const findInstitution = (institutionId: string) => action(InvestmentsActionsTypes.FIND_INSTITUTION_REQUEST, { institutionId })

export const findInstitutionSuccess = (response: IAxiosResponse<Institution>) => action(
    InvestmentsActionsTypes.FIND_INSTITUTION_SUCCESS, {
        institution: response.data,
        headers: response.headers
    })

export const findInstitutionFailure = (error: ErrorEvent) => action(InvestmentsActionsTypes.FIND_INSTITUTION_FAILURE, { error })

/* Actions for Load Monthly Investments */
export const loadInvestmentsMonthly = (date?: string, paginator?: IPaginator) => action(
    InvestmentsActionsTypes.LOAD_MONTHLY_REQUEST, {
        date,
        paginator
    })

export const loadInvestmentsMonthlySuccessReset = (response: IAxiosResponse<InvestmentsMonthlySummary>) => action(
    InvestmentsActionsTypes.LOAD_MONTHLY_SUCCESS_RESET, {
        monthlyInvestments: response.data,
        headers: response.headers
    })

export const loadInvestmentsMonthlySuccessConcat = (response: IAxiosResponse<InvestmentsMonthlySummary>) => action(
    InvestmentsActionsTypes.LOAD_MONTHLY_SUCCESS_CONCAT, {
        monthlyInvestments: response.data,
        headers: response.headers
    })

export const loadInvestmentsMonthlyFailure = (error: ErrorEvent) => action(InvestmentsActionsTypes.LOAD_MONTHLY_FAILURE, { error })

/* Actions for Load Annual Investments */
export const loadInvestmentsAnnual = (year: string, paginator?: IPaginator) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_REQUEST, {
        year,
        paginator
    })

export const loadInvestmentsAnnualSuccessReset = (response) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_SUCCESS_RESET,
    response
)


export const loadInvestmentsAnnualSuccessConcat = (response) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_SUCCESS_CONCAT,
    response
)


export const loadInvestmentsAnnualFailure = (error: ErrorEvent) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_FAILURE,
    { error }
)

export const loadAnnualDash = (year: string) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_DASH,
    { year }
)

export const loadAnnualDashSuccess = (response: IAxiosResponse<InvestmentsAnnualSummary>) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_DASH_SUCCESS,
    {
        investmentsAnnual: response.data
    }
)


export const loadAnnualDashFailure = (error: ErrorEvent) => action(
    InvestmentsActionsTypes.LOAD_ANNUAL_DASH_FAILURE,
    { error }
)
