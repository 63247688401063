import Revenue from './revenue'
import MonthlySummary from '../../summary/monthly.summary'
import { MonthlyPayer } from '../../financial.agents/payer/montlhy.payer'
import { RevenueCategory } from '../../financial.agents/payer/payer'

export default class RevenueMonthlySummary extends MonthlySummary<MonthlyPayer, Revenue> {
    private _total_net_operating_revenues: number | undefined
    private _total_other_incomes: number | undefined

    get total_net_operating_revenues(): number | undefined {
        return this._total_net_operating_revenues
    }

    set total_net_operating_revenues(value: number | undefined) {
        this._total_net_operating_revenues = value
    }

    get total_other_incomes(): number | undefined {
        return this._total_other_incomes
    }

    set total_other_incomes(value: number | undefined) {
        this._total_other_incomes = value
    }

    public fromJSON(json: any): RevenueMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyPayer().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_net_operating_revenues !== undefined) {
            this._total_net_operating_revenues = json.total_net_operating_revenues
        }

        if (json.total_other_incomes !== undefined) {
            this._total_other_incomes = json.total_other_incomes
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_net_operating_revenues: this.total_net_operating_revenues ? this.total_net_operating_revenues : undefined,
            total_other_incomes: this.total_other_incomes ? this.total_other_incomes : undefined
        }
    }

    public concatFromJSON(json: any): RevenueMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyPayer().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_net_operating_revenues !== undefined) {
            this._total_net_operating_revenues += json.total_net_operating_revenues
        }

        if (json.total_other_incomes !== undefined) {
            this._total_other_incomes += json.total_other_incomes
        }

        return this
    }

    public getDetailsDataForPayerCategory(revenueCategory: RevenueCategory): { enumeration: any, summary: any, translate: any, map: any } {
        let FINANCIAL_AGENTS: any = {}
        let TRANSLATE_FINANCIAL_AGENTS: any = {}
        this.financial_agents
            ?.filter((financialAgent: MonthlyPayer) => {
                return financialAgent?.transactions?.length &&
                    financialAgent.revenue_category === revenueCategory
            })
            ?.forEach((financialAgent: MonthlyPayer) => {
                const totalSum = financialAgent.transactions?.length ?
                    financialAgent.transactions
                        .map((revenue: Revenue) => revenue.value)
                        .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                            return (prevValue || 0) + (currentValue || 0)
                        })
                    : 0
                FINANCIAL_AGENTS = {
                    ...FINANCIAL_AGENTS,
                    [financialAgent?.name || '']: totalSum
                }
                TRANSLATE_FINANCIAL_AGENTS = {
                    ...TRANSLATE_FINANCIAL_AGENTS,
                    [financialAgent?.name || '']: financialAgent?.name || ''
                }
            })
        return {
            enumeration: TRANSLATE_FINANCIAL_AGENTS,
            summary: FINANCIAL_AGENTS,
            translate: TRANSLATE_FINANCIAL_AGENTS,
            map: TRANSLATE_FINANCIAL_AGENTS
        }
    }
}
