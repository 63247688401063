import { action } from 'typesafe-actions'
import { ISuggestionResponse, SuggestionActionTypes } from './types'
import { TransactionTypes } from '../../application/models/prevision/prevision'

export const reset = () => action(SuggestionActionTypes.RESET, {})

/* Actions for get Suggestions */
export const loadSuggestions = (institutionId: string, transactionType: TransactionTypes) => action(
    SuggestionActionTypes.LOAD_SUGGESTIONS,
    { institutionId, transactionType }
)

export const loadSuggestionsSuccess = (response: ISuggestionResponse) => {
    const { suggestions, typeRespectively } = response
    return action(
        SuggestionActionTypes.LOAD_SUGGESTIONS_SUCCESS,
        { suggestions, typeRespectively }
    )
}

export const loadSuggestionsFailure = (error: ErrorEvent) => action(
    SuggestionActionTypes.LOAD_SUGGESTIONS_FAILURE,
    { error }
)
