import React, { Component } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'primereact/button'

import '../container.style.scss'
import { IApplicationState } from '../../store'
import * as exerciseActions from '../../store/ducks/exercise/actions'
import Exercise from '../../store/application/models/exercise/exercise'
import { Margin } from '../costing/costing.by.institution'
import { Dialog } from 'primereact/dialog'

interface IProps {
    readonly exercises: Exercise[]
    readonly dialogBlocked: boolean
    readonly redirect?: string
}

interface IDispatchProps extends RouteComponentProps<any> {
    changeDialogBlocked(dialog: boolean, redirect?: string): void
}

type IUnionProps = IProps & IDispatchProps

export const MONTH_ENABLE_NEW_EXERCISE = '01'

class ExerciseDialogBlocked extends Component<IUnionProps> {

    public render() {
        const {
            changeDialogBlocked,
            dialogBlocked,
            redirect,
            history
        } = this.props

        const nextYear: number = new Date().getFullYear() + 1

        const footer = (
            <div className="d-flex justify-content-end">
                <Button
                    className="p-button-primary"
                    label="Entendido"
                    icon="pi pi-check"
                    onClick={() => {
                        if (redirect) {
                            history.push(redirect)
                        }
                        changeDialogBlocked(false, '')
                    }}
                />
            </div>
        )

        return <Dialog
            header="Funcionalidade Bloqueada"
            footer={footer}
            visible={dialogBlocked}
            closeOnEscape={true}
            className="dialog max-width-700"
            modal={true}
            onHide={() => {
                if (redirect) {
                    history.push(redirect)
                }
                changeDialogBlocked(false, '')
            }}>
            <Margin top={10} bottom={10} left={10} right={10}
                    className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="d-flex justify-content-center align-items-center">
                        <i className="pi pi-exclamation-triangle p-15"
                           style={{ 'fontSize': '3em' }}/>
                        <div>
                            <h6>O cadastro de um novo exercício só estará liberado em:</h6>
                            <h5><strong>Janeiro de {nextYear}.</strong></h5>
                        </div>
                    </div>
                </div>
            </Margin>
        </Dialog>
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    exercises: state.exercise.exercises,
    dialogBlocked: state.exercise.create.dialogBlocked,
    redirect: state.exercise.create.redirect
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(exerciseActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExerciseDialogBlocked))
