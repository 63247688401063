import { MonthlyFinancialAgent } from '../monthly.financial.agent'
import Revenue from '../../transactions/revenue/revenue'
import Payer, { NonOperatingRevenueCategory, RevenueCategory } from './payer'

export class MonthlyPayer extends MonthlyFinancialAgent<Revenue> {
    private _payer: Payer | undefined

    get id(): string | undefined {
        return this._payer?.id
    }

    get name(): string | undefined {
        return this._payer?.name
    }

    get revenue_category(): RevenueCategory | undefined {
        return this._payer?.revenue_category
    }

    get has_nop_revenues(): boolean | undefined {
        return this._payer?.has_nop_revenues
    }

    get nop_revenue_category(): NonOperatingRevenueCategory | undefined {
        return this._payer?.nop_revenue_category
    }

    public fromJSON(json: any): MonthlyPayer {
        super.fromJSON(json)

        if (json) {
            this._payer = new Payer().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            ...this._payer?.toJSON()
        }
    }
}
