import { JsonUtils } from '../../utils/json.util'

export enum UserTypes {
    ADMIN = 'admin',
    OPERATOR = 'operator',
    VISITOR = 'visitor',
    DISBURSEMENT = 'disbursement'
}

export default class User {
    private _id?: string
    private _created_at?: string
    private _name?: string
    private _cpf?: string
    private _access_exp_date?: string
    private _type?: UserTypes
    private _is_protected?: boolean
    private _last_login?: string

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get cpf(): string | undefined {
        return this._cpf
    }

    set cpf(value: string | undefined) {
        this._cpf = value
    }

    get access_exp_date(): string | undefined {
        return this._access_exp_date
    }

    set access_exp_date(value: string | undefined) {
        this._access_exp_date = value
    }

    get type(): UserTypes | undefined {
        return this._type
    }

    set type(value: UserTypes | undefined) {
        this._type = value
    }

    get is_protected(): boolean | undefined {
        return this._is_protected
    }

    get last_login(): string | undefined {
        return this._last_login
    }

    public fromJSON(json: any): User {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }
        if (json.name !== undefined) {
            this.name = json.name
        }
        if (json.cpf !== undefined) {
            this.cpf = json.cpf
        }

        if (json.access_exp_date !== undefined) {
            this._access_exp_date = json.access_exp_date
        }

        if (json.type !== undefined) {
            this.type = json.type
        }

        if (json.is_protected !== undefined) {
            this._is_protected = json.is_protected
        }

        if (json.last_login !== undefined) {
            this._last_login = json.last_login
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : '',
            created_at: this.created_at ? this.created_at : '',
            name: this.name ? this.name : '',
            cpf: this.cpf ? this.cpf : '',
            access_exp_date: this.access_exp_date ? this.access_exp_date : undefined,
            last_login: this.last_login ? this.last_login : undefined
        }
    }
}
/**
 *  Error returned in the exceptional scenario,
 *  as the user has valid access data, but does not have a set of permissions at the base of the system
 */
export const ERROR_400_EXCEPTION = {
    code: 400,
    message: 'The user provided does not have a registration in the internal repository.',
    description: 'It is necessary to contact the system administrator to register the user before trying again.'
}
