import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { Toast } from '../../../services/toast'
import previsionService from '../../../services/prevision'
import transactionService from '../../../services/transaction'
import { IActionType, IAxiosResponse } from '../root.types'
import {
    IActionAnnualPrevision,
    IActionCreate,
    IActionLoad,
    IActionRemove,
    IActionUpdate,
    IActionUpdateMany,
    PrevisionActionTypes
} from './types'
import { MultiStatus } from '../../application/models/multi.status/multi.status'
import Prevision from '../../application/models/prevision/prevision'
import {
    createFailure,
    createSuccess,
    loadAnnualFailure,
    loadAnnualSuccess,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateManyFailure,
    updateManySuccess,
    updateSuccess
} from './actions'

const toastService = Toast.getInstance()

function* createPrevision(action: IActionType<IActionCreate>) {
    try {
        const { previsions, financialAgentId, transactionType, paginator } = action.payload
        const response: IAxiosResponse<MultiStatus<Prevision>> = yield apply(
            previsionService,
            previsionService.create, [financialAgentId, transactionType, previsions]
        )
        yield put<any>(createSuccess(
            response.data,
            financialAgentId,
            transactionType,
            paginator
        ))
        const msg = previsions?.length > 1 ? 'Previsões criadas com sucesso' : 'Previsão criada com sucesso'
        toastService.show('success', msg, '')
    } catch (err) {
        yield put(createFailure(err))
    }
}

function* getPrevisions(action: IActionType<IActionLoad>) {
    try {
        const { financialAgentId, paginator, transactionType } = action.payload
        const response = yield apply(
            previsionService,
            previsionService.getAll,
            [financialAgentId, transactionType, paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getAnnualPrevision(action: IActionType<IActionAnnualPrevision>) {
    try {
        const { financialAgentId, year, transactionType } = action.payload
        const response = yield apply(transactionService, transactionService.getAnnualByInstitutionId, [financialAgentId, transactionType, year])
        yield put(loadAnnualSuccess(response))
    } catch (err) {
        yield put(loadAnnualFailure(err))
    }
}

function* updatePrevision(action: IActionType<IActionUpdate>) {
    try {
        const { prevision, transactionType, paginator } = action.payload
        const response = yield apply(previsionService, previsionService.update, [prevision])
        yield put<any>(updateSuccess(response.data, transactionType, paginator))
        toastService.show('success', 'Previsão atualizada com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err))
    }
}

function* updateManyPrevision(action: IActionType<IActionUpdateMany>) {
    try {
        const { previsions, transactionType, paginator } = action.payload
        yield apply(previsionService, previsionService.updateMany, [previsions])
        yield put<any>(updateManySuccess(previsions, transactionType, paginator))
        toastService.show('success', 'Previsões atualizada com sucesso', '')
    } catch (err) {
        yield put(updateManyFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    const { idForRemove, financialAgentId, transactionType, paginator } = action.payload
    try {
        yield apply(
            previsionService,
            previsionService.remove,
            [financialAgentId, idForRemove]
        )
        yield put<any>(removeSuccess(financialAgentId, transactionType, paginator))
        // yield put(loadPayers())
        toastService.show('success', 'Previsão removida com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

export default function* previsionSaga() {
    return yield all([
        takeLatest(PrevisionActionTypes.CREATE_REQUEST, createPrevision),
        takeLatest(PrevisionActionTypes.LOAD_REQUEST, getPrevisions),
        takeLatest(PrevisionActionTypes.UPDATE_REQUEST, updatePrevision),
        takeLatest(PrevisionActionTypes.UPDATE_MANY_REQUEST, updateManyPrevision),
        takeLatest(PrevisionActionTypes.LOAD_ANNUAL_REQUEST, getAnnualPrevision),
        takeLatest(PrevisionActionTypes.REMOVE_REQUEST, remove)
    ])
}
