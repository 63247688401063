import { DisbursementTransaction } from './disbursement.transaction'
import { DisbursementTransactionType } from '../../utils/disbursement.transaction.type'

/**
 * Implementation of the Output entity.
 *
 * @category Application
 * @subcategory Model
 *
 * @extends {DisbursementTransaction}
 *
 * @param {string} [description] Disbursement transaction description.
 * @param {string} [date] Disbursement transaction date.
 * @param {string} [value] Disbursement transaction value.
 * @param {string} [institution_id] Institution identifier.
 * @param {boolean} [siaf] Flag that identifies a resource automatically generated from the Siaf data response.
 */
export class Output extends DisbursementTransaction {
    public constructor() {
        super()
        this._transaction_type = DisbursementTransactionType.OUTPUT
    }
}
