import { all, fork } from 'redux-saga/effects'

import authSaga from './auth/sagas'
import userSaga from './users/sagas'
import institutionSaga from './institutions/sagas'
import balanceAgentSagas from './balance.agent/sagas'
import balanceSaga from './balance/sagas'
import costingSaga from './costing/sagas'
import previsionSaga from './prevision/sagas'
import eventSaga from './events/sagas'
import debtSaga from './debt/sagas'
import exerciseSaga from './exercise/sagas'
import investmentSaga from './investments/sagas'
import payeeSaga from './payee/sagas'
import expenseSaga from './expense/sagas'
import suggestionSaga from './suggestion/sagas'
import payerSaga from './payer/sagas'
import revenueSaga from './revenue/sagas'
import cashFlowSaga from './cashflow/sagas'
import scenarioSaga from './scenario/sagas'
import budgetSaga from './budget/sagas'
import supplementaryBudgetSaga from './supplementary.budget/sagas'
import auditSaga from './audit/sagas'
import exportSaga from './export/sagas'
import disbursementSaga from './disbursement/sagas'
import conferenceRevenueSaga from './conference.revenue/sagas'
import conferenceExpenseSaga from './conference.expense/sagas'
import disbursementReportBalanceSagas from './disbursement.report.balance/sagas'
import disbursementReportIOSagas from './disbursement.report.io/sagas'
import disbursementReportConfigSagas from './disbursement.report.config/sagas'
import disbursementBalanceConfigSagas from './disbursement.balance.config/sagas'
import disbursementReportBalanceObservationSagas from './disbursement.report.balance.observation/sagas'
import liberationSagas from './liberation/sagas'

export default function* rootSaga() {
    return yield all([
        fork(authSaga),
        fork(userSaga),
        fork(institutionSaga),
        fork(balanceAgentSagas),
        fork(balanceSaga),
        fork(costingSaga),
        fork(previsionSaga),
        fork(eventSaga),
        fork(debtSaga),
        fork(exerciseSaga),
        fork(investmentSaga),
        fork(payeeSaga),
        fork(expenseSaga),
        fork(suggestionSaga),
        fork(payerSaga),
        fork(revenueSaga),
        fork(cashFlowSaga),
        fork(scenarioSaga),
        fork(budgetSaga),
        fork(supplementaryBudgetSaga),
        fork(auditSaga),
        fork(exportSaga),
        fork(disbursementSaga),
        fork(conferenceRevenueSaga),
        fork(conferenceExpenseSaga),
        fork(disbursementReportBalanceSagas),
        fork(disbursementReportIOSagas),
        fork(disbursementReportConfigSagas),
        fork(disbursementBalanceConfigSagas),
        fork(liberationSagas),
        fork(disbursementReportBalanceObservationSagas)
    ])
}
