import { IComponentState, IPaginator } from '../root.types'
import Budget from '../../application/models/budget/budget'
import Payee from '../../application/models/financial.agents/payee/payee'

export enum BudgetTypes {
    RESET = '@budget/RESET',
    RESET_ALL = '@budget/RESET_ALL',

    CHANGE_BUDGET = '@budget/CHANGE_BUDGET',
    CHANGE_PAGINATOR = '@budget/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@budget/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@budget/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_CREATE = '@budget/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@budget/CREATE_REQUEST',
    CREATE_SUCCESS = '@budget/CREATE_SUCCESS',
    CREATE_FAILURE = '@budget/CREATE_FAILURE',

    LOAD_REQUEST = '@budget/LOAD_REQUEST',
    LOAD_SUCCESS = '@budget/LOAD_SUCCESS',
    LOAD_FAILURE = '@budget/LOAD_FAILURE',

    FIND_REQUEST = '@budget/FIND_REQUEST',
    FIND_SUCCESS = '@budget/FIND_SUCCESS',
    FIND_FAILURE = '@budget/FIND_FAILURE',

    FIND_PAYEE_REQUEST = '@budget/FIND_PAYEE_REQUEST',
    FIND_PAYEE_SUCCESS = '@budget/FIND_PAYEE_SUCCESS',
    FIND_PAYEE_FAILURE = '@budget/FIND_PAYEE_FAILURE',

    UPDATE_REQUEST = '@budget/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@budget/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@budget/UPDATE_FAILURE',

    REMOVE_REQUEST = '@budget/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@budget/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@budget/REMOVE_FAILURE'
}

/* Interface Actions */
export interface IActionCreate {
    readonly budget: Budget
}

export interface IActionLoad {
    readonly payeeId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly payeeId: string
    readonly budgetId: string
}

export interface IActionRemove {
    readonly payeeId: string
    readonly budgetId: string
    readonly paginator: IPaginator
}

export interface IActionFindPayee {
    readonly payeeId: string
}

/* Interfaces Component State */
export interface ICreateState extends IComponentState {
    readonly budget: Budget
    readonly data: ErrorEvent
    readonly dialog: boolean
}

export interface IListState extends IComponentState {
    readonly budgets: Budget[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly payee: Payee | undefined
}

export interface IRemoveState extends IComponentState {
    readonly payeeId: string
    readonly budgetId: string
    readonly visibilityModal: boolean
}

/* State Type */
export interface IBudgetState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
