import { InstitutionalExpense } from '../institutional.expense'
import { ExpenseStatus } from '../transaction'

export default class Event extends InstitutionalExpense {
    private _status: ExpenseStatus | undefined

    constructor() {
        super();
        this.id = ''
        this.created_at = ''
        this.description = ''
        this._status = '' as ExpenseStatus | undefined
        this.date = ''
        this.institution_id = ''
    }

    get status(): ExpenseStatus | undefined {
        return this._status
    }

    set status(value: ExpenseStatus | undefined) {
        this._status = value
    }

    public fromJSON(json: any): Event {
        super.fromJSON(json)

        if (json?.status !== undefined) {
            this._status = json.status
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            status: this.status ? this.status : undefined
        }
    }
}
