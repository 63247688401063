import { IComponentState, IPaginator } from '../root.types'
import Revenue from '../../application/models/transactions/revenue/revenue'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'
import { AnnualTransaction } from '../../application/models/transactions/annual.transaction'

export enum RevenuesActionsTypes {
    RESET_REVENUES = '@revenue/RESET_REVENUES',
    RESET_MONTHLY_PAGINATOR = '@revenue/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@revenue/RESET_ANNUAL_PAGINATOR',

    CHANGE_REVENUES = '@revenue/CHANGE_REVENUES',
    CHANGE_DIALOG = '@revenue/CHANGE_DIALOG',
    CHANGE_DATE = '@revenue/CHANGE_DATE',
    CHANGE_PAGINATOR = '@revenue/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@revenue/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@revenue/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@revenue/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@revenue/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@revenue/CREATE_REQUEST',
    CREATE_SUCCESS = '@revenue/CREATE_SUCCESS',
    CREATE_FAILURE = '@revenue/CREATE_FAILURE',

    LOAD_REQUEST = '@revenue/LOAD_REQUEST',
    LOAD_SUCCESS = '@revenue/LOAD_SUCCESS',
    LOAD_FAILURE = '@revenue/LOAD_FAILURE',

    FIND_REQUEST = '@revenue/FIND_REQUEST',
    FIND_SUCCESS = '@revenue/FIND_SUCCESS',
    FIND_FAILURE = '@revenue/FIND_FAILURE',

    UPDATE_REQUEST = '@revenue/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@revenue/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@revenue/UPDATE_FAILURE',

    REMOVE_REQUEST = '@revenue/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@revenue/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@revenue/REMOVE_FAILURE',

    FIND_PAYER_REQUEST = '@revenue/FIND_PAYER_REQUEST',
    FIND_PAYER_SUCCESS = '@revenue/FIND_PAYER_SUCCESS',
    FIND_PAYER_FAILURE = '@revenue/FIND_PAYER_FAILURE',

    LOAD_MONTHLY_REQUEST = '@revenue/LOAD_MONTHLY_REQUEST',
    LOAD_MONTHLY_SUCCESS_RESET = '@revenue/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@revenue/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@revenue/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL_REQUEST = '@revenue/LOAD_ANNUAL_REQUEST',
    LOAD_ANNUAL_SUCCESS_RESET = '@revenue/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@revenue/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@revenue/LOAD_ANNUAL_FAILURE',

    LOAD_ANNUAL_EXECUTED_REQUEST = '@revenue/LOAD_ANNUAL_EXECUTED_REQUEST',
    LOAD_ANNUAL_EXECUTED_SUCCESS = '@revenue/LOAD_ANNUAL_EXECUTED_SUCCESS',
    LOAD_ANNUAL_EXECUTED_FAILURE = '@revenue/LOAD_ANNUAL_EXECUTED_FAILURE'
}

export enum RevenueLocation {
    BY_PAYER = 'by_payer',
    MONTHLY = 'monthly'
}

/*
* Interface Actions
* */
export interface IActionCreate {
    readonly revenue: Revenue
    readonly location: RevenueLocation
}

export interface IActionLoad {
    readonly payerId: string
    readonly  paginator?: IPaginator
}

export interface IActionFind {
    readonly payerId: string
    readonly revenueId: string
}

export interface IActionRemove {
    readonly payerId: string
    readonly revenueId: string
    readonly paginator: IPaginator
    readonly date?: string
    readonly location: RevenueLocation
}

export interface IActionFindPayer {
    readonly payerId: string
}

export interface IActionAnnualExecuted {
    readonly payerId: string
    readonly year: number
}

/*
* Interface Component State
* */
interface ICreateState extends IComponentState {
    readonly revenue: Revenue
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly revenues: Revenue[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly payers: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly payerId: string
    readonly visibilityModal: boolean
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyRevenue: RevenueMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

export interface IAnnualExecutedState extends IComponentState {
    readonly annualRevenues: AnnualTransaction[]
    readonly data: ErrorEvent
}


interface IAnnualState extends IComponentState {
    readonly annualRevenue: RevenueAnnualSummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

/* State Type */
export interface IRevenueState {
    readonly createRevenue: ICreateState
    readonly listRevenues: IListState
    readonly removeRevenue: IRemoveState
    readonly monthlyRevenue: IMonthlyState
    readonly annualRevenue: IAnnualState
    readonly executedByPayer: IAnnualExecutedState
}

