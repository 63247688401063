import * as Yup from 'yup'
import { TransactionTypes } from '../models/prevision/prevision'

export class InitialPrevisionValidator {

    private static _initial_prevision: Yup.NumberSchema<number | null> = Yup.number()
        .test(
            'valueIsValid',
            'Informe um valor válido',
            (value) => value
        )
        .required('Informe um valor válido')
        .nullable()

    private static _transaction_type: Yup.StringSchema<string | null> = Yup.string()
        .oneOf(Object.values(TransactionTypes), 'Tipo de transação inválida')
        .required('Informe o tipo da transação')
        .nullable()

    private static _type_prevision: Yup.MixedSchema = Yup.mixed()
        .when('transaction_type', {
            is: (transactionType) => {
                return transactionType === TransactionTypes.DEBT || transactionType === TransactionTypes.INVESTMENT
            },
            then: Yup.mixed()
                .required('Selecione o Tipo')
                .nullable()
        })

    static get validationScheme(): Yup.ObjectSchema<object> {
        return Yup
            .object()
            .shape({

                initial_prevision: this.initial_prevision,

                type_prevision: this.type_prevision

            })
    }

    static get initial_prevision(): Yup.NumberSchema<number | null> {
        return this._initial_prevision
    }


    static get transaction_type(): Yup.StringSchema<string | null> {
        return this._transaction_type
    }


    static get type_prevision(): Yup.MixedSchema {
        return this._type_prevision
    }
}
