import { lazy } from 'react'
import { LogicalStrategy } from '../services/auth'

const Management = lazy(() => import('../containers/management'))
const List = lazy(() => import('../containers/budget/list.budgets'))
const Create = lazy(() => import('../containers/budget/create'))
const Duodecimos = lazy(() => import('../containers/payees/list.duodecimos'))
const CreatePayee = lazy(() => import('../containers/payees/create'))
const ExpenseByPayee = lazy(() => import('../containers/expense/expense.by.payee'))
const PrevisionsByPayee = lazy(() => import('../containers/payees/prevision.by.payee'))
const ListInstitutions = lazy(() => import('../containers/institutions/list.instituition'))
const ListPayers = lazy(() => import('../containers/payers/list.payers'))
const RevenueByPayer = lazy(() => import('../containers/revenue/revenue.by.payer'))
const ListPayees = lazy(() => import('../containers/payees/list.payees'))
const ListBalanceAgent = lazy(() => import('../containers/balance.agent/list.balance.agents'))
const CreateBalanceAgent = lazy(() => import('../containers/balance.agent/create'))
const Previsions = lazy(() => import('../containers/management/previsions'))

export const MANAGEMENT_ROUTES = [
    {
        path: '/app/management',
        component: Management,
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos',
        component: Duodecimos,
        scopes: ['fa:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos/new',
        component: Duodecimos,
        scopes: ['fa:c'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos/:payeeId',
        component: CreatePayee,
        scopes: ['bg:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos/:payeeId/budgets',
        component: List,
        scopes: ['bg:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos/:payeeId/budgets/new',
        component: Create,
        scopes: ['bg:c'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/duodecimos/:payeeId/budgets/:budgetId',
        component: Create,
        scopes: ['bg:r', 'bg:u'],
        logicalStrategy: LogicalStrategy.OR,
        exact: true,
        private: true
    },
    {
        path: '/app/management/institutions',
        component: ListInstitutions,
        scopes: ['fa:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payers',
        component: ListPayers,
        scopes: ['fa:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payers/:payerId',
        component: ListPayers,
        logicalStrategy: LogicalStrategy.OR,
        scopes: ['fa:c', 'fa:u'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payers/:payerId/revenues',
        component: RevenueByPayer,
        scopes: ['tr:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payees',
        component: ListPayees,
        scopes: ['fa:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payees/new',
        component: ListPayees,
        scopes: ['fa:c'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payees/:payeeId',
        component: ListPayees,
        logicalStrategy: LogicalStrategy.OR,
        scopes: ['fa:r', 'fa:u'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payees/:payeeId/expenses',
        component: ExpenseByPayee,
        scopes: ['tr:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/payees/:payeeId/previsions',
        component: PrevisionsByPayee,
        scopes: ['pr:c', 'pr:u'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/balance_agents',
        component: ListBalanceAgent,
        scopes: ['ba:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/balance_agents/new',
        component: CreateBalanceAgent,
        scopes: ['ba:c'],
        exact: true,
        private: true
    },
    {
        path: '/app/management/balance_agents/:balanceAgentId',
        component: CreateBalanceAgent,
        scopes: ['ba:r', 'ba:u'],
        logicalStrategy: LogicalStrategy.OR,
        exact: true,
        private: true
    },
    {
        path: '/app/management/previsions',
        component: Previsions,
        scopes: ['pr:c', 'pr:u'],
        logicalStrategy: LogicalStrategy.OR,
        exact: true,
        private: true
    }
]
