/**
 * Action types
 */
export enum LayoutTypes {
    CHANGE_TOGGLE = '@layout/CHANGE_TOGGLE',
    TOGGLE_ACTION = '@layout/TOGGLE_ACTION',
    CHANGE_USERNAME = '@layout/CHANGE_USERNAME',
    CHANGE_COLLAPSE = '@layout/CHANGE_COLLAPSE',
    UPDATE_AVAILABLE = '@layout/UPDATE_AVAILABLE'
}

/**
 * State type
 */
export interface ILayoutState {
    readonly username: string
    readonly toggle: boolean
    readonly toggleState: string
    readonly collapse: boolean
    readonly updateAvailable: boolean
    readonly registration: ServiceWorkerRegistration | undefined
}
