/**
 * Action types
 */

import { IComponentState, IPaginator } from '../root.types'
import { DisbursementTransaction } from '../../application/models/disbursement/disbursement.transaction'
import { DisbursementTransactionType } from '../../application/utils/disbursement.transaction.type'
import { DailyDisbursementSummary } from '../../application/models/disbursement/daily.disbursement.summary'
import { YearDisbursementSummaryItem } from '../../application/models/disbursement/year.disbursement.summary.item'
import { DisbursementConferenceItem } from '../../application/models/disbursement/conference.disbursement.item'
import { DisbursementReport } from '../../application/models/disbursement/disbursement.report'
import { DisbursementInputTransferLog } from '../../application/models/disbursement/disbursement.input.transfer.log'

export enum DisbursementActionTypes {
    RESET = '@disbursement/RESET',
    CHANGE = '@disbursement/CHANGE',
    CHANGE_CREATE_MODAL = '@disbursement/CHANGE_CREATE_MODAL',
    CHANGE_REMOVE_MODAL = '@disbursement/CHANGE_REMOVE_MODAL',

    CREATE_REQUEST = '@disbursement/CREATE_REQUEST',
    CREATE_SUCCESS = '@disbursement/CREATE_SUCCESS',
    CREATE_FAILURE = '@disbursement/CREATE_FAILURE',

    LOAD_REQUEST = '@disbursement/LOAD_REQUEST',
    LOAD_SUCCESS = '@disbursement/LOAD_SUCCESS',
    LOAD_FAILURE = '@disbursement/LOAD_FAILURE',

    FIND_REQUEST = '@disbursement/FIND_REQUEST',
    FIND_SUCCESS = '@disbursement/FIND_SUCCESS',
    FIND_FAILURE = '@disbursement/FIND_FAILURE',

    UPDATE_REQUEST = '@disbursement/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@disbursement/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@disbursement/UPDATE_FAILURE',

    REMOVE_REQUEST = '@disbursement/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@disbursement/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@disbursement/REMOVE_FAILURE',

    LOAD_BALANCE_REQUEST = '@disbursement/LOAD_BALANCE_REQUEST',
    LOAD_BALANCE_SUCCESS = '@disbursement/LOAD_BALANCE_SUCCESS',
    LOAD_BALANCE_FAILURE = '@disbursement/LOAD_BALANCE_FAILURE',

    LOAD_CONFERENCE_REQUEST = '@disbursement/LOAD_CONFERENCE_REQUEST',
    LOAD_CONFERENCE_SUCCESS = '@disbursement/LOAD_CONFERENCE_SUCCESS',
    LOAD_CONFERENCE_FAILURE = '@disbursement/LOAD_CONFERENCE_FAILURE',

    SYNC_REQUEST = '@disbursement/SYNC_REQUEST',
    SYNC_SUCCESS = '@disbursement/SYNC_SUCCESS',
    SYNC_FAILURE = '@disbursement/SYNC_FAILURE',

    REPORT_REQUEST = '@disbursement/REPORT_REQUEST',
    REPORT_SUCCESS = '@disbursement/REPORT_SUCCESS',
    REPORT_FAILURE = '@disbursement/REPORT_FAILURE'
}

/* Actions  */

export interface IActionCreate {
    readonly data: DisbursementTransaction | DisbursementInputTransferLog
}

export interface IActionFind {
    readonly type: DisbursementTransactionType
    readonly id: string
}

export interface IActionLoad {
    readonly type: DisbursementTransactionType
    readonly date: string
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly type: DisbursementTransactionType
    readonly ids: string[]
    readonly date: string
}


export interface IActionLoadBalance {
    readonly year: string
    readonly paginator: IPaginator
    readonly startDate?: string
    readonly endDate?: string
}

export interface IActionLoadReport {
    readonly date: string
}

/* States */
interface ICreateState extends IComponentState {
    readonly data: DisbursementTransaction | DisbursementInputTransferLog | any
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly data: DailyDisbursementSummary
    readonly paginator: IPaginator
    readonly sync: boolean
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly ids: string[]
}


interface IBalancesState extends IComponentState {
    readonly data: YearDisbursementSummaryItem[]
    readonly paginator: IPaginator
}

interface IConferenceState extends IComponentState {
    readonly data: DisbursementConferenceItem[]
    readonly paginator: IPaginator
}

interface IReportState extends IComponentState {
    readonly data: DisbursementReport
}

/**
 * State type
 */
export interface IDisbursementState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly balances: IBalancesState
    readonly conference: IConferenceState
    readonly report: IReportState
}
