import { InstitutionalExpense } from '../institutional.expense'

export default class Costing extends InstitutionalExpense {
    constructor() {
        super()
        this.id = ''
        this.created_at = ''
        this.description = ''
        this.date = ''
        this.institution_id = ''
    }
}

