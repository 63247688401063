import { Transaction } from '../transaction'

export default class Revenue extends Transaction {

    private _payer_id: string | undefined

    get payer_id(): string | undefined {
        return this._payer_id
    }

    set payer_id(value: string | undefined) {
        this._payer_id = value
    }

    public fromJSON(json: any): Revenue {
        super.fromJSON(json)

        if (json.payer_id !== undefined) {
            this._payer_id = json.payer_id
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            payer_id: this.payer_id ? this.payer_id : undefined
        }
    }

}