import { JsonUtils } from '../../utils/json.util'

export enum ExpenseStatus {
    REALIZED = 'realized',
    UNREALIZED = 'unrealized'
}

export enum TypeOfTransaction {
    INPUT = 'input',
    OUTPUT = 'output',
    BALANCE = 'balance'
}

export abstract class Transaction {
    private _id: string | undefined
    private _created_at: string | undefined
    private _description: string | undefined
    private _date: string | undefined
    private _value: number | undefined
    private _financial_agent_id: string | undefined
    private _note: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get description(): string | undefined {
        return this._description
    }

    set description(value: string | undefined) {
        this._description = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get financial_agent_id(): string | undefined {
        return this._financial_agent_id
    }

    set financial_agent_id(value: string | undefined) {
        this._financial_agent_id = value
    }

    get note(): string | undefined {
        return this._note
    }

    set note(value: string | undefined) {
        this._note = value
    }

    public fromJSON(json: any): Transaction {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json?.id !== undefined) {
            this._id = json.id
        }
        if (json?.created_at !== undefined) {
            this._created_at = json.created_at
        }
        if (json?.description !== undefined) {
            this._description = json.description
        }
        if (json?.date !== undefined) {
            this._date = json.date
        }
        if (json?.value !== undefined) {
            this._value = json.value
        }
        if (json?.financial_agent_id !== undefined) {
            this._financial_agent_id = json.financial_agent_id
        }
        if (json?.note !== undefined) {
            this._note = json.note
        }
        return this
    }

    public toJSON(): any {
        return {
            id: this._id ? this._id : undefined,
            created_at: this._created_at ? this._created_at : undefined,
            description: this.description ? this.description : undefined,
            date: this.date ? this.date : undefined,
            value: this.value ? this.value : undefined,
            financial_agent_id: this.financial_agent_id ? this.financial_agent_id : undefined,
            note: this.note ? this.note : undefined
        }
    }
}
