import { lazy } from 'react'

const DailySummary = lazy(() => import('../containers/liberations/daily'))
const DaySelected = lazy(() => import('../containers/liberations/day.selected'))
const MonthlySummary = lazy(() => import('../containers/liberations/monthly'))

export const LIBERATIONS_ROUTES = [
    {
        path: '/app/liberations/daily',
        component: DailySummary,
        exact: true,
        private: true
    },
    {
        path: '/app/liberations/daily/:date',
        component: DaySelected,
        exact: true,
        private: true
    },
    {
        path: '/app/liberations/monthly',
        component: MonthlySummary,
        exact: true,
        private: true
    }
]
