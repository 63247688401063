/**
 * Action types
 */
import Exercise from '../../application/models/exercise/exercise'

export enum ExportActionTypes {
    EXPORT_REQUEST = '@export/EXPORT_REQUEST'
}

/**
 *  Interface Actions
 */
export interface IActionExportData {
    readonly exerciseSelected: Exercise
    readonly month: string
    readonly elements: string[]
}
