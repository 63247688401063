import { all, apply, put, takeLatest } from 'redux-saga/effects'
import AuditService from '../../../services/audit'
import { loadFailure, loadSuccess, loadUsersFailure, loadUsersSuccess } from './actions'
import { AuditActionTypes } from './types'
import { IActionType, IAxiosResponse } from '../root.types'
import usersService from '../../../services/users'
import User, { UserTypes } from '../../application/models/auth/user'

function* getAll(action: IActionType) {
    const { paginator } = action.payload
    try {
        const response: IAxiosResponse = yield apply(
            AuditService,
            AuditService.getAll,
            [paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getAllUsers() {
    try {
        const admins: IAxiosResponse<User[]> = yield apply(
            usersService,
            usersService.getAll,
            [UserTypes.ADMIN]
        )
        const operators: IAxiosResponse<User[]> = yield apply(
            usersService,
            usersService.getAll,
            [UserTypes.OPERATOR]
        )
        const visitors: IAxiosResponse<User[]> = yield apply(
            usersService,
            usersService.getAll,
            [UserTypes.VISITOR]
        )
        const disbursement: IAxiosResponse<User[]> = yield apply(
            usersService,
            usersService.getAll,
            [UserTypes.DISBURSEMENT]
        )
        const response: any = [
            ...(admins.data || []),
            ...(operators?.data||[]),
            ...(visitors.data||[]),
            ...(disbursement.data || [])
        ]
        yield put(loadUsersSuccess(response))
    } catch (err) {
        yield put(loadUsersFailure(err))
    }
}

export default function* auditSaga() {
    return yield all([
        takeLatest(AuditActionTypes.LOAD_REQUEST, getAll),
        takeLatest(AuditActionTypes.LOAD_USER_REQUEST, getAllUsers)
    ])
}
