import { all, apply, put, takeLatest } from 'redux-saga/effects'

import {
    createFailure,
    createSuccess,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateSuccess
} from './actions'
import { IActionType, IAxiosResponse } from '../root.types'
import { Toast } from '../../../services/toast'
import { DisbursementBalanceConfigActionTypes, IActionCreate, IActionLoad, IActionRemove } from './types'
import disbursementBalanceConfigService from '../../../services/disbursement.balance.config'
import { DisbursementBalanceConfig } from '../../application/models/disbursement/disbursement.balance.config'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: DisbursementBalanceConfig = yield apply(
            disbursementBalanceConfigService,
            disbursementBalanceConfigService.create,
            [data]
        )
        yield put<any>(createSuccess(response))
        toastService.show('success', 'Configuração salva com sucesso!', '')
    } catch (err) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<DisbursementBalanceConfig[]> = yield apply(
            disbursementBalanceConfigService,
            disbursementBalanceConfigService.getAll,
            [paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(
            disbursementBalanceConfigService,
            disbursementBalanceConfigService.remove,
            [id]
        )
        yield put<any>(removeSuccess(id))
        toastService.show('success', 'Configuração removida com sucesso!', '')
    } catch (err) {
        yield put(removeFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { data } = action.payload
    try {
        const response: DisbursementBalanceConfig = yield apply(
            disbursementBalanceConfigService,
            disbursementBalanceConfigService.update,
            [data]
        )
        yield put<any>(updateSuccess(response))
        toastService.show('success', 'Configuração atualizada com sucesso!', '')
    } catch (err) {
        yield put(createFailure())
    }
}

export default function* disbursementBalanceConfigSaga() {
    return yield all([
        takeLatest(DisbursementBalanceConfigActionTypes.CREATE_REQUEST, create),
        takeLatest(DisbursementBalanceConfigActionTypes.LOAD_REQUEST, getAll),
        takeLatest(DisbursementBalanceConfigActionTypes.REMOVE_REQUEST, remove),
        takeLatest(DisbursementBalanceConfigActionTypes.UPDATE_REQUEST, update)
    ])
}
