import { action } from 'typesafe-actions'
import { InstitutionActionTypes } from './types'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'

/* Actions for Reset Create Institution */
export const resetCreateInstitution = () => action(InstitutionActionTypes.RESET_CREATE_INSTITUTION, {})

/* Actions for Change Institution */
export const changeInstitution = (institution: Institution) => action(
    InstitutionActionTypes.CHANGE_INSTITUTION,
    { institution }
)

export const changeDialog = (dialog: boolean) => action(
    InstitutionActionTypes.CHANGE_DIALOG,
    { dialog }
)

export const changeRemoveModal = (
    visibilityModal: boolean,
    idForRemove: string
) =>
    action(InstitutionActionTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove
    })


export const changePaginator = (paginator: IPaginator) => [
    action(InstitutionActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    InstitutionActionTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

/* Actions for Create Institution */
export const createInstitution = (institution: Institution) => action(
    InstitutionActionTypes.CREATE_REQUEST,
    { institution }
)

export const createSuccess = (institution: Institution) => [
    action(InstitutionActionTypes.CREATE_SUCCESS, { institution }),
    loadRequest()
]

export const createFailure = (error: ErrorEvent) => action(InstitutionActionTypes.CREATE_FAILURE, { error })

/* Actions for Find Institution */
export const findRequest = (institutionId: string) =>
    action(InstitutionActionTypes.FIND_REQUEST, { institutionId })

export const findSuccess = (institution: Institution) => action(InstitutionActionTypes.FIND_SUCCESS, { institution })

export const findFailure = (error: ErrorEvent) => action(InstitutionActionTypes.FIND_FAILURE, { error })

/* Actions for Update Institution */
export const updateRequest = (institution: Institution) =>
    action(InstitutionActionTypes.UPDATE_REQUEST, { institution })

export const updateSuccess = (institution: Institution) => action(InstitutionActionTypes.UPDATE_SUCCESS, { institution })

export const updateFailure = (error: ErrorEvent) => action(InstitutionActionTypes.UPDATE_FAILURE, { error })

/* Actions for Remove Institution */
export const removeRequest = (idForRemove: string) => action(InstitutionActionTypes.REMOVE_REQUEST, { idForRemove })

export const removeSuccess = () =>
    action(InstitutionActionTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(InstitutionActionTypes.REMOVE_FAILURE, { error })

/* Actions for Load Institutions */
export const loadRequest = (paginator?: IPaginator) => action(InstitutionActionTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<Institution[]>) =>
    action(InstitutionActionTypes.LOAD_SUCCESS, {
        institutions: response.data,
        headers: response.headers
    })

export const loadFailure = (error: ErrorEvent) => action(InstitutionActionTypes.LOAD_FAILURE, { error })
