import { JsonUtils } from '../../utils/json.util'

export enum RequestOperators {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

export default class AuditRequest {
    private _http_version?: string
    private _user_agent?: string
    private _remote_address?: string
    private _operator?: RequestOperators
    private _path?: string
    private _resource?: string
    private _body?: any

    get http_version(): string | undefined {
        return this._http_version
    }

    set http_version(value: string | undefined) {
        this._http_version = value
    }

    get user_agent(): string | undefined {
        return this._user_agent
    }

    get remote_address(): string | undefined {
        return this._remote_address
    }

    get operator(): RequestOperators | undefined {
        return this._operator
    }

    set operator(value: RequestOperators | undefined) {
        this._operator = value
    }

    get path(): string | undefined {
        return this._path
    }

    get resource(): string | undefined {
        return this._resource
    }

    set resource(value:string | undefined  ){
        this._resource = value
    }

    get body(): any | undefined {
        return this._body
    }

    get bodyIsEmpty(): boolean {
        return !this.body || JSON.stringify(this.body) === '{}'
    }

    public fromJSON(json: any): AuditRequest {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.http_version !== undefined) {
            this._http_version = json.http_version
        }

        if (json.user_agent !== undefined) {
            this._user_agent = json.user_agent
        }

        if (json.remote_address !== undefined) {
            this._remote_address = json.remote_address
        }

        if (json.operator !== undefined) {
            this._operator = json.operator
        }

        if (json.path !== undefined) {
            this._path = json.path
        }

        if (json.resource !== undefined) {
            this._resource = json.resource
        }

        if (json.body !== undefined) {
            this._body = json.body
        }

        return this
    }

    public toJSON(): any {
        return {
            http_version: this.http_version ? this.http_version : undefined,
            user_agent: this.user_agent ? this.user_agent : undefined,
            remote_address: this.remote_address ? this.remote_address : '',
            operator: this.operator ? this.operator : '',
            path: this.path ? this.path : '',
            resource: this.resource ? this.resource : '',
            body: this.body ? this.body : ''
        }
    }
}
