import { FinancialAgent } from '../financial.agent'

export enum PayeeCategory {
    UNMANAGEABLE = 'unmanageable',
    PERSONNEL_EXPENSES = 'personnel_expenses',
    GAC = 'general_administration_charges'
}

export default class Payee extends FinancialAgent {
    private _expense_category: PayeeCategory | undefined
    private _can_have_budgets: boolean | undefined
    private _siaf_code: number | undefined

    constructor() {
        super()
        this.expense_category = '' as PayeeCategory
        this.can_have_budgets = false
        this.siaf_code = 0
    }

    get expense_category(): PayeeCategory | undefined {
        return this._expense_category
    }

    set expense_category(value: PayeeCategory | undefined) {
        this._expense_category = value
    }

    get can_have_budgets(): boolean | undefined {
        return this._can_have_budgets
    }

    set can_have_budgets(value: boolean | undefined) {
        this._can_have_budgets = value
    }

    get siaf_code(): number | undefined {
        return this._siaf_code
    }

    set siaf_code(value: number | undefined) {
        this._siaf_code = value
    }

    public fromJSON(json: any): Payee {

        super.fromJSON(json)

        if (json.expense_category !== undefined) {
            this._expense_category = json.expense_category
        }

        if (json.can_have_budgets !== undefined) {
            this._can_have_budgets = json.can_have_budgets
        }

        if (json.siaf_code !== undefined) {
            this._siaf_code = json.siaf_code
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            expense_category: this.expense_category ? this.expense_category : undefined,
            can_have_budgets: this.can_have_budgets !== undefined ? this.can_have_budgets : undefined,
            siaf_code: this.siaf_code !== undefined ? this.siaf_code : undefined
        }
    }
}
