import { IPaginator } from '../store/ducks/root.types'
import UtilDatePicker from '../components/date.picker/utils'

export abstract class ApiService {

    protected buildDefaultParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            paginator = this.formatterPaginator(paginator)
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (paginator?.search?.key !== 'date' && paginator?.search?.value) {
                params.append(paginator.search.key, paginator.search.value)
            }

            if (paginator?.search?.key === 'date' && paginator?.search?.value) {

                const range = paginator?.search?.value
                const rangeSplit = range.split(' - ')

                if (UtilDatePicker.isValidDate(rangeSplit[0]) && UtilDatePicker.isValidDate(rangeSplit[1])) {
                    const start = UtilDatePicker.usaFormatDate(rangeSplit[0])
                    const end = UtilDatePicker.usaFormatDate(rangeSplit[1])

                    if (start === end) {
                        params.append(paginator?.search?.key, `gte:${start}T00:00:00.000Z`)
                        params.append(paginator?.search?.key, `lte:${start}T23:59:59.000Z`)
                    } else {
                        params.append(paginator?.search?.key, `gte:${start}`)
                        params.append(paginator?.search?.key, `lte:${end}`)
                    }
                }
            }
        }

        return params
    }

    private formatterPaginator(paginator: IPaginator): IPaginator {
        if (paginator?.search?.key === 'description' || paginator?.search?.key === 'name' || paginator?.search?.key === 'code') {
            return {
                ...paginator,
                search: { ...paginator.search, value: `*${paginator?.search?.value}*` }
            }
        }

        return paginator
    }

}

