import { all, apply, call, put, takeLatest } from 'redux-saga/effects'

import {
    createFailure,
    createSuccess,
    findFailure,
    findInstitutionFailure,
    findInstitutionSuccess,
    findSuccess,
    load,
    loadAnnualDashFailure,
    loadAnnualDashSuccess,
    loadAnnualFailure,
    loadAnnualSuccessConcat,
    loadAnnualSuccessReset,
    loadFailure,
    loadMonthly,
    loadMonthlyFailure,
    loadMonthlySuccessConcat,
    loadMonthlySuccessReset,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionMonthly, IActionType, IAxiosResponse } from '../root.types'
import { Toast } from '../../../services/toast'
import debtService from '../../../services/debt'
import { TransactionService } from '../../../services/transaction'
import institutionService from '../../../services/institutions'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import { DebtActionTypes, IActionCreate, IActionFind, IActionLoad } from './types'
import Debt from '../../application/models/transactions/debt/debt'
import UtilDatePicker from '../../../components/date.picker/utils'
import { AnnualInstitution } from '../../application/models/financial.agents/institution/annual.institution'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import { IActionFindInstitution, IActionLoadAnnual, IPaginator, TransactionLocation } from '../costing/types'

const toastService = Toast.getInstance()

const transactionService = new TransactionService<AnnualInstitution, Debt>()

function* refreshByLocation(location: TransactionLocation, institutionId: string, date: string, paginator?: IPaginator) {
    location === TransactionLocation.BY_INSTITUTION ?
        yield put(load(institutionId, paginator))
        : yield put(loadMonthly(date, paginator))
}

function* create(action: IActionType<IActionCreate>) {
    const { debt, dateOfInstallments, location } = action.payload
    try {
        let response: IAxiosResponse<Debt> | Array<IAxiosResponse<Debt>> | any
        if (dateOfInstallments?.length) {
            const debts = dateOfInstallments.map(date => {
                return new Debt().fromJSON({ ...debt.toJSON(), date })
            })
            response = yield apply(debtService, debtService.createMany, [debts])
            yield put<any>(createSuccess(response[0]?.data))
        } else {
            response = yield apply(debtService, debtService.create, [debt])
            yield put<any>(createSuccess(response?.data))
        }
        const institutionId = debt?.institution_id || ''
        const month = debt.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, month)
        toastService.show('success', 'Dívida criada com sucesso', '')
    } catch (err) {
        yield put(createFailure(err, debt))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { institutionId, paginator } = action.payload
        const response = yield apply(debtService, debtService.getAll, [institutionId, paginator])
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { institutionId, debtId } = action.payload
        const response = yield apply(debtService, debtService.getById, [institutionId, debtId])
        yield put(findSuccess(response))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* remove(action: IActionType) {
    try {
        const { institutionId, debtId, paginator, date, location } = action.payload
        yield apply(debtService, debtService.remove, [institutionId, debtId])
        yield put(removeSuccess())
        yield call(refreshByLocation, location, institutionId, date || '', paginator)
        toastService.show('success', 'Dívida removida com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { debt, location } = action.payload
    try {
        const response = yield apply(debtService, debtService.update, [debt])
        yield put<any>(updateSuccess(response.data))
        const institutionId = debt?.institution_id || ''
        const month = debt.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, month)
        toastService.show('success', 'Dívida atualizada com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err, debt))
    }
}

function* getInstitution(action: IActionType<IActionFindInstitution>) {
    const { institutionId } = action.payload
    try {
        const institution = yield apply(institutionService, institutionService.getById, [institutionId])
        const response: any = { data: institution, headers: {} }
        yield put(findInstitutionSuccess(response))
    } catch (err) {
        yield put(findInstitutionFailure(err))
    }
}

function* getMonthly(action: IActionType<IActionMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadMonthlySuccessConcat(response))
            : yield put(loadMonthlySuccessReset(response))
    } catch (err) {
        yield put(loadMonthlyFailure(err))
    }
}

function* getAnnual(action: IActionType<IActionLoadAnnual>) {
    const { year, paginator } = action.payload
    const responses: Array<IAxiosResponse<DebtMonthlySummary>> = Array.from(
        { length: 12 }, /* 12 months */
        (value, index) => {
            return {
                data: new DebtMonthlySummary(),
                headers: undefined
            }
        }
    )

    try {
        responses[0] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-01` : `${new Date().getFullYear()}-01`,
                paginator
            ])

        responses[1] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-02` : `${new Date().getFullYear()}-02`,
                paginator
            ])

        responses[2] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-03` : `${new Date().getFullYear()}-03`,
                paginator
            ])

        responses[3] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-04` : `${new Date().getFullYear()}-04`,
                paginator
            ])

        responses[4] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-05` : `${new Date().getFullYear()}-05`,
                paginator
            ])

        responses[5] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-06` : `${new Date().getFullYear()}-06`,
                paginator
            ])

        responses[6] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-07` : `${new Date().getFullYear()}-07`,
                paginator
            ])

        responses[7] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-08` : `${new Date().getFullYear()}-08`,
                paginator
            ])

        responses[8] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-09` : `${new Date().getFullYear()}-09`,
                paginator
            ])

        responses[9] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-10` : `${new Date().getFullYear()}-10`,
                paginator
            ])

        responses[10] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-11` : `${new Date().getFullYear()}-11`,
                paginator
            ])

        responses[11] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.DEBT,
                year ? `${year}-12` : `${new Date().getFullYear()}-12`,
                paginator
            ])

        const totalDirects = responses
            .map((response: IAxiosResponse<DebtMonthlySummary>) => response?.data?.total_direct_debts)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)


        const totalIndirects = responses
            .map((response: IAxiosResponse<DebtMonthlySummary>) => response?.data?.total_indirect_debts)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)

        const totalJudicialDecisionsAndAgreements = responses
            .map((response: IAxiosResponse<DebtMonthlySummary>) => response?.data?.total_jda)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)


        const total = (totalDirects || 0) +
            (totalIndirects || 0) +
            (totalJudicialDecisionsAndAgreements || 0)

        const xTotalCount = parseInt(responses[0].headers['x-total-count'], 10)

        const annualDebt = {
            january: responses[0].data,
            february: responses[1].data,
            march: responses[2].data,
            april: responses[3].data,
            may: responses[4].data,
            june: responses[5].data,
            july: responses[6].data,
            august: responses[7].data,
            september: responses[8].data,
            october: responses[9].data,
            november: responses[10].data,
            december: responses[11].data,
            totalDirects,
            totalIndirects,
            totalJudicialDecisionsAndAgreements,
            total,
            xTotalCount
        }


        paginator?.page && paginator?.page !== 0
            ? yield put(loadAnnualSuccessConcat(annualDebt))
            : yield put(loadAnnualSuccessReset(annualDebt))
    } catch (error) {
        yield put(loadAnnualFailure(error))
    }
}

function* getAnnualDash(action: IActionType) {
    const { year } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnual, [
                TransactionTypes.DEBT,
                year ? year : new Date().getFullYear()
            ]
        )
        yield put(loadAnnualDashSuccess(response))
    } catch (err) {
        yield put(loadAnnualDashFailure(err))
    }
}

export default function* debtSaga() {
    return yield all([
        takeLatest(DebtActionTypes.CREATE_REQUEST, create),
        takeLatest(DebtActionTypes.LOAD_REQUEST, getAll),
        takeLatest(DebtActionTypes.FIND_REQUEST, getById),
        takeLatest(DebtActionTypes.REMOVE_REQUEST, remove),
        takeLatest(DebtActionTypes.UPDATE_REQUEST, update),
        takeLatest(DebtActionTypes.FIND_INSTITUTION, getInstitution),
        takeLatest(DebtActionTypes.LOAD_MONTHLY, getMonthly),
        takeLatest(DebtActionTypes.LOAD_ANNUAL, getAnnual),
        takeLatest(DebtActionTypes.LOAD_ANNUAL_DASH, getAnnualDash)
    ])
}
