import { Reducer } from 'redux'
import { ISupplementaryBudgetState, SupplementaryBudgetTypes } from './types'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'

export const INITIAL_STATE: ISupplementaryBudgetState = {
    create: {
        supplementary: new SupplementaryBudget(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        supplementaries: [],
        data: new ErrorEvent(''),
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        supplementaryId: '',
        payeeId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<ISupplementaryBudgetState> = (state: ISupplementaryBudgetState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SupplementaryBudgetTypes.RESET: {
            return {
                ...state,
                create: INITIAL_STATE.create
            }
        }

        case SupplementaryBudgetTypes.CHANGE_SUPPLEMENTARY:
            const { supplementary: changeSupplementary } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: false,
                    success: false,
                    supplementary: changeSupplementary
                }
            }

        case SupplementaryBudgetTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    paginator
                }
            }

        case SupplementaryBudgetTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    paginator: {
                        ...state.list.paginator,
                        search
                    }
                }
            }

        case SupplementaryBudgetTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, supplementaryId } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    visibilityModal,
                    supplementaryId
                }
            }

        case SupplementaryBudgetTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    dialog
                }
            }

        case SupplementaryBudgetTypes.CREATE_REQUEST:
            const { supplementary: newRequestBudgetSupplementary } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    supplementary: newRequestBudgetSupplementary
                }
            }

        case SupplementaryBudgetTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    supplementary: new SupplementaryBudget(),
                    success: false,
                    loading: false
                }
            }

        case SupplementaryBudgetTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: createError
                }
            }

        case SupplementaryBudgetTypes.LOAD_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true
                }
            }

        case SupplementaryBudgetTypes.LOAD_SUCCESS:
            const { supplementaries, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    supplementaries,
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case SupplementaryBudgetTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case SupplementaryBudgetTypes.FIND_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case SupplementaryBudgetTypes.FIND_SUCCESS:
            const { supplementary: supplementaryFound } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    supplementary: new SupplementaryBudget().fromJSON(supplementaryFound),
                    loading: false
                }
            }

        case SupplementaryBudgetTypes.FIND_FAILURE:
            const { error: findError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: findError
                }
            }

        case SupplementaryBudgetTypes.UPDATE_REQUEST:
            const { supplementary: updateBudgetSupplementary } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    supplementary: updateBudgetSupplementary
                }
            }

        case SupplementaryBudgetTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false
                }
            }

        case SupplementaryBudgetTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: updateError
                }
            }

        case SupplementaryBudgetTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    visibilityModal: false
                }
            }

        case SupplementaryBudgetTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    supplementaryId: ''
                }
            }

        case SupplementaryBudgetTypes.REMOVE_FAILURE:
            const { error: removeError } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: false,
                    visibilityModal: true,
                    error: true,
                    data: removeError
                }
            }

        default:
            return state
    }
}

export default reducer
