import { Transaction } from './transaction'

export abstract class InstitutionalExpense extends Transaction {
    private _institution_id: string | undefined

    get institution_id(): string | undefined {
        return this._institution_id
    }

    set institution_id(value: string | undefined) {
        this._institution_id = value
    }

    public fromJSON(json: any): InstitutionalExpense {

        super.fromJSON(json)

        if (json?.institution_id !== undefined) {
            this._institution_id = json.institution_id
        }
        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            institution_id: this.institution_id ? this.institution_id : undefined
        }
    }
}
