import { Reducer } from 'redux'
import { IUserState, UserActionTypes } from './types'
import User from '../../application/models/auth/user'

export const INITIAL_STATE: IUserState = {
    profile: {
        user: new User(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false
    },
    createUser: {
        user: new User(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    removeUser: {
        userIdForRemove: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    list: {
        users: [],
        paginator: {
            first: 0,
            rows: 20,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: { key: '', value: '' }
        },
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IUserState> = (state: IUserState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case UserActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return {
                ...state,
                createUser: { ...state.createUser, dialog }
            }

        case UserActionTypes.CHANGE_USER:
            const { user } = action.payload
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    loading: false,
                    error: false,
                    success: false,
                    user: new User().fromJSON(user)
                },
                profile: {
                    ...state.profile,
                    loading: false,
                    error: false,
                    success: false,
                    user: new User().fromJSON(user)
                }
            }

        case UserActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                list: {
                    ...state.list, paginator: { ...state.list.paginator, search }
                }
            }

        case UserActionTypes.REMOVE_REQUEST:
            return { ...state, removeUser: { ...state.removeUser, loading: true, visibilityModal: false } }

        case UserActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeUser: {
                    ...state.removeUser,
                    loading: false,
                    visibilityModal: false,
                    sucess: true,
                    userIdForRemove: ''
                }
            }

        case UserActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                removeUser: { ...state.removeUser, loading: false, error: true, success: false, visibilityModal: true }
            }

        case UserActionTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, userIdForRemove } = action.payload
            return {
                ...state,
                removeUser: { ...state.removeUser, visibilityModal, userIdForRemove }
            }

        case UserActionTypes.FIND_REQUEST:
            return {
                ...state,
                createUser: { ...state.createUser, loading: true },
                profile: { ...state.profile, loading: true }
            }

        case UserActionTypes.FIND_SUCCESS:
            const findUser = action.payload.user
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    user: new User().fromJSON(findUser),
                    loading: false
                },
                profile: {
                    ...state.profile,
                    user: new User().fromJSON(findUser),
                    loading: false
                }
            }

        case UserActionTypes.FIND_FAILURE:
            const findError = action.payload.error
            return {
                ...state,
                createUser: { ...state.createUser, loading: false, error: true, success: false, data: findError },
                profile: {
                    ...state.profile,
                    user: new User(),
                    loading: false
                }
            }

        case UserActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                createUser: { ...state.createUser, loading: true }
            }

        case UserActionTypes.UPDATE_SUCCESS:

            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    loading: false
                }
            }

        case UserActionTypes.UPDATE_FAILURE:
            const updateError = action.payload.error
            return {
                ...state,
                createUser: { ...state.createUser, loading: false, error: true, success: false, data: updateError }
            }

        case UserActionTypes.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                profile: { ...state.profile, loading: true }
            }

        case UserActionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: false
                }
            }

        case UserActionTypes.UPDATE_PROFILE_FAILURE:
            const updateProfileError = action.payload.error
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: false,
                    error: true,
                    success: false,
                    data: updateProfileError
                }
            }

        case UserActionTypes.CREATE_REQUEST:
            return { ...state, createUser: { ...state.createUser, loading: true } }

        case UserActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                createUser: {
                    ...state.createUser,
                    success: true,
                    loading: false
                }
            }

        case UserActionTypes.CREATE_FAILURE:
            const createError = action.payload.error
            return {
                ...state,
                createUser: { ...state.createUser, loading: false, error: true, success: false, data: createError }
            }

        case UserActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            const newPaginator = {
                ...paginator,
                totalRecords: state.list.paginator.totalRecords
            }
            return { ...state, list: { ...state.list, loading: true, paginator: newPaginator } }

        case UserActionTypes.LOAD_USERS:
            return { ...state, list: { ...state.list, loading: true } }

        case UserActionTypes.LOAD_USERS_SUCCESS:
            const { users, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    users,
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case UserActionTypes.LOAD_USERS_FAILURE:
            return { ...state, list: { ...state.list, loading: false } }

        case UserActionTypes.RESET_CREATE_USER:
            return { ...state, createUser: INITIAL_STATE.createUser }


        case UserActionTypes.CHANGE_ICON_PASSWORD:
            const { icon } = action.payload
            return { ...state, profile: { ...state.profile, passwordIcon: icon } }

        default:
            return state
    }
}

export default reducer
