/**
 * Action types
 */
import User from '../../application/models/auth/user'
import { IPaginator } from '../root.types'

export enum UserActionTypes {
    RESET_CREATE_USER = '@users/RESET_CREATE_USER',

    CHANGE_USER = '@users/CHANGE_USER',
    CHANGE_PAGINATOR = '@users/CHANGE_PAGINATOR',
    CHANGE_DIALOG = '@users/CHANGE_DIALOG',
    CHANGE_SEARCH_PAGINATOR = '@users/CHANGE_SEARCH_PAGINATOR',

    CHANGE_ICON_PASSWORD = '@users/CHANGE_ICON_PASSWORD',

    CREATE_REQUEST = '@users/CREATE_REQUEST',
    CREATE_SUCCESS = '@users/CREATE_SUCCESS',
    CREATE_FAILURE = '@users/CREATE_FAILURE',

    FIND_REQUEST = '@users/FIND_REQUEST',
    FIND_SUCCESS = '@users/FIND_SUCCESS',
    FIND_FAILURE = '@users/FIND_FAILURE',

    UPDATE_REQUEST = '@users/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@users/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@users/UPDATE_FAILURE',

    CHANGE_REMOVE_MODAL = '@users/CHANGE_REMOVE_MODAL',
    REMOVE_REQUEST = '@users/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@users/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@users/REMOVE_FAILURE',

    LOAD_USERS = '@users/LOAD_USERS',
    LOAD_USERS_SUCCESS = '@users/LOAD_USERS_SUCCESS',
    LOAD_USERS_FAILURE = '@users/LOAD_USERS_FAILURE',

    UPDATE_PROFILE_REQUEST = '@users/UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS = '@users/UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE = '@users/UPDATE_PROFILE_FAILURE'
}

interface IComponentState {
    readonly loading: boolean
    readonly error: boolean,
    readonly success: boolean
}

interface ICreateState extends IComponentState {
    readonly user: User
    readonly data: ErrorEvent
    readonly dialog: boolean
}

export interface ICredentials {
    readonly old_password: string
    readonly new_password: string
    readonly confirm_password: string
}

interface IProfile extends IComponentState {
    readonly user: User
    readonly data: ErrorEvent
    readonly loading: boolean
    readonly error: boolean
    readonly success: boolean
}

interface IRemoveState extends IComponentState {
    readonly visibilityModal: boolean
    readonly userIdForRemove: string
}

interface IListUserState extends IComponentState {
    readonly users: User[]
    readonly paginator: IPaginator
}

/**
 * State type
 */
export interface IUserState {
    readonly createUser: ICreateState,
    readonly profile: IProfile,
    readonly removeUser: IRemoveState,
    readonly list: IListUserState
}
