import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Payer from '../store/application/models/financial.agents/payer/payer'
import { ApiService } from './api'

class PayerService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public async create(payer: Payer): Promise<IAxiosResponse<Payer>> {
        return httpClient.post(`${this.apiVersion}/payers`, payer)
    }

    public async getById(payerId: string): Promise<IAxiosResponse<Payer>> {
        return httpClient.get(`${this.apiVersion}/payers/${payerId}`)
    }

    public async getAll(paginator?: IPaginator): Promise<IAxiosResponse<Payer[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/payers`, { params })
    }

    public async update(payer: Payer): Promise<IAxiosResponse<Payer>> {
        return httpClient.patch(`${this.apiVersion}/payers/${payer.id}`, payer)
    }

    public async remove(payerId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payers/${payerId}`)
    }
}

export default new PayerService()
