import React, { Component } from 'react'
import { Provider } from 'react-redux'

import { Growl } from 'primereact/growl'

import './assets/scss/default.scss'
import store, { history } from './store'
import Routes from './routes/routes'
import Toast from './services/toast'
import * as PackageJson from '../package.json'

const VERSION = PackageJson.version

class App extends Component {

    public configToast(growl: any) {
        return Toast.growl = growl
    }

    public render() {
        const commonStyle = 'font-weight: bold;font-style: italic;font-style: italic;'
        const style1 = `${commonStyle};font-size: 40px;color: #50668F;`
        const style2 = `${commonStyle};font-size: 12px;padding-left: 5px;color: #555;`
        const style3 = `${commonStyle};font-size: 8px;color: #555;`

        console.log(`%cFC-e%cby SEFAZ-PB\n%cv${VERSION}`, style1, style2, style3)

        return (
            <Provider store={store}>
                <div className="container-full">
                    <Growl ref={(growl) => this.configToast(growl)}/>
                    <Routes history={history}/>
                </div>
            </Provider>
        )
    }
}

export default App

