/**
 * Action types
 */

import Costing from '../../application/models/transactions/costing/costing'
import { ISearch } from '../root.types'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'
import CostAnnualSummary from '../../application/models/transactions/costing/cost.annual.summary'

export enum CostingActionTypes {
    RESET_COSTING = '@costing/RESET_COSTING',
    RESET_MONTHLY_PAGINATOR = '@costing/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@costing/RESET_ANNUAL_PAGINATOR',

    CHANGE_COSTING = '@costing/CHANGE_COSTING',
    CHANGE_DIALOG = '@costing/CHANGE_DIALOG',
    CHANGE_MONTH = '@costing/CHANGE_MONTH',
    CHANGE_DATE = '@costing/CHANGE_DATE',
    CHANGE_PAGINATOR = '@costing/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@costing/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@costing/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@costing/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@costing/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@costing/CREATE_REQUEST',
    CREATE_SUCCESS = '@costing/CREATE_SUCCESS',
    CREATE_FAILURE = '@costing/CREATE_FAILURE',

    LOAD_REQUEST = '@costing/LOAD_REQUEST',
    LOAD_SUCCESS = '@costing/LOAD_SUCCESS',
    LOAD_FAILURE = '@costing/LOAD_FAILURE',

    FIND_REQUEST = '@costing/FIND_REQUEST',
    FIND_SUCCESS = '@costing/FIND_SUCCESS',
    FIND_FAILURE = '@costing/FIND_FAILURE',

    UPDATE_REQUEST = '@costing/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@costing/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@costing/UPDATE_FAILURE',

    REMOVE_REQUEST = '@costing/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@costing/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@costing/REMOVE_FAILURE',

    FIND_INSTITUTION = '@costing/FIND_INSTITUTION',
    FIND_INSTITUTION_SUCCESS = '@costing/FIND_INSTITUTION_SUCCESS',
    FIND_INSTITUTION_FAILURE = '@costing/FIND_INSTITUTION_FAILURE',

    LOAD_MONTHLY = '@costing/LOAD_MONTHLY',
    LOAD_MONTHLY_SUCCESS_RESET = '@costing/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@costing/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@costing/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL = '@costing/LOAD_ANNUAL',
    LOAD_ANNUAL_SUCCESS_RESET = '@costing/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@costing/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@costing/LOAD_ANNUAL_FAILURE'
}

export enum TransactionLocation {
    BY_INSTITUTION = 'by_institution',
    MONTHLY = 'monthly'
}

export interface IPaginator {
    first: number,
    rows: number,
    page: number,
    pageCount: number,
    totalRecords: number,
    search: ISearch
}

/* Actions  */

export interface IActionCreate {
    readonly costing: Costing
    readonly location: TransactionLocation
}

export interface IActionLoad {
    readonly institutionId: string
    readonly paginator?: IPaginator
}

export interface IActionLoadMonthly {
    readonly date?: string
    readonly paginator?: IPaginator
}

export interface IActionLoadAnnual {
    readonly year: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly institutionId: string
    readonly costingId: string
}

export interface IActionFindInstitution {
    readonly institutionId: string
}

export interface IActionRemove {
    readonly institutionId: string
    readonly costingId: string,
    readonly paginator: IPaginator,
    readonly date?: string
    readonly location: TransactionLocation
}


/* States */

interface IComponentState {
    readonly loading: boolean
    readonly error: boolean,
    readonly success: boolean
}

interface ICreateState extends IComponentState {
    readonly costing: Costing
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly costings: Costing[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly institution: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly institutionId: string
    readonly visibilityModal: boolean
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyCosting: CostMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IAnnualState extends IComponentState {
    readonly annualCosting: CostAnnualSummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

/**
 * State type
 */
export interface ICostingState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly monthly: IMonthlyState
    readonly annual: IAnnualState
}
