import { Reducer } from 'redux'
import { ILayoutState, LayoutTypes } from './types'

const INITIAL_STATE: ILayoutState = {
    username: '',
    toggle: false,
    toggleState: 'show',
    collapse: false,
    updateAvailable: false,
    registration: undefined
}

const reducer: Reducer<ILayoutState> = (state: ILayoutState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case LayoutTypes.TOGGLE_ACTION:
            const { toggleState } = action.payload
            return { ...state, toggleState }

        case LayoutTypes.CHANGE_TOGGLE:
            const { toggle } = action.payload
            return { ...state, toggle }

        case LayoutTypes.CHANGE_USERNAME:
            const { username } = action.payload
            return { ...state, username }

        case LayoutTypes.CHANGE_COLLAPSE:
            const { collapse } = action.payload
            return { ...state, collapse }

        case LayoutTypes.UPDATE_AVAILABLE:
            const { updateAvailable, registration } = action.payload
            return { ...state, updateAvailable, registration }

        default:
            return state
    }
}

export default reducer
