import { JsonUtils } from '../../utils/json.util'
import { IColumn } from '../../../../components/prevision/prevision.table'
import { ITotal } from '../../../../components/prevision/prevision'
import { AnnualTransaction } from '../transactions/annual.transaction'
import { PrevisionTransactionTypes } from '../../utils/prevision.transaction.types'

export enum TransactionTypes {
    REVENUE = 'revenue',
    COST = 'cost',
    EXPENSE = 'expense',
    DEBT = 'debt',
    EVENT = 'event',
    INVESTMENT = 'investment'
}

export default class Prevision {


    get id(): string {
        return this._id
    }

    set id(value: string) {
        this._id = value
    }

    get created_at(): string {
        return this._created_at
    }

    set created_at(value: string) {
        this._created_at = value
    }

    get updated_at(): string {
        return this._updated_at
    }

    set updated_at(value: string) {
        this._updated_at = value
    }

    get date(): string {
        return this._date
    }

    set date(value: string) {
        this._date = value
    }

    get expected_value(): number | undefined {
        return this._expected_value
    }

    set expected_value(value: number | undefined) {
        this._expected_value = value
    }

    get real_value(): number | undefined {
        return this._real_value
    }

    set real_value(value: number | undefined) {
        this._real_value = value
    }

    get initial_value(): number | undefined {
        return this._initial_value
    }

    set initial_value(value: number | undefined) {
        this._initial_value = value
    }

    get transaction_type(): PrevisionTransactionTypes | undefined {
        return this._transaction_type
    }

    set transaction_type(value: PrevisionTransactionTypes | undefined) {
        this._transaction_type = value
    }

    get financialagent_id(): string {
        return this._financialagent_id
    }

    set financialagent_id(value: string) {
        this._financialagent_id = value
    }

    public static formatterAnnual(year: number, previsions: Prevision[] | undefined, annual: AnnualTransaction[]): any {
        const months = [
            `${year}-01`,
            `${year}-02`,
            `${year}-03`,
            `${year}-04`,
            `${year}-05`,
            `${year}-06`,
            `${year}-07`,
            `${year}-08`,
            `${year}-09`,
            `${year}-10`,
            `${year}-11`,
            `${year}-12`
        ]
        const result = months
            ?.map((month) => {
                const prevision: Prevision = previsions
                    ?.find(previsionElement => previsionElement.date === month) || new Prevision().fromJSON({ date: month })
                const exec = annual?.find(element => element.date === prevision.date)?.total_value || 0
                const prev = prevision?.expected_value || 0
                return {
                    [`${prevision.date}-exec`]: exec,
                    [`${prevision.date}-prev`]: prev
                }
            })
        return result?.length ? result.reduce((prev, current) => {
            return { ...prev, ...current }
        }) : {}
    }

    public static extractColumns(year): { 1: IColumn[], 2: IColumn[], 3: IColumn[], 4: IColumn[] } {
        const months = [
            `${year}-01-exec`, `${year}-01-prev`, `${year}-02-exec`, `${year}-02-prev`, `${year}-03-exec`, `${year}-03-prev`,
            `${year}-04-exec`, `${year}-04-prev`, `${year}-05-exec`, `${year}-05-prev`, `${year}-06-exec`, `${year}-06-prev`,
            `${year}-07-exec`, `${year}-07-prev`, `${year}-08-exec`, `${year}-08-prev`, `${year}-09-exec`, `${year}-09-prev`,
            `${year}-10-exec`, `${year}-10-prev`, `${year}-11-exec`, `${year}-11-prev`, `${year}-12-exec`, `${year}-12-prev`]
        return {
            1: months.filter((month, index) => index <= 5)
                .map(month => {
                    return { field: month, header: '' }
                }),
            2: months.filter((month, index) => index > 5 && index <= 11)
                .map(month => {
                    return { field: month, header: '' }
                }),
            3: months.filter((month, index) => index > 11 && index <= 17)
                .map(month => {
                    return { field: month, header: '' }
                }),
            4: months.filter((month, index) => index > 17)
                .map(month => {
                    return { field: month, header: '' }
                })
        }
    }

    public static extractTotals(previsions: Prevision[] | undefined, annual: AnnualTransaction[]): ITotal {
        const expected: any = previsions
            ?.map((prevision: Prevision) => prevision.expected_value)
            ?.reduce((prev: number | undefined, current: number | undefined) => (prev || 0) + (current || 0), 0) || 0

        const real = annual
            ?.map(t => t.total_value)
            ?.reduce((prev: number | undefined, current: number | undefined) => {
                return (prev || 0) + (current || 0)
            }, 0) || 0

        const initial: number = previsions
            ?.map((prevision: Prevision) => prevision.initial_value)
            ?.reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0) || 0
        return { initial_expected: initial, expected: expected || 0, real }
    }


    public static isRealized(month: string): boolean {
        const yearCurrent = new Date().getFullYear()
        const monthCurrent = new Date().getMonth() + 1
        const yearSelected = Number(month.split('-')[0])
        const monthSelected = Number(month.split('-')[1])
        return (yearSelected < yearCurrent) || (yearSelected === yearCurrent && monthSelected < monthCurrent)
    }

    public static isCurrentMonth(month: string): boolean {
        const yearCurrent = new Date().getFullYear()
        const monthCurrent = new Date().getMonth() + 1
        const yearSelected = Number(month.split('-')[0])
        const monthSelected = Number(month.split('-')[1])
        return (yearSelected === yearCurrent) && (monthSelected === monthCurrent)
    }

    private _id: string
    private _created_at: string
    private _updated_at: string
    private _date: string
    private _expected_value: number | undefined
    private _real_value: number | undefined
    private _initial_value: number | undefined
    private _transaction_type: PrevisionTransactionTypes | undefined
    private _financialagent_id: string


    constructor() {
        this._id = ''
        this._created_at = ''
        this._updated_at = ''
        this._date = ''
        this._financialagent_id = ''
    }

    public fromJSON(json: any): Prevision {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this._id = json.id
        }
        if (json.created_at !== undefined) {
            this._created_at = json.created_at
        }
        if (json.updated_at !== undefined) {
            this._updated_at = json.updated_at
        }
        if (json.date !== undefined) {
            this._date = json.date
        }
        if (json.expected_value !== undefined) {
            this._expected_value = json.expected_value
        }
        if (json.real_value !== undefined) {
            this._real_value = json.real_value
        }
        if (json.initial_value !== undefined) {
            this._initial_value = json.initial_value
        }
        if (json.transaction_type !== undefined) {
            this._transaction_type = json.transaction_type
        }
        if (json.financialagent_id !== undefined) {
            this._financialagent_id = json.financialagent_id
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this._id ? this.id : undefined,
            created_at: this._created_at ? this._created_at : undefined,
            updated_at: this._updated_at ? this._updated_at : undefined,
            date: this.date ? this.date : undefined,
            expected_value: this.expected_value ? this.expected_value : undefined,
            real_value: this.real_value ? this.real_value : undefined,
            initial_value: this.initial_value ? this.initial_value : undefined,
            transaction_type: this.transaction_type ? this.transaction_type : undefined,
            financialagent_id: this.financialagent_id ? this.financialagent_id : undefined
        }
    }

}
