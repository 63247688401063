import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import ScenarioDataModel from '../store/application/models/scenario/scenario.data.model'

export class ScenariosService {

    constructor(private apiVersion: string = 'v1') {
    }

    public async create(scenario: ScenarioDataModel): Promise<IAxiosResponse<number>> {
        return httpClient.post(`${this.apiVersion}/exercises/${scenario.exercise_id}/scenarios`, scenario.toJSON())
    }

    public async getAll(exerciseId: string, paginator?: IPaginator): Promise<IAxiosResponse<ScenarioDataModel[]>> {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }

        return httpClient.get(`${this.apiVersion}/exercises/${exerciseId}/scenarios`, { params })
    }

    public async update(scenario: ScenarioDataModel): Promise<IAxiosResponse<number>> {
        const exerciseId = scenario.exercise_id
        const body = scenario.toJSON()
        delete body.exercise_id
        return httpClient
          .patch(`${this.apiVersion}/exercises/${exerciseId}/scenarios/${scenario.id}`, body)
    }

    public async remove(exerciseId: string, scenarioId: string): Promise<IAxiosResponse<number>> {
        return httpClient.delete(`${this.apiVersion}/exercises/${exerciseId}/scenarios/${scenarioId}`)
    }
}

export default new ScenariosService()
