import { action } from 'typesafe-actions'
import { DisbursementBalanceConfigActionTypes } from './types'
import { IAxiosResponse, IPaginator } from '../root.types'
import { DisbursementBalanceConfig } from '../../application/models/disbursement/disbursement.balance.config'

/* Actions for Reset State */
export const reset = () => action(DisbursementBalanceConfigActionTypes.RESET)

/* Actions for Load Disbursement Balance Config */
export const changePaginator = (paginator: IPaginator) => [
    action(DisbursementBalanceConfigActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

/* Actions for Create Disbursement Balance Config */

export const changeCreateModal = (dialog: boolean, data: DisbursementBalanceConfig) => action(
    DisbursementBalanceConfigActionTypes.CHANGE_CREATE_DIALOG, { dialog, data }
)

export const createRequest = (data: DisbursementBalanceConfig) => action(
    DisbursementBalanceConfigActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: DisbursementBalanceConfig) => action(
    DisbursementBalanceConfigActionTypes.CREATE_SUCCESS,
    { data }
)

export const createFailure = () => action(DisbursementBalanceConfigActionTypes.CREATE_FAILURE)

/* Actions for Load Disbursement Balance Config */
export const loadRequest = (paginator: IPaginator) => action(
    DisbursementBalanceConfigActionTypes.LOAD_REQUEST,
    { paginator }
)

export const loadSuccess = (response: IAxiosResponse<DisbursementBalanceConfig[]>) => action(
    DisbursementBalanceConfigActionTypes.LOAD_SUCCESS,
    { data: response.data, headers: response.headers }
)

export const loadFailure = () => action(DisbursementBalanceConfigActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement Balance Config */

export const changeRemoveModal = (dialog: boolean, id: string) => action(
    DisbursementBalanceConfigActionTypes.CHANGE_REMOVE_DIALOG, { dialog, id }
)

export const removeRequest = (id: string) => action(
    DisbursementBalanceConfigActionTypes.REMOVE_REQUEST,
    { id }
)

export const removeSuccess = (id: string) => action(
    DisbursementBalanceConfigActionTypes.REMOVE_SUCCESS,
    { id }
)

export const removeFailure = () => action(DisbursementBalanceConfigActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement Balance Config */
export const updateRequest = (data: DisbursementBalanceConfig) => action(
    DisbursementBalanceConfigActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: DisbursementBalanceConfig) => action(
    DisbursementBalanceConfigActionTypes.UPDATE_SUCCESS,
    { data }
)

export const updateFailure = () => action(DisbursementBalanceConfigActionTypes.UPDATE_FAILURE)
