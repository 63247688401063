import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementReportBalance } from '../store/application/models/disbursement/disbursement.report.balance'

class DisbursementReportBalanceService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: DisbursementReportBalance): Promise<DisbursementReportBalance> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/reports/date/${data?.date}/balances`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportBalance().fromJSON(response.data))
    }

    public getByDate(date: string): Promise<DisbursementReportBalance[]> {
        return httpClient
            .get(`${this.apiVersion}/disbursement/reports/date/${date}/balances`)
            .then((response: IAxiosResponse<any>) =>
                response?.data?.map((item: any) => new DisbursementReportBalance().fromJSON(item)) || []
            )
    }

    public update(data: DisbursementReportBalance): Promise<DisbursementReportBalance> {
        return httpClient
            .patch(`${this.apiVersion}/disbursement/reports/date/${data?.date}/balances/${data?.id}`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportBalance().fromJSON(response.data))
    }

    public remove(date: string, id: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/disbursement/reports/date/${date}/balances/${id}`)
    }
}

export default new DisbursementReportBalanceService()

