import { Transaction } from '../transactions/transaction'
import { JsonUtils } from '../../utils/json.util'
import Prevision from '../prevision/prevision'

export abstract class MonthlyFinancialAgent<T extends Transaction> {
    private _previsions: Prevision[] | undefined
    private _transactions: T[] | undefined

    abstract get id(): string | undefined

    get transactions(): T[] | undefined {
        return this._transactions
    }

    set transactions(value: T[] | undefined) {
        this._transactions = value
    }

    get previsions(): Prevision[] | undefined {
        return this._previsions
    }

    set previsions(value: Prevision[] | undefined) {
        this._previsions = value
    }

    public fromJSON(json: any): MonthlyFinancialAgent<T> {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.transactions !== undefined) {
            this._transactions = json.transactions
        }

        if (json.previsions !== undefined) {
            this._previsions = json.previsions.map(prevision => new Prevision().fromJSON(prevision))
        }

        return this
    }

    public toJSON(): any {
        return {
            transactions: this.transactions ? this.transactions : undefined,
            previsions: this.previsions ? this.previsions.map(prevision => prevision.toJSON()) : undefined
        }
    }
}
