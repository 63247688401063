import { Component } from 'react'
import memoizee from 'memoizee'
import authService, { LogicalStrategy } from '../../services/auth'

interface IProps {
    readonly usersType: string[]
    readonly logicalStrategy?: LogicalStrategy
}

export class VerifyUserType extends Component<IProps> {

    public static validate(expectedUsers?: string[], logicalStrategy = LogicalStrategy.AND): boolean {
        return VerifyUserType._validateMemo(expectedUsers, logicalStrategy)
    }

    private static _validateMemo: any

    private static validateUserType(expectedUsers?: string[], logicalStrategy = LogicalStrategy.AND): boolean {
        if (!logicalStrategy) {
            logicalStrategy = LogicalStrategy.AND
        }
        if (expectedUsers) {
            try {
                const { sub_type } = authService.decodeToken()
                const usersTypes = [`${sub_type}`]
                if (expectedUsers.length === 0) {
                    return true
                }
                return logicalStrategy === LogicalStrategy.AND ?
                    expectedUsers.every((itemScope: string) => usersTypes.includes(itemScope))
                    : expectedUsers.some((itemScope: string) => usersTypes.includes(itemScope))
            } catch (e) {
                return false
            }
        }
        return true
    }

    constructor(props: IProps) {
        super(props)
        VerifyUserType.validate = VerifyUserType.validate.bind(this)
        VerifyUserType.validateUserType = VerifyUserType.validateUserType.bind(this)
        VerifyUserType._validateMemo = memoizee(VerifyUserType.validateUserType, { length: 2, primitive: true })
    }

    public render() {
        const { usersType, children, logicalStrategy } = this.props
        if (VerifyUserType.validate(usersType, logicalStrategy)) {
            return children
        }
        return ''
    }
}
