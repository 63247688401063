import { Reducer } from 'redux'
import { ExpenseActionTypes, IExpenseState } from './types'
import Expense from '../../application/models/transactions/expense/expense'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'

export const INITIAL_STATE: IExpenseState = {
    create: {
        expense: new Expense(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        expenses: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        payee: undefined
    },
    remove: {
        idForRemove: '',
        payeeId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthly: {
        monthlyExpense: new ExpenseMonthlySummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annual: {
        annualExpense: new ExpenseAnnualSummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

const reducer: Reducer<IExpenseState> = (state: IExpenseState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ExpenseActionTypes.CHANGE_EXPENSE:
            const { expense } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: false,
                    success: false,
                    expense: new Expense().fromJSON(expense)
                }
            }
        case ExpenseActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, paginator } }

        case ExpenseActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case ExpenseActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }

        case ExpenseActionTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthly: { ...state.monthly, paginator: monthlyPaginator }
            }

        case ExpenseActionTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthly: { ...state.monthly, paginator: INITIAL_STATE.monthly.paginator }
            }

        case ExpenseActionTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annual: { ...state.annual, paginator: { ...state.annual.paginator, ...annualPaginator } }
            }

        case ExpenseActionTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annual: { ...state.annual, paginator: INITIAL_STATE.annual.paginator }
            }

        case ExpenseActionTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, payeeId } = action.payload
            return {
                ...state,
                remove: { ...state.remove, visibilityModal, idForRemove, payeeId }
            }

        case ExpenseActionTypes.CREATE_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case ExpenseActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    expense: new Expense().fromJSON({ payee_id: state.create.expense.payee_id }),
                    success: true,
                    loading: false
                }
            }

        case ExpenseActionTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: createError }
            }

        case ExpenseActionTypes.RESET_EXPENSE:
            return { ...state, create: INITIAL_STATE.create }

        case ExpenseActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case ExpenseActionTypes.LOAD_SUCCESS:
            const { expenses, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    expenses,
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case ExpenseActionTypes.LOAD_FAILURE:
            const { error } = action.payload
            return { ...state, list: { ...state.list, loading: false, error: true, data: error } }

        case ExpenseActionTypes.FIND_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case ExpenseActionTypes.FIND_SUCCESS:
            const { expense: expenseFound } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    expense: new Expense().fromJSON(expenseFound),
                    loading: false
                }
            }

        case ExpenseActionTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: errorFound }
            }

        case ExpenseActionTypes.REMOVE_REQUEST:
            return { ...state, remove: { ...state.remove, loading: true, visibilityModal: false } }

        case ExpenseActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case ExpenseActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: { ...state.remove, loading: false, error: true, success: false, visibilityModal: true }
            }

        case ExpenseActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case ExpenseActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: { ...state.create, loading: false }
            }

        case ExpenseActionTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: updateError }
            }

        case ExpenseActionTypes.FIND_PAYEE:
            return {
                ...state,
                list: {
                    ...state.list,
                    payee: undefined
                }
            }

        case ExpenseActionTypes.FIND_PAYEE_SUCCESS:
            const { payee } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    payee
                }
            }

        case ExpenseActionTypes.FIND_PAYEE_FAILURE:
            const { error: payeeError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    error: payeeError
                }
            }

        case ExpenseActionTypes.LOAD_MONTHLY:
            return { ...state, monthly: { ...state.monthly, loading: true } }

        case ExpenseActionTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyHeaders, monthlyExpense } = action.payload
            const result = new ExpenseMonthlySummary().fromJSON(monthlyExpense)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyExpense: result,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyHeaders['x-total-count'], 10)
                    }
                }
            }

        case ExpenseActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyExpense: concatMonthlyExpense } = action.payload
            state.monthly.monthlyExpense.concatFromJSON(concatMonthlyExpense)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case ExpenseActionTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    monthlyExpense: INITIAL_STATE.monthly.monthlyExpense,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case ExpenseActionTypes.LOAD_ANNUAL:
            return { ...state, annual: { ...state.annual, loading: true } }

        case ExpenseActionTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const { headers: annualHeaders, annualExpense } = action.payload
            const annualResult = new ExpenseAnnualSummary().fromJSON(annualExpense)
            return {
                ...state,
                annual: {
                    ...state.annual,
                    loading: false,
                    success: true,
                    error: false,
                    annualExpense: annualResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator, totalRecords: parseInt(annualHeaders['x-total-count'], 10)
                    }
                }
            }

        case ExpenseActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const { headers: annualConcatHeaders, annualExpense: concatAnnualExpense } = action.payload
            const concatAnnualResult = state.annual.annualExpense
                .concatFromJSON(concatAnnualExpense)
            return {
                ...state,
                annual: {
                    ...state.annual,
                    loading: false,
                    success: true,
                    error: false,
                    annualExpense: concatAnnualResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator, totalRecords: parseInt(annualConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case ExpenseActionTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    annualExpense: INITIAL_STATE.annual.annualExpense,
                    loading: false,
                    error: true,
                    data: annualError
                }
            }

        default:
            return state
    }
}

export default reducer
