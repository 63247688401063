import { JsonUtils } from '../../utils/json.util'
import { AnnualTransaction } from '../transactions/annual.transaction'
import { AnnualFinancialAgent } from '../financial.agents/annual.financial.agent'

export default abstract class AnnualSummary<T extends AnnualFinancialAgent> {
    private static hasValues(transactions: AnnualTransaction[] | undefined): boolean {
        if (transactions?.length) {
            return transactions?.some(transaction => transaction.total_value || transaction.prevision)
        }
        return false
    }
    private _total_transactions_value: number | undefined
    private _financial_agents: T[] | undefined

    get total_transactions_value(): number | undefined {
        return this._total_transactions_value
    }

    set total_transactions_value(value: number | undefined) {
        this._total_transactions_value = value
    }

    get financial_agents(): T[] | undefined {
        return this._financial_agents
    }

    set financial_agents(value: T[] | undefined) {
        this._financial_agents = value
    }

    public fromJSON(json: any): AnnualSummary<T>{
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.total_transactions_value !== undefined) {
            this._total_transactions_value = json.total_transactions_value
        }

        if (json?.financial_agents?.length) {
            this._financial_agents = json.financial_agents
        }

        return this
    }

    public toJSON(): any {
        return {
            total_transactions_value: this.total_transactions_value ? this.total_transactions_value : undefined,
            financial_agents: this.financial_agents ? this.financial_agents
                .map((financialAgent:any) => financialAgent?.toJSON()) : undefined
        }
    }

    public concatFromJSON(json: any): AnnualSummary<T> {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.total_transactions_value !== undefined) {
            this._total_transactions_value += json.total_transactions_value
        }

        if (json.financial_agents !== undefined) {
            this._financial_agents = this._financial_agents?.concat(json?.financial_agents)
        }

        return this
    }

    public filterResources(): any {
        const result: AnnualSummary<T> = Object.assign({}, this)

        result.financial_agents = this.financial_agents
            ?.filter((financialAgent) => {
                return AnnualSummary.hasValues(financialAgent.transactions)
            })

        return result
    }
}
