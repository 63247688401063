import { Reducer } from 'redux'

import { ExerciseTypes, IExerciseState } from './types'
import Exercise from '../../application/models/exercise/exercise'
import { TransactionTypes } from '../../application/models/prevision/prevision'

export const INITIAL_STATE: IExerciseState = {
    exerciseSelected: new Exercise(),
    exercises: [],
    loading: false,
    error: false,
    success: false,
    list: {
        exercises: [],
        loading: false,
        error: false,
        success: false,
        data: new ErrorEvent(''),
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    create: {
        exercise: new Exercise(),
        loading: false,
        error: false,
        success: false,
        dialogRevenuePrevision: false,
        dialogPrevision: false,
        dialogBudget: false,
        dialogBlocked: false,
        data: new ErrorEvent(''),
        payerChecked: [],
        payeeChecked: [],
        duodecimosChecked: [],
        institutionsChecked: {
            cost: [], debt: [], investment: [], event: []
        }
    },
    remove: {
        idForRemove: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    }
}


const reducer: Reducer<IExerciseState> = (state: IExerciseState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case ExerciseTypes.ADD_CHECKED:
            const { financialAgentId, transactionType } = action.payload
            switch (transactionType) {
                case TransactionTypes.REVENUE:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            payerChecked: state.create.payerChecked.concat(financialAgentId)
                        }
                    }

                case TransactionTypes.EXPENSE:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            payeeChecked: state.create.payeeChecked.concat(financialAgentId)
                        }
                    }

                case 'DUODECIMOS':
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            duodecimosChecked: state.create.duodecimosChecked.concat(financialAgentId)
                        }
                    }

                case TransactionTypes.COST:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            institutionsChecked: {
                                ...state.create.institutionsChecked,
                                cost: state.create.institutionsChecked.cost.concat(financialAgentId)
                            }
                        }
                    }

                case TransactionTypes.DEBT:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            institutionsChecked: {
                                ...state.create.institutionsChecked,
                                debt: state.create.institutionsChecked.debt.concat(financialAgentId)
                            }
                        }
                    }

                case TransactionTypes.EVENT:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            institutionsChecked: {
                                ...state.create.institutionsChecked,
                                event: state.create.institutionsChecked.event.concat(financialAgentId)
                            }
                        }
                    }

                case TransactionTypes.INVESTMENT:
                    return {
                        ...state,
                        create: {
                            ...state.create,
                            institutionsChecked: {
                                ...state.create.institutionsChecked,
                                investment: state.create.institutionsChecked.investment.concat(financialAgentId)
                            }
                        }
                    }

                default:
                    return { ...state }
            }

        case ExerciseTypes.RESET_CHECKED:
            return {
                ...state,
                create: {
                    ...state.create,
                    payerChecked: [],
                    payeeChecked: [],
                    duodecimosChecked: [],
                    institutionsChecked: {
                        ...state.create.institutionsChecked,
                        cost: [],
                        debt: [],
                        event: [],
                        investment: []
                    }
                }
            }

        case ExerciseTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, paginator } }

        case ExerciseTypes.CHANGE:
            const { exerciseId } = action.payload
            const allExercises = state.exercises.concat(state.list.exercises)
            const exerciseSelected = allExercises.find(element => element.id === exerciseId)
            return exerciseSelected ?
                { ...state, exerciseSelected: new Exercise().fromJSON(exerciseSelected) }
                : { ...state, exerciseSelected: new Exercise().fromJSON({ id: exerciseId }) }

        case ExerciseTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                remove: { ...state.remove, visibilityModal, idForRemove }
            }

        case ExerciseTypes.CHANGE_DIALOG_REVENUE_PREVISION:
            const { dialog: dialogRevenuePrevision } = action.payload
            return { ...state, create: { ...state.create, dialogRevenuePrevision } }

        case ExerciseTypes.CHANGE_DIALOG_PREVISION:
            const { dialog: dialogPrevision } = action.payload
            return { ...state, create: { ...state.create, dialogPrevision } }

        case ExerciseTypes.CHANGE_DIALOG_BUDGET:
            const { dialog: dialogBudget } = action.payload
            return { ...state, create: { ...state.create, dialogBudget } }

        case ExerciseTypes.CHANGE_DIALOG_BLOCKED:
            const { dialog: dialogBlocked, redirect } = action.payload
            return { ...state, create: { ...state.create, dialogBlocked, redirect } }

        case ExerciseTypes.CREATE_REQUEST:
            return { ...state, create: { ...state.create, loading: true } }

        case ExerciseTypes.CREATE_SUCCESS:
            const { exercise: exerciseAdded } = action.payload
            const { list: { exercises: listNewExercises }, exercises: dropDownExercises } = state
            const newExercise = new Exercise().fromJSON(exerciseAdded)
            listNewExercises.push(newExercise)
            dropDownExercises.push(newExercise)
            dropDownExercises.sort((prev: Exercise, current: Exercise) => {
                return parseInt(`${current.date}`, 10) - parseInt(`${prev.date}`, 10)
            })
            return {
                ...state,
                create: { ...state.create, loading: false },
                list: {
                    ...state.list,
                    exercises: listNewExercises.map(exerciseElement => new Exercise().fromJSON(exerciseElement))
                }
            }

        case ExerciseTypes.CREATE_FAILURE:
            const { error } = action.payload
            return { ...state, create: { ...state.create, loading: false, error: true, data: error } }

        case ExerciseTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case ExerciseTypes.LOAD_SUCCESS:
            const { exercises, headers } = action.payload
            exercises.sort((prev: Exercise, current: Exercise) => {
                return parseInt(`${current.date}`, 10) - parseInt(`${prev.date}`, 10)
            })
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    exercises: exercises.map(exerciseElement => new Exercise().fromJSON(exerciseElement)),
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case ExerciseTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return { ...state, list: { ...state.list, loading: false, error: loadError } }

        case ExerciseTypes.LOAD_LAST_REQUEST:
            return { ...state, loading: true }

        case ExerciseTypes.LOAD_LAST_SUCCESS:
            const { exercises: lastExercises } = action.payload
            lastExercises.sort((prev: Exercise, current: Exercise) => {
                return parseInt(`${current.date}`, 10) - parseInt(`${prev.date}`, 10)
            })
            const newExerciseSelected: Exercise = (state.exerciseSelected.id && !state.exerciseSelected?.date) ?
                lastExercises.find((exerciseItem: Exercise) => exerciseItem?.id === state.exerciseSelected?.id)
                : state.exerciseSelected
            return {
                ...state,
                exerciseSelected: newExerciseSelected,
                exercises: lastExercises.map(exerciseElement => new Exercise().fromJSON(exerciseElement)),
                loading: false
            }

        case ExerciseTypes.LOAD_LAST_FAILURE:
            const { error: loadLastError } = action.payload
            return { ...state, loading: false, error: loadLastError }

        case ExerciseTypes.REMOVE_REQUEST:
            return { ...state, remove: { ...state.remove, loading: true, visibilityModal: false } }

        case ExerciseTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case ExerciseTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: { ...state.remove, loading: false, error: true, success: false, visibilityModal: true }
            }

        default:
            return state
    }
}

export default reducer
