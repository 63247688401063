import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import '../container.style.scss'
import { IPaginator } from '../../store/ducks/costing/types'
import { IApplicationState } from '../../store'
import * as PrevisionActions from '../../store/ducks/prevision/actions'
import PrevisionDataModel from '../../store/application/models/prevision/prevision'
import Prevision from '../../components/prevision/prevision'
import { AnnualTransaction } from '../../store/application/models/transactions/annual.transaction'
import { TypeOfTransaction } from '../../store/application/models/transactions/transaction'
import { PrevisionTransactionTypes } from '../../store/application/utils/prevision.transaction.types'

interface IProps {
    /* Redux props */
    readonly initialValue: number | undefined
    readonly previsions: PrevisionDataModel[] | undefined
    readonly previsionsForUpdate: PrevisionDataModel[] | undefined
    readonly error: boolean
    readonly success: boolean
    readonly loading: boolean
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly year: number | undefined
    /* Annual Prevision */
    readonly annualPrevision: AnnualTransaction[]
    readonly annualLoading: boolean
    /* Other props */
    readonly financialAgentId: string | undefined
    readonly transactionType: PrevisionTransactionTypes | undefined
    readonly typeOfResource: TypeOfTransaction
    readonly dropDownOptions?: Array<{ label: string, value: string }>
}

interface IDispatchProps extends RouteComponentProps<any> {

    load(financialAgentId: string, transactionType: PrevisionTransactionTypes, paginator?: IPaginator): void

    create(financialAgentId: string, transactionType: PrevisionTransactionTypes, previsions: PrevisionDataModel[]): void

    update(prevision: PrevisionDataModel, transactionType: PrevisionTransactionTypes, paginator: IPaginator): void

    changeInitialValue(initialValue: number): void

    loadAnnualPrevision(financialAgentId: string, transactionType: PrevisionTransactionTypes, year: number): void

    updateMany(previsions: PrevisionDataModel[], transactionType: PrevisionTransactionTypes, paginator: IPaginator): void

    resetPrevision(): void

    changeRemoveModal(
        visibilityModal: boolean,
        financialAgentId: string,
        idForRemove: string,
        transactionType: PrevisionTransactionTypes,
        paginator?: IPaginator
    ): void

    changePrevision(prevision: PrevisionDataModel): void

    changeDialogCreate(dialog: boolean): void
}

type IUnionProps = IProps & IDispatchProps

interface IState {
    create: boolean
    previsions: PrevisionDataModel[] | undefined
    disabledSave: boolean
    previsionsForUpdate: PrevisionDataModel[] | undefined
}

class ContainerPrevision extends Component<IUnionProps, IState> {

    constructor(props: IUnionProps) {
        super(props)
        /* Bind Context */
        this.updateButtonSave = this.updateButtonSave.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onAdd = this.onAdd.bind(this)
        this.onChange = this.onChange.bind(this)
        this.generateByInitialValue = this.generateByInitialValue.bind(this)
        this.loadPrevisions = this.loadPrevisions.bind(this)
        /* Initial State */
        const { previsions } = this.props

        this.state = {
            create: previsions?.length ? !previsions?.length : true,
            previsions,
            disabledSave: false,
            previsionsForUpdate: []
        }
    }

    public componentDidMount() {
        this.loadPrevisions()
    }

    public componentDidUpdate(prevProps: Readonly<IUnionProps>, prevState: Readonly<IState>, snapshot?: any): void {
        const {
            year,
            previsions,
            financialAgentId,
            transactionType,
            typeOfResource
        } = this.props
        const {
            financialAgentId: prevFinancialAgentId,
            typeOfResource: prevTypeOfResource,
            transactionType: prevTransactionType
        } = prevProps
        if (!this.comparePrevisions(prevProps.previsions, this.props.previsions)) {
            return this.setState({
                ...this.state,
                previsions,
                create: previsions?.length ? !previsions?.length : true
            })
        }
        if (!this.comparePrevisions(prevProps.previsionsForUpdate, this.props.previsionsForUpdate)) {
            const { previsionsForUpdate } = this.props
            return this.setState({ ...this.state, previsionsForUpdate })
        }

        if (
            (year && year !== prevProps.year) ||
            (financialAgentId !== prevFinancialAgentId) ||
            (typeOfResource !== prevTypeOfResource) ||
            (transactionType !== prevTransactionType)
        ) {
            this.loadPrevisions()
        }
    }

    public componentWillUnmount(): void {
        this.props.resetPrevision()
    }

    public render() {
        const { create, previsions, disabledSave } = this.state

        const {
            year,
            loading,
            initialValue,
            changeInitialValue,
            annualPrevision,
            annualLoading,
            typeOfResource,
            changeRemoveModal,
            financialAgentId,
            transactionType,
            paginator,
            changePrevision,
            changeDialogCreate
        } = this.props

        const previsionFormatted = PrevisionDataModel.formatterAnnual(
            year || 0,
            previsions,
            annualPrevision
        )

        const columns = PrevisionDataModel.extractColumns(year)

        const totals = PrevisionDataModel.extractTotals(previsions, annualPrevision)

        return (
            <React.Fragment>
                <Prevision
                    initialValue={initialValue}
                    year={year || 0}
                    previsions={previsionFormatted}
                    typeOfResource={typeOfResource}
                    transactionType={transactionType}
                    columns={columns}
                    totals={totals}
                    create={create}
                    disabledSave={disabledSave}
                    loading={loading || annualLoading}
                    onChange={this.onChange}
                    cancel={this.onCancel}
                    submit={this.onSubmit}
                    changeInitialPrevision={changeInitialValue}
                    onClickAdd={(date: string) => {
                        changePrevision(new PrevisionDataModel().fromJSON({ date }))
                        changeDialogCreate(true)
                    }}
                    onClickUpdate={(date: string) => {
                        const finded: PrevisionDataModel | undefined = previsions
                            ?.find((prevision: PrevisionDataModel) => prevision.date === date)
                        if (finded) {
                            finded.transaction_type = transactionType
                            changePrevision(finded)
                            changeDialogCreate(true)
                        }
                    }}
                    onClickRemove={(date: string) => {
                        if (transactionType) {
                            const finded: PrevisionDataModel | undefined = previsions
                                ?.find((prevision: PrevisionDataModel) => prevision.date === date)
                            changeRemoveModal(
                                true,
                                financialAgentId || '',
                                finded?.id || '',
                                transactionType,
                                paginator
                            )
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    private loadPrevisions(): void {
        const {
            load,
            paginator,
            financialAgentId,
            loadAnnualPrevision,
            transactionType,
            year
        } = this.props
        if (financialAgentId && year && transactionType) {
            load(
                financialAgentId,
                transactionType, {
                    ...paginator,
                    search: { key: 'date', value: String(year) }
                }
            )
            loadAnnualPrevision(financialAgentId, transactionType, year)
        }
    }

    private updateButtonSave(previsions): boolean {
        if (previsions?.length === 12) {
            const reducer = previsions.filter((prevision) => prevision.value === 0)
            return !reducer
        }
        return previsions.length < 12
    }

    private comparePrevisions(prevPrevisions: PrevisionDataModel[] | undefined, previsions: PrevisionDataModel[] | undefined): boolean {
        const validation1 = prevPrevisions?.length === previsions?.length
        const validation2 = prevPrevisions?.every((element1, index) => {
            return previsions?.find(element2 =>
                element2.date === element1.date &&
                element2.id === element1.id &&
                element2.expected_value === element1.expected_value)
        })
        return !!validation1 && !!validation2
    }

    private onSubmit(previsionsForSubmit): void {
        const {
            financialAgentId,
            create,
            initialValue,
            transactionType,
            updateMany,
            paginator
        } = this.props
        const { previsionsForUpdate } = this.state

        if (transactionType) {
            if (previsionsForUpdate?.length) {
                updateMany(previsionsForUpdate, transactionType, paginator)
            } else {
                const keys = Object.keys(previsionsForSubmit)
                let body: PrevisionDataModel[] = []
                keys.forEach(key => {
                    body.push(
                        new PrevisionDataModel()
                            .fromJSON({
                                date: key,
                                expected_value: previsionsForSubmit[key]
                            }))
                })
                if (!keys.length && !body.length) {
                    body = this.generateByInitialValue(initialValue)
                }
                if (financialAgentId) {
                    create(financialAgentId, transactionType, body)
                }
            }
        }

    }

    private generateByInitialValue(initialValue): PrevisionDataModel[] {
        const { year } = this.props
        /* 12 months */
        const length = 12
        return Array.from(
            { length },
            (value, index) => {
                return new PrevisionDataModel().fromJSON({
                    date: `${year}-${String('0' + Number(index + 1)).slice(-2)}`,
                    expected_value: initialValue / 12
                })
            })
    }

    private onCancel(): void {
        const { year, financialAgentId, load, paginator, transactionType } = this.props
        this.setState({
            create: false,
            previsions: this.props.previsions,
            disabledSave: this.updateButtonSave(this.props.previsions)
        })
        if (financialAgentId && transactionType) {
            load(financialAgentId, transactionType, {
                ...paginator,
                search: { key: 'date', value: String((year || 0) - 1) }
            })
        }
    }

    private onAdd(): void {
        this.setState({
            create: true,
            previsions: [],
            disabledSave: this.updateButtonSave([])
        })
    }

    private onChange(date: string, value: number): void {
        const {
            financialAgentId,
            create: actionCreate,
            transactionType
        } = this.props
        const {
            create,
            previsions,
            previsionsForUpdate
        } = this.state
        /* Recover the modified element */
        const previsionSelected = previsions?.find(prevision => prevision.date === date)
        if (previsionSelected) {
            /* Adding the resource updated to the list */
            if (create && !previsionSelected?.id) {
                /* Update expected value */
                const remaining = previsions?.map(prevision => {
                    if (prevision.date === date) {
                        prevision.expected_value = value
                    }
                    return prevision
                })
                /* Update list of previsions */
                return this.setState({
                    ...this.state,
                    previsions: remaining,
                    disabledSave: this.updateButtonSave(remaining)
                })
            }
            /* Update Resource*/
            if (!create && previsionSelected?.id) {
                /* Add the resource to the list of resources to be updated */
                const localPrevisionsForUpdate = previsionsForUpdate
                    ?.filter(prevision => prevision.id !== previsionSelected?.id)
                    ?.concat(new PrevisionDataModel().fromJSON({ ...previsionSelected, expected_value: value }))
                this.setState({ ...this.state, previsionsForUpdate: localPrevisionsForUpdate })
            }
        }
        /* Adding the resource updated to the list */
        if (create && !previsionSelected) {
            const p = this.state.previsions
            p?.push(new PrevisionDataModel().fromJSON({
                date,
                expected_value: value
            }))
            this.setState({
                ...this.state,
                previsions: p,
                disabledSave: this.updateButtonSave(p)
            })
        }
        /* Add resource individually */
        if (!create && !previsionSelected?.id) {
            if (financialAgentId && transactionType) {
                const body = [new PrevisionDataModel().fromJSON({ date, expected_value: value })]
                actionCreate(financialAgentId, transactionType, body)
            }
        }
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    initialValue: state.prevision.initialValue,
    previsions: state.prevision.previsions,
    previsionsForUpdate: state.prevision.previsionsForUpdate,
    error: state.prevision.error,
    success: state.prevision.success,
    loading: state.prevision.loading,
    data: state.prevision.data,
    paginator: state.prevision.paginator,
    year: parseInt(state?.exercise?.exerciseSelected?.date || '0', 10),
    /* Annual Prevision */
    annualPrevision: state.prevision.annual.annualPrevision,
    annualLoading: state.prevision.annual.loading
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(PrevisionActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerPrevision))
