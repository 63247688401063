import { Reducer } from 'redux'
import { AuthTypes, IAuthState } from './types'
import { UserTypes } from '../../application/models/auth/user'

const INITIAL_STATE: IAuthState = {
    data: {
        sub: '',
        sub_type: '' as UserTypes,
        iss: '',
        exp: 0,
        iat: 0,
        scope: ''
    },
    error: false,
    loading: false,
    passwordIcon: 'fa-eye-slash'
}

const reducer: Reducer<IAuthState> = (state: IAuthState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case AuthTypes.CHANGE_ICON_PASSWORD:
            const { icon } = action.payload
            return { ...state, loading: false, error: false, passwordIcon: icon }

        case AuthTypes.LOGIN_SUCCESS:
            return INITIAL_STATE

        case AuthTypes.LOGIN_REQUEST:
            return { ...state, loading: true }

        case AuthTypes.LOGIN_FAILURE:
            const loginError = action.payload.error
            return { ...state, loading: false, error: true, data: loginError }

        case AuthTypes.RESET_ERROR:
            return { ...state, loading: false, error: false, data: INITIAL_STATE.data }

        case AuthTypes.LOGIN_BY_CERT:
            return { ...state, loading: true }

        case AuthTypes.LOGIN_BY_CERT_SUCCESS:
            return INITIAL_STATE

        case AuthTypes.LOGIN_BY_CERT_FAILURE:
            const loginCertError = action.payload.error
            return { ...state, loading: false, error: true, data: loginCertError }

        default:
            return state
    }
}

export default reducer
