import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import { RadioButton } from 'primereact/radiobutton'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import styled from 'styled-components'

import '../container.style.scss'
import Costing from '../../store/application/models/transactions/costing/costing'
import { IPaginator, TransactionLocation } from '../../store/ducks/costing/types'
import { IApplicationState } from '../../store'
import * as CostingActions from '../../store/ducks/costing/actions'
import Currency from '../../components/currency/currency'
import UtilDatePicker from '../../components/date.picker/utils'
import ContainerPrevision from '../prevision/prevision'
import { ISearch } from '../../store/ducks/root.types'
import ExpenseContainerFilter from '../../components/filters/expense.container.filter'
import { TypeOfTransaction } from '../../store/application/models/transactions/transaction'
import { VerifyScopes } from '../../components/verify.scopes/verify.scopes'
import ListActionsButtons from '../../components/list.buttons/list.actions.buttons'
import { INITIAL_STATE } from '../../store/ducks/costing/reducer'
import ExportCSV from '../../components/export-csv/export.csv'
import DateRanger from '../../components/date.picker/date.range'
import RemoveCosting from './remove'
import Create from './create'
import { PrevisionTransactionTypes } from '../../store/application/utils/prevision.transaction.types'

interface IState {
    readonly costings: Costing[],
    readonly data: ErrorEvent,
    readonly loading: boolean,
    readonly error: boolean
    readonly success: boolean
    readonly paginator: IPaginator

    readonly removeVisibilityModal: boolean,
    readonly removeId: string

    readonly institution: any
}

interface IDispatchProps extends RouteComponentProps<any> {

    changePaginator(institutionId: string, paginator: IPaginator): void

    changeSearchPaginator(search: ISearch): void

    load(institutionId: string, paginator: IPaginator): void

    changeRemoveModal(visibilityModal: boolean, userIdForRemove: string, institutionId: string): void

    findInstitution(institutionId: string): void

    changeDialog(dialog: boolean): void

    changeCosting(costing: Costing): void

}

type IProps = IState & IDispatchProps

export const Margin = styled.div`
  margin: ${props => props.top}px ${props => props.right}px ${props => props.bottom}px ${props => props.left}px
`

const filters = [
    { label: 'Data', value: 'date' },
    { label: 'Valor', value: 'value' },
    { label: 'Descrição', value: 'description' }
]

class CostingByInstitution extends ExpenseContainerFilter<IProps, { dateError: boolean, filterBy: string, activeIndex: number }> {

    private ref: DataTable | null | undefined

    constructor(props: IProps) {
        super(props)
        /* Bind Conntext */
        this.getMessageListEmpty = this.getMessageListEmpty.bind(this)
        this.onExportCSV = this.onExportCSV.bind(this)
        this.onExportPDF = this.onExportPDF.bind(this)

        /* Initial State */
        this.state = {
            dateError: false,
            filterBy: 'date',
            activeIndex: 0
        }

        const { match: { params }, findInstitution, load, paginator } = this.props
        if (params?.institutionId) {
            findInstitution(params.institutionId)
            load(params.institutionId, paginator)
        }
    }

    public componentWillUnmount(): void {
        this.props.changeSearchPaginator(INITIAL_STATE.list.paginator.search)
    }

    public onExportCSV() {
        this.ref?.exportCSV()
    }

    public onExportPDF() {
        this.ref?.exportCSV()
    }

    public render() {
        const {
            changePaginator,
            paginator,
            history,
            institution,
            removeVisibilityModal,
            changeRemoveModal,
            loading,
            costings,
            changeCosting,
            changeDialog
        } = this.props

        const { activeIndex, filterBy } = this.state

        return (
            <React.Fragment>

                <Helmet>
                    <title>
                        {
                            institution?.name ?
                                `[Fc-e] - Gerenciamento / Custeio do(a) ${institution.name}`
                                : '[Fc-e] - Gerenciamento / Custeio'
                        }
                    </title>
                </Helmet>

                <RemoveCosting paginator={paginator} costingLocation={TransactionLocation.BY_INSTITUTION}/>

                <Create costingLocation={TransactionLocation.BY_INSTITUTION}/>

                <div className="header">
                    <div className="row page-header">
                        <h5 className="ellipsis">
                            {
                                institution ?
                                    `Gerenciamento / ${institution.name}`
                                    : 'Carregando Órgão selecionado, aguarde...'
                            }
                        </h5>
                    </div>
                </div>

                <div id="page-inner">
                    <div className="row max-width">
                        <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => this.setState({ activeIndex: e.index })}
                            className="fade-in-down">

                            <TabPanel header="Custeio" leftIcon="fa fa-dollar">
                                <div className="row no-after"
                                     style={{ paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px' }}>
                                    <div className="col-12">
                                        <label className="label-bold"/>
                                    </div>

                                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4"
                                         style={{
                                             paddingBottom: '0px',
                                             paddingLeft: '0px',
                                             paddingRight: '9px'
                                         }}>
                                        {
                                            filterBy === 'date' && (
                                                <React.Fragment>
                                                    <label
                                                        className="label-bold"
                                                        htmlFor="date">
                                                        Informe uma data
                                                    </label>
                                                    <DateRanger
                                                        mask="99/99/9999 - 99/99/9999"
                                                        selectionMode="range"
                                                        value={paginator?.search?.value}
                                                        setFieldValue={(field, value) => {
                                                            const range = value ? value.split(' - ') : ''
                                                            const monthStart = range[0] ? range[0].split('/')[1] : ''
                                                            const monthEnd = range[1] ? range[1].split('/')[1] : ''

                                                            if ((Number(monthEnd) < Number(monthStart))) {
                                                                this.setState({ ...this.state, dateError: true })
                                                            }

                                                            if (UtilDatePicker.isValidDate(range[0]) && UtilDatePicker.isValidDate(range[1])) {
                                                                this.setState({ ...this.state, dateError: false })
                                                                changePaginator(institution.id ? institution.id : '', {
                                                                    ...paginator,
                                                                    search: {
                                                                        key: 'date',
                                                                        value
                                                                    }
                                                                })
                                                            }

                                                            if (!value) {
                                                                changePaginator(institution.id ? institution.id : '', {
                                                                    ...paginator,
                                                                    search: {
                                                                        key: '',
                                                                        value: ''
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        setFieldTouched={() => {
                                                            // not implemented
                                                        }}
                                                    />
                                                </React.Fragment>
                                            )
                                        }

                                        {
                                            filterBy === 'value' && (
                                                <React.Fragment>
                                                    <label
                                                        className="label-bold"
                                                        htmlFor="value">
                                                        Informe um valor
                                                    </label>
                                                    <span className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">R$: </span>
                                                        <Currency
                                                            id="value"
                                                            name="value"
                                                            value={Number(paginator?.search?.value)}
                                                            onChange={(event: any, v: any) => {
                                                                const obj = {
                                                                    target: {
                                                                        value: v
                                                                    }
                                                                }
                                                                this.setSearchTime('value', obj)
                                                            }}
                                                        />
                                                        <Button
                                                            className="p-inputgroup-addon p-button-clean"
                                                            style={{ right: '10px' }}
                                                            icon="pi pi-times"
                                                            type="button"
                                                            tooltip="Limpar"
                                                            onClick={this.cleansPaginator}
                                                        />
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }

                                        {
                                            filterBy === 'description' && (
                                                <React.Fragment>
                                                    <label
                                                        className="label-bold"
                                                        htmlFor="name">
                                                        Informe uma descrição
                                                    </label>
                                                    <span className="p-inputgroup">
                                                        <InputText
                                                            id="name"
                                                            className="input-container"
                                                            value={paginator?.search?.value || ''}
                                                            onChange={(event: any) => {
                                                                this.setSearchTime('description', event)
                                                            }}/>
                                                            <Button
                                                                className="p-inputgroup-addon p-button-clean"
                                                                style={{ right: '10px' }}
                                                                icon="pi pi-times"
                                                                type="button"
                                                                tooltip="Limpar"
                                                                onClick={this.cleansPaginator}
                                                            />
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }

                                    </div>
                                    <div className="col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="label-bold">Filtrar por: </label>
                                            </div>
                                            {
                                                filters.map(filter => {
                                                    return <div className="col col-sm-4 center" key={filter.value}>
                                                        <RadioButton
                                                            inputId={filter.value}
                                                            name="filterBy"
                                                            value={filter.value}
                                                            onChange={(e) => {
                                                                this.setState({ filterBy: e.value })
                                                                this.cleansPaginator()
                                                            }}
                                                            checked={this.state.filterBy === filter.value}/>
                                                        <label htmlFor={filter.value}
                                                               className="p-radiobutton-label">{filter.label}</label>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <ExportCSV
                                    data={costings.map(element => {
                                        return {
                                            'Valor': element.value,
                                            'Data': element.date,
                                            'Descrição': element.description
                                        }
                                    })}
                                    disabled={!costings.length}
                                    filename={`CUSTEIO-${institution ? institution.name : ''}`}
                                />

                                <DataTable
                                    value={institution?.id ?
                                        costings.map((costingElement: Costing) => costingElement.toJSON())
                                        : []
                                    }
                                    ref={(dataTableRef: DataTable | null) => this.ref = dataTableRef}
                                    responsive={true}
                                    lazy={true}
                                    loading={loading}
                                    emptyMessage={this.getMessageListEmpty}>
                                    <Column
                                        header="#"
                                        style={{ width: '5%' }}
                                        body={(rowData: Costing, column: any) =>
                                            ((column.rowIndex + 1) + paginator.first)
                                        }/>
                                    <Column
                                        header="Valor"
                                        className="center"
                                        style={{ width: '25%' }}
                                        body={(rowData: any) => <Currency value={rowData.value} readOnly={true}/>}/>
                                    <Column
                                        field="date"
                                        header="Data"
                                        className="center"
                                        style={{ width: '25%' }}
                                        body={(rowData: any) => UtilDatePicker.brFormatDate(rowData.date)}/>
                                    <Column
                                        field="description"
                                        header="Descrição"
                                        className="center"
                                        style={{ width: '40%' }}/>
                                    <Column
                                        header="Ações"
                                        style={{ width: '15%' }}
                                        body={(rowData: Costing) => {
                                            return <ListActionsButtons
                                                disableAuxiliary={true}
                                                disableEdit={UtilDatePicker.hasOccurred(rowData?.date)}
                                                titleEdit="Editar custeio..."
                                                scopesEdit={['tr:u']}
                                                titleDelete="Excluir custeio..."
                                                scopesRemove={['tr:d']}
                                                editAction={() => {
                                                    const costingSelected: Costing = new Costing().fromJSON({
                                                        ...rowData,
                                                        institution_id: institution?.id
                                                    })
                                                    changeCosting(costingSelected)
                                                    changeDialog(true)
                                                }}
                                                deleteAction={() => {
                                                    changeRemoveModal(!removeVisibilityModal, `${rowData.id}`, institution?.id)
                                                }}/>
                                        }}/>
                                </DataTable>

                                <Paginator
                                    rows={paginator.rows}
                                    totalRecords={paginator.totalRecords}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    first={paginator.first}
                                    onPageChange={(e: any) => {
                                        changePaginator(institution.id, { ...paginator, ...e })
                                    }}/>
                            </TabPanel>


                            <TabPanel header="Previsto" leftIcon="pi pi-file">
                                <VerifyScopes scopes={['pr:ra']}>
                                    <ContainerPrevision
                                        financialAgentId={institution?.id}
                                        transactionType={PrevisionTransactionTypes.COST}
                                        typeOfResource={TypeOfTransaction.OUTPUT}/>
                                </VerifyScopes>
                            </TabPanel>

                        </TabView>
                        <Margin top={15} right={0} bottom={10} left={0} className="row">
                            <Button
                                className="p-button-secondary left"
                                label="Voltar"
                                icon="pi pi-arrow-left"
                                title="Voltar..."
                                onClick={history.goBack}/>
                            {
                                activeIndex === 0 && <VerifyScopes scopes={['tr:c']}>
                                    <Button
                                        className="p-button-primary right"
                                        label="Adicionar"
                                        icon="pi pi-plus-circle"
                                        title="Adicionar custeio..."
                                        disabled={!institution?.id}
                                        onClick={() => {
                                            changeCosting(new Costing().fromJSON({ institution_id: institution?.id }))
                                            changeDialog(true)
                                        }}/>
                                </VerifyScopes>
                            }
                        </Margin>

                    </div>

                </div>


            </React.Fragment>
        )
    }

    protected getMessageListEmpty(): React.ReactNode {
        const { paginator } = this.props
        return this.messageListEmpty(paginator, paginator.search.value);
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    costings: state.costing.list.costings,
    error: state.costing.list.error,
    success: state.costing.list.success,
    loading: state.costing.list.loading,
    data: state.costing.list.data,
    paginator: state.costing.list.paginator,

    removeVisibilityModal: state.costing.remove.visibilityModal,
    removeLoading: state.costing.remove.loading,
    removeError: state.costing.remove.error,
    removeSuccess: state.costing.remove.success,
    removeId: state.costing.remove.idForRemove,

    institution: state.costing.list.institution
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(CostingActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CostingByInstitution))

