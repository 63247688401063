import { Reducer } from 'redux'
import { IInvestmentsState, InvestmentsActionsTypes } from './types'
import Investments from '../../application/models/transactions/investments/investments'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import InvestmentsAnnualSummary from '../../application/models/transactions/investments/investments.annual.summary'

export const INITIAL_STATE: IInvestmentsState = {
    createInvestments: {
        investments: new Investments(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listInvestments: {
        investments: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        institution: undefined
    },
    removeInvestments: {
        idForRemove: '',
        institutionId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthlyInvestments: {
        monthlyInvestments: new InvestmentsMonthlySummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annualInvestments: {
        investmentsAnnual: {
            january: undefined,
            february: undefined,
            march: undefined,
            april: undefined,
            may: undefined,
            june: undefined,
            july: undefined,
            august: undefined,
            september: undefined,
            october: undefined,
            november: undefined,
            december: undefined,
            totalCovenant: undefined,
            totalDirectInvestments: undefined,
            totalFinanceCharges: undefined,
            totalFunds: undefined,
            totalPrograms: undefined
        },
        loading: false,
        error: false,
        success: false,
        data: new ErrorEvent(''),
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    dashboard: {
        investmentsAnnual: new InvestmentsAnnualSummary(),
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IInvestmentsState> = (state: IInvestmentsState = INITIAL_STATE, action: any) => {

    switch (action.type) {

        case InvestmentsActionsTypes.RESET_INVESTMENTS:
            return {
                ...state,
                createInvestments: INITIAL_STATE.createInvestments
            }

        case InvestmentsActionsTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    paginator: INITIAL_STATE.monthlyInvestments.paginator
                }
            }

        case InvestmentsActionsTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annualInvestments: {
                    ...state.annualInvestments,
                    paginator: INITIAL_STATE.annualInvestments.paginator
                }
            }

        case InvestmentsActionsTypes.CHANGE_INVESTMENTS:
            const { investment } = action.payload
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: false,
                    error: false,
                    success: false,
                    investments: new Investments().fromJSON(investment)
                }
            }

        case InvestmentsActionsTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, createInvestments: { ...state.createInvestments, dialog } }

        case InvestmentsActionsTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    paginator
                }
            }

        case InvestmentsActionsTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    paginator: {
                        ...state.listInvestments.paginator,
                        search
                    }
                }
            }

        case InvestmentsActionsTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, institutionId } = action.payload
            return {
                ...state,
                removeInvestments: {
                    ...state.removeInvestments,
                    visibilityModal,
                    idForRemove,
                    institutionId
                }
            }

        case InvestmentsActionsTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    paginator: monthlyPaginator
                }
            }

        case InvestmentsActionsTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annualInvestments: {
                    ...state.annualInvestments,
                    paginator: {
                        ...state.annualInvestments.paginator,
                        ...annualPaginator
                    }
                }
            }

        case InvestmentsActionsTypes.CREATE_REQUEST:
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.CREATE_SUCCESS:
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    investments: new Investments().fromJSON({ institution_id: state.createInvestments.investments.institution_id }),
                    success: true,
                    loading: false
                }
            }

        case InvestmentsActionsTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: false,
                    error: true,
                    success: false,
                    data: createError
                }
            }

        case InvestmentsActionsTypes.LOAD_REQUEST:
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.LOAD_SUCCESS:
            const { investments, headers } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    loading: false,
                    success: false,
                    error: false,
                    investments: investments.map((json: any) => new Investments().fromJSON(json)),
                    paginator: {
                        ...state.listInvestments.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case InvestmentsActionsTypes.LOAD_FAILURE:
            const { error } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    loading: false,
                    success: false,
                    error: true,
                    data: error
                }
            }

        case InvestmentsActionsTypes.FIND_REQUEST:
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.FIND_SUCCESS:
            const { investment: investmentFound } = action.payload
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    investments: new Investments().fromJSON(investmentFound),
                    loading: false
                }
            }

        case InvestmentsActionsTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: false,
                    error: true,
                    success: false,
                    data: errorFound
                }
            }

        case InvestmentsActionsTypes.UPDATE_REQUEST:
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: false
                }
            }

        case InvestmentsActionsTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                createInvestments: {
                    ...state.createInvestments,
                    loading: false,
                    error: true,
                    success: false,
                    data: updateError
                }
            }

        case InvestmentsActionsTypes.REMOVE_REQUEST:
            return {
                ...state,
                removeInvestments: {
                    ...state.removeInvestments,
                    loading: true,
                    visibilityModal: false
                }
            }

        case InvestmentsActionsTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeInvestments: {
                    ...state.removeInvestments,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case InvestmentsActionsTypes.REMOVE_FAILURE:
            return {
                ...state,
                removeInvestments: {
                    ...state.removeInvestments,
                    loading: false,
                    error: true,
                    success: false,
                    visibilityModal: true
                }
            }

        case InvestmentsActionsTypes.FIND_INSTITUTION_REQUEST:
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    institution: undefined
                }
            }

        case InvestmentsActionsTypes.FIND_INSTITUTION_SUCCESS:
            const { institution } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    institution
                }
            }

        case InvestmentsActionsTypes.FIND_INSTITUTION_FAILURE:
            const { error: institutionError } = action.payload
            return {
                ...state,
                listInvestments: {
                    ...state.listInvestments,
                    error: institutionError
                }
            }

        case InvestmentsActionsTypes.LOAD_MONTHLY_REQUEST:
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyResetHeaders, monthlyInvestments: resetMonthlyInvestments } = action.payload
            const result = new InvestmentsMonthlySummary().fromJSON(resetMonthlyInvestments)
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyInvestments: result,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyInvestments.paginator,
                        totalRecords: parseInt(monthlyResetHeaders['x-total-count'], 10)
                    }
                }
            }

        case InvestmentsActionsTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyInvestments: concatMontlhyInvestments } = action.payload
            state.monthlyInvestments.monthlyInvestments.concatFromJSON(concatMontlhyInvestments)
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyInvestments.paginator,
                        totalRecords: parseInt(monthlyConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case InvestmentsActionsTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthlyInvestments: {
                    ...state.monthlyInvestments,
                    monthlyInvestments: INITIAL_STATE.monthlyInvestments.monthlyInvestments,
                    loading: false,
                    error: true,
                    success: false,
                    data: monthlyError
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_REQUEST:
            return {
                ...state,
                annualInvestments: {
                    ...state.annualInvestments,
                    loading: true
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const {
                january: januaryInvestments,
                february: februaryInvestments,
                march: marchInvestments,
                april: aprilInvestments,
                may: mayInvestments,
                june: juneInvestments,
                july: julyInvestments,
                august: augustInvestments,
                september: septemberInvestments,
                october: octoberInvestments,
                november: novemberInvestments,
                december: decemberInvestments,
                totalCovenant: totalCovenantReset,
                totalDirectInvestments: totalDirectInvestmentsReset,
                totalFinanceCharges: totalFinanceChargesReset,
                totalFunds: totalFundsReset,
                totalPrograms: totalProgramsReset,
                total: totalReset,
                xTotalCount: xTotalCountReset
            } = action.payload

            return {
                ...state,
                annualInvestments: {
                    ...state.annualInvestments,
                    investmentsAnnual: {
                        january: januaryInvestments ? new InvestmentsMonthlySummary().fromJSON(januaryInvestments) : undefined,
                        february: februaryInvestments ? new InvestmentsMonthlySummary().fromJSON(februaryInvestments) : undefined,
                        march: marchInvestments ? new InvestmentsMonthlySummary().fromJSON(marchInvestments) : undefined,
                        april: aprilInvestments ? new InvestmentsMonthlySummary().fromJSON(aprilInvestments) : undefined,
                        may: mayInvestments ? new InvestmentsMonthlySummary().fromJSON(mayInvestments) : undefined,
                        june: juneInvestments ? new InvestmentsMonthlySummary().fromJSON(juneInvestments) : undefined,
                        july: julyInvestments ? new InvestmentsMonthlySummary().fromJSON(julyInvestments) : undefined,
                        august: augustInvestments ? new InvestmentsMonthlySummary().fromJSON(augustInvestments) : undefined,
                        september: septemberInvestments ? new InvestmentsMonthlySummary().fromJSON(septemberInvestments) : undefined,
                        october: octoberInvestments ? new InvestmentsMonthlySummary().fromJSON(octoberInvestments) : undefined,
                        november: novemberInvestments ? new InvestmentsMonthlySummary().fromJSON(novemberInvestments) : undefined,
                        december: decemberInvestments ? new InvestmentsMonthlySummary().fromJSON(decemberInvestments) : undefined,
                        totalCovenant: totalCovenantReset,
                        totalDirectInvestments: totalDirectInvestmentsReset,
                        totalFinanceCharges: totalFinanceChargesReset,
                        totalFunds: totalFundsReset,
                        totalPrograms: totalProgramsReset,
                        total: totalReset
                    },
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualInvestments.paginator,
                        totalRecords: xTotalCountReset
                    }
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const {
                january: januaryInvestmentsConcat,
                february: februaryInvestmentsConcat,
                march: marchInvestmentsConcat,
                april: aprilInvestmentsConcat,
                may: mayInvestmentsConcat,
                june: juneInvestmentsConcat,
                july: julyInvestmentsConcat,
                august: augustInvestmentsConcat,
                september: septemberInvestmentsConcat,
                october: octoberInvestmentsConcat,
                november: novemberInvestmentsConcat,
                december: decemberInvestmentsConcat,
                totalCovenant: totalCovenantConcat,
                totalDirectInvestments: totalDirectInvestmentsConcat,
                totalFinanceCharges: totalFinanceChargesConcat,
                totalFunds: totalFundsConcat,
                totalPrograms: totalProgramsConcat,
                total: totalConcat,
                xTotalCount: xTotalCountConcat
            } = action.payload

            return {
                ...state,
                annualInvestments: {
                    ...state.annualInvestments,
                    investmentsAnnual: {
                        january: state.annualInvestments.investmentsAnnual.january?.concatFromJSON(januaryInvestmentsConcat),
                        february: state.annualInvestments.investmentsAnnual.february?.concatFromJSON(februaryInvestmentsConcat),
                        march: state.annualInvestments.investmentsAnnual.march?.concatFromJSON(marchInvestmentsConcat),
                        april: state.annualInvestments.investmentsAnnual.april?.concatFromJSON(aprilInvestmentsConcat),
                        may: state.annualInvestments.investmentsAnnual.may?.concatFromJSON(mayInvestmentsConcat),
                        june: state.annualInvestments.investmentsAnnual.june?.concatFromJSON(juneInvestmentsConcat),
                        july: state.annualInvestments.investmentsAnnual.july?.concatFromJSON(julyInvestmentsConcat),
                        august: state.annualInvestments.investmentsAnnual.august?.concatFromJSON(augustInvestmentsConcat),
                        september: state.annualInvestments.investmentsAnnual.september?.concatFromJSON(septemberInvestmentsConcat),
                        october: state.annualInvestments.investmentsAnnual.october?.concatFromJSON(octoberInvestmentsConcat),
                        november: state.annualInvestments.investmentsAnnual.november?.concatFromJSON(novemberInvestmentsConcat),
                        december: state.annualInvestments.investmentsAnnual.december?.concatFromJSON(decemberInvestmentsConcat),
                        totalCovenant: state.annualInvestments.investmentsAnnual.totalCovenant + totalCovenantConcat,
                        totalDirectInvestments: state.annualInvestments.investmentsAnnual.totalDirectInvestments + totalDirectInvestmentsConcat,
                        totalFinanceCharges: state.annualInvestments.investmentsAnnual.totalFinanceCharges + totalFinanceChargesConcat,
                        totalFunds: state.annualInvestments.investmentsAnnual.totalFunds + totalFundsConcat,
                        totalPrograms: state.annualInvestments.investmentsAnnual.totalPrograms + totalProgramsConcat,
                        total: state.annualInvestments.investmentsAnnual.total + totalConcat
                    },
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualInvestments.paginator,
                        totalRecords: xTotalCountConcat
                    }
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                investmentsAnnual: {
                    ...state.annualInvestments,
                    investmentsAnnual: INITIAL_STATE.annualInvestments.investmentsAnnual,
                    loading: false,
                    error: true,
                    data: annualError
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_DASH:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    loading: true,
                    error: true
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_DASH_SUCCESS:
            const { investmentsAnnual } = action.payload
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    investmentsAnnual: new InvestmentsAnnualSummary().fromJSON(investmentsAnnual),
                    loading: false,
                    error: false
                }
            }

        case InvestmentsActionsTypes.LOAD_ANNUAL_DASH_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    investmentsAnnual: INITIAL_STATE.dashboard.investmentsAnnual,
                    loading: false,
                    error: true
                }
            }

        default:
            return state

    }

}

export default reducer
