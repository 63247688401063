import { FinancialAgent } from '../financial.agent'

export enum RevenueCategory {
    NET_OPERATING_REVENUES = 'net_operating_revenues',
    OTHER_INCOMES = 'other_incomes'
}

export enum NonOperatingRevenueCategory {
    CREDIT_RECOVERY = 'credit_recovery',
    OTHER_CREDIT_OPERATIONS = 'other_credit_operations'
}

export default class Payer extends FinancialAgent {
    private _revenue_category: RevenueCategory | undefined
    private _has_nop_revenues: boolean | undefined
    private _nop_revenue_category: NonOperatingRevenueCategory | undefined
    private _siaf_code: number | undefined

    constructor() {
        super()
        this.id = ''
        this.created_at = ''
        this.updated_at = ''
        this.name = ''
        this.revenue_category = '' as RevenueCategory | undefined
        this.has_nop_revenues = false
        this.nop_revenue_category = '' as NonOperatingRevenueCategory | undefined
        this.siaf_code = 0
    }

    get revenue_category(): RevenueCategory | undefined {
        return this._revenue_category
    }

    set revenue_category(value: RevenueCategory | undefined) {
        this._revenue_category = value
    }

    get has_nop_revenues(): boolean | undefined {
        return this._has_nop_revenues
    }

    set has_nop_revenues(value: boolean | undefined) {
        this._has_nop_revenues = value
    }

    get nop_revenue_category(): NonOperatingRevenueCategory | undefined {
        return this._nop_revenue_category
    }

    set nop_revenue_category(value: NonOperatingRevenueCategory | undefined) {
        this._nop_revenue_category = value
    }

    get siaf_code(): number | undefined {
        return this._siaf_code
    }

    set siaf_code(value: number | undefined) {
        this._siaf_code = value
    }

    public fromJSON(json: any): Payer {
        super.fromJSON(json)

        if (json.revenue_category !== undefined) {
            this._revenue_category = json.revenue_category
        }

        if (json.has_nop_revenues !== undefined) {
            this._has_nop_revenues = json.has_nop_revenues
        }

        if (json.nop_revenue_category !== undefined) {
            this._nop_revenue_category = json.nop_revenue_category
        }

        if (json.siaf_code !== undefined) {
            this._siaf_code = json.siaf_code
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            revenue_category: this.revenue_category ? this.revenue_category : undefined,
            has_nop_revenues: this.has_nop_revenues !== undefined ? this.has_nop_revenues : undefined,
            nop_revenue_category: this.nop_revenue_category ? this.nop_revenue_category : undefined,
            siaf_code: this.siaf_code ? this.siaf_code : undefined
        }
    }
}
