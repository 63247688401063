import { DailyDisbursementSummaryItem } from './daily.disbursement.summary.item'
import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Daily Disbursement Summary entity.
 *
 */
export class DailyDisbursementSummary {
    private _institutions?: DailyDisbursementSummaryItem[] | undefined
    get institutions(): DailyDisbursementSummaryItem[] | undefined {
        return this._institutions
    }

    set institutions(value: DailyDisbursementSummaryItem[] | undefined) {
        this._institutions = value
    }

    public fromJSON(json: any): DailyDisbursementSummary {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.institutions instanceof Array && json.institutions.length) {
            this.institutions = json
                ?.institutions
                ?.map((institutionItem: any) => new DailyDisbursementSummaryItem().fromJSON(institutionItem)) || []
        }

        return this
    }

    public toJSON(): any {
        return {
            institutions: this.institutions?.length ?
                this.institutions.map((item: DailyDisbursementSummaryItem) => item.toJSON())
                : []
        }
    }
}
