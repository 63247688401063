import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { SupplementaryBudgetTypes } from './types'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'

/* Actions for Reset State */
export const reset = () => action(SupplementaryBudgetTypes.RESET, {})

/* Actions for Change */
export const changeSupplementary = (supplementary: SupplementaryBudget) => action(
    SupplementaryBudgetTypes.CHANGE_SUPPLEMENTARY, { supplementary }
)

export const changePaginator = (payeeId: string, paginator: IPaginator) => [
    action(SupplementaryBudgetTypes.CHANGE_PAGINATOR, { paginator }),
    load(payeeId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    SupplementaryBudgetTypes.CHANGE_SEARCH_PAGINATOR, { search }
)

export const changeRemoveModal = (visibilityModal: boolean, supplementaryId: string) => action(
    SupplementaryBudgetTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        supplementaryId
    }
)

export const changeDialogCreate = (dialog: boolean) => action(SupplementaryBudgetTypes.CHANGE_DIALOG_CREATE, { dialog })

/* Actions for Create Supplementary Budget */
export const create = (supplementary: SupplementaryBudget, paginator: IPaginator) => action(
    SupplementaryBudgetTypes.CREATE_REQUEST, { supplementary }
)

export const createSuccess = (supplementary: SupplementaryBudget, paginator: IPaginator) => [
    action(SupplementaryBudgetTypes.CREATE_SUCCESS, { supplementary }),
    load(supplementary?.payee_id || '', paginator)
]

export const createFailure = (error: ErrorEvent, supplementary: SupplementaryBudget) => action(
    SupplementaryBudgetTypes.CREATE_FAILURE, {
        error,
        supplementary
    }
)

/* Actions for Load Supplementaries Budget */
export const load = (payeeId: string, paginator?: IPaginator) => action(
    SupplementaryBudgetTypes.LOAD_REQUEST, {
        payeeId,
        paginator
    })

export const loadSuccess = (response: IAxiosResponse<SupplementaryBudget[]>) => action(
    SupplementaryBudgetTypes.LOAD_SUCCESS, {
        supplementaries: response.data,
        headers: response.headers
    }
)

export const loadFailure = (error: ErrorEvent) => action(SupplementaryBudgetTypes.LOAD_FAILURE, { error })

/* Actions for Find Supplementary Budget */
export const find = (payeeId: string, supplementaryId: string) => action(
    SupplementaryBudgetTypes.FIND_REQUEST, {
        payeeId,
        supplementaryId
    })

export const findSuccess = (supplementary: SupplementaryBudget) => action(
    SupplementaryBudgetTypes.FIND_SUCCESS, { supplementary })

export const findFailure = (error: ErrorEvent) => action(SupplementaryBudgetTypes.FIND_FAILURE, { error })

/* Actions for Update Supplementary Budget */
export const update = (supplementary: SupplementaryBudget, paginator: IPaginator) => action(
    SupplementaryBudgetTypes.UPDATE_REQUEST, { supplementary, paginator }
)

export const updateSuccess = (supplementary: SupplementaryBudget, paginator: IPaginator) => [
    action(SupplementaryBudgetTypes.UPDATE_SUCCESS, { supplementary }),
    load(supplementary?.payee_id || '', paginator)
]

export const updateFailure = (error: ErrorEvent, supplementary: SupplementaryBudget) => action(
    SupplementaryBudgetTypes.UPDATE_FAILURE, {
        error,
        supplementary
    }
)

/* Actions for Remove Supplementary Budget */
export const remove = (payeeId: string, supplementaryId: string, paginator: IPaginator) => action(
    SupplementaryBudgetTypes.REMOVE_REQUEST, {
        payeeId,
        supplementaryId,
        paginator
    }
)

export const removeSuccess = () => action(SupplementaryBudgetTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(SupplementaryBudgetTypes.REMOVE_FAILURE, { error })
