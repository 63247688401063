import { Component } from 'react'
import memoizee from 'memoizee'
import authService, { LogicalStrategy } from '../../services/auth'

interface IProps {
    readonly scopes: string[]
    readonly logicalStrategy?: LogicalStrategy
}

export class VerifyScopes extends Component<IProps> {

    public static validate(expectedScopes?: string[], logicalStrategy: LogicalStrategy = LogicalStrategy.AND): boolean {
        return VerifyScopes._validateMemo(expectedScopes, logicalStrategy, `${authService?.decodeToken()?.sub}`)
    }

    private static _validateMemo: any = memoizee(VerifyScopes.validateScopes, { length: 3, primitive: true })

    private static validateScopes(expectedScopes?: string[], logicalStrategy = LogicalStrategy.AND): boolean {
        if (!logicalStrategy) {
            logicalStrategy = LogicalStrategy.AND
        }
        if (expectedScopes) {
            try {
                const { scope } = authService.decodeToken()
                const scopes = scope.split(' ')
                if (expectedScopes.length === 0) {
                    return true
                }
                return logicalStrategy === LogicalStrategy.AND ? expectedScopes.every(itemScope => scopes.includes(itemScope))
                    : expectedScopes.some(itemScope => scopes.includes(itemScope))
            } catch (e) {
                return false
            }
        }
        return true
    }

    constructor(props: IProps) {
        super(props)
        VerifyScopes.validate = VerifyScopes.validate.bind(this)
        VerifyScopes.validateScopes = VerifyScopes.validateScopes.bind(this)
    }

    public render() {
        const { scopes, children, logicalStrategy } = this.props
        if (VerifyScopes.validate(scopes, logicalStrategy)) {
            return children
        }
        return ''
    }
}
