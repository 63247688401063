/**
 * Names of supported prevision transactions types.
 */
export enum PrevisionTransactionTypes {
    // Disbursement
    COST = 'cost',
    EXTRA_COST = 'extra_cost',
    INVESTMENT = 'investment',
    EVENT = 'event',
    GUYS = 'guys',
    SEAMS = 'seams',
    STAFF_COST = 'staff_cost',
    // Others
    REVENUE = 'revenue',
    EXPENSE = 'expense'
}
