import * as Yup from 'yup'
import moment from 'moment'
import UtilDatePicker from '../../../components/date.picker/utils'
import { InvestmentsType } from '../models/transactions/investments/investments'
import { TransactionTypes } from '../models/prevision/prevision'
import { DebtType } from '../models/transactions/debt/debt'

export class PrevisionValidator {
    private static _date: Yup.StringSchema<string | null> = Yup.string()
        .test(
            'dateIsValid',
            'Informe uma data válida',
            (value) => moment(UtilDatePicker.usaFormatMonth(value)).isValid())
        .required('Informe uma data válida')
        .nullable()

    private static _expected_value: Yup.NumberSchema<number | null> = Yup.number()
        .test(
            'valueIsValid',
            'Informe um valor válido',
            (value) => value
        )
        .required('Informe um valor válido')
        .nullable()

    private static _transaction_type: Yup.StringSchema<string | null> = Yup.string()
        .oneOf(Object.values(TransactionTypes), 'Tipo de transação inválida')
        .required('Informe o tipo da transação')
        .nullable()

    private static _description: Yup.StringSchema<string | undefined> = Yup.string()
        .min(2, 'Quantidade mínima de caracteres igual a 2')
        .max(90, 'Quantidade máxima igual a 90')
        .notRequired()

    private static _debt_type: Yup.MixedSchema = Yup.mixed()
        .when('transaction_type', {
            is: (transactionType) => transactionType === TransactionTypes.DEBT,
            then: Yup.mixed()
                .oneOf(Object.values(DebtType), 'Tipo de dívida inválida')
                .required('Selecione o Tipo da Dívida')
                .nullable()
        })


    private static _investment_type: Yup.StringSchema<string | null> = Yup.string()
        .when('transaction_type', {
            is: (transactionType) => transactionType === TransactionTypes.INVESTMENT,
            then: Yup.string()
                .oneOf(Object.values(InvestmentsType), 'Tipo de investimento inválido')
                .required('Informe o tipo do investimento')
                .nullable()
        })

    static get validationScheme(): Yup.ObjectSchema<object> {
        return Yup
            .object()
            .shape({

                date: this.date,

                expected_value: this.expected_value,

                transaction_type: this.transaction_type,

                description: this.description,

                debt_type: this.debt_type,

                investment_type: this.investment_type

            })
    }

    static get date(): Yup.StringSchema<string | null> {
        return this._date
    }

    static get expected_value(): Yup.NumberSchema<number | null> {
        return this._expected_value
    }


    static get transaction_type(): Yup.StringSchema<string | null> {
        return this._transaction_type
    }


    static get description(): Yup.StringSchema<string | undefined> {
        return this._description
    }

    static get debt_type(): Yup.MixedSchema {
        return this._debt_type
    }

    static get investment_type(): Yup.StringSchema<string | null> {
        return this._investment_type
    }
}
