import { action } from 'typesafe-actions'
import { ConferenceRevenueActionTypes } from './types'
import { ConferenceItem } from '../../application/models/disbursement/conference.item'
import { IAxiosResponse, IPaginator } from '../root.types'

/* Actions for Reset State */
export const reset = () => action(ConferenceRevenueActionTypes.RESET)


/* Actions for Create Disbursement Transaction */

export const changeCreateDialog = (dialog: boolean, data: ConferenceItem) => action(
    ConferenceRevenueActionTypes.CHANGE_CREATE_DIALOG, { dialog, data }
)

export const createRequest = (data: ConferenceItem) => action(
    ConferenceRevenueActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: ConferenceItem) => action(
    ConferenceRevenueActionTypes.CREATE_SUCCESS,
    { data }
)

export const createFailure = () => action(ConferenceRevenueActionTypes.CREATE_FAILURE)

/* Actions for Load Disbursement transaction */
export const loadRequest = (year: number, paginator?: IPaginator) => action(
    ConferenceRevenueActionTypes.LOAD_REQUEST,
    { year, paginator }
)

export const loadSuccess = (
    response: IAxiosResponse<ConferenceItem[]>,
    isFirst: boolean,
    isLast: boolean
) => action(
    ConferenceRevenueActionTypes.LOAD_SUCCESS,
    { data: response.data, headers: response.headers, isFirst, isLast }
)

export const loadFailure = () => action(ConferenceRevenueActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement type */

export const changeRemoveModal = (dialog: boolean, ids: string[]) => action(
    ConferenceRevenueActionTypes.CHANGE_REMOVE_DIALOG, { dialog, ids }
)

export const removeRequest = (ids: string[]) => action(
    ConferenceRevenueActionTypes.REMOVE_REQUEST,
    { ids }
)

export const removeSuccess = (ids: string[]) => action(ConferenceRevenueActionTypes.REMOVE_SUCCESS, { ids })


export const removeFailure = () => action(ConferenceRevenueActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement transaction */
export const updateRequest = (data: ConferenceItem) => action(
    ConferenceRevenueActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: ConferenceItem) => action(
    ConferenceRevenueActionTypes.UPDATE_SUCCESS,
    { data }
)

export const updateFailure = () => action(ConferenceRevenueActionTypes.UPDATE_FAILURE)
