import { Reducer } from 'redux'

import { EventsActionTypes, IEventsState } from './types'
import Event from '../../application/models/transactions/event/event'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'

export const INITIAL_STATE: IEventsState = {
    createEvent: {
        event: new Event(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listEvent: {
        events: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        institution: undefined
    },
    removeEvent: {
        idForRemove: '',
        institutionId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthlyEvent: {
        monthlyEventing: new EventMonthlySummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annualEvent: {
        annualEventing: {
            january: undefined,
            february: undefined,
            march: undefined,
            april: undefined,
            may: undefined,
            june: undefined,
            july: undefined,
            august: undefined,
            september: undefined,
            october: undefined,
            november: undefined,
            december: undefined,
            total: undefined,
            totalRealized: undefined,
            totalUnrealized: undefined
        },
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    dashboard: {
        annualEvent: new EventAnnualSummary(),
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IEventsState> = (state: IEventsState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case EventsActionTypes.RESET_EVENT:
            return {
                ...state, createEvent: INITIAL_STATE.createEvent
            }

        case EventsActionTypes.CHANGE_EVENT:
            const { event } = action.payload
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: false,
                    error: false,
                    success: false,
                    event: new Event().fromJSON(event)
                }
            }

        case EventsActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, createEvent: { ...state.createEvent, dialog } }

        case EventsActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listEvent: {
                    ...state.listEvent, paginator
                }
            }

        case EventsActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listEvent: {
                    ...state.listEvent,
                    paginator: {
                        ...state.listEvent.paginator,
                        search
                    }
                }
            }
        case EventsActionTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, institutionId } = action.payload
            return {
                ...state,
                removeEvent: { ...state.removeEvent, visibilityModal, idForRemove, institutionId }
            }

        case EventsActionTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent, paginator: monthlyPaginator
                }
            }

        case EventsActionTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent, paginator: INITIAL_STATE.monthlyEvent.paginator
                }
            }


        case EventsActionTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent, paginator: {
                        ...state.annualEvent.paginator, ...annualPaginator
                    }
                }
            }

        case EventsActionTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent, paginator: INITIAL_STATE.annualEvent.paginator
                }
            }

        case EventsActionTypes.CREATE_REQUEST:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: true
                }
            }

        case EventsActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    event: new Event().fromJSON({ institution_id: state.createEvent.event.institution_id }),
                    success: true,
                    loading: false
                }
            }

        case EventsActionTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: false,
                    error: true,
                    success: false,
                    data: createError
                }
            }

        case EventsActionTypes.LOAD_REQUEST:
            return {
                ...state,
                listEvent: {
                    ...state.listEvent, loading: true
                }
            }

        case EventsActionTypes.LOAD_SUCCESS:
            const { events, headers } = action.payload
            return {
                ...state,
                listEvent: {
                    ...state.listEvent,
                    loading: false,
                    success: true,
                    error: false,
                    events: events.map((eventJson: any) => new Event().fromJSON(eventJson)),
                    paginator: {
                        ...state.listEvent.paginator, totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case EventsActionTypes.LOAD_FAILURE:
            const { error } = action.payload
            return {
                ...state, listEvent: {
                    ...state.listEvent, loading: false, error: true, data: error
                }
            }

        case EventsActionTypes.FIND_REQUEST:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent, loading: true
                }
            }

        case EventsActionTypes.FIND_SUCCESS:
            const eventsFound = action.payload.event
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    event: new Event().fromJSON(eventsFound),
                    loading: false
                }
            }

        case EventsActionTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload.error
            return {
                ...state,
                createEvent: {
                    ...state.createEvent, loading: false, error: true, success: false, data: errorFound
                }
            }

        case EventsActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: true
                }
            }

        case EventsActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: false
                }
            }

        case EventsActionTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload.error
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: false,
                    error: true,
                    success: false,
                    data: updateError
                }
            }

        case EventsActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                removeEvent: {
                    ...state.removeEvent,
                    loading: true,
                    visibilityModal: false
                }
            }

        case EventsActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeEvent: {
                    ...state.removeEvent,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case EventsActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                removeEvent: {
                    ...state.removeEvent,
                    loading: false,
                    error: true, success: false,
                    visibilityModal: true
                }
            }

        case EventsActionTypes.FIND_INSTITUTION:
            return {
                ...state,
                listEvent: {
                    ...state.listEvent,
                    institution: undefined
                }
            }

        case EventsActionTypes.FIND_INSTITUTION_SUCCESS:
            const { institution } = action.payload
            return {
                ...state,
                listEvent: {
                    ...state.listEvent,
                    institution
                }
            }

        case EventsActionTypes.FIND_INSTITUTION_FAILURE:
            const { error: institutionError } = action.payload.error
            return {
                ...state,
                listEvent: {
                    ...state.listEvent,
                    error: institutionError
                }
            }

        case EventsActionTypes.LOAD_MONTHLY:
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent,
                    loading: true
                }
            }

        case EventsActionTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyHeaders, monthlyEventing } = action.payload
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyEventing: new EventMonthlySummary().fromJSON(monthlyEventing),
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyEvent.paginator,
                        totalRecords: parseInt(monthlyHeaders['x-total-count'], 10)
                    }
                }
            }

        case EventsActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyEventing: concatMonthlyEventing } = action.payload
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyEventing: state.monthlyEvent.monthlyEventing.concatFromJSON(concatMonthlyEventing),
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyEvent.paginator,
                        totalRecords: parseInt(
                            monthlyConcatHeaders['x-total-count'],
                            10)
                    }
                }
            }

        case EventsActionTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthlyEvent: {
                    ...state.monthlyEvent,
                    monthlyEventing: INITIAL_STATE.monthlyEvent.monthlyEventing,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case EventsActionTypes.LOAD_ANNUAL:
            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent, loading: true
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const {
                january: januaryEventReset,
                february: februaryEventReset,
                march: marchEventReset,
                april: aprilEventReset,
                may: mayEventReset,
                june: juneEventReset,
                july: julyEventReset,
                august: augustEventReset,
                september: septemberEventReset,
                october: octoberEventReset,
                november: novemberEventReset,
                december: decemberEventReset,
                total: totalReset,
                totalRealized: totalRealizedReset,
                totalUnrealized: totalNotRealizedReset,
                xTotalCount: xTotalCountReset
            } = action.payload

            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent,
                    loading: false,
                    success: true,
                    error: false,
                    annualEventing: {
                        january: januaryEventReset ? new EventMonthlySummary().fromJSON(januaryEventReset) : undefined,
                        february: februaryEventReset ? new EventMonthlySummary().fromJSON(februaryEventReset) : undefined,
                        march: marchEventReset ? new EventMonthlySummary().fromJSON(marchEventReset) : undefined,
                        april: aprilEventReset ? new EventMonthlySummary().fromJSON(aprilEventReset) : undefined,
                        may: mayEventReset ? new EventMonthlySummary().fromJSON(mayEventReset) : undefined,
                        june: juneEventReset ? new EventMonthlySummary().fromJSON(juneEventReset) : undefined,
                        july: julyEventReset ? new EventMonthlySummary().fromJSON(julyEventReset) : undefined,
                        august: augustEventReset ? new EventMonthlySummary().fromJSON(augustEventReset) : undefined,
                        september: septemberEventReset ? new EventMonthlySummary().fromJSON(septemberEventReset) : undefined,
                        october: octoberEventReset ? new EventMonthlySummary().fromJSON(octoberEventReset) : undefined,
                        november: novemberEventReset ? new EventMonthlySummary().fromJSON(novemberEventReset) : undefined,
                        december: decemberEventReset ? new EventMonthlySummary().fromJSON(decemberEventReset) : undefined,
                        totalRealized: totalRealizedReset,
                        totalUnrealized: totalNotRealizedReset,
                        total: totalReset
                    },
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualEvent.paginator,
                        totalRecords: xTotalCountReset
                    }
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const {
                january: januaryEventConcat,
                february: februaryEventConcat,
                march: marchEventConcat,
                april: aprilEventConcat,
                may: mayEventConcat,
                june: juneEventConcat,
                july: julyEventConcat,
                august: augustEventConcat,
                september: septemberEventConcat,
                october: octoberEventConcat,
                november: novemberEventConcat,
                december: decemberEventConcat,
                total: totalConcat,
                totalRealized: totalRealizedConcat,
                totalUnrealized: totalNotRealizedConcat,
                xTotalCount: xTotalCountConcat
            } = action.payload
            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent,
                    loading: false,
                    success: true,
                    error: false,
                    annualEventing: {
                        january: state.annualEvent.annualEventing.january?.concatFromJSON(januaryEventConcat),
                        february: state.annualEvent.annualEventing.february?.concatFromJSON(februaryEventConcat),
                        march: state.annualEvent.annualEventing.march?.concatFromJSON(marchEventConcat),
                        april: state.annualEvent.annualEventing.april?.concatFromJSON(aprilEventConcat),
                        may: state.annualEvent.annualEventing.may?.concatFromJSON(mayEventConcat),
                        june: state.annualEvent.annualEventing.june?.concatFromJSON(juneEventConcat),
                        july: state.annualEvent.annualEventing.july?.concatFromJSON(julyEventConcat),
                        august: state.annualEvent.annualEventing.august?.concatFromJSON(augustEventConcat),
                        september: state.annualEvent.annualEventing.september?.concatFromJSON(septemberEventConcat),
                        october: state.annualEvent.annualEventing.october?.concatFromJSON(octoberEventConcat),
                        november: state.annualEvent.annualEventing.november?.concatFromJSON(novemberEventConcat),
                        december: state.annualEvent.annualEventing.december?.concatFromJSON(decemberEventConcat),
                        totalRealized: state.annualEvent.annualEventing.totalRealized + totalRealizedConcat,
                        totalUnrealized: state.annualEvent.annualEventing.totalUnrealized + totalNotRealizedConcat,
                        total: state.annualEvent.annualEventing.total + totalConcat
                    },
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualEvent.paginator,
                        totalRecords: xTotalCountConcat
                    }
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annualEvent: {
                    ...state.annualEvent,
                    annualEventing: INITIAL_STATE.annualEvent.annualEventing,
                    loading: false,
                    error: true,
                    success: false,
                    data: annualError
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_DASH:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard, loading: true
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_DASH_SUCCESS:
            const {
                annualEvent: dashAnnualEvent
            } = action.payload

            return {
                ...state,
                dashboard: {
                    annualEvent: new EventAnnualSummary().fromJSON(dashAnnualEvent),
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case EventsActionTypes.LOAD_ANNUAL_DASH_FAILURE:

            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    loading: false,
                    success: true,
                    error: true
                }
            }

        default:
            return state
    }
}

export default reducer
