import authService, { LogicalStrategy } from '../../../services/auth'

export class VerifyScopesUtil {

    public static verify(expectedScopes?: string[], logicalStrategy = LogicalStrategy.AND): boolean {
        if (expectedScopes) {
            try {
                const { scope } = authService.decodeToken()
                const scopes = scope.split(' ')
                if (expectedScopes.length === 0) {
                    return true
                }
                return logicalStrategy === LogicalStrategy.AND ?
                    expectedScopes.every((itemScope: string) => scopes.includes(itemScope))
                    : expectedScopes.some((itemScope: string) => scopes.includes(itemScope))
            } catch (e) {
                return false
            }
        }

        return true

    }
}
