import { DailyDisbursementSummary } from './daily.disbursement.summary'

/**
 * Implementation of the Disbursement Input Output Report Item entity.
 *
 */
export class DisbursementIOReportItem extends DailyDisbursementSummary {
    private _date: string | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    public fromJSON(json: any): DisbursementIOReportItem {
        super.fromJSON(json)

        if (json.date !== undefined) {
            this.date = json.date
        }

        return this
    }

    public toJSON(): any {
        return {
            date: this.date,
            ...super.toJSON()
        }
    }
}
