import { JsonUtils } from '../../utils/json.util'

export default class ScenarioDataModel {
    private _id: string | undefined
    private _created_at: string | undefined
    private _percentage: number | undefined
    private _exercise_id: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get percentage(): number | undefined {
        return this._percentage
    }

    set percentage(value: number | undefined) {
        this._percentage = value
    }

    get exercise_id(): string | undefined {
        return this._exercise_id
    }

    set exercise_id(value: string | undefined) {
        this._exercise_id = value
    }

    public fromJSON(json: any): ScenarioDataModel {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }
        if (json.percentage !== undefined) {
            this._percentage = parseInt(json.percentage, 10)
        }
        if (json.exercise_id !== undefined) {
            this._exercise_id = json.exercise_id
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            percentage: !!this.percentage || this.percentage === 0 ? this.percentage : undefined,
            exercise_id: this.exercise_id ? this.exercise_id : undefined
        }
    }
}
