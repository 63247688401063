import React, { lazy, Suspense } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import { ConnectedRouter } from 'connected-react-router'

import Spinner from '../components/spinner/spinner'
import Layout from '../containers/layout/layout'
import authService, { LogicalStrategy } from '../services/auth'
import { VerifyScopesUtil } from '../store/application/utils/verify.scopes.util'
import { COSTING_ROUTES } from './costing'
import { AUTH_ROUTES } from './auth'
import { EVENTING_ROUTES } from './eventing'
import { DEBT_ROUTES } from './debts'
import { INVESTMENTS_ROUTES } from './investments'
import { EXPENSE_ROUTES } from './expense'
import { BALANCES_ROUTES } from './balances'
import { SCENARIOS_ROUTES } from './scenario'
import { EXPORT_ROUTES } from './export'
import { VerifyUsersUtil } from '../store/application/utils/verify.users.util'
import { DISBURSEMENT_ROUTES } from './disbursement'
import { LIBERATIONS_ROUTES } from './liberations'
import { SETTINGS_ROUTES } from './settings'
import { MANAGEMENT_ROUTES } from './management'
import { COMMITTED_BALANCES_ROUTES } from './committed.balances'

const NotFound = lazy(() => import('../components/scape.pages/notfound'))

const AccessDenied = lazy(() => import('../components/scape.pages/access.denied'))

const Home = lazy(() => import('../containers/home/home'))

const CashFlow = lazy(() => import('../containers/cash.flow/cash.flow'))

const AvailableFinancial = lazy(() => import('../containers/cash.flow/available.financial'))

const Profile = lazy(() => import('../containers/users/profile'))

interface IPrivateRouteProps extends RouteProps {
    key?: number
    component?: any
    private?: boolean
    redirect?: string
    path?: string
    routes?: any
    properties?: any[]
    scopes?: string[]
    users?: string[]
    logicalStrategy?: LogicalStrategy
}

export const RouteWithSubRoutes = (route: IPrivateRouteProps) => {


    return (
        <Route
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={(props: RouteProps) => {
                /* Verify user is authenticated */
                if (route.private && !authService.isAuthenticated()) {
                    return (
                        <Redirect
                            to={{ pathname: '/login', state: { from: props.location } }}
                        />
                    )
                }
                /* Make sure the user has the necessary scopes */
                if (route.scopes) {
                    try {
                        if (!VerifyScopesUtil.verify(route.scopes, route.logicalStrategy)) {
                            throw new Error('ACCESS DENIED')
                        }
                    } catch (e) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/access_denied',
                                    state: { from: props.location }
                                }}
                            />
                        )
                    }
                }
                /* Make sure the user has the necessary scopes */
                if (route.users?.length) {
                    try {
                        if (!VerifyUsersUtil.verify(route.users, route.logicalStrategy)) {
                            throw new Error('ACCESS DENIED')
                        }
                    } catch (e) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/access_denied',
                                    state: { from: props.location }
                                }}
                            />
                        )
                    }
                }
                /* Checks for a redirect link */
                if (route.redirect) {
                    return (
                        <Redirect
                            to={{
                                pathname: `${route.redirect}`,
                                state: { from: props.location }
                            }}
                        />
                    )
                }
                return (
                    <route.component
                        {...props}
                        {...route.properties}
                        exact={true}
                        routes={route.routes}
                    />
                )
            }}
        />
    )
}

const routes = [
    { path: '/', exact: true, redirect: '/app/home' },
    ...AUTH_ROUTES,
    {
        path: '/app',
        strict: true,
        private: true,
        component: Layout,
        routes: [
            { path: '/app', exact: true, redirect: '/app/home' },
            {
                path: '/app/my_profile',
                component: Profile,
                exact: true,
                private: true
            },
            {
                path: '/app/home',
                component: Home,
                exact: true,
                private: true
            },
            {
                path: '/app/cashflow',
                component: CashFlow,
                exact: true,
                private: true
            },
            {
                path: '/app/availablefinancial',
                component: AvailableFinancial,
                exact: true,
                private: true
            },
            /* Committed balances */
            ...COMMITTED_BALANCES_ROUTES,
            /* Releases */
            ...LIBERATIONS_ROUTES,
            /* Settings */
            ...SETTINGS_ROUTES,
            /* Management */
            ...MANAGEMENT_ROUTES,
            /* Export Data */
            ...EXPORT_ROUTES,
            /* Bank History */
            ...BALANCES_ROUTES,
            /* Costing */
            ...COSTING_ROUTES,
            /* Debts */
            ...DEBT_ROUTES,
            /* Events */
            ...EVENTING_ROUTES,
            /* Investments */
            ...INVESTMENTS_ROUTES,
            /* Expenses */
            ...EXPENSE_ROUTES,
            /* Scenarios */
            ...SCENARIOS_ROUTES,
            /* Disbursement */
            ...DISBURSEMENT_ROUTES,
            { path: '*', redirect: '/not_found' }
        ]
    },
    {
        path: '/not_found',
        component: NotFound,
        exact: true
    },
    {
        path: '/access_denied',
        component: AccessDenied,
        exact: true
    },
    {
        path: '*',
        component: NotFound
    }
]

class Routes extends React.Component<{ history: any }> {
    public render() {
        return (
            <ConnectedRouter history={this.props.history}>
                <Suspense fallback={<Spinner/>}>
                    <Switch>
                        {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                        ))}
                    </Switch>
                </Suspense>
            </ConnectedRouter>
        )
    }
}

export default Routes
