import React, { Component, FormEvent, ReactNode, RefObject } from 'react'
import { Dropdown } from 'primereact/dropdown'

import './select.scss'
import { IPaginator, ISearch } from '../../store/ducks/root.types'
import { MultiSelect } from 'primereact/multiselect'
import { TypeInstitution } from '../../store/application/models/financial.agents/institution/institution'

export interface IFinancialAgentProps {
    readonly name: string

    readonly value: string | string[]

    readonly filterPlaceholder?: string

    readonly placeholder?: string

    readonly disabled?: boolean

    readonly match?: any

    readonly multiple?: boolean

    readonly selectCategory?: TypeInstitution | ''

    setFieldValue(field: string, value: any): void

    setFieldTouched(field: string, value1: boolean, value2: boolean): void
}

export interface IFinancialAgentState {
    readonly elements: any[]
}

const INITIAL_STATE = {
    elements: [{ label: 'Aguarde, carregando...', value: null, type: null, className: 'loading' }]
}

export abstract class SelectFinancialAgent extends Component<IFinancialAgentProps, IFinancialAgentState> {
    private static templateDropdown(item): ReactNode {
        return (
            <React.Fragment key={item.id}>
                {
                    item.type && <div className="dropdown-header">{item.type}</div>
                }
                <div
                    className={'dropdown ' + item.className}
                    onClick={(e: any) => {
                        if (item.className === 'disabled') {
                            e?.stopPropagation()
                            e?.preventDefault()
                        }
                    }}>
                    <span style={{ margin: '.5em .25em 0 0' }}>{item.label}</span>
                </div>
            </React.Fragment>
        )
    }

    private ref: RefObject<Dropdown> | undefined
    private refMulti: RefObject<MultiSelect> | undefined
    private searchTime: any

    constructor(props: IFinancialAgentProps) {
        super(props)

        /* Initial State */
        this.state = INITIAL_STATE

        /* Bind Context */
        this.setRef = this.setRef.bind(this)
        this.setMultiRef = this.setMultiRef.bind(this)
        this.search = this.search.bind(this)
        this.getAll = this.getAll.bind(this)
        this.getById = this.getById.bind(this)

        const id = this.props.value
        if (id && typeof id === 'string') {
            this.getById(id).then()
        }
    }

    public componentWillUnmount() {
        this.resetState()
    }

    /* Checks the need to request the backend */
    public componentDidUpdate(prevProps: Readonly<IFinancialAgentProps>, prevState: Readonly<IFinancialAgentState>, snapshot?: any): void {
        const { value } = this.props
        if (prevProps?.value !== value && typeof value === 'string') {
            this.getById(value).then()
        }
    }

    public render() {
        const {
            name,
            value,
            disabled,
            filterPlaceholder,
            placeholder,
            setFieldValue,
            setFieldTouched,
            multiple
        } = this.props
        return multiple ?
            <MultiSelect
                disabled={disabled}
                name={name}
                value={value}
                options={this.state.elements}
                filter={true}
                filterPlaceholder={filterPlaceholder}
                placeholder={placeholder}
                style={{ width: '100%' }}
                onChange={(event: any) => {
                    setFieldValue(name, event.target.value)
                }}
                onBlur={() => {
                    setFieldTouched(name, true, true)
                }}
                ref={this.setMultiRef}/>
            : <Dropdown
                disabled={disabled}
                name={name}
                value={value}
                options={this.state.elements}
                filter={true}
                filterPlaceholder={filterPlaceholder}
                filterBy="label,value"
                filterMatchMode="notEquals"
                showClear={true}
                scrollHeight="150px"
                placeholder={placeholder}
                style={{ width: '100%' }}
                onChange={(event: any) => {
                    setFieldValue(name, event.target.value)
                }}
                onBlur={() => {
                    setFieldTouched(name, true, true)
                }}
                autoWidth={false}
                ref={this.setRef}
                filterInputAutoFocus={true}
                itemTemplate={SelectFinancialAgent.templateDropdown}
                className="financial-select"/>
    }

    protected resetState(): void {
        this.setState(INITIAL_STATE)
    }

    /* Method to invoke the request to the backend */
    protected abstract getAll(paginator?: IPaginator | any): Promise<void>

    /* Method to invoke the request to the backend */
    protected abstract async getById(id: string): Promise<void>

    /* Inclusion of function to capture search bar value */
    private setRef(element: RefObject<Dropdown> | any): void {
        if (element) {
            const filterChange = element.onFilterInputChange;
            const optionClick = element.onOptionClick;
            element.onFilterInputChange = (e) => {
                this.search(e)
                filterChange(e)
            }
            element.onOptionClick = (e) => {
                const emptyEvent: any = {
                    ...e,
                    target: {
                        ...e.target,
                        value: ''
                    }
                }
                filterChange(emptyEvent)
                optionClick(e)
            }
            this.ref = element
        }
    }

    /* Inclusion of function to capture search bar value */
    private setMultiRef(element: RefObject<MultiSelect> | any): void {
        if (element) {
            const optionClick = element.onOptionClick;
            element.onOptionClick = (e) => {
                const emptyEvent: any = {
                    ...e,
                    query: ''
                }
                if (element?.onFilter) {
                    element.onFilter(emptyEvent)
                }
                optionClick(e)
            }
            this.refMulti = element
        }
    }

    /* Clears and reset search time */
    private search(event: FormEvent<HTMLInputElement> | any): void {
        const value = event.target.value
        const isNumber: boolean = !isNaN(parseInt(value, 10))
        clearTimeout(this.searchTime)
        this.searchTime = setTimeout(() => {
            const search: ISearch = { key: isNumber ? 'code' : 'name', value: `*${value}*` }
            this.getAll({ search }).then()
        }, 500)
    }

}
