import React, { Component } from 'react'
import { InputMask } from 'primereact/inputmask'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import UtilDatePicker, { LOCALE } from './utils'

interface IProps {

    id?: string

    name?: string

    value: string

    disabled?: boolean

    mask?: string

    selectionMode?: string

    setFieldValue(field: string, value: any): void

    setFieldTouched(field: string, value1: boolean, value2: boolean): void

}

interface IState {
    calendar: boolean
    date: Date | undefined
}

class DateRanger extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            calendar: false,
            date: new Date(UtilDatePicker.usaFormatDate(props.value) + 'T03:00:00.000')
        }

        this.closeCalendar = this.closeCalendar.bind(this)
    }

    public render() {
        const { setFieldValue, setFieldTouched, value, id, name, disabled, mask, selectionMode } = this.props
        return (
            <React.Fragment>
                <span className="p-inputgroup">
                    <InputMask
                        id={id ? id : 'date'}
                        name={name ? name : 'date'}
                        mask={mask ? mask : '99/99/9999'}
                        value={value}
                        disabled={disabled}
                        onChange={(event: any) => {
                            setFieldValue('date', event.target.value)
                            setFieldTouched('date', true, true)
                        }}
                        onComplete={(event) => {
                            this.setState({
                                ...this.state,
                                date: UtilDatePicker.stringToDate(event.value)
                            })
                            setFieldValue('date', event.value)
                        }}
                    />
                    <Button
                        className="p-inputgroup-addon p-button-clean"
                        disabled={disabled}
                        style={{ right: '45px' }}
                        icon="pi pi-times"
                        type="button"
                        tooltip="Limpar"
                        onClick={() => {
                            this.setState({ ...this.state, date: undefined })
                            setFieldValue('date', null)
                        }}
                    />
                    <Button
                        className="p-inputgroup-addon p-button-secondary"
                        disabled={disabled}
                        icon="pi pi-calendar"
                        type="button"
                        tooltip="Abrir calendario"
                        onClick={() => {
                            this.setState({ ...this.state, calendar: !this.state.calendar })
                        }}
                    />

                </span>
                <Dialog
                    visible={this.state.calendar}
                    closeOnEscape={true}
                    modal={false}
                    position="center"
                    onHide={this.closeCalendar}>
                    <Calendar
                        value={this.state.date}
                        onClearButtonClick={this.closeCalendar}
                        onTodayButtonClick={this.closeCalendar}
                        inline={true}
                        locale={LOCALE}
                        onChange={(e: any) => {
                            if (selectionMode) {
                                const start = e.value ? e.value[0] : ''
                                const end = e.value ? e.value[1] : ''
                                this.setState({
                                    ...this.state,
                                    date: e.value
                                })
                                if (start && end) {
                                    setFieldValue('date', `${start ? UtilDatePicker.dateToString(start) : ''} - ${end ? UtilDatePicker.dateToString(end) : ''}`)
                                    this.closeCalendar()
                                }
                            } else {
                                const dateSelected: Date = e.value as Date
                                this.setState({
                                    ...this.state,
                                    date: dateSelected
                                })
                                setFieldValue('date', UtilDatePicker.dateToString(dateSelected))
                            }
                        }}
                        // options: default, range and multiple
                        selectionMode={selectionMode ? selectionMode : 'default'}/>
                </Dialog>
            </React.Fragment>
        )
    }

    /* Close modal calendar */
    private closeCalendar() {
        this.setState({ calendar: false })
    }
}

export default DateRanger
