import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementTransaction } from '../store/application/models/disbursement/disbursement.transaction'
import { DisbursementTransactionType } from '../store/application/utils/disbursement.transaction.type'
import { Input } from '../store/application/models/disbursement/input'
import { Output } from '../store/application/models/disbursement/output'
import { DailyDisbursementSummary } from '../store/application/models/disbursement/daily.disbursement.summary'
import { YearDisbursementSummaryItem } from '../store/application/models/disbursement/year.disbursement.summary.item'
import { DisbursementConferenceItem } from '../store/application/models/disbursement/conference.disbursement.item'
import { DisbursementReport } from '../store/application/models/disbursement/disbursement.report'
import { InputTransfer } from '../store/application/models/disbursement/input.transfer'

const MAP_DISBURSEMENT_TYPE = {
    [DisbursementTransactionType.INPUT]: 'inputs',
    [DisbursementTransactionType.OUTPUT]: 'outputs',
    [DisbursementTransactionType.INPUT_TRANSFER]: 'inputs_transfers'
}

class DisbursementTransactionService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: DisbursementTransaction): Promise<DisbursementTransaction> {
        const type: string = MAP_DISBURSEMENT_TYPE[`${data?.transaction_type}`]
        return httpClient
            .post(`${this.apiVersion}/disbursement/${type}`, data.toJSON())
            .then((response: IAxiosResponse<any>) => {
                switch (data?.transaction_type) {
                    case DisbursementTransactionType.INPUT:
                        return new Input().fromJSON(response.data)
                    case DisbursementTransactionType.INPUT_TRANSFER:
                        return new InputTransfer().fromJSON(response.data)
                    case DisbursementTransactionType.OUTPUT:
                        return new Output().fromJSON(response.data)
                    default:
                        return new DisbursementTransaction().fromJSON(response.data)
                }
            })
    }

    public getById(transaction_type: DisbursementTransactionType, id:string): Promise<DisbursementTransaction> {
        const type: string = MAP_DISBURSEMENT_TYPE[`${transaction_type}`]
        return httpClient
            .post(`${this.apiVersion}/disbursement/${type}/${id}`)
            .then((response: IAxiosResponse<any>) => {
                switch (transaction_type) {
                    case DisbursementTransactionType.INPUT:
                        return new Input().fromJSON(response.data)
                    case DisbursementTransactionType.INPUT_TRANSFER:
                        return new InputTransfer().fromJSON(response.data)
                    case DisbursementTransactionType.OUTPUT:
                        return new Output().fromJSON(response.data)
                    default:
                        return new DisbursementTransaction().fromJSON(response.data)
                }
            })
    }

    public getDailyDisbursementSummary(
        transactionType: DisbursementTransactionType,
        date: string,
        paginator?: IPaginator
    ): Promise<IAxiosResponse<DailyDisbursementSummary>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(`${this.apiVersion}/disbursement/${transactionType}/date/${date}/dailysummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new DailyDisbursementSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public getMonthlyDisbursementSummary(
        transactionType: DisbursementTransactionType,
        date: string,
        paginator?: IPaginator
    ): Promise<IAxiosResponse<DailyDisbursementSummary>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(`${this.apiVersion}/disbursement/${transactionType}/date/${date}/monthlysummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new DailyDisbursementSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public syncDailyDisbursementSummary(date: string): Promise<IAxiosResponse<DailyDisbursementSummary>> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/outputs/date/${date}/dailysummary/sync`)
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new DailyDisbursementSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public update(data: DisbursementTransaction): Promise<DisbursementTransaction> {
        const type: string = MAP_DISBURSEMENT_TYPE[`${data.transaction_type}`]
        const body = data.toJSON()
        delete body.date
        delete body.institution_id
        const url = `${this.apiVersion}/disbursement/${type}/${data.id}`
        return httpClient
            .patch(url, body)
            .then((response: IAxiosResponse<any>) => {
                switch (data?.transaction_type) {
                    case DisbursementTransactionType.INPUT:
                        return new Input().fromJSON(response.data)
                    case DisbursementTransactionType.INPUT_TRANSFER:
                        return new InputTransfer().fromJSON(response.data)
                    case DisbursementTransactionType.OUTPUT:
                        return new Output().fromJSON(response.data)
                    default:
                        return new DisbursementTransaction().fromJSON(response.data)
                }
            })
    }

    public remove(transactionType: DisbursementTransactionType, id: string): Promise<IAxiosResponse<void>> {
        const type: string = MAP_DISBURSEMENT_TYPE[`${transactionType}`]
        return httpClient.delete(`${this.apiVersion}/disbursement/${type}/${id}`)
    }

    public getYearDisbursementSummary(
        year: string,
        paginator?: IPaginator,
        startDate?: string,
        endDate?: string
    ): Promise<IAxiosResponse<YearDisbursementSummaryItem[]>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
            if (paginator.search.key && paginator.search.value) {
                params.append(`${paginator.search.key}`, `*${paginator.search.value}*`)
            }
        }
        if (startDate) {
            params.append(`date`, `gte:${startDate}`)
        }
        if (endDate) {
            params.append(`date`, `lte:${endDate}`)
        }
        return httpClient
            .get(`${this.apiVersion}/disbursement/balances/year/${year}/annualsummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: response
                        ?.data
                        ?.map((item: any) => new YearDisbursementSummaryItem().fromJSON(item)) || [],
                    headers: response.headers
                }
            })
    }

    public getConference(
        year: string,
        paginator?: IPaginator
    ): Promise<IAxiosResponse<DisbursementConferenceItem[]>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
            if (paginator.search.key && paginator.search.value) {
                params.append(`${paginator.search.key}`, `*${paginator.search.value}*`)
            }
        }
        return httpClient
            .get(`${this.apiVersion}/disbursement/conference/year/${year}`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: response
                        ?.data
                        ?.map((item: any) => new DisbursementConferenceItem().fromJSON(item)) || [],
                    headers: response.headers
                }
            })
    }

    public getReport(date: string): Promise<DisbursementReport> {
        return httpClient
            .get(`${this.apiVersion}/disbursement/reports/date/${date}`)
            .then((response: IAxiosResponse<any>) => new DisbursementReport().fromJSON(response.data))
    }
}

export default new DisbursementTransactionService()

