import { lazy } from 'react'
import { LogicalStrategy } from '../services/auth'

const AnnualInvestments = lazy(() => import('../containers/investments/annual'))
const MonthlyInvestments = lazy(() => import('../containers/investments/monthly'))
const CreateInvestment = lazy(() => import('../containers/investments/create'))


export const INVESTMENTS_ROUTES = [
    {
        path: '/app/investments/annual',
        component: AnnualInvestments,
        scopes: ['as:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/investments/monthly',
        component: MonthlyInvestments,
        scopes: ['ms:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/investments/new',
        component: CreateInvestment,
        scopes: ['tr:c'],
        exact: true,
        private: true
    },

    {
        path: '/app/institutions/:institutionId/investments/new',
        component: CreateInvestment,
        scopes: ['tr:c'],
        exact: true,
        private: true
    },
    {
        path: '/app/institutions/:institutionId/investments/:investmentsId',
        component: CreateInvestment,
        logicalStrategy: LogicalStrategy.OR,
        scopes: ['tr:r', 'tr:u'],
        exact: true,
        private: true
    }
]
