import { all, apply, call, put, takeLatest } from 'redux-saga/effects'

import { Toast } from '../../../services/toast'
import institutionService from '../../../services/institutions'
import {
    createFailure,
    createSuccess,
    loadSuccess,
    loadFailure,
    findSuccess,
    findFailure,
    updateSuccess,
    updateFailure,
    removeSuccess,
    removeFailure,
    loadRequest
} from './actions'
import { InstitutionActionTypes } from './types'
import { IActionType, IAxiosResponse } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'

const toastService = Toast.getInstance()

function* create(action: IActionType) {
    try {
        const { institution } = action.payload
        const response = yield call(institutionService.create, institution)
        yield put<any>(createSuccess(response))
        toastService.show('success', 'Órgão adicionado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err))
    }
}

function* getAll(action: IActionType) {
    const { paginator } = action.payload
    try {
        const response: IAxiosResponse = yield apply(
            institutionService,
            institutionService.getAll,
            [paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getById(action: IActionType) {
    const { institutionId } = action.payload
    try {
        const response: Institution = yield call(
            institutionService.getById,
            institutionId
        )
        yield put(findSuccess(response))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* update(action: IActionType) {
    const { institution } = action.payload
    try {
        yield call(institutionService.update, institution)
        yield put(updateSuccess(institution))
        toastService.show('success', 'Órgão atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err))
    }
}

function* remove(action: IActionType) {
    const { idForRemove } = action.payload
    try {
        yield call(institutionService.remove, idForRemove)
        yield put(removeSuccess())
        yield put(loadRequest())
        toastService.show('success', 'Órgão removido com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

export default function* userSaga() {
    return yield all([
        takeLatest(InstitutionActionTypes.LOAD_REQUEST, getAll),
        takeLatest(InstitutionActionTypes.FIND_REQUEST, getById),
        takeLatest(InstitutionActionTypes.CREATE_REQUEST, create),
        takeLatest(InstitutionActionTypes.UPDATE_REQUEST, update),
        takeLatest(InstitutionActionTypes.REMOVE_REQUEST, remove)
    ])
}
