import { InstitutionalExpense } from '../institutional.expense'

export enum DebtType {
    DIRECTS = 'directs',
    INDIRECTS = 'indirects',
    JUDICIAL_DECISIONS_AND_AGREEMENTS = 'judicial_decisions_and_agreements'
}

export default class Debt extends InstitutionalExpense {
    private _debt_type: DebtType | undefined
    private _installments: number | undefined

    constructor() {
        super()
        this._installments = 1
    }

    get debt_type(): DebtType | undefined {
        return this._debt_type
    }

    set debt_type(value: DebtType | undefined) {
        this._debt_type = value
    }

    get installments(): number | undefined {
        return this._installments
    }

    set installments(value: number | undefined) {
        this._installments = value
    }

    public fromJSON(json: any): Debt {

        super.fromJSON(json)

        if (json?.debt_type !== undefined) {
            this._debt_type = json.debt_type
        }

        if (json?.installments !== undefined) {
            this._installments = json.installments
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            debt_type: this.debt_type ? this.debt_type : undefined,
            installments: this.installments ? this.installments : undefined
        }
    }
}

