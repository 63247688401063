import React, { Component } from 'react'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import { Button } from 'primereact/button'

interface IProps {
    data: any
    filename: string
    disabled: boolean
}

class ExportCSV extends Component<IProps> {

    public render() {

        const { data, filename, disabled } = this.props
        return (
            <div className="row">
                <CSVLink data={data}
                    filename={`${filename}-${moment(new Date().toISOString()).format('DD/MM/YYYY')}.csv`}>
                    <Button
                        type="button"
                        className="p-button-primary small right"
                        icon="pi pi-file-excel"
                        tooltip="Exportar CSV"
                        tooltipOptions={{ position: 'top' }}
                        iconPos="left"
                        label="CSV"
                        disabled={disabled} />
                </CSVLink>
            </div>
        )
    }
}

export default ExportCSV