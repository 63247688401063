import * as Yup from 'yup'
import { CostingCategory, TypeInstitution } from '../models/financial.agents/institution/institution'

export class InstitutionValidator {
    private static _code: Yup.StringSchema<string> = Yup.string()
        .required('Código Obrigatório')
        .min(2, 'Quantidade mínima de caracteres é 2')
        .max(6, 'Quantidade máxima de caracteres é 6')

    private static _name: Yup.StringSchema<string> = Yup.string().required('Nome do Órgão Obrigatório')

    private static _institution_type: Yup.StringSchema<string | null> = Yup.string()
        .oneOf(Object.values(TypeInstitution), 'Tipo inválido')
        .required('Selecione um Tipo')
        .nullable()

    private static _cost_category: Yup.StringSchema<string> = Yup.string()
        .when('institution_type', {
            is: (institution_type) => institution_type === TypeInstitution.DIRECT_ADMINISTRATION,
            then: Yup.string()
                .oneOf(Object.values(CostingCategory), 'Categoria inválida')
                .required('Selecione uma Categoria')
        })

    static get validationScheme(): Yup.ObjectSchema<object> {
        return Yup
            .object()
            .shape({

                code: this.code,

                name: this.institutionName,

                institution_type: this.institution_type,

                cost_category: this.cost_category
            })
    }

    static get code(): Yup.StringSchema<string> {
        return this._code
    }

    static get institutionName(): Yup.StringSchema<string> {
        return this._name
    }

    static get institution_type(): Yup.StringSchema<string | null> {
        return this._institution_type
    }

    static get cost_category(): Yup.StringSchema<string> {
        return this._cost_category
    }
}


