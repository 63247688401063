import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import Exercise from '../store/application/models/exercise/exercise'

export class ExerciseService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(exercise: Exercise): Promise<IAxiosResponse<Exercise>> {
        return httpClient.post(`${this.apiVersion}/exercises`, exercise.toJSON())
    }

    public getAll(paginator?: IPaginator): Promise<IAxiosResponse<Exercise[]>> {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (paginator?.search?.key && paginator?.search?.value) {
                params.append(paginator.search.key, paginator.search.value)
            }
        }

        return httpClient.get(`${this.apiVersion}/exercises`, { params })
    }

    public remove(exerciseId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/exercises/${exerciseId}`)
    }
}

export default new ExerciseService()
