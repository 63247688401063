import CryptoJS from 'crypto-js'

const lsSecretKey = process.env.REACT_APP_LS_SECRET_KEY

class LocalStorage {

    public getItem(key: string): string {
        const encryptedKey = this.encryptKey(key)
        const encryptedItem = localStorage.getItem(encryptedKey.toString())
        return encryptedItem ? this.decryptItem(encryptedItem) : ''
    }

    public setItem(key: string, item: string): void {
        const encryptedKey = this.encryptKey(key)
        const encryptedItem = this.encryptItem(item)
        localStorage.setItem(encryptedKey, encryptedItem)
    }

    public logout(): void {
        localStorage.clear()
    }

    private encryptKey(str: string): string {
        return btoa(str)
    }

    private encryptItem(str: string): string {
        const encrypted = CryptoJS.AES.encrypt(str, lsSecretKey)
        return encrypted.toString()
    }

    private decryptItem(encrypted: string): string {
        const decrypted = CryptoJS.AES.decrypt(encrypted, lsSecretKey)
        return decrypted.toString(CryptoJS.enc.Utf8)
    }
}

export default new LocalStorage()
