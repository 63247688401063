import React, { Component } from 'react'

import './footer.scss'

interface IState {
    collapse: boolean
}

class Footer extends Component<IState> {

    public render() {
        return <footer className={`footer ${this.props.collapse ? 'collapseClose' : 'collapseOpen'}`}>
            <div className="container-fluid">
                <nav className="text-center">
                    <ul className="links-vertical">
                        <li className="links-vertical">
                            <a href="http://www.uepb.edu.br/" target="_blank" rel="noopener noreferrer">
                                UEPB
                            </a>
                        </li>
                        <li className="links-vertical">
                            <a href="https://www.sefaz.pb.gov.br/" target="_blank" rel="noopener noreferrer">
                                SEFAZ
                            </a>
                        </li>
                        <li className="links-vertical">
                            <a href="https://paraiba.pb.gov.br/" target="_blank" rel="noopener noreferrer">
                                GOVERNO DO ESTADO
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    }
}

export default Footer

