import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementInputTransferLog } from '../store/application/models/disbursement/disbursement.input.transfer.log'
class DisbursementTransferLogService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: DisbursementInputTransferLog): Promise<DisbursementInputTransferLog> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/inputs_transfers`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementInputTransferLog().fromJSON(response.data))
    }

    public getById(id: string): Promise<DisbursementInputTransferLog> {
        return httpClient
            .get(`${this.apiVersion}/disbursement/inputs_transfers/${id}`)
            .then((response: IAxiosResponse<any>) => new DisbursementInputTransferLog().fromJSON(response.data))
    }

    public update(data: DisbursementInputTransferLog): Promise<DisbursementInputTransferLog> {
        const url = `${this.apiVersion}/disbursement/inputs_transfers/${data.id}`
        return httpClient
            .patch(url, { description: data.description, value: data.value })
            .then((response: IAxiosResponse<any>) => new DisbursementInputTransferLog().fromJSON(response.data))
    }

    public remove(id: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/disbursement/inputs_transfers/${id}`)
    }

}

export default new DisbursementTransferLogService()

