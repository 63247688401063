import { action } from 'typesafe-actions'
import { DisbursementIOReportActionTypes } from './types'
import { DisbursementReportType } from '../../application/utils/disbursement.report.type'
import { DisbursementIOReportItem } from '../../application/models/disbursement/disbursement.io.report.item'

/* Actions for Reset State */
export const reset = () => action(DisbursementIOReportActionTypes.RESET)

/* Actions for Load Disbursement Report Input/Output items */
export const loadRequest = (
    startDate: string,
    endDate: string,
    reportType: DisbursementReportType,
    transactionCategory?: string,
    institutionsIds?: string[]
) => action(
    DisbursementIOReportActionTypes.LOAD_REQUEST,
    {
        startDate,
        endDate,
        reportType,
        transactionCategory,
        institutionsIds
    }
)

/* Actions for Load Disbursement Report Input items */
export const loadInputSuccess = (data: DisbursementIOReportItem[]) => action(
    DisbursementIOReportActionTypes.LOAD_INPUT_SUCCESS,
    { data }
)

export const loadInputFailure = () => action(DisbursementIOReportActionTypes.LOAD_INPUT_FAILURE)

/* Actions for Load Disbursement Report Output items */
export const loadOutputSuccess = (data: DisbursementIOReportItem[]) => action(
    DisbursementIOReportActionTypes.LOAD_OUTPUT_SUCCESS,
    { data }
)

export const loadOutputFailure = () => action(DisbursementIOReportActionTypes.LOAD_OUTPUT_FAILURE)
