import { all, put, takeLatest } from 'redux-saga/effects'

import { IActionType } from '../root.types'
import { ExportActionTypes, IActionExportData } from './types'
import { load as loadCashFlow } from '../cashflow/actions'
import { load as loadScenario, loadScenarios, loadDuodecimos } from '../scenario/actions'
import { loadAnnualBalancesRequest, loadMonthlyBalancesRequest } from '../balance/actions'
import { loadRevenueAnnual, loadRevenueMonthly } from '../revenue/actions'
import { loadAnnual as loadAnnualExpense, loadMonthly as loadMonthlyExpense } from '../expense/actions'
import { loadAnnual as loadAnnualCosting, loadMonthly as loadMonthlyCosting } from '../costing/actions'
import { loadAnnual as loadAnnualDebt, loadMonthly as loadMonthlyDebt } from '../debt/actions'
import { loadAnnual as loadAnnualEvents, loadMonthly as loadMonthlyEvents } from '../events/actions'
import { loadInvestmentsAnnual, loadInvestmentsMonthly } from '../investments/actions'

function* exportData(action: IActionType<IActionExportData>) {
    const { month, elements, exerciseSelected } = action.payload
    const year = month.substr(0, 4)

    if (elements.includes('cash_flow')) {
        yield put(loadCashFlow(month))
    }
    if (!elements.includes('cash_flow') && elements.includes('financial_available')) {
        yield put(loadCashFlow(month))
    }
    if (elements.includes('scenarios')) {
        yield put(loadScenario(year))
        yield put(loadDuodecimos(exerciseSelected))
        yield put(loadScenarios(`${exerciseSelected?.id}`))
    }
    if (elements.includes('balance')) {
        yield put(loadAnnualBalancesRequest(year))
        yield put(loadMonthlyBalancesRequest(month))
    }
    if (elements.includes('revenue')) {
        yield put(loadRevenueAnnual(year))
        yield put(loadRevenueMonthly(month))
    }
    if (elements.includes('expense')) {
        yield put(loadAnnualExpense(year))
        yield put(loadMonthlyExpense(month))
    }
    if (elements.includes('costing')) {
        yield put(loadAnnualCosting(year))
        yield put(loadMonthlyCosting(month))
    }
    if (elements.includes('debt')) {
        yield put(loadAnnualDebt(year))
        yield put(loadMonthlyDebt(month))
    }
    if (elements.includes('event')) {
        yield put(loadAnnualEvents(year))
        yield put(loadMonthlyEvents(month))
    }
    if (elements.includes('investment')) {
        yield put(loadInvestmentsAnnual(year))
        yield put(loadInvestmentsMonthly(month))
    }

}

export default function* exportSaga() {
    return yield all([
        takeLatest(ExportActionTypes.EXPORT_REQUEST, exportData)
    ])
}
