import BalanceSummaryItem from './balance.summary.item'
import { JsonUtils } from '../../utils/json.util'
import Balance, { BalanceType } from './balance'
import { AgentCategory } from './balance.agent'

export default class BalanceSummary {
    private static hasValues(balances: Balance[] | undefined): boolean {
        if (balances?.length) {
            return balances?.some(balance => balance.value)
        }
        return false
    }

    private _from_disbursement: BalanceSummaryItem[] | undefined
    private _balance_agents: BalanceSummaryItem[] | undefined

    constructor() {
        this._from_disbursement = []
        this._balance_agents = []
    }

    get from_disbursement(): BalanceSummaryItem[] | undefined {
        return this._from_disbursement
    }

    set from_disbursement(value: BalanceSummaryItem[] | undefined) {
        this._from_disbursement = value
    }

    get balance_agents(): BalanceSummaryItem[] | undefined {
        return this._balance_agents
    }

    set balance_agents(value: BalanceSummaryItem[] | undefined) {
        this._balance_agents = value
    }

    public fromJSON(json: any): BalanceSummary {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.from_disbursement !== undefined) {
            this._from_disbursement = json.from_disbursement
                .map(balanceAgent => new BalanceSummaryItem().fromJSON(balanceAgent))
        }

        if (json.balance_agents !== undefined) {
            this._balance_agents = json.balance_agents
                .map(balanceAgent => new BalanceSummaryItem().fromJSON(balanceAgent))
        }

        return this
    }

    public toJSON(): any {
        return {
            from_disbursement: this.from_disbursement ?
                this.from_disbursement.map(balanceAgent => balanceAgent.toJSON()) : [],
            balance_agents: this.balance_agents ?
                this.balance_agents.map(balanceAgent => balanceAgent.toJSON()) : []
        }
    }

    public filterResources(): any {
        const result: BalanceSummary = Object.assign({}, this)

        result.balance_agents = this.balance_agents
            ?.filter((balanceAgent: BalanceSummaryItem) => {
                return BalanceSummary.hasValues(balanceAgent.balances)
            })

        return result
    }

    public concatFromJSON(json: any): BalanceSummary {

        if (json?.balance_agents?.length) {
            const concatBalanceSummaryItems: BalanceSummaryItem[] = json.balance_agents
                .map((balanceAgent) => new BalanceSummaryItem().fromJSON(balanceAgent))
            this.balance_agents = this.balance_agents?.concat(concatBalanceSummaryItems)
        }

        return this
    }

    public getLastBalanceForBalanceType(category: BalanceType): number | undefined {
        return this?.balance_agents?.length ?
            this.balance_agents
                ?.filter((balanceAgent: BalanceSummaryItem) => {
                    return balanceAgent?.balances?.length && balanceAgent.balance_category === category
                })
                ?.map((balanceAgent: BalanceSummaryItem) => {
                    return balanceAgent.monthlyLastBalance.value || 0
                })
                ?.reduce((prev: number | undefined, current: number | undefined) => {
                    return (prev ? prev : 0) + (current ? current : 0)
                }, 0)
            : 0
    }

    public getTotalBalanceForAgentCategory(agentCategory: AgentCategory): number | undefined {
        return this?.balance_agents?.length ?
            this.balance_agents
                ?.filter((balanceAgent: BalanceSummaryItem) => {
                    return balanceAgent?.balances?.length &&
                        balanceAgent.balance_category === BalanceType.OTHERS &&
                        balanceAgent.agent_category === agentCategory
                })
                ?.map((balanceAgent: BalanceSummaryItem) => {
                    return balanceAgent?.monthlyLastBalance?.value || 0
                })
                ?.reduce((prev: number | undefined, current: number | undefined) => {
                    return (prev ? prev : 0) + (current ? current : 0)
                }, 0)
            : 0
    }

    public getDetailsDataForBalanceType(balanceType: BalanceType): { enumeration: any, summary: any, translate: any, map: any } {
        let BALANCE_AGENTS: any = {}
        let TRANSLATE_BALANCE_AGENTS: any = {}
        this.balance_agents
            ?.filter((balanceAgent: BalanceSummaryItem) => {
                return balanceAgent?.balances?.length &&
                    balanceAgent.balance_category === balanceType
            })
            ?.forEach((balanceAgent: BalanceSummaryItem) => {
                BALANCE_AGENTS = {
                    ...BALANCE_AGENTS,
                    [balanceAgent?.name || '']: balanceAgent?.monthlyLastBalance?.value || 0
                }
                TRANSLATE_BALANCE_AGENTS = {
                    ...TRANSLATE_BALANCE_AGENTS,
                    [balanceAgent?.name || '']: balanceAgent?.name || ''
                }
            })
        return {
            enumeration: TRANSLATE_BALANCE_AGENTS,
            summary: BALANCE_AGENTS,
            translate: TRANSLATE_BALANCE_AGENTS,
            map: TRANSLATE_BALANCE_AGENTS
        }
    }
}
