import Payee, { PayeeCategory } from './payee'
import Expense from '../../transactions/expense/expense'
import { MonthlyFinancialAgent } from '../monthly.financial.agent'

export class MonthlyPayee extends MonthlyFinancialAgent<Expense> {
    private _payee: Payee | undefined

    get id(): string | undefined {
        return this._payee?.id
    }

    get name(): string | undefined {
        return this._payee?.name
    }

    get expense_category(): PayeeCategory | undefined {
        return this._payee?.expense_category
    }

    get can_have_budgets(): boolean | undefined {
        return this._payee?.can_have_budgets
    }

    public fromJSON(json: any): MonthlyPayee {

        super.fromJSON(json)

        if (json) {
            this._payee = new Payee().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...this._payee?.toJSON(),
            ...super.toJSON()
        }
    }
}
