import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Audit from '../store/application/models/audits/audit'
import { ApiService } from './api'
import UtilDatePicker from '../components/date.picker/utils'

class AuditService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super();
    }

    public async getAll(paginator?: IPaginator): Promise<IAxiosResponse<Audit[]>> {
        const params = new URLSearchParams()
        if (paginator) {

            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (
                paginator?.search?.key === 'user.id'
                || paginator?.search?.key === 'request.operator'
                || paginator?.search?.key === 'request.remote_address'
            ) {
                params.append(paginator?.search?.key, paginator?.search?.value)
            }

            if (paginator?.search?.key === 'date' && paginator?.search?.value) {
                const range = paginator?.search?.value
                const rangeSplit = range.split(' - ')

                if (UtilDatePicker.isValidDate(rangeSplit[0]) && UtilDatePicker.isValidDate(rangeSplit[1])) {
                    const start = UtilDatePicker.usaFormatDate(rangeSplit[0])
                    const end = UtilDatePicker.usaFormatDate(rangeSplit[1])


                    if (start === end) {
                        params.append(paginator?.search?.key, `gte:${start}T00:00:00.000Z`)
                        params.append(paginator?.search?.key, `lte:${start}T23:59:59.000Z`)
                    } else {
                        params.append(paginator?.search?.key, `gte:${start}`)
                        params.append(paginator?.search?.key, `lte:${end}`)
                    }
                }
            }
        }
        return httpClient.get(`${this.apiVersion}/auditlogs`, { params })
    }
}

export default new AuditService()
