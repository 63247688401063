import { Reducer } from 'redux'
import { DisbursementReportConfigActionTypes, IDisbursementReportConfigState } from './types'
import { DisbursementReportConfig } from '../../application/models/disbursement/disbursement.report.config'

export const INITIAL_STATE: IDisbursementReportConfigState = {
    create: {
        data: new DisbursementReportConfig(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        id: '',
        dialog: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IDisbursementReportConfigState> = (state: IDisbursementReportConfigState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case DisbursementReportConfigActionTypes.CHANGE_PAGINATOR:
            const { paginator: changedPaginator } = action.payload
            return {
                ...state,
                list: { ...state.list, paginator: changedPaginator }
            }

        case DisbursementReportConfigActionTypes.CHANGE_CREATE_DIALOG:
            const { dialog: createdDialog, data: changedData } = action.payload
            return {
                ...state,
                create: { ...state.create, data: changedData, dialog: createdDialog }
            }

        case DisbursementReportConfigActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data }
            }

        case DisbursementReportConfigActionTypes.CREATE_SUCCESS:
            const { data: newData } = action.payload
            const resultData: DisbursementReportConfig[] = [newData].concat(state.list.data)
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: newData
                },
                list: {
                    ...state.list,
                    data: resultData
                }
            }

        case DisbursementReportConfigActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case DisbursementReportConfigActionTypes.RESET:
            return INITIAL_STATE

        case DisbursementReportConfigActionTypes.LOAD_REQUEST:
            const { paginator: listPaginator } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                    paginator: listPaginator
                }
            }

        case DisbursementReportConfigActionTypes.LOAD_SUCCESS:
            const { data: listData, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: false,
                    success: true,
                    data: listData,
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case DisbursementReportConfigActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementReportConfigActionTypes.CHANGE_REMOVE_DIALOG:
            const { id: removeId, dialog: removeDialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog: removeDialog, id: removeId }
            }

        case DisbursementReportConfigActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case DisbursementReportConfigActionTypes.REMOVE_SUCCESS:
            const { id: removedId } = action.payload
            const resultRemoveData: DisbursementReportConfig[] = state.list.data
                ?.filter((item: DisbursementReportConfig) => removedId !== `${item?.id}`) || []
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    id: ''
                },
                list: {
                    ...state.list,
                    data: resultRemoveData
                }
            }

        case DisbursementReportConfigActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case DisbursementReportConfigActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case DisbursementReportConfigActionTypes.UPDATE_SUCCESS:
            const { data: updatedData } = action.payload
            const resultUpdateData: DisbursementReportConfig[] = state.list.data
                ?.map((item: DisbursementReportConfig) => {
                    if (item?.id === updatedData?.id) {
                        return updatedData
                    }
                    return item
                }) || []
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: updatedData
                },
                list: {
                    ...state.list,
                    data: resultUpdateData
                }
            }

        case DisbursementReportConfigActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state;
    }
}

export default reducer
