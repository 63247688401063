import { JsonUtils } from '../../utils/json.util'
import { InvestmentsType } from './investments/investments'
import { DebtType } from './debt/debt'

export class AnnualTransaction {
    private _total_value: number | undefined
    private _date: string | undefined
    private _prevision: number | undefined
    /* Attributes used only by annual summaries of debts and investments */
    private _description: string | undefined
    private _investment_type: InvestmentsType | undefined
    private _debt_type: DebtType | undefined

    get total_value(): number | undefined {
        return this._total_value
    }

    set total_value(value: number | undefined) {
        this._total_value = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get prevision(): number | undefined {
        return this._prevision
    }

    set prevision(value: number | undefined) {
        this._prevision = value
    }

    get description(): string | undefined {
        return this._description
    }

    set description(value: string | undefined) {
        this._description = value
    }

    get investment_type(): InvestmentsType | undefined {
        return this._investment_type
    }

    set investments_type(value: InvestmentsType | undefined) {
        this._investment_type = value
    }

    get debt_type(): DebtType | undefined {
        return this._debt_type
    }

    set debt_type(value: DebtType | undefined) {
        this._debt_type = value
    }

    public fromJSON(json: any): AnnualTransaction {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.total_value !== undefined) {
            this._total_value = json.total_value
        }

        if (json.date !== undefined) {
            this._date = json.date
        }

        if (json.prevision !== undefined) {
            this._prevision = json.prevision
        }

        if (json.description !== undefined) {
            this._description = json.description
        }

        if (json.investment_type !== undefined) {
            this._investment_type = json.investment_type
        }

        if (json.debt_type !== undefined) {
            this._debt_type = json.debt_type
        }

        return this
    }

    public toJSON(): any {
        return {
            total_value: this.total_value ? this.total_value : undefined,
            date: this.date ? this.date : undefined,
            prevision: this.prevision ? this.prevision : undefined,
            description: this.description ? this.description : undefined,
            investment_type: this.investment_type ? this.investment_type : undefined,
            debt_type: this.debt_type ? this.debt_type : undefined
        }
    }
}
