import { JsonUtils } from '../../utils/json.util'
import { AnnualTransaction } from '../transactions/annual.transaction'

export abstract class AnnualFinancialAgent {
    private _previsions: number | undefined
    private _transactions: AnnualTransaction[] | undefined

    get transactions(): AnnualTransaction[] | undefined {
        return this._transactions
    }

    set transactions(value: AnnualTransaction[] | undefined) {
        this._transactions = value
    }

    get previsions(): number | undefined {
        return this._previsions
    }

    set previsions(value: number | undefined) {
        this._previsions = value
    }

    public fromJSON(json: any): AnnualFinancialAgent {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.previsions !== undefined) {
            this._previsions = json.previsions
        }

        if (json.transactions !== undefined) {
            this._transactions = json.transactions
        }

        return this
    }

    public toJSON(): any {
        return {
            transactions: this.transactions ? this.transactions : undefined,
            previsions: this.previsions ? this.previsions : undefined
        }
    }
}
