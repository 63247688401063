import { applyMiddleware, createStore, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import multi from 'redux-multi'
import { composeWithDevTools } from 'redux-devtools-extension'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './ducks/root.reducer'
import rootSaga from './ducks/root.saga'
import { IAuthState } from './ducks/auth/types'
import { ILayoutState } from './ducks/layout/types'
import { IUserState } from './ducks/users/types'
import { IInstitutionState } from './ducks/institutions/types'
import { ICostingState } from './ducks/costing/types'
import { IEventsState } from './ducks/events/types'
import { IPrevisionState } from './ducks/prevision/types'
import { IAuditState } from './ducks/audit/types'
import { IDebtState } from './ducks/debt/types'
import { IExerciseState } from './ducks/exercise/types'
import { IInvestmentsState } from './ducks/investments/types'
import { IPayeeState } from './ducks/payee/types'
import { IExpenseState } from './ducks/expense/types'
import { ISuggestionState } from './ducks/suggestion/types'
import { IPayersState } from './ducks/payer/types'
import { IRevenueState } from './ducks/revenue/types'
import { ICashFlowState } from './ducks/cashflow/types'
import { IBalanceAgentState } from './ducks/balance.agent/types'
import { IBalancesState } from './ducks/balance/types'
import { IScenarioState } from './ducks/scenario/types'
import { IBudgetState } from './ducks/budget/types'
import { ISupplementaryBudgetState } from './ducks/supplementary.budget/types'
import { IDisbursementState } from './ducks/disbursement/types'
import { IConferenceRevenueState } from './ducks/conference.revenue/types'
import { IConferenceExpenseState } from './ducks/conference.expense/types'
import { IDisbursementReportBalanceState } from './ducks/disbursement.report.balance/types'
import { IDisbursementIOReportState } from './ducks/disbursement.report.io/types'
import { IDisbursementReportConfigState } from './ducks/disbursement.report.config/types'
import { IDisbursementBalanceConfigState } from './ducks/disbursement.balance.config/types'
import { ILiberationState } from './ducks/liberation/types'
import { IDisbursementReportBalanceObservationState } from './ducks/disbursement.report.balance.observation/types'

export interface IApplicationState {
    auth: IAuthState
    layout: ILayoutState
    user: IUserState
    institution: IInstitutionState
    costing: ICostingState
    prevision: IPrevisionState,
    event: IEventsState,
    debt: IDebtState,
    exercise: IExerciseState,
    investments: IInvestmentsState,
    payee: IPayeeState,
    expense: IExpenseState,
    suggestion: ISuggestionState,
    payer: IPayersState,
    revenue: IRevenueState,
    cashFlow: ICashFlowState,
    balanceAgent: IBalanceAgentState,
    balance: IBalancesState,
    audit: IAuditState,
    scenario: IScenarioState,
    budget: IBudgetState,
    supplementaryBudget: ISupplementaryBudgetState,
    disbursement: IDisbursementState,
    conferenceRevenue: IConferenceRevenueState,
    conferenceExpense: IConferenceExpenseState,
    disbursementReportBalance: IDisbursementReportBalanceState,
    disbursementReportIO: IDisbursementIOReportState,
    disbursementReportConfig: IDisbursementReportConfigState,
    disbursementBalanceConfig: IDisbursementBalanceConfigState,
    liberation: ILiberationState,
    disbursementReportBalanceObservation: IDisbursementReportBalanceObservationState
}

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

/* composeWithDevTools is integration for plugin redux/devtools chrome.
* see https://github.com/zalmoxisus/redux-devtools-extension
* */
const store: Store<IApplicationState> = createStore(
    rootReducer(history),
    composeWithDevTools(
        applyMiddleware(multi, sagaMiddleware, routerMiddleware(history))
    )
)

sagaMiddleware.run(rootSaga)

export default store
