import { Transaction } from '../transaction'

export default class Expense extends Transaction {
    private _payee_id: string | undefined

    get payee_id(): string | undefined {
        return this._payee_id
    }

    set payee_id(value: string | undefined) {
        this._payee_id = value
    }

    public fromJSON(json: any): Expense {

        super.fromJSON(json)

        if (json.payee_id !== undefined) {
            this._payee_id = json.payee_id
        }
        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            payee_id: this.payee_id ? this.payee_id : undefined
        }
    }

}

