import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementReportConfig } from '../store/application/models/disbursement/disbursement.report.config'

class DisbursementReportConfigService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: DisbursementReportConfig): Promise<DisbursementReportConfig> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/reports/config`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportConfig().fromJSON(response.data))
    }

    public getById(id: string): Promise<DisbursementReportConfig> {
        return httpClient
            .get(`${this.apiVersion}/disbursement/reports/config/${id}`)
            .then((response: IAxiosResponse<any>) => new DisbursementReportConfig().fromJSON(response.data))
    }

    public getAll(paginator: IPaginator): Promise<IAxiosResponse<DisbursementReportConfig[]>> {
        const params = this.buildDefaultParams(paginator)
        return httpClient
            .get(`${this.apiVersion}/disbursement/reports/config`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: response?.data?.map((item: any) => new DisbursementReportConfig().fromJSON(item)) || [],
                    headers: response.headers
                }
            })
    }

    public update(data: DisbursementReportConfig): Promise<DisbursementReportConfig> {
        return httpClient
            .patch(`${this.apiVersion}/disbursement/reports/config/${data?.id}`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportConfig().fromJSON(response.data))
    }

    public remove(id: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/disbursement/reports/config/${id}`)
    }
}

export default new DisbursementReportConfigService()

