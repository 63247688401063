import { action } from 'typesafe-actions'
import { BalanceLocation, BalanceTypes } from './types'
import Balance from '../../application/models/balance/balance'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import BalanceAgent from '../../application/models/balance/balance.agent'
import BalanceSummary from '../../application/models/balance/balance.summary'

/* Actions for Reset State */
export const resetBalance = () => action(BalanceTypes.RESET_BALANCE, {})

/* Actions for Change */
export const changeBalance = (dialog: boolean, balance: Balance) => action(
    BalanceTypes.CHANGE_BALANCE,
    { dialog, balance }
)

export const changeDialog = (dialog: boolean) => action(BalanceTypes.CHANGE_DIALOG, { dialog })

export const changePaginator = (balanceAgentId: string, paginator: IPaginator) => [
    action(BalanceTypes.CHANGE_PAGINATOR, { paginator }),
    loadBalanceRequest(balanceAgentId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(BalanceTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, balanceAgentId: string) => action(
    BalanceTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove,
        balanceAgentId
    }
)

export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(BalanceTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadMonthlyBalancesRequest(date, paginator)
]

export const resetMonthlyPaginator = () => action(BalanceTypes.RESET_MONTHLY_PAGINATOR)


export const changeAnnualPaginator = (year: string, paginator: IPaginator) => [
    action(BalanceTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadAnnualBalancesRequest(year, paginator)
]

export const resetAnnualPaginator = () => action(BalanceTypes.RESET_ANNUAL_PAGINATOR)

/* Actions for Create Balance */
export const createBalanceRequest = (balance: Balance, location: BalanceLocation) => action(
    BalanceTypes.CREATE_REQUEST,
    { balance, location }
)

export const createBalanceSuccess = (balance: Balance) => action(BalanceTypes.CREATE_SUCCESS, { balance })

export const createBalanceFailure = (error: ErrorEvent, balance: Balance) => action(
    BalanceTypes.CREATE_FAILURE, {
        error,
        balance
    }
)

/* Actions for Load Balance */
export const loadBalanceRequest = (balanceAgentId: string, paginator?: IPaginator) => action(BalanceTypes.LOAD_REQUEST, {
    balanceAgentId,
    paginator
})

export const loadBalanceSuccess = (response: IAxiosResponse<Balance[]>) => action(
    BalanceTypes.LOAD_SUCCESS, {
        balances: response.data,
        headers: response.headers
    }
)

export const loadBalanceFailure = (error: ErrorEvent) => action(BalanceTypes.LOAD_FAILURE, { error })

/* Actions for Find Balance */
export const findBalanceRequest = (balanceAgentId: string, balanceId: string) => action(BalanceTypes.FIND_REQUEST, {
    balanceAgentId,
    balanceId
})

export const findBalanceSuccess = (response: IAxiosResponse<Balance[]>) => action(
    BalanceTypes.FIND_SUCCESS, {
        balance: response.data,
        headers: response.headers
    }
)

export const findBalanceFailure = (error: ErrorEvent) => action(BalanceTypes.FIND_FAILURE, { error })

/* Actions for Update Balance */
export const updateBalanceRequest = (balance: Balance, location: BalanceLocation) => action(
    BalanceTypes.UPDATE_REQUEST,
    { balance, location }
)

export const updateBalanceSuccess = (balance: Balance) => action(
    BalanceTypes.UPDATE_SUCCESS,
    { balance }
)

export const updateBalanceFailure = (error: ErrorEvent, balance: Balance) => action(
    BalanceTypes.UPDATE_FAILURE, {
        error,
        balance
    }
)

/* Actions for Remove Balance */
export const removeBalanceRequest = (
    balanceAgentId: string,
    balanceId: string,
    location: BalanceLocation,
    paginator: IPaginator,
    date?: string) => action(
    BalanceTypes.REMOVE_REQUEST, {
        balanceAgentId,
        balanceId,
        location,
        paginator,
        date
    }
)

export const removeBalanceSuccess = () => action(BalanceTypes.REMOVE_SUCCESS, {})

export const removeBalanceFailure = (error: ErrorEvent) => action(BalanceTypes.REMOVE_FAILURE, { error })

/* Actions for Find Balance Agent */
export const findBalanceAgentRequest = (balanceAgentId: string) => action(BalanceTypes.FIND_BALANCE_AGENT_REQUEST, { balanceAgentId })

export const findBalanceAgentSuccess = (response: IAxiosResponse<BalanceAgent>) => action(
    BalanceTypes.FIND_BALANCE_AGENT_SUCCESS, {
        balanceAgents: response.data,
        headers: response.headers
    }
)

export const findBalanceAgentFailure = (error: ErrorEvent) => action(BalanceTypes.FIND_BALANCE_AGENT_FAILURE, { error })

/* Actions for Load Daily summary */
export const loadDailyBalancesRequest = (date?: string, paginator?: IPaginator) => action(
    BalanceTypes.LOAD_DAILY_REQUEST,
    { date, paginator }
)

export const loadDailyBalancesSuccessReset = (response: IAxiosResponse<BalanceSummary[]>) => action(
    BalanceTypes.LOAD_DAILY_SUCCESS_RESET, {
        summary: response.data,
        headers: response.headers
    }
)

export const loadDailyBalancesSuccessConcat = (response: IAxiosResponse<BalanceSummary[]>) => action(
    BalanceTypes.LOAD_MONTHLY_SUCCESS_CONCAT, {
        summary: response.data,
        headers: response.headers
    }
)

export const loadDailyBalancesFailure = (error: ErrorEvent) => action(BalanceTypes.LOAD_DAILY_FAILURE, { error })

/* Actions for Load Monthly */
export const loadMonthlyBalancesRequest = (date?: string, paginator?: IPaginator) => action(BalanceTypes.LOAD_MONTHLY_REQUEST, {
    date,
    paginator
})

export const loadMonthlyBalancesSuccessReset = (response: IAxiosResponse<BalanceSummary[]>) => action(
    BalanceTypes.LOAD_MONTHLY_SUCCESS_RESET, {
        monthlyBalances: response.data,
        headers: response.headers
    }
)

export const loadMonthlyBalancesSuccessConcat = (response: IAxiosResponse<BalanceSummary[]>) => action(
    BalanceTypes.LOAD_MONTHLY_SUCCESS_CONCAT, {
        monthlyBalances: response.data,
        headers: response.headers
    }
)

export const loadMonthlyBalancesFailure = (error: ErrorEvent) => action(BalanceTypes.LOAD_MONTHLY_FAILURE, { error })

/* Actions for Load Annual */
export const loadAnnualBalancesRequest = (year: string, paginator?: IPaginator) => action(BalanceTypes.LOAD_ANNUAL_REQUEST, {
    year,
    paginator
})

export const loadAnnualBalancesSuccessReset = (response: IAxiosResponse<BalanceSummary>) => action(
    BalanceTypes.LOAD_ANNUAL_SUCCESS_RESET, {
        annualBalances: response.data,
        headers: response.headers
    }
)

export const loadAnnualBalancesSuccessConcat = (response: IAxiosResponse<BalanceSummary>) => action(
    BalanceTypes.LOAD_ANNUAL_SUCCESS_CONCAT, {
        annualBalances: response.data,
        headers: response.headers
    }
)

export const loadAnnualBalancesFailure = (error: ErrorEvent) => action(BalanceTypes.LOAD_ANNUAL_FAILURE, { error })
