import { lazy } from 'react'

const AnnualDebt = lazy(() => import('../containers/debts/annual'))
const MonthlyDebt = lazy(() => import('../containers/debts/monthly'))


export const DEBT_ROUTES = [
    {
        path: '/app/debts/annual',
        component: AnnualDebt,
        scopes: ['as:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/debts/monthly',
        component: MonthlyDebt,
        scopes: ['ms:r'],
        exact: true,
        private: true
    },

]
