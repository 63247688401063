import * as Yup from 'yup'
import moment from 'moment'
import UtilDatePicker from '../../../components/date.picker/utils'

export class TransactionValidator {
    private static _note: Yup.StringSchema<string | undefined> = Yup.string()
        .min(2, 'Quantidade mínima de caracteres igual a 2')
        .max(90, 'Quantidade máxima igual a 90')
        .notRequired()

    private static _description: Yup.StringSchema<string | undefined> = Yup.string()
        .min(2, 'Quantidade mínima de caracteres igual a 2')
        .max(90, 'Quantidade máxima igual a 90')
        .notRequired()

    private static _date: Yup.StringSchema<string | null> = Yup.string()
        .test(
            'dateIsValid',
            'Informe uma data válida',
            (value) => moment(UtilDatePicker.usaFormatDate(value)).isValid())
        .test(
            'isWeekend',
            'Finais de semana são inválidos',
            (value) => !UtilDatePicker.hasWeekEnd(UtilDatePicker.usaFormatDate(value)))
        .test(
            'hasOccurred',
            'A data informada já ocorreu!',
            (value) => !UtilDatePicker.hasOccurred(UtilDatePicker.usaFormatDate(value)))
        .test(
            'hasFuture',
            'A data informada é futura!',
            (value) => !UtilDatePicker.hasFuture(UtilDatePicker.usaFormatDate(value)))
        .required('Informe uma data válida')
        .nullable()

    private static _value: Yup.NumberSchema<number | null> = Yup.number()
        .test(
            'valueIsValid',
            'Informe um valor válido',
            (value) => value
        )
        .required('Informe um valor válido')
        .nullable()

    static get validationScheme(): Yup.ObjectSchema<object> {
        return Yup
            .object()
            .shape({

                note: this.note,

                description: this.description,

                date: this.date,

                value: this.value

            })
    }

    static get note(): Yup.StringSchema<string | undefined> {
        return this._note
    }

    static get description(): Yup.StringSchema<string | undefined> {
        return this._description
    }

    static get date(): Yup.StringSchema<string | null> {
        return this._date
    }

    static get value(): Yup.NumberSchema<number | null> {
        return this._value
    }
}


