import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { Toast } from '../../../services/toast'
import payeeService from '../../../services/payee'
import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    loadDuodecimosFailure,
    loadDuodecimosSuccess,
    loadFailure,
    loadRequest,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, PayeeTypes } from './types'
import { IActionType, IAxiosResponse } from '../root.types'
import Payee from '../../application/models/financial.agents/payee/payee'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    try {
        const { payee, location } = action.payload
        const response = yield apply(payeeService, payeeService.create, [payee])
        yield put<any>(createSuccess(response, location))
        toastService.show('success', 'Gasto adicionado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    const { paginator } = action.payload
    try {
        const response: IAxiosResponse = yield apply(
            payeeService,
            payeeService.getAll,
            [paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {
    const { payeeId } = action.payload
    try {
        const response: IAxiosResponse<Payee> = yield apply(
            payeeService,
            payeeService.getById,
            [payeeId]
        )
        yield put(findSuccess(response?.data))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { payee, location } = action.payload
    try {
        yield apply(payeeService, payeeService.update, [payee])
        yield put<any>(updateSuccess(payee, location))
        toastService.show('success', 'Gasto atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    const { idForRemove, paginator } = action.payload
    try {
        yield apply(payeeService, payeeService.remove, [idForRemove])
        yield put(removeSuccess())
        yield put(loadRequest(paginator))
        toastService.show('success', 'Gasto removido com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* getDuodecimos(action: IActionType<IActionLoad>) {
    const { paginator } = action.payload
    try {
        const response = yield apply(
            payeeService,
            payeeService.getDuodecimos, [paginator]
        )
        yield put(loadDuodecimosSuccess(response))
    } catch (err) {
        yield put(loadDuodecimosFailure(err))
    }
}

export default function* payeeSaga() {
    return yield all([
        takeLatest(PayeeTypes.LOAD_REQUEST, getAll),
        takeLatest(PayeeTypes.FIND_REQUEST, getById),
        takeLatest(PayeeTypes.CREATE_REQUEST, create),
        takeLatest(PayeeTypes.UPDATE_REQUEST, update),
        takeLatest(PayeeTypes.REMOVE_REQUEST, remove),
        takeLatest(PayeeTypes.LOAD_DUODECIMOS, getDuodecimos)
    ])
}
