import React, { Component } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import InputCurrency from 'react-intl-currency-input'

import './currency.scss'

interface IState {
    id?: string

    name?: string

    value: number | undefined

    readOnly?: boolean
    disabled?: boolean

    className?: any

    style?: any

    tooltip?: string

    tooltipOptions?: any

    disabledColor?: boolean

    enterPressed?(value: number | undefined): void

    onClick?(event: any): void

    onChange?(event: any, value: number): void

    onBlur?(): void
}

class Currency extends Component<IState, { value?: number }> {

    public static formatter(value: number | undefined): string {
        const options = {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }
        return value ? Number(value).toLocaleString('pt-BR', options) : '0,00'
    }

    private ref: any

    constructor(props: IState) {
        super(props)

        this.state = { value: props.value }

        /* Bind Context */
        this.setRef = this.setRef.bind(this)
        this.enterPressed = this.enterPressed.bind(this)
    }

    public componentDidUpdate(prevProps: Readonly<IState>, prevState: Readonly<{ value?: number }>, snapshot?: any): void {
        if (prevProps.value !== this.props.value && prevState.value !== this.props.value && prevProps.value === prevState.value) {
            this.setState({ value: this.props.value })
        }
    }

    public setRef(element: any): void {
        if (element) {
            const { value } = this.props
            const oldOnKeyDown = element.onInputKeyDown
            element.onInputKeyDown = (e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    this.enterPressed(this.state.value ? this.state.value : 0)
                }
                if (e.key === 'Escape') {
                    this.enterPressed(value)
                }
                oldOnKeyDown(e)
            }
            element.onPaste = (e) => {
                e?.preventDefault()
            }
            this.ref = element
        }
    }

    public render() {
        const {
            enterPressed,
            tooltip,
            tooltipOptions,
            readOnly,
            onClick,
            className,
            style,
            id,
            name,
            onChange,
            onBlur,
            disabled,
            disabledColor
        } = this.props
        const { value } = this.state
        let signal = ''
        if (value && !disabledColor) {
            signal = value > 0 ? 'positive' : 'negative'
        }
        // const formatted = Currency.formatterWithSuffix(value ? value : 0)

        return readOnly ?
            <InputNumber
                ref={this.setRef}
                className={'input-column ' + className + ' ' + signal}
                value={value && parseFloat(value.toFixed(2)) !== 0 ? parseFloat(value.toFixed(2)) : undefined}
                // mode="currency"
                // currency="BRL"
                locale="pt-BR"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={10}
                // suffix={formatted.suffix}
                prefix={'R$ '}
                readonly={!enterPressed}
                tooltip={tooltip ? tooltip : `R$ ${value ? value.toLocaleString('pt-BR') : '0,00'}`}
                tooltipOptions={tooltipOptions}
                disabled={readOnly}
                onChange={(e) => this.setState({ value: e.value })}
                onFocus={onClick}
                onBlur={() => this.enterPressed(this.state.value)}
                inputStyle={style}
                style={style}/>
            :
            <InputCurrency
                id={id}
                name={name}
                disabled={disabled}
                currency="BRL"
                value={value}
                config={{
                    locale: 'pt-BR',
                    formats: {
                        number: {
                            BRL: {
                                // For R$ 0,00
                                // style: "currency",
                                // currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                        }
                    }
                }}
                onChange={(e, v) => {
                    this.setState({ value: v })
                    if (onChange) {
                        onChange(e, v)
                    }
                }}
                onFocus={onClick}
                onBlur={() => {
                    this.enterPressed(value)
                    if (onBlur) {
                        onBlur()
                    }
                }}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                        this.enterPressed(this.state.value ? this.state.value : 0)
                    }
                    if (e.key === 'Escape') {
                        this.enterPressed(value)
                    }
                }}
                className={'input-column ' + className}
                style={style}/>
    }

    private enterPressed(value: number | undefined): void {
        const { enterPressed } = this.props
        if (enterPressed) {
            this.setState({ value })
            enterPressed(value)
        }

    }
}

export default Currency
