import { YearDisbursementSummaryTransactionItem } from './year.disbursement.summary.transaction.item'
import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Year Disbursement Summary Item entity.
 *
 */
export class YearDisbursementSummaryItem {
    private _institution_id: string | undefined
    private _name: string | undefined
    private _code: string | undefined
    private _cost: YearDisbursementSummaryTransactionItem | undefined
    private _extra_cost: YearDisbursementSummaryTransactionItem | undefined
    private _investment: YearDisbursementSummaryTransactionItem | undefined
    private _event: YearDisbursementSummaryTransactionItem | undefined
    private _guys: YearDisbursementSummaryTransactionItem | undefined
    private _seams: YearDisbursementSummaryTransactionItem | undefined
    private _staff_cost: YearDisbursementSummaryTransactionItem | undefined
    private _fundeb: YearDisbursementSummaryTransactionItem | undefined
    private _consignment: YearDisbursementSummaryTransactionItem | undefined
    private _mr: YearDisbursementSummaryTransactionItem | undefined
    private _gd: YearDisbursementSummaryTransactionItem | undefined

    get institution_id(): string | undefined {
        return this._institution_id
    }

    set institution_id(value: string | undefined) {
        this._institution_id = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get code(): string | undefined {
        return this._code
    }

    set code(value: string | undefined) {
        this._code = value
    }

    get cost(): YearDisbursementSummaryTransactionItem | undefined {
        return this._cost
    }

    set cost(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._cost = value
    }

    get extra_cost(): YearDisbursementSummaryTransactionItem | undefined {
        return this._extra_cost
    }

    set extra_cost(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._extra_cost = value
    }

    get investment(): YearDisbursementSummaryTransactionItem | undefined {
        return this._investment
    }

    set investment(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._investment = value
    }

    get event(): YearDisbursementSummaryTransactionItem | undefined {
        return this._event
    }

    set event(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._event = value
    }

    get guys(): YearDisbursementSummaryTransactionItem | undefined {
        return this._guys
    }

    set guys(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._guys = value
    }

    get seams(): YearDisbursementSummaryTransactionItem | undefined {
        return this._seams
    }

    set seams(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._seams = value
    }

    get staff_cost(): YearDisbursementSummaryTransactionItem | undefined {
        return this._staff_cost
    }

    set staff_cost(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._staff_cost = value
    }

    get fundeb(): YearDisbursementSummaryTransactionItem | undefined {
        return this._fundeb
    }

    set fundeb(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._fundeb = value
    }

    get consignment(): YearDisbursementSummaryTransactionItem | undefined {
        return this._consignment
    }

    set consignment(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._consignment = value
    }

    get mr(): YearDisbursementSummaryTransactionItem | undefined {
        return this._mr
    }

    set mr(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._mr = value
    }

    get gd(): YearDisbursementSummaryTransactionItem | undefined {
        return this._gd
    }

    set gd(value: YearDisbursementSummaryTransactionItem | undefined) {
        this._gd = value
    }

    public fromJSON(json: any): YearDisbursementSummaryItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.institution_id !== undefined) {
            this.institution_id = json.institution_id
        }
        if (json.name !== undefined) {
            this.name = json.name
        }
        if (json.code !== undefined) {
            this.code = json.code
        }
        if (json.cost !== undefined) {
            this.cost = new YearDisbursementSummaryTransactionItem().fromJSON(json.cost)
        }
        if (json.extra_cost !== undefined) {
            this.extra_cost = new YearDisbursementSummaryTransactionItem().fromJSON(json.extra_cost)
        }
        if (json.investment !== undefined) {
            this.investment = new YearDisbursementSummaryTransactionItem().fromJSON(json.investment)
        }
        if (json.event !== undefined) {
            this.event = new YearDisbursementSummaryTransactionItem().fromJSON(json.event)
        }
        if (json.guys !== undefined) {
            this.guys = new YearDisbursementSummaryTransactionItem().fromJSON(json.guys)
        }
        if (json.seams !== undefined) {
            this.seams = new YearDisbursementSummaryTransactionItem().fromJSON(json.seams)
        }
        if (json.staff_cost !== undefined) {
            this.staff_cost = new YearDisbursementSummaryTransactionItem().fromJSON(json.staff_cost)
        }
        if (json.fundeb !== undefined) {
            this.fundeb = new YearDisbursementSummaryTransactionItem().fromJSON(json.fundeb)
        }
        if (json.consignment !== undefined) {
            this.consignment = new YearDisbursementSummaryTransactionItem().fromJSON(json.consignment)
        }
        if (json.mr !== undefined) {
            this.mr = new YearDisbursementSummaryTransactionItem().fromJSON(json.mr)
        }
        if (json.gd !== undefined) {
            this.gd = new YearDisbursementSummaryTransactionItem().fromJSON(json.gd)
        }

        return this
    }

    public toJSON(): any {
        return {
            institution_id: this.institution_id,
            name: this.name,
            code: this.code,
            cost: this.cost?.toJSON(),
            extra_cost: this.extra_cost?.toJSON(),
            investment: this.investment?.toJSON(),
            event: this.event?.toJSON(),
            guys: this.guys?.toJSON(),
            seams: this.seams?.toJSON(),
            staff_cost: this.staff_cost?.toJSON(),
            fundeb: this.fundeb?.toJSON(),
            consignment: this.consignment?.toJSON(),
            mr: this.mr?.toJSON(),
            gd: this.gd?.toJSON()
        }
    }

    public getInputSum(): number {
        return (this.cost?.input || 0) +
            (this.extra_cost?.input || 0) +
            (this.investment?.input || 0) +
            (this.event?.input || 0) +
            (this.guys?.input || 0) +
            (this.seams?.input || 0) +
            (this.staff_cost?.input || 0) +
            (this.fundeb?.input || 0) +
            (this.consignment?.input || 0) +
            (this.mr?.input || 0) +
            (this.gd?.input || 0)
    }

    public getOutputSum(): number {
        return (this.cost?.output || 0) +
            (this.extra_cost?.output || 0) +
            (this.investment?.output || 0) +
            (this.event?.output || 0) +
            (this.guys?.output || 0) +
            (this.seams?.output || 0) +
            (this.staff_cost?.output || 0) +
            (this.fundeb?.output || 0) +
            (this.consignment?.output || 0) +
            (this.mr?.output || 0) +
            (this.gd?.output || 0)
    }

    public getBalance(): number {
        const input: number = this.getInputSum()
        const output: number = this.getOutputSum()
        return input - output
    }
}
