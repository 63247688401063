import { lazy } from 'react'
import { LogicalStrategy } from '../services/auth'

const Settings = lazy(() => import('../containers/settings'))
const ListOfUsers = lazy(() => import('../containers/settings/users'))
const Create = lazy(() => import('../containers/users/create'))
const Audit = lazy(() => import('../containers/audit/audits'))
const CreateExercise = lazy(() => import('../containers/exercise/create'))
const ListOfExercise = lazy(() => import('../containers/exercise/list'))

export const SETTINGS_ROUTES = [
    {
        path: '/app/settings',
        component: Settings,
        exact: true,
        private: true
    },
    {
        path: '/app/settings/users',
        component: ListOfUsers,
        scopes: ['ad:ra', 'op:ra', 'vi:ra', 'di:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/settings/users/:userId',
        scopes: ['ad:r', 'op:r', 'di:r'],
        logicalStrategy: LogicalStrategy.OR,
        component: Create,
        exact: true,
        private: true
    },
    {
        path: '/app/settings/audits',
        component: Audit,
        scopes: ['al:ra'],
        exact: true,
        private: true
    },
    {
        path: '/app/settings/exercises',
        component: ListOfExercise,
        exact: true,
        private: true,
        scopes: ['ex:ra']
    },
    {
        path: '/app/settings/exercises/new',
        component: CreateExercise,
        exact: true,
        private: true,
        scopes: ['ex:c']
    }
]
