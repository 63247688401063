import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Disbursement Report Balance Observation entity.
 * @param {string} [id] Identifier of resource.
 * @param {string} [created_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is saved.
 * @param {string} [updated_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is updated.
 * @param {string} [date] Observation date.
 * @param {string} [value] Observation value.
 * @param {number} [index] Observation index.
 */
export class DisbursementReportBalanceObservation {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _date: string | undefined
    private _value: string | undefined
    private _index: number | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): string | undefined {
        return this._value
    }

    set value(value: string | undefined) {
        this._value = value
    }

    get index(): number | undefined {
        return this._index
    }

    set index(value: number | undefined) {
        this._index = value
    }

    public fromJSON(json: any): DisbursementReportBalanceObservation {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (json.index !== undefined) {
            this.index = json.index
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            created_at: this.created_at || undefined,
            updated_at: this.updated_at || undefined,
            date: this.date || undefined,
            value: this.value !== undefined ? this.value : undefined,
            index: this.index !== undefined ? this.index : undefined
        }
    }
}
