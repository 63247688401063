import { IPaginator } from '../store/ducks/costing/types'
import { IAxiosResponse } from '../store/ducks/root.types'
import MonthlySummary from '../store/application/models/summary/monthly.summary'
import httpClient from './config.axios'
import { TransactionTypes } from '../store/application/models/prevision/prevision'
import AnnualSummary from '../store/application/models/summary/annual.summary'
import { AnnualTransaction } from '../store/application/models/transactions/annual.transaction'
import { Transaction } from '../store/application/models/transactions/transaction'
import { AnnualFinancialAgent } from '../store/application/models/financial.agents/annual.financial.agent'
import { PrevisionTransactionTypes } from '../store/application/utils/prevision.transaction.types'
import { LiberationSummary } from '../store/application/models/liberation/liberation.summary'
import { LiberationTransaction } from '../store/application/models/liberation/liberation.transaction'

export class TransactionService<F extends AnnualFinancialAgent, T extends Transaction> {

    constructor(private apiVersion: string = 'v1') {
    }

    public getMonthly(transactionType: TransactionTypes, date: string, paginator?: IPaginator)
        : Promise<IAxiosResponse<MonthlySummary<any, T>>> {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page !== undefined && paginator.page !== null) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }

        params.append('sort', 'name')

        return httpClient.get(`${this.apiVersion}/transactions/${transactionType}/date/${date}/monthlysummary`, { params })
    }

    public getAnnual(transactionType: TransactionTypes, year: string, paginator?: IPaginator)
        : Promise<IAxiosResponse<AnnualSummary<F>>> {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page !== undefined && paginator.page !== null) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }

        params.append('sort', 'name')

        return httpClient
            .get(`${this.apiVersion}/transactions/${transactionType}/date/${year}/annualsummary`, { params })
    }

    public async getAnnualByInstitutionId(
        financialAgentId: string,
        transactionType: PrevisionTransactionTypes,
        year: number): Promise<AnnualTransaction[]> {

        const params = new URLSearchParams()

        params.append('financial_agent_id', financialAgentId)

        if (
            transactionType !== PrevisionTransactionTypes.EXPENSE &&
            transactionType !== PrevisionTransactionTypes.REVENUE
        ) {
            params.append('type', transactionType)
        }

        const annualSummary: IAxiosResponse<LiberationSummary> = await httpClient
            .get(`${this.apiVersion}/liberations/date/${year}/annualsummary`, { params })

        const financialTransaction = annualSummary?.data?.financial_agents?.length ?
            annualSummary?.data?.financial_agents[0].transactions || []
            : []

        const disbursementTransaction = annualSummary?.data?.from_disbursement?.length ?
            annualSummary?.data?.from_disbursement[0].transactions || []
            : []

        return Array
            .from({ length: 12 })
            .map((item: any, index: number) => {
                const month: string = String('0' + Number(index + 1)).slice(-2)
                const date: string = `${year}-${month}`
                const financialItem: LiberationTransaction | undefined = financialTransaction
                    ?.find((transactionItem: LiberationTransaction) => transactionItem.date === date)
                const disbursementItem: LiberationTransaction | undefined = disbursementTransaction
                    ?.find((transactionItem: LiberationTransaction) => transactionItem.date === date)
                const totalValue: number = (financialItem?.value || 0) + (disbursementItem?.value || 0)

                return new AnnualTransaction()
                    .fromJSON({
                        date,
                        total_value: totalValue
                    })
            })
    }

    private aggregateResources(transactions: any[], year: number): AnnualTransaction[] {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        if (transactions) {
            const aggregated = months.map(month => {
                return transactions.filter(transaction => transaction.date?.match(`^${year}-${month}`))
            })

            const result: AnnualTransaction[] = []
            months.forEach((month: string, index: number) => {
                const values = aggregated[index]
                    .map(transaction => transaction.value)
                const sum = values?.length ? values
                        .reduce((prev: number | undefined, current: number | undefined) => (prev ? prev : 0) + (current ? current : 0))
                    : 0
                const annualTransaction = new AnnualTransaction()
                    .fromJSON({ date: `${year}-${month}`, total_value: sum })
                result.push(annualTransaction)
            })

            return result

        }

        return []
    }
}

export default new TransactionService()
