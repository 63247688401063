import { Reducer } from 'redux'
import { DisbursementIOReportActionTypes, IDisbursementIOReportState } from './types'

export const INITIAL_STATE: IDisbursementIOReportState = {
    input: {
        data: [],
        loading: false,
        error: false,
        success: false
    },
    output: {
        data: [],
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IDisbursementIOReportState> = (state: IDisbursementIOReportState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case DisbursementIOReportActionTypes.RESET:
            return INITIAL_STATE

        case DisbursementIOReportActionTypes.LOAD_REQUEST:
            return {
                ...state,
                input: { ...state.input, loading: true },
                output: { ...state.output, loading: true }
            }

        case DisbursementIOReportActionTypes.LOAD_INPUT_SUCCESS:
            const { data: inputData } = action.payload
            return {
                ...state,
                input: {
                    ...state.input,
                    loading: false,
                    error: false,
                    success: true,
                    data: inputData
                }
            }

        case DisbursementIOReportActionTypes.LOAD_INPUT_FAILURE:
            return {
                ...state,
                input: {
                    ...state.input,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementIOReportActionTypes.LOAD_OUTPUT_SUCCESS:
            const { data: outputData } = action.payload
            return {
                ...state,
                output: {
                    ...state.output,
                    loading: false,
                    error: false,
                    success: true,
                    data: outputData
                }
            }

        case DisbursementIOReportActionTypes.LOAD_OUTPUT_FAILURE:
            return {
                ...state,
                output: {
                    ...state.output,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        default:
            return state;
    }
}

export default reducer
