import { IComponentState, IPaginator } from '../root.types'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'
import Expense from '../../application/models/transactions/expense/expense'

/**
 * Action types
 */
export enum ExpenseActionTypes {
    RESET_EXPENSE = '@expense/RESET_EXPENSE',
    RESET_MONTHLY_PAGINATOR = '@expense/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@expense/RESET_ANNUAL_PAGINATOR',

    CHANGE_EXPENSE = '@expense/CHANGE_EXPENSE',
    CHANGE_DIALOG = '@expense/CHANGE_DIALOG',
    CHANGE_PAGINATOR = '@expense/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@expense/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@expense/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@expense/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@expense/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@expense/CREATE_REQUEST',
    CREATE_SUCCESS = '@expense/CREATE_SUCCESS',
    CREATE_FAILURE = '@expense/CREATE_FAILURE',

    LOAD_REQUEST = '@expense/LOAD_REQUEST',
    LOAD_SUCCESS = '@expense/LOAD_SUCCESS',
    LOAD_FAILURE = '@expense/LOAD_FAILURE',

    FIND_REQUEST = '@expense/FIND_REQUEST',
    FIND_SUCCESS = '@expense/FIND_SUCCESS',
    FIND_FAILURE = '@expense/FIND_FAILURE',

    UPDATE_REQUEST = '@expense/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@expense/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@expense/UPDATE_FAILURE',

    REMOVE_REQUEST = '@expense/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@expense/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@expense/REMOVE_FAILURE',

    FIND_PAYEE = '@expense/FIND_PAYEE',
    FIND_PAYEE_SUCCESS = '@expense/FIND_PAYEE_SUCCESS',
    FIND_PAYEE_FAILURE = '@expense/FIND_PAYEE_FAILURE',

    LOAD_MONTHLY = '@expense/LOAD_MONTHLY',
    LOAD_MONTHLY_SUCCESS_RESET = '@expense/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@expense/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@expense/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL = '@expense/LOAD_ANNUAL',
    LOAD_ANNUAL_SUCCESS_RESET = '@expense/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@expense/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@expense/LOAD_ANNUAL_FAILURE'
}

export enum ExpenseLocation {
    BY_PAYEE = 'by_payee',
    MONTHLY = 'monthly'
}

/**
 *  Interface Actions
 */
export interface IActionCreate {
    readonly expense: Expense
    readonly location: ExpenseLocation
}

export interface IActionLoad {
    readonly payeeId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly payeeId: string
    readonly expenseId: string
}

export interface IActionRemove {
    readonly payeeId: string
    readonly expenseId: string
    readonly paginator: IPaginator
    readonly date?: string
    readonly location: ExpenseLocation
}

export interface IActionFindPayee {
    readonly payeeId: string
}

/**
 *  Interface Component States
 */
interface ICreateState extends IComponentState {
    readonly expense: Expense
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly expenses: Expense[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly payee: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly visibilityModal: boolean
    readonly payeeId: string
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyExpense: ExpenseMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IAnnualState extends IComponentState {
    readonly annualExpense: ExpenseAnnualSummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

/**
 * State type
 */
export interface IExpenseState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly monthly: IMonthlyState
    readonly annual: IAnnualState
}
