import Institution, { CostingCategory, TypeInstitution } from './institution'
import { MonthlyFinancialAgent } from '../monthly.financial.agent'
import { Transaction } from '../../transactions/transaction'

export class MonthlyInstitution<T extends Transaction> extends MonthlyFinancialAgent<T> {
    private _institution: Institution | undefined

    get id(): string | undefined {
        return this._institution?.id
    }

    get name(): string | undefined {
        return this._institution?.name
    }

    get institution_type(): TypeInstitution | undefined {
        return this._institution?.institution_type
    }

    get cost_category(): CostingCategory | undefined {
        return this._institution?.cost_category
    }

    get institution(): Institution | undefined {
        return this._institution
    }

    public fromJSON(json: any): MonthlyInstitution<T> {

        super.fromJSON(json)

        if (json) {
            this._institution = new Institution().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...this._institution?.toJSON(),
            ...super.toJSON()
        }
    }

}
