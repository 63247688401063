import React, { Component, ErrorInfo } from 'react'

export default class ErrorBoundary extends Component<{}, { error: any, errorInfo: any }> {

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo
        })
        // You can also log error messages to an error reporting service here
        console.error('Error: ', error)
        console.error('ErrorInfo: ', errorInfo)
        // logErrorToMyService(error, errorInfo)
    }

    public render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <React.Fragment>

                    <div className="header">

                        <div className="row page-header">
                            <h1>Ops... Ocorreu um erro inesperado.</h1>
                            <h6>Por gentileza recarrega a página pressionando F5.</h6>
                        </div>

                        <div id="page-inner">

                            <div className="row">

                                <div className="fade-in-down">

                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        <br/>
                                        {this.state.errorInfo.componentStack}
                                    </details>

                                </div>

                            </div>

                        </div>

                    </div>


                </React.Fragment>
            )
        }
        // Normally, just render children
        return this.props.children
    }
}
