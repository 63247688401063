import { Reducer } from 'redux'
import { CostingActionTypes, ICostingState } from './types'
import Costing from '../../application/models/transactions/costing/costing'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'
import CostAnnualSummary from '../../application/models/transactions/costing/cost.annual.summary'

export const INITIAL_STATE: ICostingState = {
    create: {
        costing: new Costing(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        costings: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        institution: undefined
    },
    remove: {
        idForRemove: '',
        institutionId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthly: {
        monthlyCosting: new CostMonthlySummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annual: {
        annualCosting: new CostAnnualSummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

const reducer: Reducer<ICostingState> = (state: ICostingState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case CostingActionTypes.CHANGE_COSTING:
            const { costing } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: false,
                    success: false,
                    costing: new Costing().fromJSON(costing)
                }
            }

        case CostingActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case CostingActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, paginator } }

        case CostingActionTypes.CHANGE_MONTH:
            const { month } = action.payload
            return { ...state, month }

        case CostingActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }

        case CostingActionTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthly: { ...state.monthly, paginator: monthlyPaginator }
            }

        case CostingActionTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthly: { ...state.monthly, paginator: INITIAL_STATE.monthly.paginator }
            }

        case CostingActionTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annual: { ...state.annual, paginator: { ...state.annual.paginator, ...annualPaginator } }
            }

        case CostingActionTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annual: { ...state.annual, paginator: INITIAL_STATE.annual.paginator }
            }

        case CostingActionTypes.CHANGE_REMOVE_MODAL:
            const { institutionId, visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                remove: { ...state.remove, visibilityModal, idForRemove, institutionId }
            }

        case CostingActionTypes.CREATE_REQUEST:
            const { costing: newRequestCosting } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, costing: newRequestCosting }
            }

        case CostingActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    costing: new Costing().fromJSON({ institution_id: state.create.costing.institution_id }),
                    success: true,
                    loading: false
                }
            }

        case CostingActionTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            const monthlyCostingCreateError = state?.monthly?.monthlyCosting ? state.monthly.monthlyCosting : new CostMonthlySummary()
            monthlyCostingCreateError.addTransaction(new Costing().fromJSON(action?.payload?.costing), createError)
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: createError },
                monthly: {
                    ...state.monthly,
                    loading: false,
                    monthlyCosting: monthlyCostingCreateError
                }
            }

        case CostingActionTypes.RESET_COSTING:
            return { ...state, create: INITIAL_STATE.create }

        case CostingActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case CostingActionTypes.LOAD_SUCCESS:
            const { costings, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    costings: costings.map((json: any) => new Costing().fromJSON(json)),
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case CostingActionTypes.LOAD_FAILURE:
            const { error } = action.payload
            return { ...state, list: { ...state.list, loading: false, error: true, data: error } }

        case CostingActionTypes.FIND_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case CostingActionTypes.FIND_SUCCESS:
            const { costing: costingFound } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    costing: new Costing().fromJSON(costingFound),
                    loading: false
                }
            }

        case CostingActionTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: errorFound }
            }

        case CostingActionTypes.REMOVE_REQUEST:
            return { ...state, remove: { ...state.remove, loading: true, visibilityModal: false } }

        case CostingActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case CostingActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: { ...state.remove, loading: false, error: true, success: false, visibilityModal: true }
            }

        case CostingActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case CostingActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: { ...state.create, loading: false }
            }

        case CostingActionTypes.UPDATE_FAILURE:
            const { error: updateError, costing: costingError } = action.payload
            const monthlyCostingUpdateError = state?.monthly?.monthlyCosting ? state.monthly.monthlyCosting : new CostMonthlySummary()
            monthlyCostingUpdateError.updateTransaction(new Costing().fromJSON(costingError), updateError)
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: updateError },
                monthly: { ...state.monthly, loading: false, monthlyCosting: monthlyCostingUpdateError }
            }

        case CostingActionTypes.FIND_INSTITUTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    institution: undefined
                }
            }

        case CostingActionTypes.FIND_INSTITUTION_SUCCESS:
            const { institution } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    institution
                }
            }

        case CostingActionTypes.FIND_INSTITUTION_FAILURE:
            const { error: institutionError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    error: institutionError
                }
            }

        case CostingActionTypes.LOAD_MONTHLY:
            return { ...state, monthly: { ...state.monthly, loading: true } }

        case CostingActionTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyHeaders, monthlyCosting } = action.payload
            const result = new CostMonthlySummary().fromJSON(monthlyCosting)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyCosting: result,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyHeaders['x-total-count'], 10)
                    }
                }
            }

        case CostingActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyCosting: concatMonthlyCosting } = action.payload
            state.monthly.monthlyCosting.concatFromJSON(concatMonthlyCosting)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case CostingActionTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    monthlyCosting: INITIAL_STATE.monthly.monthlyCosting,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case CostingActionTypes.LOAD_ANNUAL:
            return { ...state, annual: { ...state.annual, loading: true } }

        case CostingActionTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const { headers: annualHeaders, annualCosting } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    loading: false,
                    success: true,
                    error: false,
                    annualCosting: new CostAnnualSummary().fromJSON(annualCosting),
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator, totalRecords: parseInt(annualHeaders['x-total-count'], 10)
                    }
                }
            }

        case CostingActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const { headers: annualConcatHeaders, annualCosting: concatAnnualCosting } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    loading: false,
                    success: true,
                    error: false,
                    annualCosting: state.annual.annualCosting.concatFromJSON(concatAnnualCosting),
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator, totalRecords: parseInt(annualConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case CostingActionTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    annualCosting: INITIAL_STATE.annual.annualCosting,
                    loading: false,
                    error: true,
                    data: annualError
                }
            }

        default:
            return state;
    }
}

export default reducer
