import { action } from 'typesafe-actions'

import { IAxiosResponse, IPaginator } from '../root.types'
import Prevision from '../../application/models/prevision/prevision'
import { PrevisionActionTypes } from './types'
import { MultiStatus } from '../../application/models/multi.status/multi.status'
import { AnnualTransaction } from '../../application/models/transactions/annual.transaction'
import { addChecked } from '../exercise/actions'
import { PrevisionTransactionTypes } from '../../application/utils/prevision.transaction.types'

/* Actions for Reset State */
export const resetPrevision = () => action(PrevisionActionTypes.RESET, {})

/* Actions for Change */
export const changeInitialValue = (initialValue: number) => action(
    PrevisionActionTypes.CHANGE_INITIAL_VALUE,
    { initialValue }
)

export const changePrevision = (prevision: Prevision) => action(
    PrevisionActionTypes.CHANGE_PREVISION,
    { prevision }
)


export const changeRemoveModal = (
    visibilityModal: boolean,
    financialAgentId: string,
    idForRemove: string,
    transactionType: PrevisionTransactionTypes,
    paginator?: IPaginator) => action(
    PrevisionActionTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        financialAgentId,
        idForRemove,
        transactionType,
        paginator
    }
)

/* Actions for Change Dialog Create */
export const changeDialogCreate = (dialog: boolean) => action(PrevisionActionTypes.CHANGE_DIALOG_CREATE, { dialog })

/* Actions for Create Prevision */
export const create = (
    financialAgentId: string,
    transactionType: PrevisionTransactionTypes,
    previsions: Prevision[],
    paginator?: IPaginator) =>
    action(
        PrevisionActionTypes.CREATE_REQUEST,
        { financialAgentId, transactionType, previsions, paginator }
    )

export const createSuccess = (
    data: MultiStatus<Prevision>,
    financialAgentId: string,
    transactionType: PrevisionTransactionTypes,
    paginator?: IPaginator) => [
    action(PrevisionActionTypes.CREATE_SUCCESS, { success: data.success }),
    load(financialAgentId, transactionType, paginator),
    addChecked(financialAgentId, transactionType)
]

export const createFailure = (error: ErrorEvent) => action(PrevisionActionTypes.CREATE_FAILURE, { error })

/* Actions for Update Prevision */
export const update = (prevision: Prevision, transactionType: PrevisionTransactionTypes, paginator: IPaginator) => action(
    PrevisionActionTypes.UPDATE_REQUEST,
    { prevision, transactionType, paginator }
)

export const updateSuccess = (prevision: Prevision, transactionType: PrevisionTransactionTypes, paginator: IPaginator) => [
    action(
        PrevisionActionTypes.UPDATE_SUCCESS,
        { prevision }
    ),
    load(
        prevision?.financialagent_id ? prevision.financialagent_id : '',
        transactionType,
        paginator
    )
]

export const updateFailure = (error: ErrorEvent) => action(PrevisionActionTypes.UPDATE_FAILURE, { error })

export const updateMany = (previsions: Prevision[], transactionType: PrevisionTransactionTypes, paginator: IPaginator) => action(
    PrevisionActionTypes.UPDATE_MANY_REQUEST,
    { previsions, transactionType, paginator }
)

export const updateManySuccess = (previsions: Prevision[], transactionType: PrevisionTransactionTypes, paginator: IPaginator) => [
    action(
        PrevisionActionTypes.UPDATE_MANY_SUCCESS,
        { previsions }
    ),
    load(
        previsions.length && previsions[0]?.financialagent_id ? previsions[0].financialagent_id : '',
        transactionType,
        paginator
    )
]

export const updateManyFailure = (error: ErrorEvent) => action(PrevisionActionTypes.UPDATE_MANY_FAILURE, { error })

/* Actions for Load Previsions */
export const load = (financialAgentId: string, transactionType: PrevisionTransactionTypes, paginator?: IPaginator) =>
    action(
        PrevisionActionTypes.LOAD_REQUEST,
        { financialAgentId, transactionType, paginator }
    )

export const loadSuccess = (response: IAxiosResponse<Prevision[]>) => action(
    PrevisionActionTypes.LOAD_SUCCESS,
    { previsions: response.data, headers: response.headers }
)

export const loadFailure = (error: ErrorEvent) => action(PrevisionActionTypes.LOAD_FAILURE, { error })

/* Actions for Load Annual Prevision */
export const loadAnnualPrevision = (financialAgentId: string, transactionType: PrevisionTransactionTypes, year: number) =>
    action(
        PrevisionActionTypes.LOAD_ANNUAL_REQUEST,
        { financialAgentId, transactionType, year }
    )

export const loadAnnualSuccess = (annualPrevision: AnnualTransaction[]) => action(
    PrevisionActionTypes.LOAD_ANNUAL_SUCCESS, { annualPrevision }
)

export const loadAnnualFailure = (error: ErrorEvent) => action(PrevisionActionTypes.LOAD_ANNUAL_FAILURE, { error })

/* Actions for Remove Prevision */
export const remove = (
    financialAgentId: string,
    idForRemove: string,
    transactionType: PrevisionTransactionTypes,
    paginator?: IPaginator
) => action(
    PrevisionActionTypes.REMOVE_REQUEST, { financialAgentId, idForRemove, transactionType, paginator }
)

export const removeSuccess = (
    financialAgentId: string,
    transactionType: PrevisionTransactionTypes,
    paginator?: IPaginator
) => [
    action(PrevisionActionTypes.REMOVE_SUCCESS, {}),
    load(financialAgentId, transactionType, paginator)
]

export const removeFailure = (error: ErrorEvent) => action(PrevisionActionTypes.REMOVE_FAILURE, { error })
