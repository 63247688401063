import { Reducer } from 'redux'
import { DisbursementReportBalanceObservation } from '../../application/models/disbursement/disbursement.report.balance.observation'
import { DisbursementReportBalanceObservationActionTypes, IDisbursementReportBalanceObservationState } from './types'

export const INITIAL_STATE: IDisbursementReportBalanceObservationState = {
    create: {
        data: new DisbursementReportBalanceObservation(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        id: '',
        dialog: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IDisbursementReportBalanceObservationState> = (state: IDisbursementReportBalanceObservationState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case DisbursementReportBalanceObservationActionTypes.CHANGE_CREATE_DIALOG:
            const { dialog: createdDialog, data: changedData } = action.payload
            return {
                ...state,
                create: { ...state.create, data: changedData, dialog: createdDialog }
            }

        case DisbursementReportBalanceObservationActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data }
            }

        case DisbursementReportBalanceObservationActionTypes.CREATE_SUCCESS:
            const { data: newData } = action.payload
            const resultData: DisbursementReportBalanceObservation[] = [newData].concat(state.list.data)
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: new DisbursementReportBalanceObservation(),
                    dialog: false
                },
                list: {
                    ...state.list,
                    data: resultData
                }
            }

        case DisbursementReportBalanceObservationActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case DisbursementReportBalanceObservationActionTypes.RESET:
            return INITIAL_STATE

        case DisbursementReportBalanceObservationActionTypes.LOAD_REQUEST:
            const { paginator: listPaginator } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                    paginator: listPaginator
                }
            }

        case DisbursementReportBalanceObservationActionTypes.LOAD_SUCCESS:
            const { data: listData } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: false,
                    success: true,
                    data: listData
                }
            }

        case DisbursementReportBalanceObservationActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementReportBalanceObservationActionTypes.CHANGE_REMOVE_DIALOG:
            const { id: removeId, dialog: removeDialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog: removeDialog, id: removeId }
            }

        case DisbursementReportBalanceObservationActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case DisbursementReportBalanceObservationActionTypes.REMOVE_SUCCESS:
            const { id: removedId } = action.payload
            const resultRemoveData: DisbursementReportBalanceObservation[] = state.list.data
                ?.filter((item: DisbursementReportBalanceObservation) => removedId !== `${item?.id}`) || []
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    id: ''
                },
                list: {
                    ...state.list,
                    data: resultRemoveData
                }
            }

        case DisbursementReportBalanceObservationActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case DisbursementReportBalanceObservationActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case DisbursementReportBalanceObservationActionTypes.UPDATE_SUCCESS:
            const { data: updatedData } = action.payload
            const resultUpdateData: DisbursementReportBalanceObservation[] = state.list.data
                ?.map((item: DisbursementReportBalanceObservation) => {
                    if (item?.id === updatedData?.id) {
                        return updatedData
                    }
                    return item
                }) || []
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: new DisbursementReportBalanceObservation(),
                    dialog: false
                },
                list: {
                    ...state.list,
                    data: resultUpdateData
                }
            }

        case DisbursementReportBalanceObservationActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state;
    }
}

export default reducer
