import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'
import { ConferenceTransactionType } from '../store/application/utils/conference.transaction.type'
import { ConferenceItem } from '../store/application/models/disbursement/conference.item'

const MAP_DISBURSEMENT_TYPE = {
    [ConferenceTransactionType.REVENUE]: 'revenues',
    [ConferenceTransactionType.EXPENSE]: 'expenses'
}

class ConferenceTransactionService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: ConferenceItem, type: ConferenceTransactionType): Promise<ConferenceItem> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/conference/${MAP_DISBURSEMENT_TYPE[type]}`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new ConferenceItem().fromJSON(response.data))
    }

    public getAllByYear(
        type: ConferenceTransactionType,
        year: number,
        paginator?: IPaginator
    ): Promise<IAxiosResponse<ConferenceItem[]>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(
                `${this.apiVersion}/disbursement/conference/${MAP_DISBURSEMENT_TYPE[type]}/year/${year}`,
                { params }
            )
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: response.data.map((item: any) => new ConferenceItem().fromJSON(item)),
                    headers: response.headers
                }
            })
    }

    public update(data: ConferenceItem, type: ConferenceTransactionType): Promise<ConferenceItem> {
        const body = data.toJSON()
        delete body.date
        const url = `${this.apiVersion}/disbursement/conference/${MAP_DISBURSEMENT_TYPE[type]}/${data.id}`
        return httpClient
            .patch(url, body)
            .then((response: IAxiosResponse<any>) => new ConferenceItem().fromJSON(response.data))
    }

    public remove(type: ConferenceTransactionType, id: string): Promise<IAxiosResponse<void>> {
        return httpClient
            .delete(`${this.apiVersion}/disbursement/conference/${MAP_DISBURSEMENT_TYPE[type]}/${id}`)
    }

}

export default new ConferenceTransactionService()

