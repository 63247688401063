import { all, apply, call, put, takeLatest, select } from 'redux-saga/effects'
import { Toast } from '../../../services/toast'

import {
    createBalanceFailure,
    createBalanceSuccess,
    findBalanceAgentFailure,
    findBalanceAgentSuccess,
    findBalanceFailure,
    findBalanceSuccess,
    loadAnnualBalancesFailure,
    loadAnnualBalancesSuccessConcat,
    loadAnnualBalancesSuccessReset,
    loadBalanceFailure,
    loadBalanceRequest,
    loadBalanceSuccess,
    loadDailyBalancesFailure,
    loadDailyBalancesRequest,
    loadDailyBalancesSuccessConcat,
    loadDailyBalancesSuccessReset,
    loadMonthlyBalancesFailure,
    loadMonthlyBalancesRequest,
    loadMonthlyBalancesSuccessConcat,
    loadMonthlyBalancesSuccessReset,
    removeBalanceFailure,
    removeBalanceSuccess,
    updateBalanceFailure,
    updateBalanceSuccess
} from './actions'
import { IActionAnnual, IActionMonthly, IActionType } from '../root.types'
import {
    BalanceLocation,
    BalanceTypes,
    IActionCreate,
    IActionFind,
    IActionFindBalanceAgent,
    IActionLoad,
    IActionRemove
} from './types'
import balanceService from '../../../services/balances'
import balanceAgentService from '../../../services/balance.agent'
import bankService from '../../../services/balance.summary'
import UtilDatePicker from '../../../components/date.picker/utils'
import { IPaginator } from '../costing/types'
import { IApplicationState } from '../../index'

const toastService = Toast.getInstance()

function* refreshByLocation(location: BalanceLocation, balanceAgentId: string, date: string, paginator?: IPaginator) {
    if (location === BalanceLocation.BY_AGENT) {
        yield put(loadBalanceRequest(balanceAgentId, paginator))
    } else if (location === BalanceLocation.DAILY) {
        yield put(loadDailyBalancesRequest(date, paginator))
    } else if (location === BalanceLocation.MONTHLY) {
        yield put(loadMonthlyBalancesRequest(date?.substr(0, 7) || '', paginator))
    }
}

function* create(action: IActionType<IActionCreate>) {
    const { balance, location } = action.payload
    try {
        const response = yield apply(balanceService, balanceService.create, [balance])
        yield put<any>(createBalanceSuccess(response?.data))
        const balanceAgentId = balance?.balanceagent_id || ''
        const paginator: IPaginator = yield select(
            (state: IApplicationState) => state.balance.monthlyBalances.paginator
        )
        yield call(refreshByLocation, location, balanceAgentId, `${balance.date}`, paginator)
        toastService.show('success', 'Saldo criado com sucesso', '')
    } catch (err) {
        yield put(createBalanceFailure(err, balance))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { balanceAgentId, balanceId } = action.payload
        const response = yield apply(balanceService, balanceService.getById, [balanceAgentId, balanceId])
        yield put(findBalanceSuccess(response))
    } catch (err) {
        yield put(findBalanceFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { balanceAgentId, paginator } = action.payload
        const response = yield apply(balanceService, balanceService.getAll, [balanceAgentId, paginator])
        yield put(loadBalanceSuccess(response))
    } catch (err) {
        yield put(loadBalanceFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { balance, location } = action.payload
    try {
        const response = yield apply(balanceService, balanceService.update, [balance])
        yield put<any>(updateBalanceSuccess(response.data))
        const balanceAgentId = balance?.balanceagent_id || ''
        const paginator: IPaginator = yield select(
            (state: IApplicationState) => state.balance.dailyBalances.paginator
        )
        yield call(refreshByLocation, location, balanceAgentId, `${balance.date}`, paginator)
        toastService.show('success', 'Saldo atualizado com sucesso', '')
    } catch (err) {
        yield put(updateBalanceFailure(err, balance))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { balanceAgentId, balanceId, paginator, date, location } = action.payload
        yield apply(balanceService, balanceService.remove, [balanceAgentId, balanceId])
        yield put(removeBalanceSuccess())
        yield call(refreshByLocation, location, balanceAgentId, date || '', paginator)
        toastService.show('success', 'Saldo excluído com sucesso', '')
    } catch (err) {
        yield put(removeBalanceFailure(err))
    }
}

function* getBalance(action: IActionType<IActionFindBalanceAgent>) {
    const { balanceAgentId } = action.payload
    try {
        const response = yield apply(balanceAgentService, balanceAgentService.getById, [balanceAgentId])
        yield put(findBalanceAgentSuccess(response))
    } catch (err) {
        yield put(findBalanceAgentFailure(err))
    }
}

function* getDailyBalance(action: IActionType<IActionMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            bankService,
            bankService.getDaily,
            [date, paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadDailyBalancesSuccessConcat(response))
            : yield put(loadDailyBalancesSuccessReset(response))
    } catch (err) {
        yield put(loadDailyBalancesFailure(err))
    }
}

function* getMonthlyBalance(action: IActionType<IActionMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            bankService,
            bankService.getMonthly,
            [date ? date : UtilDatePicker.getCurrentMonth(), paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadMonthlyBalancesSuccessConcat(response))
            : yield put(loadMonthlyBalancesSuccessReset(response))
    } catch (err) {
        yield put(loadMonthlyBalancesFailure(err))
    }
}

function* getAnnualBalance(action: IActionType<IActionAnnual>) {
    const { year, paginator } = action.payload
    try {
        const response = yield apply(
            bankService,
            bankService.getAnnual,
            [year, paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadAnnualBalancesSuccessConcat(response))
            : yield put(loadAnnualBalancesSuccessReset(response))
    } catch (err) {
        yield put(loadAnnualBalancesFailure(err))
    }
}

export default function* balanceSaga() {
    return yield all([
        takeLatest(BalanceTypes.CREATE_REQUEST, create),
        takeLatest(BalanceTypes.LOAD_REQUEST, getAll),
        takeLatest(BalanceTypes.FIND_REQUEST, getById),
        takeLatest(BalanceTypes.UPDATE_REQUEST, update),
        takeLatest(BalanceTypes.REMOVE_REQUEST, remove),
        takeLatest(BalanceTypes.FIND_BALANCE_AGENT_REQUEST, getBalance),
        takeLatest(BalanceTypes.LOAD_DAILY_REQUEST, getDailyBalance),
        takeLatest(BalanceTypes.LOAD_MONTHLY_REQUEST, getMonthlyBalance),
        takeLatest(BalanceTypes.LOAD_ANNUAL_REQUEST, getAnnualBalance)
    ])
}
