import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import authService from '../../../services/auth'
import { AxiosResponse } from 'axios'
import { loginByCertFailure, loginByCertSuccess, loginFailure, loginSuccess } from './actions'
import { IActionType } from '../root.types'
import { AuthTypes, IAccessToken, IAuth } from './types'
import { UserTypes } from '../../application/models/auth/user'

export function* authenticate(action: IActionType<AxiosResponse<IAuth>>) {
    try {
        const { data } = action.payload
        const response: IAccessToken = yield apply(authService, authService.login, [data])
        yield put(loginSuccess(response))
        response.sub_type === UserTypes.DISBURSEMENT ?
            yield put(push(`/app/disbursement`))
            : yield put(push(`/app/home`))
    } catch (err) {
        yield put(loginFailure(err))
    }
}

export function* authenticateByCert() {
    try {
        const response = yield apply(authService, authService.loginByCert, [])
        yield put(loginByCertSuccess(response))
        yield put(push(`/app/home`))
    } catch (err) {
        yield put(loginByCertFailure(err))
    }
}


export default function* authSaga() {
    return yield all([
        takeLatest(AuthTypes.LOGIN_REQUEST, authenticate),
        takeLatest(AuthTypes.LOGIN_BY_CERT, authenticateByCert)
    ])
}
