import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'
import httpClient from './config.axios'
import Payee, { PayeeCategory } from '../store/application/models/financial.agents/payee/payee'

class PayeeService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public async create(payee: Payee): Promise<IAxiosResponse<Payee>> {
        return httpClient.post(`${this.apiVersion}/payees`, payee)
    }

    public async remove(payeeId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payees/${payeeId}`)
    }

    public async update(payee: Payee): Promise<IAxiosResponse<Payee>> {
        return httpClient.patch(`${this.apiVersion}/payees/${payee.id}`, payee)
    }

    public async getById(payeeId: string): Promise<IAxiosResponse<Payee>> {
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}`)
    }

    public async getAll(paginator?: IPaginator): Promise<IAxiosResponse<Payee[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        params.append('can_have_budgets', 'ne:true')
        return httpClient.get(`${this.apiVersion}/payees`, { params })
    }

    public async getDuodecimos(paginator?: IPaginator): Promise<IAxiosResponse<Payee[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        params.append('expense_category', PayeeCategory.UNMANAGEABLE)
        params.append('can_have_budgets', 'true')
        params.append('sort', 'name')
        return httpClient.get(`${this.apiVersion}/payees`, { params })
    }
}

export default new PayeeService()
