/**
 * Action types
 */

import { IComponentState } from '../root.types'
import { DisbursementReportBalance } from '../../application/models/disbursement/disbursement.report.balance'

export enum DisbursementReportBalanceActionTypes {
    RESET = '@disbursement.report.balance/RESET',

    CREATE_REQUEST = '@disbursement.report.balance/CREATE_REQUEST',
    CREATE_SUCCESS = '@disbursement.report.balance/CREATE_SUCCESS',
    CREATE_FAILURE = '@disbursement.report.balance/CREATE_FAILURE',

    LOAD_REQUEST = '@disbursement.report.balance/LOAD_REQUEST',
    LOAD_SUCCESS = '@disbursement.report.balance/LOAD_SUCCESS',
    LOAD_FAILURE = '@disbursement.report.balance/LOAD_FAILURE',

    UPDATE_REQUEST = '@disbursement.report.balance/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@disbursement.report.balance/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@disbursement.report.balance/UPDATE_FAILURE',

    CHANGE_REMOVE_DIALOG = '@disbursement.report.balance/CHANGE_REMOVE_DIALOG',

    REMOVE_REQUEST = '@disbursement.report.balance/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@disbursement.report.balance/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@disbursement.report.balance/REMOVE_FAILURE'
}

/* Actions  */

export interface IActionCreate {
    readonly data: DisbursementReportBalance
}

export interface IActionLoad {
    readonly date: string
}

export interface IActionRemove {
    readonly date: string
    readonly id: string
}

/* States */
interface ICreateState extends IComponentState {
    readonly data: DisbursementReportBalance
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly data: DisbursementReportBalance[]
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
}

/**
 * State type
 */
export interface IDisbursementReportBalanceState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
