import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Helmet } from 'react-helmet'
import { Field, Form, Formik } from 'formik'
import queryString from 'query-string'

import '../container.style.scss'
import { Toast } from '../../services/toast'
import { IApplicationState } from '../../store'
import Spinner from '../../components/spinner/spinner'
import Costing from '../../store/application/models/transactions/costing/costing'
import * as CostingActions from '../../store/ducks/costing/actions'
import DatePicker from '../../components/date.picker/date.picker'
import UtilDatePicker from '../../components/date.picker/utils'
import SelectInstitution from '../../components/select/select.institution'
import CreatedAt from '../../components/created.at/created.at'
import { FormErrorMessage } from '../../components/form.error.message/form.error.message'
import { InstitutionTransactionValidator } from '../../store/application/validators/institution.transaction'
import { VerifyScopes } from '../../components/verify.scopes/verify.scopes'
import { LogicalStrategy } from '../../services/auth'
import Currency from '../../components/currency/currency'
import { TransactionLocation } from '../../store/ducks/costing/types'
import { Dialog } from 'primereact/dialog'

interface IState {
    /* Redux Props */
    readonly costing: Costing,
    readonly data: ErrorEvent,
    readonly loading: boolean,
    readonly error: boolean
    readonly success: boolean
    readonly dialog: boolean | undefined
    /* Others Props */
    readonly costingLocation: TransactionLocation
}

interface IDispatchProps extends RouteComponentProps<any> {

    resetCosting(): void

    create(costing: Costing, location: TransactionLocation): void

    changeCosting(costing: Costing): void

    changeDate(date: Date): void

    find(institutionId: string, costingId: string): void

    update(costing: Costing, location: TransactionLocation): void

    changeDialog(dialog: boolean): void

}

type Props = IState & IDispatchProps

class Create extends Component<Props> {
    private spinnerMessage: string
    private toastService: Toast

    constructor(props: Props) {
        super(props)

        this.toastService = Toast.getInstance()
        this.spinnerMessage = ''

        const { find, match: { params }, changeCosting } = this.props
        if (params && params.institutionId) {
            if (params.costingId) {
                changeCosting(new Costing().fromJSON({
                    ...this.props,
                    id: params.costingId,
                    institution_id: params.institutionId
                }))
                this.spinnerMessage = 'Buscando custeio...'
                find(params.institutionId, params.costingId)
            } else {
                const query = queryString.parse(this.props.location.search)
                changeCosting(new Costing().fromJSON({
                    ...this.props,
                    institution_id: params.institutionId,
                    date: query?.date
                }))
            }
        }
    }

    public handleSubmit = async (values) => {
        const costing = new Costing().fromJSON({ ...values, date: UtilDatePicker.usaFormatDate(values.date) })
        const { create, update, costingLocation } = this.props
        if (costing.id) {
            this.spinnerMessage = 'Atualizando custeio...'
            update(costing, costingLocation)
        } else {
            this.spinnerMessage = 'Salvando custeio...'
            create(costing, costingLocation)
        }
    }

    public componentWillUnmount(): void {
        this.props?.resetCosting()
    }


    public render() {

        const {
            costing,
            match,
            dialog,
            changeDialog,
            loading,
            resetCosting
        } = this.props

        return (
            <React.Fragment>
                {
                    dialog && <Helmet>
                        <title>[Fc-e] - {costing?.id ? 'Atualizar Custeio' : 'Cadastrar Custeio'}</title>
                    </Helmet>
                }

                {loading && <Spinner message={this.spinnerMessage}/>}

                <Formik
                    initialValues={{ ...costing.toJSON(), date: UtilDatePicker.brFormatDate(costing.date) }}
                    enableReinitialize={true}
                    validationSchema={InstitutionTransactionValidator.validationScheme}
                    onSubmit={this.handleSubmit}
                    isInitialValid={false}>
                    {({ isValid, resetForm }) => (
                        <React.Fragment>
                            <Form>
                                <Dialog
                                    header={costing?.id ? 'Atualizar Custeio' : 'Adicionar Custeio'}
                                    footer={
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                label="Fechar"
                                                className="p-button-secondary left"
                                                type="button"
                                                icon="pi pi-times"
                                                onClick={() => {
                                                    resetForm()
                                                    changeDialog(false)
                                                    resetCosting()
                                                }}
                                            />
                                            <VerifyScopes
                                                scopes={['tr:c', 'tr:u']}
                                                logicalStrategy={LogicalStrategy.OR}>
                                                <Button
                                                    label="Salvar"
                                                    className="p-button-primary right"
                                                    icon="pi pi-save"
                                                    disabled={!isValid || UtilDatePicker.hasOccurred(costing.date)}
                                                    type="submit"
                                                />
                                            </VerifyScopes>
                                        </div>
                                    }
                                    visible={dialog}
                                    closeOnEscape={true}
                                    modal={true}
                                    className="dialog max-width-700"
                                    onHide={() => {
                                        resetForm()
                                        changeDialog(false)
                                        resetCosting()
                                    }}
                                >
                                    <div style={{ padding: '20px' }}>
                                        <h5 className="center">Dados do Custeio</h5>

                                        {
                                            costing?.created_at && <div className="row">
                                                <div
                                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                    style={{ position: 'relative' }}>
                                                    <CreatedAt createdAt={costing?.created_at}/>
                                                </div>
                                            </div>
                                        }

                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 m-tb-5">
                                                <label className="label-bold"
                                                       style={{ marginBottom: '15px' }}>Orgão</label>
                                                <Field name="institution_id" id="institution_id"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <SelectInstitution
                                                            name="institution_id"
                                                            value={field.value}
                                                            filterPlaceholder="Pesquise pelo nome ou código do orgão"
                                                            placeholder="Selecione um orgão"
                                                            disabled={!!costing.id && !!costing.institution_id}
                                                            match={match}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="institution_id"/>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-tb-5">
                                                <label className="label-bold" htmlFor="value">Valor</label>
                                                <Field name="value" id="value"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <span className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">R$: </span>
                                                                    <Currency
                                                                        id="value"
                                                                        name="value"
                                                                        value={field.value}
                                                                        onChange={(e: any, value: any) => {
                                                                            setFieldValue('value', value)
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFieldTouched('value', true, true)
                                                                        }}
                                                                    />
                                                                </span>
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="value"/>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-tb-5">
                                                <label className="label-bold" htmlFor="date">Data</label>
                                                <Field name="date" id="date"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <DatePicker
                                                            value={field.value}
                                                            disabled={!!costing.institution_id && !!costing.date}
                                                            setFieldValue={setFieldValue}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="date"/>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 m-tb-5">
                                                <Field name="description" id="description"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <span className="p-float-label">
                                                            <InputText
                                                                id="description"
                                                                name="description"
                                                                value={field?.value ? field.value : ''}
                                                                onChange={(event: any) => {
                                                                    setFieldValue('description', event.target.value)
                                                                }}
                                                                onBlur={() => {
                                                                    setFieldTouched('description', true, true)
                                                                }}
                                                            />
                                                            <label htmlFor="description">Lembrete/Observação</label>
                                                        </span>
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="description"/>
                                            </div>
                                        </div>

                                    </div>
                                </Dialog>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    costing: state.costing.create.costing,
    error: state.costing.create.error,
    data: state.costing.create.data,
    success: state.costing.create.success,
    loading: state.costing.create.loading,
    dialog: state.costing.create.dialog
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(CostingActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create))
