import { action } from 'typesafe-actions'

import { EventsActionTypes, IAnnualEvent } from './types'
import Event from '../../application/models/transactions/event/event'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'
import { TransactionLocation } from '../costing/types'

/* Action for Reset State */
export const resetEvent = () => action(EventsActionTypes.RESET_EVENT, {})

/* Actions for change */
export const changeEvent = (event: Event) => action(EventsActionTypes.CHANGE_EVENT, { event })

export const changeDialog = (dialog: boolean) => action(EventsActionTypes.CHANGE_DIALOG, { dialog })

export const changeDate = (date: Date) => action(EventsActionTypes.CHANGE_DATE, { date })

export const changePaginator = (institutionId: string, paginator: IPaginator) => [
    action(EventsActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadEvent(institutionId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(EventsActionTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, institutionId: string) => action(
    EventsActionTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove,
        institutionId
    })

export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(EventsActionTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(EventsActionTypes.RESET_MONTHLY_PAGINATOR)


export const changeAnnualPaginator = (year: string, paginator: IPaginator) => [
    action(EventsActionTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(EventsActionTypes.RESET_ANNUAL_PAGINATOR)


/* Actions for Create EventsModel */
export const createEvent = (event: Event, location: TransactionLocation) => action(
    EventsActionTypes.CREATE_REQUEST,
    { event, location }
)

export const createSuccess = (event: Event) => action(EventsActionTypes.CREATE_SUCCESS, { event })

export const createFailure = (error: ErrorEvent) => action(EventsActionTypes.CREATE_FAILURE, { error })

/* Actions for Load EventsModel */
export const loadEvent = (institutionId: string, paginator?: IPaginator) => action(EventsActionTypes.LOAD_REQUEST, {
    institutionId,
    paginator
})

export const loadEventSuccess = (response: IAxiosResponse<Event[]>) => action(EventsActionTypes.LOAD_SUCCESS, {
    events: response.data,
    headers: response.headers
})

export const loadEventFailure = (error: ErrorEvent) => action(EventsActionTypes.LOAD_FAILURE, { error })

/* Actions for Find EventsModel */
export const findEvent = (institutionId: string, eventsId: string) => action(EventsActionTypes.FIND_REQUEST, {
    institutionId,
    eventsId
})

export const findEventSuccess = (response: IAxiosResponse<Event[]>) => action(EventsActionTypes.FIND_SUCCESS, {
    event: response.data,
    headers: response.headers
})

export const findEventFailure = (error: ErrorEvent) => action(EventsActionTypes.FIND_FAILURE, { error })

/* Actions for Update EventsModel */
export const updateEvent = (event: Event, location: TransactionLocation) => action(
    EventsActionTypes.UPDATE_REQUEST,
    { event, location }
)

export const updateEventSuccess = (event: Event) => action(EventsActionTypes.UPDATE_SUCCESS, { event })

export const updateEventFailure = (error: ErrorEvent) => action(EventsActionTypes.UPDATE_FAILURE, { error })

/* Actions for Remove EventsModel */
export const removeEvent = (institutionId: string, eventsId: string, location: TransactionLocation, paginator: IPaginator, date?: string) => action(EventsActionTypes.REMOVE_REQUEST, {
    institutionId,
    eventsId,
    paginator,
    date,
    location
})

export const removeEventSuccess = () => action(EventsActionTypes.REMOVE_SUCCESS, {})

export const removeEventFailure = (error: ErrorEvent) => action(EventsActionTypes.REMOVE_FAILURE, { error })

/* Actions for get Institution */
export const findInstitution = (institutionId: string) => action(EventsActionTypes.FIND_INSTITUTION, { institutionId })

export const findInstitutionSuccess = (response: IAxiosResponse<Institution>) => action(EventsActionTypes.FIND_INSTITUTION_SUCCESS, {
    institution: response.data,
    headers: response.headers
})

export const findInstitutionFailure = (error: ErrorEvent) => action(EventsActionTypes.FIND_INSTITUTION_FAILURE, { error })

/* Actions for load Monthly Event */
export const loadMonthly = (date?: string, paginator?: IPaginator) => action(EventsActionTypes.LOAD_MONTHLY, {
    date,
    paginator
})

export const loadMonthlySuccessReset = (response: IAxiosResponse<EventMonthlySummary>) => action(EventsActionTypes.LOAD_MONTHLY_SUCCESS_RESET, {
    monthlyEventing: response.data,
    headers: response.headers
})

export const loadMonthlySuccessConcat = (response: IAxiosResponse<EventMonthlySummary>) => action(EventsActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT, {
    monthlyEventing: response.data,
    headers: response.headers
})

export const loadMonthlyFailure = (error: ErrorEvent) => action(EventsActionTypes.LOAD_MONTHLY_FAILURE, { error })

/* Actions for load Annual */
export const loadAnnual = (year: string, paginator?: IPaginator) => action(EventsActionTypes.LOAD_ANNUAL, {
    year,
    paginator
})

export const loadAnnualSuccessReset = (response: IAnnualEvent) => action(
    EventsActionTypes.LOAD_ANNUAL_SUCCESS_RESET,
    response
)

export const loadAnnualSuccessConcat = (response: IAnnualEvent) => action(
    EventsActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT,
    response
)

export const loadAnnualFailure = (error: ErrorEvent) => action(
    EventsActionTypes.LOAD_ANNUAL_FAILURE, { error }
)

export const loadAnnualDash = (year: string) => [
    action(EventsActionTypes.LOAD_ANNUAL_DASH, { year })
]

export const loadAnnualDashSuccess = (response: IAxiosResponse<EventAnnualSummary>) => action(
    EventsActionTypes.LOAD_ANNUAL_DASH_SUCCESS, {
        annualEvent: response.data
    }
)

export const loadAnnualDashFailure = (error: ErrorEvent) => action(
    EventsActionTypes.LOAD_ANNUAL_DASH_FAILURE, { error }
)
