import { JsonUtils } from '../../utils/json.util'
import Institution from '../financial.agents/institution/institution'

/**
 * Implementation of the Disbursement Report Config entity.
 * @param {string} [id] Identifier of resource.
 * @param {string} [created_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is saved.
 * @param {string} [updated_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is updated.
 * @param {string} [start_date] Date of start configuration report item.
 * @param {string} [end_date] Date of end configuration report item.
 * @param {string} [description] Configuration of disbursement report.
 * @param {Institution[]} [include_institutions] Identifiers of institutions that must be included in the configuration.
 * @param {Institution[]} [exclude_institutions] Identifiers of institutions that must be excluded in the configuration.
 * @param {string} [type] Disbursement report configuration type.
 */
export class DisbursementReportConfig {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _start_date: string | undefined
    private _end_date: string | undefined
    private _description: string | undefined
    private _include_institutions: Institution[] | undefined
    private _exclude_institutions: Institution[] | undefined
    private _type: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get start_date(): string | undefined {
        return this._start_date
    }

    set start_date(value: string | undefined) {
        this._start_date = value
    }

    get end_date(): string | undefined {
        return this._end_date
    }

    set end_date(value: string | undefined) {
        this._end_date = value
    }

    get description(): string | undefined {
        return this._description
    }

    set description(value: string | undefined) {
        this._description = value
    }

    get include_institutions(): Institution[] | undefined {
        return this._include_institutions
    }

    set include_institutions(value: Institution[] | undefined) {
        this._include_institutions = value
    }

    get exclude_institutions(): Institution[] | undefined {
        return this._exclude_institutions
    }

    set exclude_institutions(value: Institution[] | undefined) {
        this._exclude_institutions = value
    }

    get type(): string | undefined {
        return this._type
    }

    set type(value: string | undefined) {
        this._type = value
    }

    public fromJSON(json: any): DisbursementReportConfig {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.id) {
            this.id = json.id
        }

        if (json.created_at) {
            this.created_at = json.created_at
        }

        if (json.updated_at) {
            this.updated_at = json.updated_at
        }

        if (json.start_date) {
            this.start_date = json.start_date
        }

        if (json.end_date) {
            this.end_date = json.end_date
        }

        if (json.description) {
            this.description = json.description
        }

        if (json?.include_institutions?.length) {
            this.include_institutions = json
                .include_institutions
                .map((institution: Institution) => new Institution().fromJSON(institution))
        }

        if (json?.exclude_institutions?.length) {
            this.exclude_institutions = json
                .exclude_institutions
                .map((institution: Institution) => new Institution().fromJSON(institution))
        }

        if (json.type) {
            this.type = json.type
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            created_at: this.created_at || undefined,
            updated_at: this.updated_at || undefined,
            start_date: this.start_date || undefined,
            end_date: this.end_date || undefined,
            description: this.description || undefined,
            include_institutions: this.include_institutions
                ?.map((institution: Institution) => institution.toJSON()) || [],
            exclude_institutions: this.exclude_institutions
                ?.map((institution: Institution) => institution.toJSON()) || [],
            type: this.type || undefined
        }
    }
}
