/* Actions types */
import { IComponentState, IPaginator } from '../root.types'
import Event from '../../application/models/transactions/event/event'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'
import { TransactionLocation } from '../costing/types'

export enum EventsActionTypes {

    RESET_EVENT = '@event/RESET_EVENT',
    RESET_MONTHLY_PAGINATOR = '@event/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@event/RESET_ANNUAL_PAGINATOR',

    CHANGE_EVENT = '@event/CHANGE_EVENT',
    CHANGE_DIALOG = '@event/CHANGE_DIALOG',
    CHANGE_DATE = '@event/CHANGE_DATE',
    CHANGE_PAGINATOR = '@event/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@event/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@event/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@event/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@event/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@event/CREATE_REQUEST',
    CREATE_SUCCESS = '@event/CREATE_SUCCESS',
    CREATE_FAILURE = '@event/CREATE_FAILURE',

    LOAD_REQUEST = '@event/LOAD_REQUEST',
    LOAD_SUCCESS = '@event/LOAD_SUCCESS',
    LOAD_FAILURE = '@event/LOAD_FAILURE',

    FIND_REQUEST = '@event/FIND_REQUEST',
    FIND_SUCCESS = '@event/FIND_SUCCESS',
    FIND_FAILURE = '@event/FIND_FAILURE',

    UPDATE_REQUEST = '@event/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@event/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@event/UPDATE_FAILURE',

    REMOVE_REQUEST = '@event/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@event/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@event/REMOVE_FAILURE',

    FIND_INSTITUTION = '@event/FIND_INSTITUTION',
    FIND_INSTITUTION_SUCCESS = '@event/FIND_INSTITUTION_SUCCESS',
    FIND_INSTITUTION_FAILURE = '@event/FIND_INSTITUTION_FAILURE',

    LOAD_MONTHLY = '@event/LOAD_MONTHLY',
    LOAD_MONTHLY_SUCCESS_RESET = '@event/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@event/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@event/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL = '@event/LOAD_ANNUAL',
    LOAD_ANNUAL_SUCCESS_RESET = '@event/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@event/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@event/LOAD_ANNUAL_FAILURE',

    LOAD_ANNUAL_DASH = '@event/LOAD_ANNUAL_DASH',
    LOAD_ANNUAL_DASH_SUCCESS = '@event/LOAD_ANNUAL_DASH_SUCCESS',
    LOAD_ANNUAL_DASH_FAILURE = '@event/LOAD_ANNUAL_DASH_FAILURE'
}

/* Action Types */

export interface IActionCreate {
    readonly event: Event
    readonly location: TransactionLocation
}

export interface IActionLoad {
    readonly institutionId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly institutionId: string
    readonly eventsId: string
}

export interface IActionRemove {
    readonly institutionId: string
    readonly eventsId: string
    readonly paginator: IPaginator
    readonly date?: string
    readonly location: TransactionLocation
}

interface ICreateState extends IComponentState {
    readonly event: Event
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly events: Event[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly institution: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly institutionId: string
    readonly visibilityModal: boolean
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyEventing: EventMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

export interface IAnnualEvent {
    readonly january?: EventMonthlySummary
    readonly february?: EventMonthlySummary
    readonly march?: EventMonthlySummary
    readonly april?: EventMonthlySummary
    readonly may?: EventMonthlySummary
    readonly june?: EventMonthlySummary
    readonly july?: EventMonthlySummary
    readonly august?: EventMonthlySummary
    readonly september?: EventMonthlySummary
    readonly october?: EventMonthlySummary
    readonly november?: EventMonthlySummary
    readonly december?: EventMonthlySummary
    readonly total?: number
    readonly totalRealized?: number
    readonly totalUnrealized?: number
}

interface IAnnualState extends IComponentState {
    readonly annualEventing: IAnnualEvent
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IDashAnnualState extends IComponentState {
    readonly annualEvent: EventAnnualSummary
}

/* State type */
export interface IEventsState {
    readonly createEvent: ICreateState
    readonly listEvent: IListState
    readonly removeEvent: IRemoveState
    readonly monthlyEvent: IMonthlyState
    readonly annualEvent: IAnnualState
    readonly dashboard: IDashAnnualState
}
