import { action } from 'typesafe-actions'
import { DisbursementReportBalanceActionTypes } from './types'
import { DisbursementReportBalance } from '../../application/models/disbursement/disbursement.report.balance'

/* Actions for Reset State */
export const reset = () => action(DisbursementReportBalanceActionTypes.RESET)


/* Actions for Create Disbursement Report Balance */

export const createRequest = (data: DisbursementReportBalance) => action(
    DisbursementReportBalanceActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: DisbursementReportBalance) => action(
    DisbursementReportBalanceActionTypes.CREATE_SUCCESS,
    { data }
)

export const createFailure = () => action(DisbursementReportBalanceActionTypes.CREATE_FAILURE)

/* Actions for Load Disbursement Report Balance */
export const loadRequest = (date: string) => action(
    DisbursementReportBalanceActionTypes.LOAD_REQUEST,
    { date }
)

export const loadSuccess = (data: DisbursementReportBalance[]) => action(
    DisbursementReportBalanceActionTypes.LOAD_SUCCESS,
    { data }
)

export const loadFailure = () => action(DisbursementReportBalanceActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement Report Balance */

export const changeRemoveModal = (dialog: boolean, id: string) => action(
    DisbursementReportBalanceActionTypes.CHANGE_REMOVE_DIALOG, { dialog, id }
)

export const removeRequest = (id: string) => action(
    DisbursementReportBalanceActionTypes.REMOVE_REQUEST,
    { id }
)

export const removeSuccess = (id: string) => action(
    DisbursementReportBalanceActionTypes.REMOVE_SUCCESS,
    { id }
)

export const removeFailure = () => action(DisbursementReportBalanceActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement Report Balance */
export const updateRequest = (data: DisbursementReportBalance) => action(
    DisbursementReportBalanceActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: DisbursementReportBalance) => action(
    DisbursementReportBalanceActionTypes.UPDATE_SUCCESS,
    { data }
)

export const updateFailure = () => action(DisbursementReportBalanceActionTypes.UPDATE_FAILURE)
