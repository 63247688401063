import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducer'
import layout from './layout/reducer'
import user from './users/reducer'
import { History } from 'history'
import institution from './institutions/reducer'
import balanceAgent from './balance.agent/reducer'
import balance from './balance/reducer'
import payer from './payer/reducer'
import revenue from './revenue/reducer'
import costing from './costing/reducer'
import event from './events/reducer'
import prevision from './prevision/reducer'
import debt from './debt/reducer'
import exercise from './exercise/reducer'
import investments from './investments/reducer'
import payee from './payee/reducer'
import expense from './expense/reducer'
import suggestion from './suggestion/reducer'
import cashFlow from './cashflow/reducer'
import audit from './audit/reducer'
import scenario from './scenario/reducer'
import budget from './budget/reducer'
import supplementaryBudget from './supplementary.budget/reducer'
import disbursement from './disbursement/reducer'
import conferenceRevenue from './conference.revenue/reducer'
import conferenceExpense from './conference.expense/reducer'
import disbursementReportBalance from './disbursement.report.balance/reducer'
import disbursementReportIO from './disbursement.report.io/reducer'
import disbursementReportConfig from './disbursement.report.config/reducer'
import disbursementBalanceConfig from './disbursement.balance.config/reducer'
import disbursementReportBalanceObservation from './disbursement.report.balance.observation/reducer'
import liberation from './liberation/reducer'

const createRootReducer = (history: History) => combineReducers({
    auth,
    layout,
    user,
    institution,
    balanceAgent,
    balance,
    router: connectRouter(history),
    payer,
    revenue,
    costing,
    prevision,
    event,
    debt,
    exercise,
    investments,
    payee,
    expense,
    suggestion,
    cashFlow,
    scenario,
    budget,
    supplementaryBudget,
    audit,
    disbursement,
    conferenceRevenue,
    conferenceExpense,
    disbursementReportBalance,
    disbursementReportIO,
    disbursementReportConfig,
    disbursementBalanceConfig,
    liberation,
    disbursementReportBalanceObservation
})

export default createRootReducer
