import { lazy } from 'react'

const Login = lazy(() => import('../containers/auth/login'))

export const AUTH_ROUTES = [
    {
        path: '/login',
        component: Login,
        exact: true
    }
]
