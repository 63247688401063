import httpClient from './config.axios'
import Investments from '../store/application/models/transactions/investments/investments'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'

class InvestmentsService extends ApiService {
    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(investment: Investments): Promise<IAxiosResponse<Investments>> {
        return httpClient.post(`${this.apiVersion}/institutions/${investment.institution_id}/investments`, investment.toJSON())
    }

    public getById(institutionId: string, investmentsId: string): Promise<IAxiosResponse<Investments>> {
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/investments/${investmentsId}`)
    }

    public getAll(institutionId: string, paginator?: IPaginator): Promise<IAxiosResponse<Investments[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/investments`, { params })
    }

    public update(investment: Investments): Promise<IAxiosResponse<Investments>> {
        const body = investment.toJSON()
        delete body.date
        return httpClient
          .patch(`${this.apiVersion}/institutions/${investment.institution_id}/investments/${investment.id}`, body)
    }

    public remove(institutionId: string, investmentsId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/institutions/${institutionId}/investments/${investmentsId}`)
    }
}

export default new InvestmentsService()
