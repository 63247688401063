import AnnualSummary from '../../summary/annual.summary'
import { AnnualInstitution } from '../../financial.agents/institution/annual.institution'
import { IAnnualInvestments } from '../../../../ducks/investments/types'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'
import Investments, { InvestmentsType } from './investments'
import { AnnualTransaction } from '../annual.transaction'
import Prevision from '../../prevision/prevision'

export default class InvestmentsAnnualSummary extends AnnualSummary<AnnualInstitution> {
    private _total_covenants: number | undefined
    private _total_direct_investments: number | undefined
    private _total_finance_charges: number | undefined
    private _total_funds: number | undefined
    private _total_programs: number | undefined

    get total_covenants(): number | undefined {
        return this._total_covenants
    }

    set total_covenants(value: number | undefined) {
        this._total_covenants = value
    }

    get total_direct_investments(): number | undefined {
        return this._total_direct_investments
    }

    set total_direct_investments(value: number | undefined) {
        this._total_direct_investments = value
    }

    get total_finance_charges(): number | undefined {
        return this._total_finance_charges
    }

    set total_finance_charges(value: number | undefined) {
        this._total_finance_charges = value
    }

    get total_funds(): number | undefined {
        return this._total_funds
    }

    set total_funds(value: number | undefined) {
        this._total_funds = value
    }

    get total_programs(): number | undefined {
        return this._total_programs
    }

    set total_programs(value: number | undefined) {
        this._total_programs = value
    }

    public fromJSON(json: any): InvestmentsAnnualSummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_covenants !== undefined) {
            this._total_covenants = json.total_covenants
        }

        if (json.total_direct_investments !== undefined) {
            this._total_direct_investments = json.total_direct_investments
        }

        if (json.total_finance_charges !== undefined) {
            this._total_finance_charges = json.total_finance_charges
        }

        if (json.total_funds !== undefined) {
            this._total_funds = json.total_funds
        }

        if (json.total_programs !== undefined) {
            this._total_programs = json.total_programs
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_covenants: this.total_covenants ? this.total_covenants : undefined,
            total_direct_investments: this.total_direct_investments ? this.total_direct_investments : undefined,
            total_finance_charges: this.total_finance_charges ? this.total_finance_charges : undefined,
            total_funds: this.total_funds ? this.total_funds : undefined,
            total_programs: this.total_programs ? this.total_programs : undefined
        }
    }

    public concatFromJSON(json: any): InvestmentsAnnualSummary {

        if (json?.financial_agents?.length) {
            const concatFinancialAgents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
            json.financial_agents = json.financial_agents?.concat(concatFinancialAgents)
        }

        super.concatFromJSON(json)

        if (json.total_covenants !== undefined) {
            this._total_covenants += json.total_covenants
        }

        if (json.total_direct_investments !== undefined) {
            this._total_direct_investments += json.total_direct_investments
        }

        if (json.total_finance_charges !== undefined) {
            this._total_finance_charges += json.total_finance_charges
        }

        if (json.total_funds !== undefined) {
            this._total_funds += json.total_funds
        }

        if (json.total_programs !== undefined) {
            this._total_programs += json.total_programs
        }

        return this
    }

    public fromTwelveMonthly(year: string | undefined, annualInvestments: IAnnualInvestments): InvestmentsAnnualSummary {
        const months = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december'
        ]
        this.total_transactions_value = annualInvestments.total
        this.total_covenants = annualInvestments.totalCovenant
        this.total_direct_investments = annualInvestments.totalDirectInvestments
        this.total_finance_charges = annualInvestments.totalFinanceCharges
        this.total_funds = annualInvestments.totalFunds
        this.total_programs = annualInvestments.totalPrograms
        this.financial_agents = []

        months.forEach((month: string, index: number) => {
            if (annualInvestments[month]) {
                annualInvestments[month]
                    .financial_agents
                    ?.forEach((financialAgent: MonthlyInstitution<Investments>) => {

                        const transactions: AnnualTransaction[] = []
                        let totalPrevision = 0

                        Object.values(InvestmentsType)
                            .forEach((investmentType: string) => {

                                const descriptions = financialAgent?.transactions?.length ?
                                    financialAgent.transactions
                                        .filter((transaction: Investments) => transaction.investment_type === investmentType)
                                        .map((transaction: Investments) => transaction?.description)
                                    : []

                                const setDescriptions = new Set(descriptions)
                                const arrayDescriptions = Array.from(setDescriptions.values())

                                arrayDescriptions.forEach((description: string | undefined) => {
                                    const annualTransaction: AnnualTransaction = new AnnualTransaction()
                                    const valuesForDescription = financialAgent?.transactions?.length ?
                                        financialAgent.transactions
                                            .filter((transaction: Investments) => {
                                                return transaction.investment_type === investmentType &&
                                                    transaction.description === description
                                            })
                                            .map((transaction: Investments) => transaction.value)
                                        : []
                                    /** Sum all values */
                                    const totalDescription = valuesForDescription?.length ?
                                        valuesForDescription
                                            .reduce((prev: number | undefined, current: number | undefined) => {
                                                return (prev ? prev : 0) + (current ? current : 0)
                                            })
                                        : undefined

                                    const previsionDescription: number = financialAgent?.previsions?.length ?
                                        financialAgent
                                            ?.previsions
                                            ?.map((previsionElement: Prevision) => previsionElement?.expected_value)
                                            ?.reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                                                return (prevValue || 0) + (currentValue || 0)
                                            }, 0) || 0
                                        : 0

                                    annualTransaction.date = `${year}-${String(`0${index + 1}`).slice(-2)}`
                                    annualTransaction.total_value = totalDescription
                                    annualTransaction.prevision = previsionDescription
                                    annualTransaction.description = description
                                    annualTransaction.investments_type = investmentType as InvestmentsType

                                    transactions.push(annualTransaction)
                                    totalPrevision += previsionDescription
                                })
                            })

                        const financialAgentIncluded = this.financial_agents?.find((financialAgentElement: AnnualInstitution) => {
                            return financialAgentElement.id === financialAgent.id
                        })

                        if (!financialAgentIncluded) {
                            const newAnnualInstitution: AnnualInstitution = new AnnualInstitution()
                                .fromJSON({
                                    ...financialAgent.institution?.toJSON(),
                                    previsions: totalPrevision,
                                    transactions
                                })
                            this.financial_agents = !this.financial_agents?.length ?
                                [newAnnualInstitution]
                                : this.financial_agents?.concat(newAnnualInstitution)
                        } else {
                            financialAgentIncluded.transactions = financialAgentIncluded.transactions?.concat(transactions)
                            financialAgentIncluded.previsions = (financialAgentIncluded?.previsions || 0) + totalPrevision
                        }
                    })
            }
        })

        return this
    }

}
