import { action } from 'typesafe-actions'

import { ExerciseTypes } from './types'
import Exercise from '../../application/models/exercise/exercise'
import { loadScenarios } from '../scenario/actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import authService from '../../../services/auth'
import { UserTypes } from '../../application/models/auth/user'
import localStorageService from '../../../services/local.storage'
import { PrevisionTransactionTypes } from '../../application/utils/prevision.transaction.types'

export const change = (exerciseId: string) => {
    localStorageService.setItem('exerciseSelected', exerciseId)
    const result: any[] = [action(ExerciseTypes.CHANGE, { exerciseId })]
    if (authService.decodeToken().sub_type !== UserTypes.DISBURSEMENT) {
        result.push(loadScenarios(exerciseId))
    }
    return result
}

export const resetAllChecked = () => action(ExerciseTypes.RESET_CHECKED)

export const addChecked = (financialAgentId: string, transactionType: PrevisionTransactionTypes | string) => [
    action(ExerciseTypes.ADD_CHECKED, { financialAgentId, transactionType }),
    changeDialogBudget(false)
]

export const changePaginator = (paginator: IPaginator) => [
    action(ExerciseTypes.CHANGE_PAGINATOR, { paginator }),
    load(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    ExerciseTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string) => action(
    ExerciseTypes.CHANGE_REMOVE_MODAL, { visibilityModal, idForRemove }
)

export const changeDialogRevenuePrevision = (dialog: boolean) => action(
    ExerciseTypes.CHANGE_DIALOG_REVENUE_PREVISION,
    { dialog }
)

export const changeDialogPrevision = (dialog: boolean) => action(ExerciseTypes.CHANGE_DIALOG_PREVISION, { dialog })

export const changeDialogBudget = (dialog: boolean) => action(ExerciseTypes.CHANGE_DIALOG_BUDGET, { dialog })

export const changeDialogBlocked = (dialog: boolean, redirect?: string) => action(
    ExerciseTypes.CHANGE_DIALOG_BLOCKED,
    { dialog, redirect }
)

export const create = (exercise: Exercise) => action(ExerciseTypes.CREATE_REQUEST, { exercise })

export const createSuccess = (exercise: Exercise) => [
    action(ExerciseTypes.CREATE_SUCCESS, { exercise }),
    change(exercise?.id || '')
]

export const createFailure = (error: ErrorEvent) => action(ExerciseTypes.CREATE_FAILURE, { error })

export const load = (paginator?: IPaginator) => action(ExerciseTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<Exercise[]>) => action(
    ExerciseTypes.LOAD_SUCCESS, { exercises: response.data, headers: response.headers }
)

export const loadFailure = (error: ErrorEvent) => action(ExerciseTypes.LOAD_FAILURE, { error })

export const loadLast = () => action(ExerciseTypes.LOAD_LAST_REQUEST, {})

export const loadLastSuccess = (exercises: Exercise[]) => {

    const currentExercise = exercises.find(exercise => {
        return parseInt(`${exercise.date}`, 10) === new Date().getFullYear()
    })

    if (currentExercise) {
        return [
            action(ExerciseTypes.LOAD_LAST_SUCCESS, { exercises }),
            change(currentExercise?.id || '')
        ]
    }
    return action(ExerciseTypes.LOAD_LAST_SUCCESS, { exercises })
}

export const loadLastFailure = (error: ErrorEvent) => action(ExerciseTypes.LOAD_LAST_FAILURE, { error })

export const remove = (exerciseId: string, paginator: IPaginator) => action(
    ExerciseTypes.REMOVE_REQUEST,
    { exerciseId, paginator }
)

export const removeSuccess = () => action(ExerciseTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(ExerciseTypes.REMOVE_FAILURE, { error })
