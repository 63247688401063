import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { ApiService } from './api'
import { DisbursementReportBalanceObservation } from '../store/application/models/disbursement/disbursement.report.balance.observation'

class DisbursementReportBalanceObservationService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: DisbursementReportBalanceObservation): Promise<DisbursementReportBalanceObservation> {
        return httpClient
            .post(`${this.apiVersion}/disbursement/reports/date/${data?.date}/balance_observations`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportBalanceObservation().fromJSON(response.data))
    }

    public getByDate(date: string): Promise<DisbursementReportBalanceObservation[]> {
        return httpClient
            .get(`${this.apiVersion}/disbursement/reports/date/${date}/balance_observations?limit=${Number.MAX_SAFE_INTEGER}`)
            .then((response: IAxiosResponse<any>) =>
                response?.data?.map((item: any) => new DisbursementReportBalanceObservation().fromJSON(item)) || []
            )
    }

    public update(data: DisbursementReportBalanceObservation): Promise<DisbursementReportBalanceObservation> {
        return httpClient
            .patch(`${this.apiVersion}/disbursement/reports/date/${data?.date}/balance_observations/${data?.id}`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new DisbursementReportBalanceObservation().fromJSON(response.data))
    }

    public remove(date: string, id: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/disbursement/reports/date/${date}/balance_observations/${id}`)
    }
}

export default new DisbursementReportBalanceObservationService()

