import React, { Component } from 'react'
import { InputMask } from 'primereact/inputmask'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import UtilDatePicker, { LOCALE } from './utils'
import moment from 'moment'

interface IProps {

    id?: string

    name?: string

    className?: string

    value: string

    disabled?: boolean

    label?: object

    view?: string

    yearNavigator?: boolean

    yearRange?: string

    onlyButton?: boolean

    tooltipOptions?: any

    withOutMinDate?: boolean

    minDate?: Date

    maxDate?: Date

    disabledDays?: number[]

    dialogPosition?: string
    modal?: boolean

    setFieldValue(field: string, value: any): void

    setFieldTouched?(field: string, value1: boolean, value2: boolean): void

}

interface IState {
    calendar: boolean
    date: Date | undefined
}

const CHECK_DATE = process.env.REACT_APP_CHECK_DATE

class DatePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)

        this.state = {
            calendar: false,
            date: new Date(UtilDatePicker.usaFormatDate(props.value) + 'T03:00:00.000')
        }

        this.closeCalendar = this.closeCalendar.bind(this)
    }

    public render() {
        const {
            setFieldValue,
            setFieldTouched,
            value,
            id,
            name,
            disabled,
            view,
            yearNavigator,
            yearRange,
            onlyButton,
            className,
            tooltipOptions,
            minDate,
            maxDate,
            label,
            disabledDays,
            dialogPosition,
            withOutMinDate,
            modal
        } = this.props
        const today = CHECK_DATE === 'true' ? moment().toDate() : undefined
        return (
            <React.Fragment>

                {
                    !onlyButton ? <React.Fragment>
                            <span className="p-inputgroup">
                                <InputMask
                                    id={id ? id : 'date'}
                                    name={name ? name : 'date'}
                                    mask={view === 'month' ? '99/9999' : '99/99/9999'}
                                    value={value}
                                    disabled={disabled}
                                    onChange={(event: any) => {
                                        setFieldValue(`${name || 'date'}`, event.target.value)
                                        if (setFieldTouched) {
                                            setFieldTouched(`${name || 'date'}`, true, true)
                                        }
                                    }}
                                    onComplete={(event) => {
                                        this.setState({
                                            ...this.state,
                                            date: UtilDatePicker.stringToDate(event.value)
                                        })
                                        setFieldValue(`${name || 'date'}`, event.value)
                                    }}
                                />
                                {label && label}
                                <Button
                                    className="p-inputgroup-addon p-button-clean"
                                    disabled={disabled}
                                    style={{ right: '45px' }}
                                    icon="pi pi-times"
                                    type="button"
                                    tooltip="Limpar"
                                    onClick={() => {
                                        this.setState({ ...this.state, date: undefined })
                                        setFieldValue(`${name || 'date'}`, null)
                                    }}
                                />

                                <Button
                                    className="p-inputgroup-addon p-button-secondary"
                                    disabled={disabled}
                                    icon="pi pi-calendar"
                                    type="button"
                                    tooltip="Abrir calendario"
                                    tooltipOptions={tooltipOptions ? tooltipOptions : {}}
                                    onClick={(e) => {
                                        this.setState({ ...this.state, calendar: !this.state.calendar })
                                    }}
                                />
                            </span>
                        </React.Fragment>
                        : <Button
                            className={`p-button-secondary ${className}`}
                            disabled={disabled}
                            icon="pi pi-calendar"
                            type="button"
                            tooltip="Abrir calendario"
                            tooltipOptions={tooltipOptions ? tooltipOptions : {}}
                            onClick={(e) => {
                                this.setState({ ...this.state, calendar: !this.state.calendar })
                            }}
                        />
                }
                <Dialog
                    header={
                        view !== 'month' && (
                            <div className="d-flex justify-content-end">
                                <Button
                                    type="button"
                                    className="p-button-link"
                                    icon="pi pi-times"
                                    style={{ padding: 0, width: 14, height: 14 }}
                                    tooltip="Fechar"
                                    onClick={this.closeCalendar}/>
                            </div>
                        )
                    }
                    visible={this.state.calendar}
                    closeOnEscape={true}
                    modal={modal}
                    position={dialogPosition || 'center'}
                    onHide={this.closeCalendar}>
                    <Calendar
                        value={this.state.date}
                        onSelect={this.closeCalendar}
                        onClearButtonClick={this.closeCalendar}
                        onTodayButtonClick={this.closeCalendar}
                        inline={true}
                        locale={LOCALE}
                        view={view}
                        minDate={withOutMinDate ? undefined : minDate || today}
                        maxDate={maxDate || (view === 'months' ? new Date() : today)}
                        readOnlyInput={true}
                        // monthNavigator={true}
                        yearNavigator={yearNavigator}
                        yearRange={yearRange}
                        disabledDays={disabledDays || [0, 6]}
                        onChange={(e) => {
                            const dateSelected: Date = e.value as Date
                            this.setState({
                                ...this.state,
                                date: dateSelected
                            })
                            setFieldValue(`${name || 'date'}`, UtilDatePicker.dateToString(dateSelected))
                        }}
                        showButtonBar={view !== 'month'}/>
                </Dialog>
            </React.Fragment>
        )
    }

    /* Close modal calendar */
    private closeCalendar() {
        this.setState({ calendar: false })
    }
}

export default DatePicker
