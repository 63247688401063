import AnnualSummary from '../../summary/annual.summary'
import { AnnualPayer } from '../../financial.agents/payer/annual.payer'

export default class RevenueAnnualSummary extends AnnualSummary<AnnualPayer> {
    private _total_net_operating_revenues: number | undefined
    private _total_other_incomes: number | undefined

    get total_net_operating_revenues(): number | undefined {
        return this._total_net_operating_revenues
    }

    set total_net_operating_revenues(value: number | undefined) {
        this._total_net_operating_revenues = value
    }

    get total_other_incomes(): number | undefined {
        return this._total_other_incomes
    }

    set total_other_incomes(value: number | undefined) {
        this._total_other_incomes = value
    }

    public fromJSON(json: any): RevenueAnnualSummary {

        if (json?.financial_agents !== undefined) {
            json.financial_agents = json.financial_agents
                ?.map(financialAgent => new AnnualPayer().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_net_operating_revenues !== undefined) {
            this._total_net_operating_revenues = json.total_net_operating_revenues
        }

        if (json.total_other_incomes !== undefined) {
            this._total_other_incomes = json.total_other_incomes
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_net_operating_revenues: this.total_net_operating_revenues ? this.total_net_operating_revenues : undefined,
            total_other_incomes: this.total_other_incomes ? this.total_other_incomes : undefined
        }
    }

    public concatFromJSON(json: any): RevenueAnnualSummary {

        if (json?.financial_agents !== undefined) {
            json.financial_agents = json.financial_agents
                ?.map(financialAgent => new AnnualPayer().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_net_operating_revenues !== undefined) {
            this._total_net_operating_revenues += json.total_net_operating_revenues
        }

        if (json.total_other_incomes !== undefined) {
            this._total_other_incomes += json.total_other_incomes
        }

        return this
    }
}
