import React, { Component } from 'react'

import ReactTooltip from 'react-tooltip'
import { Button } from 'primereact/button'

import Currency from '../currency/currency'
import './tree.table.scss'
import { LogicalStrategy } from '../../services/auth'
import { VerifyScopes } from '../verify.scopes/verify.scopes'

interface IProps {
    readonly element: any | undefined

    readonly className?: string

    readonly hasOccurred?: boolean

    readonly enableRemove?: boolean

    readonly scopesCreate?: string []

    readonly scopesEdit?: string []

    readonly scopesRemove?: string []

    onClickAdd?(event: any): void

    onClickUpdate?(event: any): void

    onClickRemove?(event: any): void
}

class Cell extends Component<IProps> {

    public render() {
        const {
            element,
            onClickAdd,
            onClickUpdate,
            hasOccurred,
            onClickRemove,
            className,
            scopesCreate,
            scopesEdit,
            scopesRemove,
            enableRemove
        } = this.props
        let signal = ''
        if (element?.value) {
            signal = element?.value > 0 ? 'positive' : 'negative'
        }
        return <div className="cell">
            {
                element?.error && <span className="alert">
                                     <i className="fa fa-exclamation-triangle"/>
                                  </span>
            }
            <Currency
                className={element?.error ? `has-error ${signal}` : `${className} ${signal}`}
                value={element?.value}
                readOnly={true}/>
            {
                (!hasOccurred) && <React.Fragment>
                    {
                        element?.value && <VerifyScopes scopes={scopesEdit ? scopesEdit : []}
                                                        logicalStrategy={LogicalStrategy.AND}>
                            <Button
                                type="button"
                                style={{ position: 'absolute', left: 0, width: '20px' }}
                                className="p-button-addon p-button-small"
                                icon="pi pi-pencil"
                                tooltip="Clique para atualizar"
                                onClick={onClickUpdate}/>
                        </VerifyScopes>
                    }
                    {
                        element?.value && <VerifyScopes scopes={scopesRemove ? scopesRemove : []}
                                                        logicalStrategy={LogicalStrategy.AND}>
                            <Button
                                style={{
                                    position: 'absolute',
                                    right: '-5px',
                                    width: '20px'
                                }}
                                type="button"
                                className="p-button-addon p-button-small"
                                icon="pi pi-times"
                                tooltip="Clique para remover"
                                onClick={onClickRemove}/>
                        </VerifyScopes>
                    }
                    {
                        !element.value && <VerifyScopes scopes={scopesCreate ? scopesCreate : []}
                                                        logicalStrategy={LogicalStrategy.AND}>
                            <Button
                                type="button"
                                style={{ position: 'absolute', left: 0, width: '20px' }}
                                className="p-button-addon p-button-small"
                                icon="pi pi-plus"
                                tooltip="Clique para adicionar"
                                onClick={onClickAdd}/>
                        </VerifyScopes>
                    }
                </React.Fragment>
            }
            {
                enableRemove && element?.value &&
                <VerifyScopes scopes={scopesRemove ? scopesRemove : []}
                              logicalStrategy={LogicalStrategy.AND}>
                    <Button
                        type="button"
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            width: '20px'
                        }}
                        className="p-button-addon p-button-small"
                        icon="pi pi-times"
                        tooltip="Clique para remover"
                        onClick={onClickRemove}/>
                </VerifyScopes>
            }
            {
                element?.description &&
                <span className="description" data-tip={element?.description}>
                    <i className="fa fa-asterisk"/>
                    <ReactTooltip/>
                </span>
            }
        </div>
    }
}

export default Cell
