import { lazy } from 'react'

const AnnualExpense = lazy(() => import('../containers/expense/annual'))
const MonthlyExpense = lazy(() => import('../containers/expense/monthly'))

export const EXPENSE_ROUTES = [
    {
        path: '/app/expenses/annual',
        component: AnnualExpense,
        scopes: ['as:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/expenses/monthly',
        component: MonthlyExpense,
        scopes: ['ms:r'],
        exact: true,
        private: true
    }
]
