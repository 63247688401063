import { Reducer } from 'redux'
import { IRevenueState, RevenuesActionsTypes } from './types'
import Revenue from '../../application/models/transactions/revenue/revenue'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'

export const INITIAL_STATE: IRevenueState = {
    createRevenue: {
        revenue: new Revenue(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    listRevenues: {
        revenues: [],
        data: new ErrorEvent(''),
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        payers: undefined
    },
    removeRevenue: {
        idForRemove: '',
        payerId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthlyRevenue: {
        monthlyRevenue: new RevenueMonthlySummary(),
        data: new ErrorEvent(''),
        lastSync: undefined,
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annualRevenue: {
        annualRevenue: new RevenueAnnualSummary(),
        data: new ErrorEvent(''),
        lastSync: undefined,
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    executedByPayer: {
        annualRevenues: [],
        data: new ErrorEvent(''),
        loading: false,
        success: false,
        error: false
    }
}

const reducer: Reducer<IRevenueState> = (state: IRevenueState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case RevenuesActionsTypes.RESET_REVENUES:
            return {
                ...state,
                createRevenue: INITIAL_STATE.createRevenue
            }

        case RevenuesActionsTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, createRevenue: { ...state.createRevenue, dialog } }

        case RevenuesActionsTypes.CHANGE_REVENUES:
            const { revenue } = action.payload
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: false,
                    error: false,
                    success: false,
                    revenue: new Revenue().fromJSON(revenue)
                }
            }

        case RevenuesActionsTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    paginator
                }
            }

        case RevenuesActionsTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    paginator: {
                        ...state.listRevenues.paginator,
                        search
                    }
                }
            }

        case RevenuesActionsTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, payerId } = action.payload
            return {
                ...state,
                removeRevenue: {
                    ...state.removeRevenue,
                    visibilityModal,
                    idForRemove,
                    payerId
                }
            }

        case RevenuesActionsTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    paginator: monthlyPaginator
                }
            }

        case RevenuesActionsTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    paginator: INITIAL_STATE.monthlyRevenue.paginator
                }
            }

        case RevenuesActionsTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    paginator: annualPaginator
                }
            }

        case RevenuesActionsTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    paginator: INITIAL_STATE.annualRevenue.paginator
                }
            }

        case RevenuesActionsTypes.CREATE_REQUEST:
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: true
                }
            }

        case RevenuesActionsTypes.CREATE_SUCCESS:
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    revenue: new Revenue().fromJSON({ payer_id: state.createRevenue.revenue.payer_id }),
                    success: true,
                    loading: false
                }
            }

        case RevenuesActionsTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: false,
                    error: true,
                    success: false,
                    data: createError
                }
            }

        case RevenuesActionsTypes.LOAD_REQUEST:
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    loading: true
                }
            }

        case RevenuesActionsTypes.LOAD_SUCCESS:
            const { revenues, headers } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    loading: false,
                    success: false,
                    error: false,
                    revenues,
                    paginator: {
                        ...state.listRevenues.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case RevenuesActionsTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case RevenuesActionsTypes.FIND_REQUEST:
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: true
                }
            }

        case RevenuesActionsTypes.FIND_SUCCESS:
            const { revenue: revenueFound } = action.payload
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    revenue: new Revenue().fromJSON(revenueFound),
                    loading: false
                }
            }

        case RevenuesActionsTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: false,
                    error: true,
                    success: false,
                    data: errorFound
                }
            }

        case RevenuesActionsTypes.UPDATE_REQUEST:
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: true
                }
            }

        case RevenuesActionsTypes.UPDATE_SUCCESS:
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: false
                }
            }

        case RevenuesActionsTypes.UPDATE_FAILURE:
            const { error: updateError} = action.payload
            return {
                ...state,
                createRevenue: {
                    ...state.createRevenue,
                    loading: false,
                    error: true,
                    success: false,
                    data: updateError
                }
            }

        case RevenuesActionsTypes.REMOVE_REQUEST:
            return {
                ...state,
                removeRevenue: {
                    ...state.removeRevenue,
                    loading: true,
                    visibilityModal: false
                }
            }

        case RevenuesActionsTypes.REMOVE_SUCCESS:
            return {
                ...state,
                removeRevenue: {
                    ...state.removeRevenue,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case RevenuesActionsTypes.REMOVE_FAILURE:
            return {
                ...state,
                removeRevenue: {
                    ...state.removeRevenue,
                    loading: false,
                    error: true,
                    success: false,
                    visibilityModal: true
                }
            }

        case RevenuesActionsTypes.FIND_PAYER_REQUEST:
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    payers: undefined
                }
            }

        case RevenuesActionsTypes.FIND_PAYER_SUCCESS:
            const { payer } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    payers: payer
                }
            }

        case RevenuesActionsTypes.FIND_PAYER_FAILURE:
            const { error: findPayersError } = action.payload
            return {
                ...state,
                listRevenues: {
                    ...state.listRevenues,
                    error: findPayersError
                }
            }

        case RevenuesActionsTypes.LOAD_MONTHLY_REQUEST:
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    loading: true
                }
            }

        case RevenuesActionsTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyResetHeaders, monthlyRevenue: resetMonthlyRevenue } = action.payload
            const monthlyResult = new RevenueMonthlySummary().fromJSON(resetMonthlyRevenue)
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    loading: false,
                    success: false,
                    error: false,
                    monthlyRevenue: monthlyResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyRevenue.paginator,
                        totalRecords: parseInt(monthlyResetHeaders['x-total-count'], 10)
                    }
                }
            }

        case RevenuesActionsTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyRevenue: concatMonthlyRevenue } = action.payload
            state.monthlyRevenue.monthlyRevenue.concatFromJSON(concatMonthlyRevenue)
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    loading: false,
                    success: false,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthlyRevenue.paginator,
                        totalRecords: parseInt(monthlyConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case RevenuesActionsTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthlyRevenue: {
                    ...state.monthlyRevenue,
                    monthlyRevenue: INITIAL_STATE.monthlyRevenue.monthlyRevenue,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_REQUEST:
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    loading: true
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const { headers: annualResetHeaders, annualRevenue: resetAnnualRevenue } = action.payload
            const annualResetResult = new RevenueAnnualSummary().fromJSON(resetAnnualRevenue)
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    loading: false,
                    success: true,
                    error: false,
                    annualRevenue: annualResetResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualRevenue.paginator,
                        totalRecords: parseInt(annualResetHeaders['x-total-count'], 10)
                    }
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const { headers: annualConcatHeaders, annualRevenue: concatAnnualRevenue } = action.payload
            const annualConcatResult = state.annualRevenue.annualRevenue.concatFromJSON(concatAnnualRevenue)
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    loading: false,
                    success: false,
                    error: false,
                    annualRevenue: annualConcatResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annualRevenue.paginator,
                        totalRecords: parseInt(annualConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annualRevenue: {
                    ...state.annualRevenue,
                    annualRevenue: INITIAL_STATE.annualRevenue.annualRevenue,
                    loading: false,
                    error: true,
                    data: annualError
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_REQUEST:
            return {
                ...state,
                executedByPayer: {
                    ...state.executedByPayer,
                    loading: true
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_SUCCESS:
            const { annualRevenues: executedRevenues } = action.payload
            return {
                ...state,
                executedByPayer: {
                    ...state.executedByPayer,
                    loading: false,
                    success: false,
                    error: false,
                    annualRevenues: executedRevenues
                }
            }

        case RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_FAILURE:
            const { error: executedError } = action.payload
            return {
                ...state,
                executedByPayer: {
                    ...state.executedByPayer,
                    loading: false,
                    success: false,
                    error: true,
                    data: executedError
                }
            }

        default:
            return state
    } // End switch
}

export default reducer
