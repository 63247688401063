import { put, apply, all, takeLatest } from 'redux-saga/effects'
import {
    createPayerFailure,
    createPayerSuccess,
    findPayerFailure,
    findPayerSuccess,
    loadPayers,
    loadPayersFailure,
    loadPayersSuccess,
    removePayerFailure,
    removePayerSuccess,
    updatePayerFailure,
    updatePayerSuccess
} from './actions'
import { IActionType } from '../root.types'
import { Toast } from '../../../services/toast'
import payerService from '../../../services/payer'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, PayerActionsTypes } from './types'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    try {
        const { payer } = action.payload
        const response = yield apply(
            payerService,
            payerService.create, [payer]
        )
        yield put<any>(createPayerSuccess(response))
        toastService.show('success', 'Ingresso criado com sucesso', '')
    } catch (err) {
        yield put(createPayerFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    const { paginator } = action.payload
    try {
        const response = yield apply(
            payerService,
            payerService.getAll, [paginator]
        )
        yield put(loadPayersSuccess(response))
    } catch (err) {
        yield put(loadPayersFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { payerId } = action.payload
        const response = yield apply(
            payerService,
            payerService.getById, [payerId]
        )
        yield put(findPayerSuccess(response))
    } catch (err) {
        yield put(findPayerFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { payer } = action.payload
    try {
        const response = yield apply(
            payerService,
            payerService.update, [payer]
        )
        yield put<any>(updatePayerSuccess(response))
        toastService.show('success', 'Ingresso atualizado com sucesso', '')
    } catch (err) {
        yield put(updatePayerFailure(err, payer))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    const { idForRemove } = action.payload
    try {
        yield apply(
            payerService,
            payerService.remove, [idForRemove]
        )
        yield put(removePayerSuccess())
        yield put(loadPayers())
        toastService.show('success', 'Ingresso removido com sucesso', '')
    } catch (err) {
        yield put(removePayerFailure(err))
    }
}

export default function* payerSaga() {
    return yield all([
        takeLatest(PayerActionsTypes.CREATE_REQUEST, create),
        takeLatest(PayerActionsTypes.LOAD_REQUEST, getAll),
        takeLatest(PayerActionsTypes.FIND_REQUEST, getById),
        takeLatest(PayerActionsTypes.UPDATE_REQUEST, update),
        takeLatest(PayerActionsTypes.REMOVE_REQUEST, remove)
    ])
}