import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { Toast } from '../../../services/toast'

import { IActionType, IAxiosResponse } from '../root.types'
import supplementaryBudgetService from '../../../services/supplementary.budget'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, SupplementaryBudgetTypes } from './types'
import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    load,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { supplementary, paginator } = action.payload
    try {
        const response: IAxiosResponse<SupplementaryBudget> = yield apply(
            supplementaryBudgetService,
            supplementaryBudgetService.create,
            [supplementary]
        )
        yield put<any>(createSuccess(response?.data, paginator))
        toastService.show('success', 'Alteração orçamentária criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err, supplementary))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { supplementaryId, payeeId } = action.payload
        const response: IAxiosResponse<SupplementaryBudget> = yield apply(
            supplementaryBudgetService,
            supplementaryBudgetService.getById,
            [payeeId, supplementaryId]
        )
        yield put(findSuccess(response?.data))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { payeeId, paginator } = action.payload
        const response: IAxiosResponse<SupplementaryBudget[]> = yield apply(
            supplementaryBudgetService,
            supplementaryBudgetService.getAll,
            [payeeId, paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { supplementary, paginator } = action.payload
    try {
        const response: IAxiosResponse<SupplementaryBudget> = yield apply(
            supplementaryBudgetService,
            supplementaryBudgetService.update,
            [supplementary]
        )
        yield put<any>(updateSuccess(response?.data, paginator))
        toastService.show('success', 'Alteração orçamentária atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err, supplementary))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { payeeId, supplementaryId, paginator } = action.payload
        yield apply(supplementaryBudgetService, supplementaryBudgetService.remove, [payeeId, supplementaryId])
        yield put(removeSuccess())
        yield put(load(payeeId, paginator))
        toastService.show('success', 'Alteração orçamentária excluído com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

export default function* supplementaryBudgetSaga() {
    return yield all([
        takeLatest(SupplementaryBudgetTypes.CREATE_REQUEST, create),
        takeLatest(SupplementaryBudgetTypes.LOAD_REQUEST, getAll),
        takeLatest(SupplementaryBudgetTypes.FIND_REQUEST, getById),
        takeLatest(SupplementaryBudgetTypes.UPDATE_REQUEST, update),
        takeLatest(SupplementaryBudgetTypes.REMOVE_REQUEST, remove)
    ])
}
