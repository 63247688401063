import { DisbursementTransaction } from './disbursement.transaction'
import { DisbursementTransactionType } from '../../utils/disbursement.transaction.type'

/**
 * Implementation of the Input entity.
 *
 * @category Application
 * @subcategory Model
 *
 * @extends {DisbursementTransaction}
 *
 * @param {string} [description] Disbursement transaction description.
 * @param {string} [date] Disbursement transaction date.
 * @param {string} [value] Disbursement transaction value.
 * @param {string} [institution_id] Institution identifier.
 */
export class Input extends DisbursementTransaction {
    public constructor() {
        super()
        this._transaction_type = DisbursementTransactionType.INPUT
    }
}
