import httpClient from './config.axios'
import { AxiosResponse } from 'axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Institution from '../store/application/models/financial.agents/institution/institution'
import { ApiService } from './api'
import { TransactionTypes } from '../store/application/models/prevision/prevision'
import { ISuggestionResponse } from '../store/ducks/suggestion/types'

const API_VERSION = '/v1'

class InstitutionService extends ApiService {
    public create(institution: Institution) {
        return httpClient.post(`${API_VERSION}/institutions`, institution)
    }

    public remove(institutionId: string) {
        return httpClient.delete(`${API_VERSION}/institutions/${institutionId}`)
    }

    public update(institution: Institution) {
        return httpClient.patch(
            `${API_VERSION}/institutions/${institution.id}`,
            institution
        )
    }

    public getById(institutionId: string) {
        return httpClient
            .get(`${API_VERSION}/institutions/${institutionId}`)
            .then((response: AxiosResponse) => response.data)
    }

    public getByIds(institutionIds: string[]): Promise<Institution[]> {
        const params = new URLSearchParams()
        params.append('_id', `${institutionIds.join(',')}`)
        return httpClient
            .get(`${API_VERSION}/institutions`, { params })
            .then((response: AxiosResponse) => response.data.map((item: any) => new Institution().fromJSON(item)))
    }

    public getAll(paginator?: IPaginator) {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        params.append('sort', '-code')
        return httpClient
            .get(`${API_VERSION}/institutions`, { params })
            .then((response: AxiosResponse) => {
                return { data: response.data, headers: response.headers }
            })
    }

    public async getSuggestionsById(institutionId: string, transactionType: TransactionTypes): Promise<ISuggestionResponse> {
        let listOfResources: any[] = []
        let response: IAxiosResponse
        do {
            response = await httpClient.get(`${API_VERSION}/institutions/${institutionId}/${transactionType}s`)
            listOfResources = listOfResources.concat(response.data)
        } while (listOfResources.length < parseInt(response?.headers['x-total-count'], 10))

        const descriptions: string[] = listOfResources
            .map(resource => resource.description)
            .filter(description => description)

        const suggestions: string[] = descriptions?.length ? Array.from(new Set(descriptions)) : []

        const types: string[] = suggestions
            .map(description => {
                const finded = listOfResources.find(resource => resource.description === description)
                switch (transactionType) {
                    case TransactionTypes.DEBT:
                        return finded.debt_type

                    case TransactionTypes.INVESTMENT:
                        return finded.investment_type

                    default:
                        return ''
                }
            })
        return { suggestions, typeRespectively: types }
    }
}

export default new InstitutionService()
