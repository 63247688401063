import { action } from 'typesafe-actions'

import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { PayeeTypes } from './types'
import Payee from '../../application/models/financial.agents/payee/payee'

/* Actions for Reset Create Payee */
export const resetCreatePayee = () => action(PayeeTypes.RESET_CREATE_PAYEE, {})

/* Actions for Change Payee */
export const changePayee = (payee: Payee) => action(PayeeTypes.CHANGE_PAYEE, { payee })

export const changeRemoveModal = (
    visibilityModal: boolean,
    idForRemove: string
) =>
    action(PayeeTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove
    })


export const changePaginator = (paginator: IPaginator) => [
    action(PayeeTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changePaginatorDuodecimos = (paginator: IPaginator) => [
    action(PayeeTypes.CHANGE_PAGINATOR, { paginator }),
    loadDuodecimos(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    PayeeTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

/* Actions for Change Dialog Create */
export const changeDialogCreate = (dialog: boolean) => action(PayeeTypes.CHANGE_DIALOG_CREATE, { dialog })

/* Actions for Create Payee */
export const create = (payee: Payee, location: 'list' | 'duodecimos') => action(
    PayeeTypes.CREATE_REQUEST,
    { payee, location }
)

export const createSuccess = (payee: Payee, location: 'list' | 'duodecimos') => [
    action(PayeeTypes.CREATE_SUCCESS, { payee }),
    resetCreatePayee(),
    location === 'list' ? loadRequest() : loadDuodecimos()
]

export const createFailure = (error: ErrorEvent) => action(PayeeTypes.CREATE_FAILURE, { error })

/* Actions for Find Payee */
export const findRequest = (payeeId: string) => action(PayeeTypes.FIND_REQUEST, { payeeId })

export const findSuccess = (payee: Payee) => action(PayeeTypes.FIND_SUCCESS, { payee })

export const findFailure = (error: ErrorEvent) => action(PayeeTypes.FIND_FAILURE, { error })

/* Actions for Update Payee */
export const updateRequest = (payee: Payee, location: 'list' | 'duodecimos') => action(
    PayeeTypes.UPDATE_REQUEST,
    { payee, location }
)

export const updateSuccess = (payee: Payee, location: 'list' | 'duodecimos') => [
    action(PayeeTypes.UPDATE_SUCCESS, { payee }),
    resetCreatePayee(),
    location === 'list' ? loadRequest() : loadDuodecimos()
]

export const updateFailure = (error: ErrorEvent) => action(PayeeTypes.UPDATE_FAILURE, { error })

/* Actions for Remove Payee */
export const removeRequest = (idForRemove: string, paginator?: IPaginator) => action(
    PayeeTypes.REMOVE_REQUEST,
    { idForRemove, paginator }
)

export const removeSuccess = () => action(PayeeTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(PayeeTypes.REMOVE_FAILURE, { error })

/* Actions for Load Payees */
export const loadRequest = (paginator?: IPaginator) => action(PayeeTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<Payee[]>) =>
    action(PayeeTypes.LOAD_SUCCESS, {
        payees: response.data,
        headers: response.headers
    })

export const loadFailure = (error: ErrorEvent) => action(PayeeTypes.LOAD_FAILURE, { error })

/* Actions for Load Duodecimos */
export const loadDuodecimos = (paginator?: IPaginator) => action(PayeeTypes.LOAD_DUODECIMOS, { paginator })

export const loadDuodecimosSuccess = (response: IAxiosResponse<Payee[]>) => action(
    PayeeTypes.LOAD_DUODECIMOS_SUCCESS, {
        payees: response.data,
        headers: response.headers
    }
)

export const loadDuodecimosFailure = (error: ErrorEvent) => action(PayeeTypes.LOAD_DUODECIMOS_FAILURE, { error })

