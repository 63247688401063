import { IPaginator } from '../costing/types'
import BalanceSummary from '../../application/models/balance/balance.summary'
import { IComponentState } from '../root.types'
import CostAnnualSummary from '../../application/models/transactions/costing/cost.annual.summary'
import EventAnnualSummary from '../../application/models/transactions/event/event.annual.summary'
import ExpenseAnnualSummary from '../../application/models/transactions/expense/expense.annual.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'
import ScenarioDataModel from '../../application/models/scenario/scenario.data.model'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import Budget from '../../application/models/budget/budget'
import SupplementaryBudget from '../../application/models/budget/supplementary.budget'
import Exercise from '../../application/models/exercise/exercise'
import Payee from '../../application/models/financial.agents/payee/payee'

/**
 * Action types
 */
export enum ScenarioActionTypes {

    RESET = '@scenario/RESET',

    CHANGE_DIALOG = '@scenario/CHANGE_DIALOG',
    CHANGE_PAGINATOR = '@scenario/CHANGE_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@revenue/CHANGE_REMOVE_MODAL',
    CHANGE_SCENARIO = '@revenue/CHANGE_SCENARIO',

    LOAD_REQUEST = '@scenario/LOAD_REQUEST',

    LOAD_CURRENT_COSTING_SUCCESS = '@scenario/LOAD_CURRENT_COSTING_SUCCESS',
    LOAD_CURRENT_COSTING_FAILURE = '@scenario/LOAD_CURRENT_COSTING_FAILURE',

    LOAD_CURRENT_DEBT_SUCCESS = '@scenario/LOAD_CURRENT_DEBT_SUCCESS',
    LOAD_CURRENT_DEBT_FAILURE = '@scenario/LOAD_CURRENT_DEBT_FAILURE',

    LOAD_CURRENT_EVENT_SUCCESS = '@scenario/LOAD_CURRENT_EVENT_SUCCESS',
    LOAD_CURRENT_EVENT_FAILURE = '@scenario/LOAD_CURRENT_EVENT_FAILURE',

    LOAD_CURRENT_INVESTMENT_SUCCESS = '@scenario/LOAD_CURRENT_INVESTMENT_SUCCESS',
    LOAD_CURRENT_INVESTMENT_FAILURE = '@scenario/LOAD_CURRENT_INVESTMENT_FAILURE',

    LOAD_CURRENT_EXPENSE_SUCCESS = '@scenario/LOAD_CURRENT_EXPENSE_SUCCESS',
    LOAD_CURRENT_EXPENSE_FAILURE = '@scenario/LOAD_CURRENT_EXPENSE_FAILURE',

    LOAD_PREV_REVENUE_SUCCESS = '@scenario/LOAD_PREV_REVENUE_SUCCESS',
    LOAD_PREV_REVENUE_FAILURE = '@scenario/LOAD_PREV_REVENUE_FAILURE',

    LOAD_CURRENT_REVENUE_SUCCESS = '@scenario/LOAD_CURRENT_REVENUE_SUCCESS',
    LOAD_CURRENT_REVENUE_FAILURE = '@scenario/LOAD_CURRENT_REVENUE_FAILURE',

    LOAD_CURRENT_BALANCE_SUCCESS = '@scenario/LOAD_CURRENT_BALANCE_SUCCESS',
    LOAD_CURRENT_BALANCE_FAILURE = '@scenario/LOAD_CURRENT_BALANCE_FAILURE',

    CREATE_REQUEST = '@scenario/CREATE_REQUEST',
    CREATE_SUCCESS = '@scenario/CREATE_SUCCESS',
    CREATE_FAILURE = '@scenario/CREATE_FAILURE',

    UPDATE_REQUEST = '@scenario/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@scenario/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@scenario/UPDATE_FAILURE',

    LOAD_ALL_REQUEST = '@scenario/LOAD_ALL_REQUEST',
    LOAD_ALL_SUCCESS = '@scenario/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAILURE = '@scenario/LOAD_ALL_FAILURE',

    REMOVE_REQUEST = '@scenario/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@scenario/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@scenario/REMOVE_FAILURE',

    LOAD_DUODECIMOS_REQUEST = '@scenario/LOAD_DUODECIMOS_REQUEST',
    LOAD_DUODECIMOS_SUCCESS = '@scenario/LOAD_DUODECIMOS_SUCCESS',
    LOAD_DUODECIMOS_FAILURE = '@scenario/LOAD_DUODECIMOS_FAILURE',

    LOAD_BUDGETS_REQUEST = '@scenario/LOAD_BUDGETS_REQUEST',
    LOAD_BUDGETS_SUCCESS = '@scenario/LOAD_BUDGETS_SUCCESS',
    LOAD_BUDGETS_FAILURE = '@scenario/LOAD_BUDGETS_FAILURE',

    LOAD_SUPPLEMENTARY_BUDGETS_REQUEST = '@scenario/LOAD_SUPPLEMENTARY_BUDGETS_REQUEST',
    LOAD_SUPPLEMENTARY_BUDGETS_SUCCESS = '@scenario/LOAD_SUPPLEMENTARY_BUDGETS_SUCCESS',
    LOAD_SUPPLEMENTARY_BUDGETS_FAILURE = '@scenario/LOAD_SUPPLEMENTARY_BUDGETS_FAILURE'
}

export interface IActionLoad {
    readonly date: string
    readonly paginator?: IPaginator
}

export interface IActionLoadDuodecimos {
    readonly paginator: IPaginator
    readonly exerciseSelected: Exercise
}

export interface IActionLoadBudgets {
    readonly payees: Payee[]
    readonly exerciseId: string
}

export interface IActionLoadSupplementaryBudgets {
    readonly payees: Payee[]
    readonly date: string
}

export interface IActionLoadAllScenarios {
    readonly exerciseId: string
    readonly paginator?: IPaginator
}

export interface IActionLoadResponse {
    readonly annualCosting?: CostAnnualSummary
    readonly annualDebt?: IDebtAnnual
    readonly annualEvent?: EventAnnualSummary
    readonly annualInvestment?: IInvestmentAnnual
    readonly annualExpense?: ExpenseAnnualSummary
    readonly annualRevenue?: RevenueAnnualSummary
    readonly monthlyBalance?: BalanceSummary
}

export interface ICosting extends IComponentState {
    readonly annualCosting?: CostAnnualSummary
}

export interface IDebtAnnual {
    readonly january?: DebtMonthlySummary
    readonly february?: DebtMonthlySummary
    readonly march?: DebtMonthlySummary
    readonly april?: DebtMonthlySummary
    readonly may?: DebtMonthlySummary
    readonly june?: DebtMonthlySummary
    readonly july?: DebtMonthlySummary
    readonly august?: DebtMonthlySummary
    readonly september?: DebtMonthlySummary
    readonly october?: DebtMonthlySummary
    readonly november?: DebtMonthlySummary
    readonly december?: DebtMonthlySummary
}

export interface IDebt extends IComponentState {
    readonly january?: DebtMonthlySummary
    readonly february?: DebtMonthlySummary
    readonly march?: DebtMonthlySummary
    readonly april?: DebtMonthlySummary
    readonly may?: DebtMonthlySummary
    readonly june?: DebtMonthlySummary
    readonly july?: DebtMonthlySummary
    readonly august?: DebtMonthlySummary
    readonly september?: DebtMonthlySummary
    readonly october?: DebtMonthlySummary
    readonly november?: DebtMonthlySummary
    readonly december?: DebtMonthlySummary
}

export interface IEvent extends IComponentState {
    readonly annualEvent?: EventAnnualSummary
}

export interface IInvestmentAnnual {
    readonly january?: InvestmentsMonthlySummary
    readonly february?: InvestmentsMonthlySummary
    readonly march?: InvestmentsMonthlySummary
    readonly april?: InvestmentsMonthlySummary
    readonly may?: InvestmentsMonthlySummary
    readonly june?: InvestmentsMonthlySummary
    readonly july?: InvestmentsMonthlySummary
    readonly august?: InvestmentsMonthlySummary
    readonly september?: InvestmentsMonthlySummary
    readonly october?: InvestmentsMonthlySummary
    readonly november?: InvestmentsMonthlySummary
    readonly december?: InvestmentsMonthlySummary
}

export interface IInvestment extends IComponentState {
    readonly january?: InvestmentsMonthlySummary
    readonly february?: InvestmentsMonthlySummary
    readonly march?: InvestmentsMonthlySummary
    readonly april?: InvestmentsMonthlySummary
    readonly may?: InvestmentsMonthlySummary
    readonly june?: InvestmentsMonthlySummary
    readonly july?: InvestmentsMonthlySummary
    readonly august?: InvestmentsMonthlySummary
    readonly september?: InvestmentsMonthlySummary
    readonly october?: InvestmentsMonthlySummary
    readonly november?: InvestmentsMonthlySummary
    readonly december?: InvestmentsMonthlySummary
}

export interface IExpense extends IComponentState {
    readonly annualExpense?: ExpenseAnnualSummary
}

export interface IRevenue extends IComponentState {
    readonly annualRevenue?: RevenueAnnualSummary
}

export interface IPrevExerciseState {
    readonly revenue: IRevenue
}

/**
 *  Monthly balance resource used to calculate the balance available at the turn of the year
 */
export interface IBalanceState extends IComponentState {
    readonly monthlyBalance?: BalanceSummary
}

export interface ICurrentExerciseState {
    readonly costing: ICosting
    readonly debt: IDebt
    readonly event: IEvent
    readonly investment: IInvestment
    readonly expense: IExpense
    readonly revenue: IRevenue
    readonly balance: IBalanceState
}

export interface IListScenarioState extends IComponentState {
    readonly scenarios: ScenarioDataModel[]
    readonly paginator: IPaginator
}

export interface ICreateScenarioState extends IComponentState {
    readonly scenario: ScenarioDataModel
    readonly dialog: boolean
}

export interface IRemoveScenarioState extends IComponentState {
    readonly exerciseId: string
    readonly scenarioId: string
    readonly dialog: boolean
}


export interface IDuodecimosState extends IComponentState {
    readonly payees: Payee[]
    readonly budgets: Map<string, Budget>
    readonly supplementaries: Map<string, SupplementaryBudget[]>
}

/**
 * State type
 */
export interface IScenarioState {
    readonly listScenarios: IListScenarioState
    readonly create: ICreateScenarioState
    readonly remove: IRemoveScenarioState
    readonly previousExercise: IPrevExerciseState
    readonly currentExercise: ICurrentExerciseState
    readonly duodecimos: IDuodecimosState
}
