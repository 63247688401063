import httpClient from './config.axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'
import { Liberation } from '../store/application/models/liberation/liberation'
import { LiberationSummary } from '../store/application/models/liberation/liberation.summary'

class LiberationService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(data: Liberation): Promise<Liberation> {
        return httpClient
            .post(`${this.apiVersion}/liberations`, data.toJSON())
            .then((response: IAxiosResponse<any>) => new Liberation().fromJSON(response.data))
    }

    public getById(id: string): Promise<Liberation> {
        return httpClient
            .post(`${this.apiVersion}/liberations/${id}`)
            .then((response: IAxiosResponse<any>) => new Liberation().fromJSON(response.data))
    }

    public getDailySummary(date: string, paginator?: IPaginator): Promise<IAxiosResponse<LiberationSummary>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(`${this.apiVersion}/liberations/date/${date}/dailysummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new LiberationSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public getMonthlySummary(date: string, paginator?: IPaginator): Promise<IAxiosResponse<LiberationSummary>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(`${this.apiVersion}/liberations/date/${date}/monthlysummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new LiberationSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public getAnnualSummary(date: string, paginator?: IPaginator): Promise<IAxiosResponse<LiberationSummary>> {
        const params = new URLSearchParams()
        if (paginator) {
            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }
            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }
        }
        return httpClient
            .get(`${this.apiVersion}/liberations/date/${date}/annualsummary`, { params })
            .then((response: IAxiosResponse<any>) => {
                return {
                    data: new LiberationSummary().fromJSON(response.data),
                    headers: response.headers
                }
            })
    }

    public update(data: Liberation): Promise<Liberation> {
        const body = data.toJSON()
        delete body.date
        delete body.institution_id
        delete body.financial_agent_id
        return httpClient
            .patch(`${this.apiVersion}/liberations/${data.id}`, body)
            .then((response: IAxiosResponse<any>) => new Liberation().fromJSON(response.data))
    }

    public remove(id: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/liberations/${id}`)
    }
}

export default new LiberationService()

