import { Reducer } from 'redux'
import { DisbursementActionTypes, IDisbursementState } from './types'
import { DisbursementTransaction } from '../../application/models/disbursement/disbursement.transaction'
import { DailyDisbursementSummary } from '../../application/models/disbursement/daily.disbursement.summary'
import { DisbursementReport } from '../../application/models/disbursement/disbursement.report'

export const INITIAL_STATE: IDisbursementState = {
    create: {
        data: new DisbursementTransaction(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: new DailyDisbursementSummary(),
        loading: false,
        error: false,
        success: false,
        sync: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        error: false,
        success: false
    },
    balances: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    conference: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    report: {
        data: new DisbursementReport(),
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IDisbursementState> = (state: IDisbursementState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case DisbursementActionTypes.CHANGE:
            const { data: dataSelected } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: dataSelected
                }
            }

        case DisbursementActionTypes.CHANGE_REMOVE_MODAL:
            const { ids, dialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog, ids }
            }

        case DisbursementActionTypes.CHANGE_CREATE_MODAL:
            const { data: changedData, dialog: changedDialog } = action.payload
            return {
                ...state,
                create: { ...state.create, dialog: changedDialog, data: changedData }
            }

        case DisbursementActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data, success: false }
            }

        case DisbursementActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    dialog: false,
                    success: true,
                    loading: false,
                    data: new DisbursementTransaction()
                }
            }

        case DisbursementActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case DisbursementActionTypes.RESET:
            return INITIAL_STATE

        case DisbursementActionTypes.LOAD_REQUEST:
            const { paginator: listPaginator } = action.payload
            return { ...state, list: { ...state.list, loading: true, paginator: listPaginator } }

        case DisbursementActionTypes.LOAD_SUCCESS:
            const { data: listData, headers: listHeaders } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    data: listData,
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(listHeaders['x-total-count'], 10)
                    }
                }
            }

        case DisbursementActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: new DailyDisbursementSummary()
                }
            }

        case DisbursementActionTypes.FIND_REQUEST:
            return { ...state, create: { ...state.create, loading: true } }

        case DisbursementActionTypes.FIND_SUCCESS:
            const { data: foundData } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: true,
                    error: false,
                    data: foundData
                }
            }

        case DisbursementActionTypes.FIND_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true
                }
            }

        case DisbursementActionTypes.SYNC_REQUEST:
            return { ...state, list: { ...state.list, loading: true, sync: true } }

        case DisbursementActionTypes.SYNC_SUCCESS:
            const { data: syncData, headers: syncHeaders } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    sync: false,
                    data: syncData,
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(syncHeaders['x-total-count'], 10)
                    }
                }
            }

        case DisbursementActionTypes.SYNC_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    sync: false
                }
            }

        case DisbursementActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case DisbursementActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    ids: []
                }
            }

        case DisbursementActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case DisbursementActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case DisbursementActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: new DisbursementTransaction()
                }
            }

        case DisbursementActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case DisbursementActionTypes.LOAD_BALANCE_REQUEST:
            const { paginator: balancePaginator } = action.payload
            return { ...state, balances: { ...state.balances, loading: true, paginator: balancePaginator } }

        case DisbursementActionTypes.LOAD_BALANCE_SUCCESS:
            const { data: balanceData, headers: balanceHeaders, isFirst, isLast } = action.payload
            const totalRecords = parseInt(balanceHeaders['x-total-count'], 10)
            const paginator = {
                ...state.balances.paginator,
                totalRecords
            }
            const resultData = state.balances.data.length ?
                state.balances.data.concat(balanceData)
                : balanceData

            if (isFirst && isLast) {
                return {
                    ...state,
                    balances: {
                        ...state.balances,
                        loading: false,
                        success: true,
                        error: false,
                        data: balanceData,
                        paginator
                    }
                }
            }

            if (isFirst) {
                return {
                    ...state,
                    balances: {
                        ...state.balances,
                        data: balanceData,
                        paginator
                    }
                }
            }

            if (isLast) {
                return {
                    ...state,
                    balances: {
                        ...state.balances,
                        loading: false,
                        success: true,
                        error: false,
                        data: resultData,
                        paginator
                    }
                }
            }

            return {
                ...state,
                balances: {
                    ...state.balances,
                    data: resultData,
                    paginator
                }
            }

        case DisbursementActionTypes.LOAD_BALANCE_FAILURE:
            return {
                ...state,
                balances: {
                    ...state.balances,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementActionTypes.LOAD_CONFERENCE_REQUEST:
            const { paginator: conferenceRequestPaginator } = action.payload
            return {
                ...state,
                conference: { ...state.conference, loading: true, paginator: conferenceRequestPaginator }
            }

        case DisbursementActionTypes.LOAD_CONFERENCE_SUCCESS:
            const {
                data: conferenceData,
                headers: conferenceHeaders,
                isFirst: conferenceIsFirst,
                isLast: conferenceIsLast
            } = action.payload
            const conferenceTotalRecords = parseInt(conferenceHeaders['x-total-count'], 10)
            const conferencePaginator = {
                ...state.conference.paginator,
                totalRecords: conferenceTotalRecords
            }
            const resultConferenceData = state.conference.data.length ?
                state.conference.data.concat(conferenceData)
                : conferenceData

            if (conferenceIsFirst && conferenceIsLast) {
                return {
                    ...state,
                    conference: {
                        ...state.conference,
                        loading: false,
                        success: true,
                        error: false,
                        data: conferenceData,
                        paginator: conferencePaginator
                    }
                }
            }

            if (conferenceIsFirst) {
                return {
                    ...state,
                    conference: {
                        ...state.conference,
                        data: conferenceData,
                        paginator: conferencePaginator
                    }
                }
            }

            if (conferenceIsLast) {
                return {
                    ...state,
                    conference: {
                        ...state.conference,
                        loading: false,
                        success: true,
                        error: false,
                        data: resultConferenceData,
                        paginator: conferencePaginator
                    }
                }
            }

            return {
                ...state,
                conference: {
                    ...state.conference,
                    data: resultConferenceData,
                    paginator: conferencePaginator
                }
            }

        case DisbursementActionTypes.LOAD_CONFERENCE_FAILURE:
            return {
                ...state,
                conference: {
                    ...state.conference,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementActionTypes.REPORT_REQUEST:
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true,
                    error: false

                }
            }

        case DisbursementActionTypes.REPORT_SUCCESS:
            const { data: reportData } = action.payload
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    error: false,
                    success: true,
                    data: reportData
                }
            }

        case DisbursementActionTypes.REPORT_FAILURE:
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state;
    }
}

export default reducer
