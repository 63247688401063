import { action } from 'typesafe-actions'
import { AuditActionTypes } from './types'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Audit from '../../application/models/audits/audit'
import User from '../../application/models/auth/user'

/* Actions for Reset Create Audit */
export const resetCreateAudit = () => action(AuditActionTypes.RESET_CREATE_AUDIT, {})

/* Actions for Change Audit */
export const changeAudit = (audit: Audit) => action(AuditActionTypes.CHANGE_AUDIT, { audit })

export const changeRemoveModal = (
    visibilityModal: boolean,
    idForRemove: string
) =>
    action(AuditActionTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove
    })

export const changePaginator = (paginator: IPaginator) => [
    action(AuditActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    AuditActionTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

/* Actions for Find Audits */
export const findRequest = (AuditId: string) =>
    action(AuditActionTypes.FIND_REQUEST, { AuditId })

export const findSuccess = (audit: Audit) => action(AuditActionTypes.FIND_SUCCESS, { audit })

export const findFailure = (error: ErrorEvent) => action(AuditActionTypes.FIND_FAILURE, { error })

/* Actions for Load Audits */
export const loadRequest = (paginator?: IPaginator) => action(AuditActionTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<Audit[]>) =>
    action(AuditActionTypes.LOAD_SUCCESS, {
        audits: response.data,
        headers: response.headers
    })

export const loadFailure = (error: ErrorEvent) => action(AuditActionTypes.LOAD_FAILURE, { error })

export const loadUsersRequest = () => action(AuditActionTypes.LOAD_USER_REQUEST, {})

export const loadUsersSuccess = (response: User[]) => action(AuditActionTypes.LOAD_USER_SUCCESS, {
    users: response
})

export const loadUsersFailure = (error: ErrorEvent) => action(AuditActionTypes.LOAD_USER_FAILURE, {
    error
})
