import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import '../container.style.scss'
import { IApplicationState } from '../../store'
import * as PrevisionActions from '../../store/ducks/prevision/actions'
import { Margin } from '../costing/costing.by.institution'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { IRemoveState } from '../../store/ducks/prevision/types'
import Spinner from '../../components/spinner/spinner'
import { IPaginator } from '../../store/ducks/root.types'
import { PrevisionTransactionTypes } from '../../store/application/utils/prevision.transaction.types'

interface IState {
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
}

interface IDispatchProps extends RouteComponentProps<any> {
    resetPrevision(): void

    changeRemoveModal(
        visibilityModal: boolean,
        financialAgentId: string,
        idForRemove: string,
        transactionType: PrevisionTransactionTypes,
        paginator?: IPaginator
    ): void

    remove(
        financialAgentId: string,
        idForRemove: string,
        transactionType: PrevisionTransactionTypes,
        paginator?: IPaginator): void
}

type IUnionProps = IState & IRemoveState & IDispatchProps

class PrevisionRemove extends Component<IUnionProps> {

    public render() {

        const {
            changeRemoveModal,
            remove,
            financialAgentId,
            idForRemove,
            removeVisibilityModal,
            loading,
            transactionType,
            paginator
        } = this.props

        const footer = (
            <div className="d-flex justify-content-around">
                <Button
                    className="p-button-primary"
                    label="Sim"
                    icon="pi pi-check"
                    onClick={() => remove(financialAgentId, idForRemove, transactionType, paginator)}
                />

                <Button
                    className="p-button-secondary"
                    label="Não"
                    icon="pi pi-times"
                    onClick={() => changeRemoveModal(!removeVisibilityModal, '', '', transactionType, paginator)}
                />
            </div>
        )

        return <React.Fragment>
            {loading && <Spinner message="Removendo previsão..."/>}
            <Dialog
                header="Remover Previsão"
                footer={footer}
                visible={removeVisibilityModal}
                closeOnEscape={true}
                className="dialog max-width-700"
                modal={true}
                onHide={() => changeRemoveModal(false, '', '', transactionType, paginator)}>
                <Margin top={10} bottom={10} left={10} right={10}>
                    Tem certeza que deseja excluir definitivamente a previsão selecionada?
                </Margin>
            </Dialog>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    financialAgentId: state.prevision.financialAgentId,
    idForRemove: state.prevision.remove.idForRemove,
    removeVisibilityModal: state.prevision.remove.removeVisibilityModal,
    error: state.prevision.remove.error,
    success: state.prevision.remove.success,
    loading: state.prevision.remove.loading,
    transactionType: state.prevision.transactionType,
    paginator: state.prevision.remove.paginator
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(PrevisionActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrevisionRemove))
