import React, { Component } from 'react'
import { IPaginator, ISearch } from '../../store/ducks/root.types'
import { RouteComponentProps } from 'react-router-dom'

interface IExpenseContainerState {
    readonly paginator: IPaginator
    readonly institution: any
}

interface IExpenseContainerDispatch extends RouteComponentProps<any> {
    changePaginator(institutionId: string, paginator: IPaginator): void

    changeSearchPaginator(search: ISearch): void
}

type InnerProps = IExpenseContainerState & IExpenseContainerDispatch

abstract class ExpenseContainerFilter<IProps extends InnerProps, IState> extends Component<IProps, IState> {
    protected searchTime: any

    constructor(props: IProps) {
        super(props)
        this.setSearchTime = this.setSearchTime.bind(this)
        this.cleansPaginator = this.cleansPaginator.bind(this)
        this.messageListEmpty = this.messageListEmpty.bind(this)

    }

    protected setSearchTime(key: string, event): void {
        clearTimeout(this.searchTime)
        const value = event.target.value
        const { changeSearchPaginator, changePaginator, institution, paginator } = this.props
        changeSearchPaginator({ key, value })
        this.searchTime = setTimeout(
            () => {
                changePaginator(
                    institution.id, {
                        ...paginator,
                        search: {
                            key,
                            value
                        }
                    })
            }, 500
        )
    }

    protected cleansPaginator(): void {
        const { changePaginator, institution, paginator, changeSearchPaginator } = this.props
        const key = ''
        const value = null
        changeSearchPaginator({ key, value: value ? value : '' })
        if (paginator?.search?.key && paginator?.search?.value) {
            changePaginator(institution.id, {
                ...paginator,
                search: {
                    key,
                    value: value ? value : ''
                }
            })
        }
    }

    protected messageListEmpty(paginator: IPaginator, value: string): React.ReactNode {
        return <div className="center">
            {paginator?.search?.value ? `Nenhum registro encontrado para o filtro: ${value}`
                : 'Nenhum registro encontrado'}
        </div>
    }

    protected abstract getMessageListEmpty(): React.ReactNode
}

export default ExpenseContainerFilter
