import httpClient from './config.axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import Costing from '../store/application/models/transactions/costing/costing'
import { IPaginator } from '../store/ducks/costing/types'
import { ApiService } from './api'

class CostingService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(costing: Costing): Promise<IAxiosResponse<Costing>> {
        return httpClient.post(`${this.apiVersion}/institutions/${costing.institution_id}/costs`, costing.toJSON())
    }

    public getById(institutionId: string, costingId: string): Promise<IAxiosResponse<Costing>> {
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/costs/${costingId}`)
    }

    public getAll(institutionId: string, paginator?: IPaginator): Promise<IAxiosResponse<Costing[]>> {
        const params = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/costs`, { params })
    }

    public update(costing: Costing): Promise<IAxiosResponse<Costing>> {
        const body = costing.toJSON()
        delete body.date
        const url = `${this.apiVersion}/institutions/${costing.institution_id}/costs/${costing.id}`
        return httpClient.patch(url, body )
    }

    public remove(institutionId: string, costingId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/institutions/${institutionId}/costs/${costingId}`)
    }
}

export default new CostingService()

