import axiosInstance from './config.axios'
import jwt from 'jsonwebtoken'
import localStorageService from './local.storage'
import sessionStorageService from './session.storage'
import { IAccessToken, IAuth } from '../store/ducks/auth/types'

export enum LogicalStrategy {
    OR = 'or',
    AND = 'and'
}

export class AuthService {

    constructor(private apiVersion: string = 'v1') {
    }

    public login(body: IAuth): Promise<IAccessToken> {
        return axiosInstance
            .post(`${this.apiVersion}/auth`, body)
            .then(response => {
                const { access_token } = response.data
                localStorageService.setItem('access_token', access_token)
                return this.decodeJWTToken(access_token)
            })
    }

    public async loginByCert(): Promise<any> {

        const casResponse = (await axiosInstance.get<any>(process.env.REACT_APP_CAS_CERT_AUTH)).data

        return axiosInstance.post(`${this.apiVersion}/auth/certificate`, casResponse)
            .then(response => {
                const { access_token } = response.data
                localStorageService.setItem('access_token', access_token)
                sessionStorageService.setItem('loginByCertificate', 'true')
                return access_token
            })
    }

    public decodeToken(): IAccessToken {
        const token = localStorageService.getItem('access_token')
        return this.decodeJWTToken(token)
    }

    public isAuthenticated(): boolean {
        try {
            const localToken = this.decodeToken()
            if (!this.accessTokenIsValid(localToken)) {
                throw new Error()
            }
            return !!localToken
        } catch (e) {
            this.logout()
            return false
        }
    }

    public logout(): void {
        localStorageService.logout()
    }

    public decodeJWTToken(token: string): any {
        const jwtPublicKey = process.env.REACT_APP_JWT_PUBLIC_KEY
        return jwt.verify(token, jwtPublicKey, { algorithms: ['RS256'] })
    }

    public accessTokenIsValid(token: IAccessToken): boolean {
        const issuer = process.env.REACT_APP_ISSUER
        return (token.exp * 1000 >= Date.now()) && (token.iss === issuer)
    }
}

export default new AuthService()
