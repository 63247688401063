import { IComponentState, IPaginator } from '../root.types'
import Exercise from '../../application/models/exercise/exercise'

/**
 * Action types
 */
export enum ExerciseTypes {
    ADD_CHECKED = '@exercise/ADD_CHECKED',
    RESET_CHECKED = '@exercise/RESET_CHECKED',

    CHANGE = '@exercise/CHANGE',
    CHANGE_PAGINATOR = '@exercise/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@exercise/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@exercise/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_REVENUE_PREVISION = '@exercise/CHANGE_DIALOG_REVENUE_PREVISION',
    CHANGE_DIALOG_PREVISION = '@exercise/CHANGE_DIALOG_PREVISION',
    CHANGE_DIALOG_BUDGET = '@exercise/CHANGE_DIALOG_BUDGET',
    CHANGE_DIALOG_BLOCKED = '@exercise/CHANGE_DIALOG_BLOCKED',

    CREATE_REQUEST = '@exercise/CREATE_REQUEST',
    CREATE_SUCCESS = '@exercise/CREATE_SUCCESS',
    CREATE_FAILURE = '@exercise/CREATE_FAILURE',

    LOAD_REQUEST = '@exercise/LOAD_REQUEST',
    LOAD_SUCCESS = '@exercise/LOAD_SUCCESS',
    LOAD_FAILURE = '@exercise/LOAD_FAILURE',

    REMOVE_REQUEST = '@exercise/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@exercise/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@exercise/REMOVE_FAILURE',

    LOAD_LAST_REQUEST = '@exercise/LOAD_LAST_REQUEST',
    LOAD_LAST_SUCCESS = '@exercise/LOAD_LAST_SUCCESS',
    LOAD_LAST_FAILURE = '@exercise/LOAD_LAST_FAILURE'
}

export interface IActionCreate {
    readonly exercise: Exercise
}

export interface IActionLoad {
    readonly paginator?: IPaginator
}

export interface IActionRemove {
    readonly exerciseId: string
    readonly paginator: IPaginator
}

export interface IListState extends IComponentState {
    readonly exercises: Exercise[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
}

export interface ICreateState extends IComponentState {
    readonly exercise: Exercise
    readonly data: ErrorEvent
    readonly payerChecked: string[]
    readonly duodecimosChecked: string[]
    readonly payeeChecked: string[]
    readonly institutionsChecked: { cost: string[], debt: string[], investment: string[], event: string[] }
    readonly dialogRevenuePrevision: boolean
    readonly dialogPrevision: boolean
    readonly dialogBudget: boolean
    /* Blocked create new exercise */
    readonly dialogBlocked: boolean
    readonly redirect?: string
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly visibilityModal: boolean
}

/**
 * State type
 */
export interface IExerciseState extends IComponentState {
    readonly exerciseSelected: Exercise
    readonly exercises: Exercise[]
    readonly list: IListState
    readonly create: ICreateState
    readonly remove: IRemoveState
}
