import httpClient from './config.axios'
import { ApiService } from './api'
import Revenue from '../store/application/models/transactions/revenue/revenue'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'

class RevenueService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public async create(revenue: Revenue): Promise<IAxiosResponse<Revenue>> {
        return httpClient.post(`${this.apiVersion}/payers/${revenue.payer_id}/revenues`, revenue.toJSON())
    }

    public async getById(payerId: string, revenueId: string): Promise<IAxiosResponse<Revenue>> {
        return httpClient.get(`${this.apiVersion}/payers/${payerId}/revenues/${revenueId}`)
    }

    public async getAll(payerId: string, paginator?: IPaginator): Promise<IAxiosResponse<Revenue[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/payers/${payerId}/revenues`, { params })
    }

    public async update(revenue: Revenue): Promise<IAxiosResponse<Revenue>> {
        const body = revenue.toJSON()
        delete body.date
        return httpClient
          .patch(`${this.apiVersion}/payers/${revenue.payer_id}/revenues/${revenue.id}`, body)
    }

    public async remove(payerId: string, revenueId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payers/${payerId}/revenues/${revenueId}`)
    }
}

export default new RevenueService()
