/**
 * Action types
 */

import { IComponentState, IPaginator } from '../root.types'
import { ConferenceItem } from '../../application/models/disbursement/conference.item'

export enum ConferenceRevenueActionTypes {
    RESET = '@conference.revenue/RESET',

    CHANGE_CREATE_DIALOG = '@conference.revenue/CHANGE_CREATE_DIALOG',

    CREATE_REQUEST = '@conference.revenue/CREATE_REQUEST',
    CREATE_SUCCESS = '@conference.revenue/CREATE_SUCCESS',
    CREATE_FAILURE = '@conference.revenue/CREATE_FAILURE',

    LOAD_REQUEST = '@conference.revenue/LOAD_REQUEST',
    LOAD_SUCCESS = '@conference.revenue/LOAD_SUCCESS',
    LOAD_FAILURE = '@conference.revenue/LOAD_FAILURE',

    UPDATE_REQUEST = '@conference.revenue/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@conference.revenue/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@conference.revenue/UPDATE_FAILURE',

    CHANGE_REMOVE_DIALOG = '@conference.revenue/CHANGE_REMOVE_DIALOG',

    REMOVE_REQUEST = '@conference.revenue/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@conference.revenue/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@conference.revenue/REMOVE_FAILURE'
}

/* Actions  */

export interface IActionCreate {
    readonly data: ConferenceItem
}

export interface IActionLoad {
    readonly year: number
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly ids: string[]
}

/* States */
interface ICreateState extends IComponentState {
    readonly data: ConferenceItem
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly data: ConferenceItem[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly ids: string[]
}

/**
 * State type
 */
export interface IConferenceRevenueState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
