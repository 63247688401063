import { AnnualFinancialAgent } from '../annual.financial.agent'
import Payer, { NonOperatingRevenueCategory, RevenueCategory } from './payer'

export class AnnualPayer extends AnnualFinancialAgent {
    private _payer: Payer | undefined

    get id(): string | undefined {
        return this._payer?.id
    }

    get revenue_category(): RevenueCategory | undefined {
        return this._payer?.revenue_category
    }

    get name(): string | undefined {
        return this._payer?.name
    }

    get has_nop_revenues(): boolean | undefined {
        return this._payer?.has_nop_revenues
    }

    get nop_revenue_category(): NonOperatingRevenueCategory | undefined {
        return this._payer?.nop_revenue_category
    }

    public fromJSON(json: any): AnnualPayer {
        super.fromJSON(json)

        if (json) {
            this._payer = new Payer().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...this._payer?.toJSON(),
            ...super.toJSON()
        }
    }

}
