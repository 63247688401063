import React, { Component } from 'react'

interface IProps {
    readonly createdAt: string | undefined
    readonly style?: object
}

export default class CreatedAt extends Component<IProps> {
    public render() {
        const { createdAt, style } = this.props
        if (createdAt) {
            const date = new Date(createdAt)
            const styleSelected: any = style ?
                style
                : { position: 'absolute', top: '15%', right: '4%', fontSize: '12px' }
            return <React.Fragment>
                {
                    date && <label
                        style={styleSelected}>
                        Criado em: {date.toLocaleDateString('pt-BR')} às {date.toLocaleTimeString('pt-BR')}
                    </label>
                }
            </React.Fragment>
        }
        return null
    }
}
