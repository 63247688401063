import { IComponentState, IPaginator } from '../root.types'
import Debt from '../../application/models/transactions/debt/debt'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import DebtAnnualSummary from '../../application/models/transactions/debt/debt.annual.summary'
import { TransactionLocation } from '../costing/types'

/**
 * Action types
 */
export enum DebtActionTypes {
    RESET = '@debt/RESET',
    RESET_MONTHLY_PAGINATOR = '@debt/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@debt/RESET_ANNUAL_PAGINATOR',

    CHANGE_DEBT = '@debt/CHANGE_DEBT',
    CHANGE_DIALOG = '@debt/CHANGE_DIALOG',
    CHANGE_PAGINATOR = '@debt/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@debt/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@debt/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@debt/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@debt/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@debt/CREATE_REQUEST',
    CREATE_SUCCESS = '@debt/CREATE_SUCCESS',
    CREATE_FAILURE = '@debt/CREATE_FAILURE',

    LOAD_REQUEST = '@debt/LOAD_REQUEST',
    LOAD_SUCCESS = '@debt/LOAD_SUCCESS',
    LOAD_FAILURE = '@debt/LOAD_FAILURE',

    FIND_REQUEST = '@debt/FIND_REQUEST',
    FIND_SUCCESS = '@debt/FIND_SUCCESS',
    FIND_FAILURE = '@debt/FIND_FAILURE',

    UPDATE_REQUEST = '@debt/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@debt/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@debt/UPDATE_FAILURE',

    REMOVE_REQUEST = '@debt/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@debt/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@debt/REMOVE_FAILURE',

    FIND_INSTITUTION = '@debt/FIND_INSTITUTION',
    FIND_INSTITUTION_SUCCESS = '@debt/FIND_INSTITUTION_SUCCESS',
    FIND_INSTITUTION_FAILURE = '@debt/FIND_INSTITUTION_FAILURE',

    LOAD_MONTHLY = '@debt/LOAD_MONTHLY',
    LOAD_MONTHLY_SUCCESS_RESET = '@debt/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@debt/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@debt/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL = '@debt/LOAD_ANNUAL',
    LOAD_ANNUAL_SUCCESS_RESET = '@debt/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@debt/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@debt/LOAD_ANNUAL_FAILURE',

    LOAD_ANNUAL_DASH = '@debt/LOAD_ANNUAL_DASH',
    LOAD_ANNUAL_DASH_SUCCESS = '@debt/LOAD_ANNUAL_DASH_SUCCESS',
    LOAD_ANNUAL_DASH_FAILURE = '@debt/LOAD_ANNUAL_DASH_FAILURE'
}

export interface IActionCreate {
    readonly debt: Debt
    readonly dateOfInstallments?: string[]
    readonly location: TransactionLocation
}

export interface IActionLoad {
    readonly institutionId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly institutionId: string
    readonly debtId: string
}

export interface IActionRemove {
    readonly institutionId: string
    readonly debtId: string
    readonly paginator: IPaginator
}

interface ICreateState<T> extends IComponentState {
    readonly resource: T
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState<T> extends IComponentState {
    readonly resources: T[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly institution: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly institutionId: string
    readonly visibilityModal: boolean
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyResource: DebtMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

export interface IAnnualDebts {
    readonly january?: DebtMonthlySummary
    readonly february?: DebtMonthlySummary
    readonly march?: DebtMonthlySummary
    readonly april?: DebtMonthlySummary
    readonly may?: DebtMonthlySummary
    readonly june?: DebtMonthlySummary
    readonly july?: DebtMonthlySummary
    readonly august?: DebtMonthlySummary
    readonly september?: DebtMonthlySummary
    readonly october?: DebtMonthlySummary
    readonly november?: DebtMonthlySummary
    readonly december?: DebtMonthlySummary
    readonly total?: number,
    readonly totalDirects?: number,
    readonly totalIndirects?: number
    readonly totalJudicialDecisionsAndAgreements?: number
}

interface IAnnualState extends IComponentState {
    readonly annualResource: IAnnualDebts
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IDashAnnualState extends IComponentState {
    readonly annualResource: DebtAnnualSummary
}

/**
 * State type
 */
export interface IDebtState {
    readonly create: ICreateState<Debt>
    readonly list: IListState<Debt>
    readonly remove: IRemoveState
    readonly monthly: IMonthlyState
    readonly annual: IAnnualState
    readonly dashboard: IDashAnnualState
}
