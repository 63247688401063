import { Reducer } from 'redux'
import { IPayeeState, PayeeTypes } from './types'
import Payee from '../../application/models/financial.agents/payee/payee'

export const INITIAL_STATE: IPayeeState = {
    create: {
        payee: new Payee(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    remove: {
        loading: false,
        error: false,
        success: false,
        removeVisibilityModal: false,
        idForRemove: ''
    },
    list: {
        payees: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 20,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

const reducer: Reducer<IPayeeState> = (state: IPayeeState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case PayeeTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, loading: true, paginator } }

        case PayeeTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }

        case PayeeTypes.CHANGE_PAYEE:
            const createPayee: Payee = action.payload.payee
            return {
                ...state,
                create: { ...state.create, payee: createPayee }
            }

        case PayeeTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case PayeeTypes.CREATE_REQUEST:
            const { payee } = action.payload
            return { ...state, create: { ...state.create, payee, loading: true } }

        case PayeeTypes.CREATE_SUCCESS:
            return { ...state, create: { ...INITIAL_STATE.create, success: true } }

        case PayeeTypes.CREATE_FAILURE:
            const createError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: createError
                }
            }

        case PayeeTypes.UPDATE_REQUEST:
            const { payee: updatedPayee } = action.payload
            return { ...state, create: { ...state.create, payee: updatedPayee, loading: true } }

        case PayeeTypes.UPDATE_SUCCESS:
            return { ...state, create: { ...INITIAL_STATE.create, success: true } }

        case PayeeTypes.UPDATE_FAILURE:
            const updatedError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: updatedError
                }
            }

        case PayeeTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, payees: [], loading: true } }

        case PayeeTypes.LOAD_SUCCESS:
            const { payees, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    payees: payees.map(payeeElement => new Payee().fromJSON(payeeElement)),
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case PayeeTypes.LOAD_FAILURE:
            const loadError = action.payload.error
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case PayeeTypes.RESET_CREATE_PAYEE:
            return { ...state, create: INITIAL_STATE.create }

        case PayeeTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    removeVisibilityModal: visibilityModal,
                    idForRemove
                }
            }

        case PayeeTypes.FIND_REQUEST:
            const { payeeId } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    payee: new Payee().fromJSON({ id: payeeId })
                }
            }

        case PayeeTypes.FIND_SUCCESS:
            const findPayee = action.payload.payee
            return {
                ...state,
                create: {
                    ...state.create,
                    payee: new Payee().fromJSON(findPayee),
                    loading: false,
                    success: true
                }
            }

        case PayeeTypes.FIND_FAILURE:
            const findError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.create,
                    payee: new Payee(),
                    loading: false,
                    error: findError
                }
            }

        case PayeeTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    removeVisibilityModal: false
                }
            }

        case PayeeTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: { ...state.remove, loading: false, success: true }
            }

        case PayeeTypes.REMOVE_FAILURE:
            const removeError = action.payload.error
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: false,
                    error: true,
                    data: removeError
                }
            }

        case PayeeTypes.LOAD_DUODECIMOS:
            return {
                ...state,
                list: {
                    ...state.list,
                    payees: [],
                    loading: true
                }
            }

        case PayeeTypes.LOAD_DUODECIMOS_SUCCESS:
            const { payees: duodecimos, headers: duodecimoHeaders } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: false,
                    payees: duodecimos.map(element => new Payee().fromJSON(element)),
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(duodecimoHeaders['x-total-count'], 10)
                    }
                }
            }

        case PayeeTypes.LOAD_DUODECIMOS_FAILURE:
            const { error: duodecimosError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: duodecimosError
                }
            }

        default:
            return state
    }
}

export default reducer
