import { action } from 'typesafe-actions'
import { DisbursementActionTypes } from './types'
import { DisbursementTransactionType } from '../../application/utils/disbursement.transaction.type'
import { DisbursementTransaction } from '../../application/models/disbursement/disbursement.transaction'
import { DailyDisbursementSummary } from '../../application/models/disbursement/daily.disbursement.summary'
import { IAxiosResponse, IPaginator } from '../root.types'
import { YearDisbursementSummaryItem } from '../../application/models/disbursement/year.disbursement.summary.item'
import { DisbursementConferenceItem } from '../../application/models/disbursement/conference.disbursement.item'
import { loadRequest as loadConferenceRevenue } from '../conference.revenue/actions'
import { loadRequest as loadConferenceExpense } from '../conference.expense/actions'
import { loadRequest as loadDisbursementReportBalance } from '../disbursement.report.balance/actions'
import { DisbursementReport } from '../../application/models/disbursement/disbursement.report'
import { DisbursementInputTransferLog } from '../../application/models/disbursement/disbursement.input.transfer.log'

/* Actions for Reset State */
export const reset = () => action(DisbursementActionTypes.RESET)

export const change = (data: DisbursementTransaction | DisbursementInputTransferLog) => action(
    DisbursementActionTypes.CHANGE, { data }
)

export const changeCreateModal = (dialog: boolean, data: DisbursementTransaction | DisbursementInputTransferLog) => action(
    DisbursementActionTypes.CHANGE_CREATE_MODAL, { dialog, data }
)

export const changeRemoveModal = (dialog: boolean, ids: string[]) => action(
    DisbursementActionTypes.CHANGE_REMOVE_MODAL, { dialog, ids }
)

/* Actions for Create Disbursement Transaction */
export const create = (data: DisbursementTransaction) => action(
    DisbursementActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: DisbursementTransaction | DisbursementInputTransferLog) => {
    const result: any[] = [action(DisbursementActionTypes.CREATE_SUCCESS, { data })]
    if (data instanceof DisbursementTransaction) {
        result.push(load(data.transaction_type!, data.date!))
    }
    if (data instanceof DisbursementInputTransferLog) {
        result.push(load(DisbursementTransactionType.INPUT_TRANSFER, data.date!))
    }
    return result
}

export const createFailure = () => action(DisbursementActionTypes.CREATE_FAILURE)


/* Actions for Load Disbursement transaction */
export const findRequest = (type: DisbursementTransactionType, id: string) => action(
    DisbursementActionTypes.FIND_REQUEST,
    { id, type }
)

export const findSuccess = (data: DisbursementTransaction | DisbursementInputTransferLog) => action(
    DisbursementActionTypes.FIND_SUCCESS,
    { data }
)

export const findFailure = () => action(DisbursementActionTypes.FIND_FAILURE)


/* Actions for Load Disbursement transaction */
export const load = (
    type: DisbursementTransactionType,
    date: string,
    paginator?: IPaginator
) => action(
    DisbursementActionTypes.LOAD_REQUEST,
    { type, date, paginator }
)

export const loadSuccess = (response: IAxiosResponse<DailyDisbursementSummary>) => action(
    DisbursementActionTypes.LOAD_SUCCESS,
    { data: response.data, headers: response.headers }
)

export const loadFailure = () => action(DisbursementActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement type */
export const remove = (type: DisbursementTransactionType, ids: string[], date: string) => action(
    DisbursementActionTypes.REMOVE_REQUEST, {
        type,
        ids,
        date
    }
)

export const removeSuccess = (type: DisbursementTransactionType, date: string) => [
    action(DisbursementActionTypes.REMOVE_SUCCESS),
    load(type, date)
]

export const removeFailure = () => action(DisbursementActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement transaction */
export const update = (data: DisbursementTransaction | DisbursementInputTransferLog) => action(
    DisbursementActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: DisbursementTransaction | DisbursementInputTransferLog) => {
    const result: any[] = [action(DisbursementActionTypes.UPDATE_SUCCESS, { data })]
    if (data instanceof DisbursementTransaction) {
        result.push(load(data.transaction_type!, data.date!))
    }
    if (data instanceof DisbursementInputTransferLog) {
        result.push(load(DisbursementTransactionType.INPUT_TRANSFER, data.date!))
    }
    return result
}

export const updateFailure = () => action(DisbursementActionTypes.UPDATE_FAILURE)

/* Actions for Load Disbursement balance */
export const loadBalance = (year: string, paginator?: IPaginator, startDate?: string, endDate?: string) => action(
    DisbursementActionTypes.LOAD_BALANCE_REQUEST,
    { year, paginator, startDate, endDate }
)

export const loadBalanceSuccess = (
    response: IAxiosResponse<YearDisbursementSummaryItem[]>,
    isFirst: boolean,
    isLast: boolean
) => action(
    DisbursementActionTypes.LOAD_BALANCE_SUCCESS,
    { data: response.data, headers: response.headers, isFirst, isLast }
)

export const loadBalanceFailure = () => action(DisbursementActionTypes.LOAD_BALANCE_FAILURE)

/* Actions for Load Disbursement conference */
export const loadConference = (year: string, paginator?: IPaginator) => [
    action(
        DisbursementActionTypes.LOAD_CONFERENCE_REQUEST,
        { year, paginator }
    ),
    loadConferenceRevenue(parseInt(year, 10), paginator),
    loadConferenceExpense(parseInt(year, 10), paginator)
]
export const loadConferenceSuccess = (
    response: IAxiosResponse<DisbursementConferenceItem[]>,
    isFirst: boolean,
    isLast: boolean
) => action(
    DisbursementActionTypes.LOAD_CONFERENCE_SUCCESS,
    { data: response.data, headers: response.headers, isFirst, isLast }
)

export const loadConferenceFailure = () => action(DisbursementActionTypes.LOAD_CONFERENCE_FAILURE)

/* Actions for Sync Disbursement transaction */
export const syncRequest = (date: string) => action(
    DisbursementActionTypes.SYNC_REQUEST,
    { date }
)

export const syncSuccess = (response: IAxiosResponse<DailyDisbursementSummary>) => action(
    DisbursementActionTypes.SYNC_SUCCESS,
    { data: response.data, headers: response.headers }
)

export const syncFailure = () => action(DisbursementActionTypes.SYNC_FAILURE)

/* Actions for Load Disbursement Report */
export const reportRequest = (date: string) => [
    action(DisbursementActionTypes.REPORT_REQUEST, { date }),
    loadDisbursementReportBalance(date)
]

export const reportSuccess = (data: DisbursementReport) => action(
    DisbursementActionTypes.REPORT_SUCCESS,
    { data }
)

export const reportFailure = () => action(DisbursementActionTypes.REPORT_FAILURE)
