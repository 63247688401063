import { AxiosRequestConfig } from 'axios'
import localStorageService from './local.storage'
import { Toast } from './toast'
import authService from './auth'
import { history } from '../store'
import { ERROR_400_EXCEPTION } from '../store/application/models/auth/user'
import MultiStatusItem from '../store/application/models/multi.status/multi.status.item'

const toast = Toast.getInstance()

export default class Interceptors {
    /* Request Interceptors
    *  Used to inject in the request header the access token saved in localStorage
    * */
    public static injectAccessToken(request: AxiosRequestConfig): AxiosRequestConfig {
        const token = localStorageService.getItem('access_token')
        if (token) {
            request.headers.Authorization = token ? `Bearer ${token}` : ''
        }
        return request
    }

    /* Response Interceptors
    *  Used to centralize the triggering of error messages returned by banckend services
    * */
    public static handlerError(error): Promise<Error> {
        let title = ''
        let message = ''
        switch (error?.response?.status) {
            case 400:
                /**
                 *  Exceptional error, occurred when the user has valid access data,
                 *  but does not have a set of permissions at the base of the system
                 *  In this situation you should not show the toast
                 */
                const responseError: MultiStatusItem<void> = error.response.data
                if (
                    responseError.code !== ERROR_400_EXCEPTION.code &&
                    responseError.message !== ERROR_400_EXCEPTION.message &&
                    responseError.description !== ERROR_400_EXCEPTION.description
                ) {
                    title = 'DADOS INCORRETOS'
                    message = 'Verifique os dados informados e tente novamente.'
                }
                break
            case 401:
                title = 'NÃO AUTENTICADO'
                message = 'Usuário não está devidamente autenticado.'
                authService.logout()
                history.push('/')
                break
            case 403:
                title = 'NÃO AUTORIZADO'
                message = 'Usuário não possui permissão para acessar o recurso solicitado.'
                break
            case 404:
                title = 'NÃO ENCONTRADO'
                message = 'Recurso solicitado encontra-se indisponível ou inexistente.'
                break
            case 409:
                title = 'DADOS DUPLICADOS'
                message = 'Ocorreram conflitos com dados que não pode ser duplicados.'
                break
            case 429:
                title = 'TENTATIVAS EXCEDIDAS'
                message = 'Aguarde o tempo de 1 hora e tente novamente.'
                break
            case 500:
                title = 'ERRO INTERNO DO SERVIDOR'
                message = 'Ocorreu um erro durante a operação, tente novamente.'
                break
            case 502:
                title = 'SERVIÇO INDISPONÍVEL'
                message = 'Serviço solicitado encontra-se indisponível, contate o administrador.'
                break
            default:
                title = 'FALHA DURANTE A OPERAÇÃO'
                message = 'Um erro não esperado ocorreu durante a operação, tente novamente.'
                if (error?.message === 'Network Error') {
                    title = 'FALHA NA CONEXÃO'
                    message = 'Verifique sua conexão e tente novamente.'
                }
                break
        }
        if (title || message) {
            toast.show('error', title, message)
        }
        return error?.response?.data ? Promise.reject(error.response.data) : Promise.reject(error)
    }

}
