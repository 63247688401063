import { Reducer } from 'redux'
import { AuditActionTypes, IAuditState } from './types' // { IEvent }
import Audit from '../../application/models/audits/audit'

const INITIAL_STATE: IAuditState = {
    list: {
        audits: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    listUsers: {
        users: [],
        loading: false,
        error: false,
        success: false
    }

}

const reducer: Reducer<IAuditState> = (state: IAuditState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case AuditActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, loading: true, paginator } }

        case AuditActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }


        case AuditActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case AuditActionTypes.LOAD_SUCCESS:
            const { audits, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    audits: audits.map(el => new Audit().fromJSON(el)),
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case AuditActionTypes.LOAD_FAILURE:
            const loadError = action.payload.error
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }


        case AuditActionTypes.FIND_REQUEST:
            const { auditId } = action.payload
            return {
                ...state,
                create: {
                    ...state.list,
                    loading: true,
                    audits: new Audit().fromJSON({ id: auditId })
                }
            }

        case AuditActionTypes.FIND_SUCCESS:
            const findAudit = action.payload.audit
            return {
                ...state,
                create: {
                    ...state.list,
                    audit: new Audit().fromJSON(findAudit),
                    loading: false,
                    success: true
                }
            }

        case AuditActionTypes.FIND_FAILURE:
            const findError = action.payload.error
            return {
                ...state,
                create: {
                    ...state.list,
                    audit: new Audit(),
                    loading: false,
                    error: findError
                }
            }

        case AuditActionTypes.LOAD_USER_REQUEST:
            return {
                ...state,
                listUsers: {
                    ...state.listUsers,
                    loading: true
                }
            }

        case AuditActionTypes.LOAD_USER_SUCCESS:
            const { users } = action.payload
            return {
                ...state,
                listUsers: {
                    ...state.listUsers,
                    loading: false,
                    success: true,
                    error: false,
                    users
                }
            }

        case AuditActionTypes.LOAD_USER_FAILURE:
            const { error: userError } = action.payload
            return {
                ...state,
                listUsers: {
                    ...state.listUsers,
                    loading: false,
                    error: true,
                    data: userError
                }
            }

        default:
            return state
    }
}

export default reducer
