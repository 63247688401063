import BalanceAgent, { AgentCategory } from './balance.agent'
import Balance, { BalanceType } from './balance'
import { JsonUtils } from '../../utils/json.util'

export default class BalanceSummaryItem {
    private _balances: Balance[] | undefined
    private _balance_agent: BalanceAgent | undefined

    constructor() {
        this._balances = []
        this._balance_agent = new BalanceAgent()
    }

    get balances(): Balance[] | undefined {
        return this._balances
    }

    set balances(value: Balance[] | undefined) {
        this._balances = value
    }

    get balance_agent(): BalanceAgent | undefined {
        return this._balance_agent
    }

    set balance_agent(value: BalanceAgent | undefined) {
        this._balance_agent = value
    }

    get annualLastBalance(): Balance {
        const ordered: Balance[] | undefined = this.balances
            ?.sort((prev: Balance, current: Balance) => {
                const prevValue = prev?.date ? parseInt(prev.date.substr(5, 6), 10) : 0
                const currentValue = current?.date ? parseInt(current.date.substr(5, 6), 10) : 0
                if (prevValue > currentValue) {
                    return 1
                }
                if (prevValue < currentValue) {
                    return -1
                }
                return 0
            })
        const values = ordered?.filter((balance: Balance) => balance.value)
        return values?.length ? values[values?.length - 1] : new Balance()
    }

    get monthlyLastBalance(): Balance {
        const ordered: Balance[] | undefined = this.balances
            ?.sort((prev: Balance, current: Balance) => {
                const prevValue = prev?.date ? parseInt(prev.date.substr(8, 9), 10) : 0
                const currentValue = current?.date ? parseInt(current.date.substr(8, 9), 10) : 0
                if (prevValue > currentValue) {
                    return 1
                }
                if (prevValue < currentValue) {
                    return -1
                }
                return 0
            })
        return ordered?.length ? ordered[ordered?.length - 1] : new Balance()
    }

    get id(): string | undefined {
        return this.balance_agent?.id
    }

    get name(): string | undefined {
        return this.balance_agent?.name
    }

    get balance_category(): BalanceType | undefined {
        return this.balance_agent?.balance_category
    }

    get agent_category(): AgentCategory | undefined {
        return this.balance_agent?.agent_category
    }

    public fromJSON(json: any): BalanceSummaryItem {

        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.balances !== undefined) {
            this._balances = json.balances.map(balance => new Balance().fromJSON(balance))
        }

        if (json !== undefined) {
            this._balance_agent = new BalanceAgent().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...this.balance_agent?.toJSON(),
            balances: this.balances ?
                this.balances.map(balance => balance.toJSON()) : []
        }
    }
}
