import { all, apply, call, put, takeLatest } from 'redux-saga/effects'

import {
    createFailure,
    createSuccess,
    findFailure,
    findPayeeFailure,
    findPayeeSuccess,
    findSuccess,
    load,
    loadAnnualFailure,
    loadAnnualSuccessConcat,
    loadAnnualSuccessReset,
    loadFailure,
    loadMonthly,
    loadMonthlyFailure,
    loadMonthlySuccessConcat,
    loadMonthlySuccessReset,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionAnnual, IActionMonthly, IActionType } from '../root.types'
import { Toast } from '../../../services/toast'
import {
    ExpenseActionTypes,
    ExpenseLocation,
    IActionCreate,
    IActionFind,
    IActionFindPayee,
    IActionLoad,
    IActionRemove
} from './types'
import { TransactionService } from '../../../services/transaction'
import expenseService from '../../../services/expense'
import payeeService from '../../../services/payee'
import Expense from '../../application/models/transactions/expense/expense'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import UtilDatePicker from '../../../components/date.picker/utils'
import { AnnualPayee } from '../../application/models/financial.agents/payee/annual.payee'
import { IPaginator } from '../costing/types'

const toastService = Toast.getInstance()

const transactionService = new TransactionService<AnnualPayee, Expense>()

function* refreshByLocation(location: ExpenseLocation, payeeId: string, date: string, paginator?: IPaginator) {
    location === ExpenseLocation.BY_PAYEE ?
        yield put(load(payeeId, paginator))
        : yield put(loadMonthly(date, paginator))
}

function* create(action: IActionType<IActionCreate>) {
    const { expense, location } = action.payload
    try {
        const response = yield apply(expenseService, expenseService.create, [expense])
        yield put<any>(createSuccess(response?.data))
        const payeeId = expense?.payee_id || ''
        const month = expense.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, payeeId, month)
        toastService.show('success', 'Gasto criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err, expense))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { payeeId, paginator } = action.payload
        const response = yield apply(expenseService, expenseService.getAll, [payeeId, paginator])
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { payeeId, expenseId } = action.payload
        const response = yield apply(expenseService, expenseService.getById, [payeeId, expenseId])
        yield put(findSuccess(response))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { payeeId, expenseId, paginator, date, location } = action.payload
        yield apply(expenseService, expenseService.remove, [payeeId, expenseId])
        yield put(removeSuccess())
        yield call(refreshByLocation, location, payeeId, date || '', paginator)
        toastService.show('success', 'Gasto removido com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { expense, location } = action.payload
    try {
        const response = yield apply(expenseService, expenseService.update, [expense])
        yield put<any>(updateSuccess(response.data))
        const payeeId = expense?.payee_id || ''
        const month = expense.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, payeeId, month)
        toastService.show('success', 'Gasto atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err, expense))
    }
}

function* getPayee(action: IActionType<IActionFindPayee>) {
    const { payeeId } = action.payload
    try {
        const response = yield apply(payeeService, payeeService.getById, [payeeId])
        yield put(findPayeeSuccess(response))
    } catch (err) {
        yield put(findPayeeFailure(err))
    }
}

function* getMonthly(action: IActionType<IActionMonthly>) {
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.EXPENSE,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadMonthlySuccessConcat(response))
            : yield put(loadMonthlySuccessReset(response))
    } catch (err) {
        yield put(loadMonthlyFailure(err))
    }
}

function* getAnnual(action: IActionType<IActionAnnual>) {
    const { year, paginator } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnual, [TransactionTypes.EXPENSE, year, paginator]
        )
        paginator?.page && paginator?.page !== 0 ?
            yield put(loadAnnualSuccessConcat(response))
            : yield put(loadAnnualSuccessReset(response))
    } catch (err) {
        yield put(loadAnnualFailure(err))
    }
}

export default function* expenseSaga() {
    return yield all([
        takeLatest(ExpenseActionTypes.CREATE_REQUEST, create),
        takeLatest(ExpenseActionTypes.LOAD_REQUEST, getAll),
        takeLatest(ExpenseActionTypes.FIND_REQUEST, getById),
        takeLatest(ExpenseActionTypes.REMOVE_REQUEST, remove),
        takeLatest(ExpenseActionTypes.UPDATE_REQUEST, update),
        takeLatest(ExpenseActionTypes.FIND_PAYEE, getPayee),
        takeLatest(ExpenseActionTypes.LOAD_MONTHLY, getMonthly),
        takeLatest(ExpenseActionTypes.LOAD_ANNUAL, getAnnual)
    ])
}
