import { all, apply, call, put, takeLatest } from 'redux-saga/effects'

import {
    createInvestmentsFailure,
    createInvestmentsSuccess,
    findInstitutionFailure,
    findInstitutionSuccess,
    findInvestmentsFailure,
    findInvestmentsSuccess,
    loadAnnualDashFailure,
    loadAnnualDashSuccess,
    loadInvestments,
    loadInvestmentsAnnualFailure,
    loadInvestmentsAnnualSuccessConcat,
    loadInvestmentsAnnualSuccessReset,
    loadInvestmentsFailure,
    loadInvestmentsMonthly,
    loadInvestmentsMonthlyFailure,
    loadInvestmentsMonthlySuccessConcat,
    loadInvestmentsMonthlySuccessReset,
    loadInvestmentsSuccess,
    removeInvestmentsFailure,
    removeInvestmentsSuccess,
    updateInvestmentsFailure,
    updateInvestmentsSuccess
} from './actions'
import { IActionType, IAxiosResponse } from '../root.types'
import investmentsService from '../../../services/investments'
import { Toast } from '../../../services/toast'
import institutionService from '../../../services/institutions'
import { TransactionService } from '../../../services/transaction'
import Investments from '../../application/models/transactions/investments/investments'
import { TransactionTypes } from '../../application/models/prevision/prevision'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, InvestmentsActionsTypes } from './types'
import UtilDatePicker from '../../../components/date.picker/utils'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import { AnnualInstitution } from '../../application/models/financial.agents/institution/annual.institution'
import Event from '../../application/models/transactions/event/event'
import {
    IActionFindInstitution,
    IActionLoadAnnual,
    IActionLoadMonthly,
    IPaginator,
    TransactionLocation
} from '../costing/types'

const toastService = Toast.getInstance()

function* refreshByLocation(location: TransactionLocation, institutionId: string, date: string, paginator?: IPaginator) {
    location === TransactionLocation.BY_INSTITUTION ?
        yield put(loadInvestments(institutionId, paginator))
        : yield put(loadInvestmentsMonthly(date, paginator))
}

function* create(action: IActionType<IActionCreate>) {
    const { investments, location } = action.payload
    try {
        const response = yield apply(investmentsService, investmentsService.create, [investments])
        yield put<any>(createInvestmentsSuccess(response?.data))
        const institutionId = investments?.institution_id || ''
        const date = investments.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, date)
        toastService.show('success', 'Investimento criado com sucesso', '')
    } catch (err) {
        yield put(createInvestmentsFailure(err, investments))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { institutionId, investmentsId } = action.payload
        const response = yield apply(investmentsService, investmentsService.getById, [institutionId, investmentsId])
        yield put(findInvestmentsSuccess(response))
    } catch (err) {
        yield put(findInvestmentsFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { institutionId, paginator } = action.payload
        const response = yield apply(investmentsService, investmentsService.getAll, [institutionId, paginator])
        yield put(loadInvestmentsSuccess(response))
    } catch (err) {
        yield put(loadInvestmentsFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { institutionId, investmentsId, paginator, date, location } = action.payload
        yield apply(investmentsService, investmentsService.remove, [institutionId, investmentsId])
        yield put(removeInvestmentsSuccess())
        yield call(refreshByLocation, location, institutionId, date || '', paginator)
        toastService.show('success', 'Investimento removido com sucesso', '')
    } catch (err) {
        yield put(removeInvestmentsFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { investments, location } = action.payload
    try {
        const response = yield apply(investmentsService, investmentsService.update, [investments])
        yield put<any>(updateInvestmentsSuccess(response.data))
        const institutionId = investments?.institution_id || ''
        const date = investments.date?.substr(0, 7) || ''
        yield call(refreshByLocation, location, institutionId, date)
        toastService.show('success', 'Investimento atualizado com sucesso', '')
    } catch (err) {
        yield put(updateInvestmentsFailure(err, investments))
    }
}

function* getInstitution(action: IActionType<IActionFindInstitution>) {
    const { institutionId } = action.payload
    try {
        const institution = yield apply(institutionService, institutionService.getById, [institutionId])
        const response: any = { data: institution, headers: {} }
        yield put(findInstitutionSuccess(response))
    } catch (err) {
        yield put(findInstitutionFailure(err))
    }
}

function* getMonthlyInvestments(action: IActionType<IActionLoadMonthly>) {
    const monthlyInvestmentsService = new TransactionService<AnnualInstitution, Investments>()
    const { date, paginator } = action.payload
    try {
        const response = yield apply(
            monthlyInvestmentsService,
            monthlyInvestmentsService.getMonthly, [
                TransactionTypes.INVESTMENT,
                date ? date : UtilDatePicker.getCurrentMonth(),
                paginator
            ]
        )
        paginator?.page && paginator?.page !== 0
            ? yield put(loadInvestmentsMonthlySuccessConcat(response))
            : yield put(loadInvestmentsMonthlySuccessReset(response))
    } catch (err) {
        yield put(loadInvestmentsMonthlyFailure(err))
    }


}

function* getAnnualInvestments(action: IActionType<IActionLoadAnnual>) {
    const transactionService = new TransactionService<AnnualInstitution, Investments>()
    const { year, paginator } = action.payload
    const responses: Array<IAxiosResponse<InvestmentsMonthlySummary>> = Array.from(
        { length: 12 }, /* 12 months */
        (value, index) => {
            return {
                data: new InvestmentsMonthlySummary(),
                headers: undefined
            }
        }
    )
    try {
        responses[0] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-01` : `${new Date().getFullYear()}-01`,
                paginator
            ])

        responses[1] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-02` : `${new Date().getFullYear()}-02`,
                paginator
            ])

        responses[2] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-03` : `${new Date().getFullYear()}-03`,
                paginator
            ])

        responses[3] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-04` : `${new Date().getFullYear()}-04`,
                paginator
            ])

        responses[4] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-05` : `${new Date().getFullYear()}-05`,
                paginator
            ])

        responses[5] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-06` : `${new Date().getFullYear()}-06`,
                paginator
            ])

        responses[6] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-07` : `${new Date().getFullYear()}-07`,
                paginator
            ])

        responses[7] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-08` : `${new Date().getFullYear()}-08`,
                paginator
            ])

        responses[8] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-09` : `${new Date().getFullYear()}-09`,
                paginator
            ])

        responses[9] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-10` : `${new Date().getFullYear()}-10`,
                paginator
            ])

        responses[10] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-11` : `${new Date().getFullYear()}-11`,
                paginator
            ])

        responses[11] = yield apply(
            transactionService,
            transactionService.getMonthly, [
                TransactionTypes.INVESTMENT,
                year ? `${year}-12` : `${new Date().getFullYear()}-12`,
                paginator
            ])

        const totalCovenant = responses
            .map((response: IAxiosResponse<InvestmentsMonthlySummary>) => response?.data?.total_covenants)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)


        const totalDirectInvestments = responses
            .map((response: IAxiosResponse<InvestmentsMonthlySummary>) => response?.data?.total_direct_investments)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)

        const totalFinanceCharges = responses
            .map((response: IAxiosResponse<InvestmentsMonthlySummary>) => response?.data?.total_finance_charges)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)

        const totalFunds = responses
            .map((response: IAxiosResponse<InvestmentsMonthlySummary>) => response?.data?.total_funds)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)

        const totalPrograms = responses
            .map((response: IAxiosResponse<InvestmentsMonthlySummary>) => response?.data?.total_programs)
            .reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                return (prevValue || 0) + (currentValue || 0)
            }, 0)

        const total = (totalCovenant || 0) +
            (totalDirectInvestments || 0) +
            (totalFinanceCharges || 0) +
            (totalFunds || 0) +
            (totalPrograms || 0)

        const xTotalCount = parseInt(responses[0].headers['x-total-count'], 10)

        const annualInvestment = {
            january: responses[0].data,
            february: responses[1].data,
            march: responses[2].data,
            april: responses[3].data,
            may: responses[4].data,
            june: responses[5].data,
            july: responses[6].data,
            august: responses[7].data,
            september: responses[8].data,
            october: responses[9].data,
            november: responses[10].data,
            december: responses[11].data,
            totalCovenant,
            totalDirectInvestments,
            totalFinanceCharges,
            totalFunds,
            totalPrograms,
            total,
            xTotalCount
        }

        paginator?.page && paginator?.page !== 0
            ? yield put(loadInvestmentsAnnualSuccessConcat({ ...annualInvestment }))
            : yield put(loadInvestmentsAnnualSuccessReset({ ...annualInvestment }))
    } catch (error) {
        yield put(loadInvestmentsAnnualFailure(error))
    }
}

function* getAnnualDash(action: IActionType) {
    const transactionService = new TransactionService<AnnualInstitution, Event>()
    const { year } = action.payload
    try {
        const response = yield apply(
            transactionService,
            transactionService.getAnnual, [
                TransactionTypes.INVESTMENT,
                year ? year : new Date().getFullYear()
            ]
        )
        yield put(loadAnnualDashSuccess(response))
    } catch (err) {
        yield put(loadAnnualDashFailure(err))
    }
}

export default function* investmentSaga() {
    return yield all([
        takeLatest(InvestmentsActionsTypes.CREATE_REQUEST, create),
        takeLatest(InvestmentsActionsTypes.LOAD_REQUEST, getAll),
        takeLatest(InvestmentsActionsTypes.FIND_REQUEST, getById),
        takeLatest(InvestmentsActionsTypes.REMOVE_REQUEST, remove),
        takeLatest(InvestmentsActionsTypes.UPDATE_REQUEST, update),
        takeLatest(InvestmentsActionsTypes.FIND_INSTITUTION_REQUEST, getInstitution),
        takeLatest(InvestmentsActionsTypes.LOAD_MONTHLY_REQUEST, getMonthlyInvestments),
        takeLatest(InvestmentsActionsTypes.LOAD_ANNUAL_REQUEST, getAnnualInvestments),
        takeLatest(InvestmentsActionsTypes.LOAD_ANNUAL_DASH, getAnnualDash)
    ])
}
