import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { Toast } from '../../../services/toast'

import { IActionType, IAxiosResponse } from '../root.types'
import budgetService from '../../../services/budget'
import payeeService from '../../../services/payee'
import { BudgetTypes, IActionCreate, IActionFind, IActionFindPayee, IActionLoad, IActionRemove } from './types'
import {
    createFailure,
    createSuccess,
    findFailure,
    findPayeeFailure,
    findPayeeSuccess,
    findSuccess,
    load,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import Budget from '../../application/models/budget/budget'
import Payee from '../../application/models/financial.agents/payee/payee'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { budget } = action.payload
    try {
        const response: IAxiosResponse<Budget> = yield apply(budgetService, budgetService.create, [budget])
        yield put<any>(createSuccess(response?.data))
        toastService.show('success', 'Orçamento criado com sucesso', '')
    } catch (err) {
        yield put(createFailure(err, budget))
    }
}

function* getById(action: IActionType<IActionFind>) {
    try {
        const { payeeId, budgetId } = action.payload
        const response: IAxiosResponse<Budget> = yield apply(
            budgetService,
            budgetService.getById,
            [payeeId, budgetId]
        )
        yield put(findSuccess(response?.data))
    } catch (err) {
        yield put(findFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { payeeId, paginator } = action.payload
        const response: IAxiosResponse<Budget[]> = yield apply(
            budgetService,
            budgetService.getAll,
            [payeeId, paginator]
        )
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { budget } = action.payload
    try {
        const response: IAxiosResponse<Budget> = yield apply(budgetService, budgetService.update, [budget])
        yield put<any>(updateSuccess(response?.data))
        toastService.show('success', 'Orçamento atualizado com sucesso', '')
    } catch (err) {
        yield put(updateFailure(err, budget))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { payeeId, budgetId, paginator } = action.payload
        yield apply(budgetService, budgetService.remove, [payeeId, budgetId])
        yield put(removeSuccess())
        yield put(load(payeeId, paginator))
        toastService.show('success', 'Orçamento excluído com sucesso', '')
    } catch (err) {
        yield put(removeFailure(err))
    }
}

function* getPayee(action: IActionType<IActionFindPayee>) {
    const { payeeId } = action.payload
    try {
        const response: IAxiosResponse<Payee> = yield apply(
            payeeService,
            payeeService.getById,
            [payeeId]
        )
        yield put(findPayeeSuccess(response?.data))
    } catch (err) {
        yield put(findPayeeFailure(err))
    }
}

export default function* budgetSaga() {
    return yield all([
        takeLatest(BudgetTypes.CREATE_REQUEST, create),
        takeLatest(BudgetTypes.LOAD_REQUEST, getAll),
        takeLatest(BudgetTypes.FIND_REQUEST, getById),
        takeLatest(BudgetTypes.UPDATE_REQUEST, update),
        takeLatest(BudgetTypes.REMOVE_REQUEST, remove),
        takeLatest(BudgetTypes.FIND_PAYEE_REQUEST, getPayee)
    ])
}
