import MultiStatusItem from './multi.status.item'

export class MultiStatus<T> {
    private readonly _success?: Array<MultiStatusItem<T>>
    private readonly _error?: Array<MultiStatusItem<T>>

    get success(): Array<MultiStatusItem<T>> | undefined {
        return this._success
    }

    get error(): Array<MultiStatusItem<T>> | undefined {
        return this._error
    }
}
