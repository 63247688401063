import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import $ from 'jquery'

import './navbar.scss'
import logo from '../../assets/img/logo.svg'
import { Toast } from '../../services/toast'
import userService from '../../services/users'
import UserDropdown from './dropdown'

interface IState {
    username: string,
    collapse: boolean
    toggle: boolean
    toggleState: string
}

interface IDispatchProps extends RouteComponentProps<any> {
    changeCollpase(data: boolean): void

    toggleAction(data: string): void

    changeToggle(data: boolean): void

    changeUsername(data: string): void
}

type Props = IState & IDispatchProps

class Navbar extends Component<Props> {
    private toastService: Toast = Toast.getInstance()

    constructor(props: Props) {
        super(props)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
        this.changeCollapse = this.changeCollapse.bind(this)
        this.showMenu = this.showMenu.bind(this)

        $('#ulSideClick').click(function () {
            if ($(this).hasClass('closed')) {
                $('.navbar-side').animate({ left: '0px' })
                $(this).removeClass('closed')
                $('#page-wrapper').animate({ 'margin-left': '260px' })

            } else {
                $(this).addClass('closed')
                $('.navbar-side').animate({ left: '-260px' })
                $('#page-wrapper').animate({ 'margin-left': '0px' })
            }
        })

        window.addEventListener('resize', this.updateWindowDimensions)
        this.updateWindowDimensions()

        this.loadUserName().then()

    }

    public async loadUserName() {
        try {
            const { changeUsername } = this.props
            const username = await userService.getUserName()
            changeUsername(username)
        } catch (e) {
            if (e.message === 'Não foi possivel buscar nome do usuário!') {
                this.toastService.show('error', 'Error de carregamento', e.message)
            }
        }
    }

    public changeCollapse(): void {
        const { changeCollpase, collapse, toggleAction } = this.props
        collapse ? toggleAction('show') : toggleAction('hidden')
        changeCollpase(!collapse)
    }

    public updateWindowDimensions() {
        const { changeToggle, toggleAction, collapse } = this.props
        const screenWidth = window.innerWidth < 783
        changeToggle(screenWidth)
        if (!collapse) {
            screenWidth ? toggleAction('hidden') : toggleAction('show')
        }
    }

    public showMenu() {
        const { changeToggle, toggleAction, toggleState } = this.props
        if (toggleState === 'show') {
            toggleAction('hidden')
        } else {
            toggleAction('show')
            changeToggle(true)
        }
    }

    public render() {
        const { toggle, history, username } = this.props
        return <nav className="navbar navbar-default top-navbar" role="navigation" style={{ height: '55px' }}>

            <div className="d-flex">

                <div className="navbar-header" style={{ flexGrow: 0 }}>
                    <img src={logo}
                         className="navbar-brand waves-effect waves-dark text-center"
                         alt="SEFAZ-PB"
                         style={{ padding: '0px', marginTop: '10px' }}/>
                </div>

                <div className="nav-div-actions">

                    {
                        toggle && (
                            <ul className="nav navbar-top-links navbar-left"
                                onClick={this.showMenu}>
                                <li>
                                            <span className="dropdown-button waves-effect waves-dark">
                                                <i className="material-icons">toc</i>
                                            </span>
                                </li>
                            </ul>
                        )
                    }

                    <ul className="nav navbar-top-links navbar-left" id="ulSideClick"
                        onClick={this.changeCollapse}>
                        <li>
                                <span className="dropdown-button waves-effect waves-dark" id="sideClick">
                                    <i className="material-icons">toc</i>
                                </span>
                        </li>
                    </ul>

                    <UserDropdown history={history} username={username}/>

                </div>

            </div>

        </nav>
    }
}

export default Navbar
