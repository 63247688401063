import { action } from 'typesafe-actions'
import { RevenueLocation, RevenuesActionsTypes } from './types'
import Revenue from '../../application/models/transactions/revenue/revenue'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Payer from '../../application/models/financial.agents/payer/payer'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import RevenueAnnualSummary from '../../application/models/transactions/revenue/revenue.annual.summary'
import { AnnualTransaction } from '../../application/models/transactions/annual.transaction'

/* Action for Reset State */
export const resetRevenue = () => action(RevenuesActionsTypes.RESET_REVENUES, {})

/* Actions for Change */
export const changeRevenue = (revenue: Revenue) => action(RevenuesActionsTypes.CHANGE_REVENUES, { revenue })

export const changeDialog = (dialog: boolean) => action(RevenuesActionsTypes.CHANGE_DIALOG, { dialog })

export const changeDate = (date: Date) => action(RevenuesActionsTypes.CHANGE_DATE, { date })

export const changePaginator = (payerId: string, paginator: IPaginator) => [
    action(RevenuesActionsTypes.CHANGE_PAGINATOR, { paginator }),
    loadRevenues(payerId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(RevenuesActionsTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, payerId: string) => action(
    RevenuesActionsTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove,
        payerId
    }
)

export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(RevenuesActionsTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadRevenueMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(RevenuesActionsTypes.RESET_MONTHLY_PAGINATOR)


export const changeAnnualPaginator = (year: string, paginator: IPaginator) => [
    action(RevenuesActionsTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadRevenueAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(RevenuesActionsTypes.RESET_ANNUAL_PAGINATOR)


/* Actions for Create Revenue */
export const createRevenue = (revenue: Revenue, location: RevenueLocation) => action(
    RevenuesActionsTypes.CREATE_REQUEST,
    { revenue, location }
)

export const createRevenueSuccess = (revenue: Revenue) => action(
    RevenuesActionsTypes.CREATE_SUCCESS,
    { revenue }
)

export const createRevenueFailure = (error: ErrorEvent, revenue: Revenue) => action(
    RevenuesActionsTypes.CREATE_FAILURE, {
        error,
        revenue
    }
)

/* Actions for Load Revenue */
export const loadRevenues = (payerId: string, paginator?: IPaginator) => action(
    RevenuesActionsTypes.LOAD_REQUEST, { payerId, paginator }
)

export const loadRevenueSuccess = (response: IAxiosResponse<Revenue[]>) => action(
    RevenuesActionsTypes.LOAD_SUCCESS, {
        revenues: response.data,
        headers: response.headers
    }
)

export const loadRevenueFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.LOAD_FAILURE, { error })

/* Actions for Find Revenue */
export const findRevenue = (payerId: string, revenueId: string) => action(RevenuesActionsTypes.FIND_REQUEST, {
    payerId,
    revenueId
})

export const findRevenueSuccess = (response: IAxiosResponse<Revenue[]>) => action(
    RevenuesActionsTypes.FIND_SUCCESS, {
        revenue: response.data,
        headers: response.headers
    }
)

export const findRevenueFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.FIND_FAILURE, { error })

/* Actions for Update Revenue */
export const updateRevenue = (revenue: Revenue, location: RevenueLocation) => action(
    RevenuesActionsTypes.UPDATE_REQUEST,
    { revenue, location }
)

export const updateRevenueSuccess = (revenue: Revenue) => action(
    RevenuesActionsTypes.UPDATE_SUCCESS,
    { revenue }
)

export const updateRevenueFailure = (error: ErrorEvent, revenue: Revenue) => action(
    RevenuesActionsTypes.UPDATE_FAILURE, {
        error,
        revenue
    })

/* Actions for Remove Revenue */
export const removeRevenue = (payerId: string, revenueId: string, location: RevenueLocation, paginator: IPaginator, date?: string) => action(
    RevenuesActionsTypes.REMOVE_REQUEST, {
        payerId,
        revenueId,
        location,
        paginator,
        date
    }
)

export const removeRevenueSuccess = () => action(RevenuesActionsTypes.REMOVE_SUCCESS, {})

export const removeRevenueFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.REMOVE_FAILURE, { error })

/* Actions for Find Payer */
export const findPayer = (payerId: string) => action(RevenuesActionsTypes.FIND_PAYER_REQUEST, { payerId })

export const findPayerSuccess = (response: IAxiosResponse<Payer>) => action(
    RevenuesActionsTypes.FIND_PAYER_SUCCESS, {
        payer: response.data,
        headers: response.headers
    }
)

export const findPayerFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.FIND_PAYER_FAILURE, { error })

/* Actions for Load Monthly */
export const loadRevenueMonthly = (date?: string, paginator?: IPaginator) => action(RevenuesActionsTypes.LOAD_MONTHLY_REQUEST, {
    date,
    paginator
})

export const loadRevenueMonthlySuccessReset = (response: IAxiosResponse<RevenueMonthlySummary>) => action(
    RevenuesActionsTypes.LOAD_MONTHLY_SUCCESS_RESET, {
        monthlyRevenue: response.data,
        headers: response.headers
    }
)

export const loadRevenueMonthlySuccessConcat = (response: IAxiosResponse<RevenueMonthlySummary>) => action(
    RevenuesActionsTypes.LOAD_MONTHLY_SUCCESS_CONCAT, {
        monthlyRevenue: response.data,
        headers: response.headers
    }
)

export const loadRevenueMonthlyFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.LOAD_MONTHLY_FAILURE, { error })

/* Actions for Load Annual */
export const loadRevenueAnnual = (year: string, paginator?: IPaginator) => action(RevenuesActionsTypes.LOAD_ANNUAL_REQUEST, {
    year,
    paginator
})

export const loadRevenueAnnualReset = (response: IAxiosResponse<RevenueAnnualSummary>) => action(
    RevenuesActionsTypes.LOAD_ANNUAL_SUCCESS_RESET, {
        annualRevenue: response.data,
        headers: response.headers
    }
)

export const loadRevenueAnnualConcat = (response: IAxiosResponse<RevenueAnnualSummary>) => action(
    RevenuesActionsTypes.LOAD_ANNUAL_SUCCESS_CONCAT, {
        annualRevenue: response.data,
        headers: response.headers
    }
)

export const loadRevenueAnnualFailure = (error: ErrorEvent) => action(RevenuesActionsTypes.LOAD_ANNUAL_FAILURE, { error })

export const loadAnnualExecuted = (payerId: string, year: number) => action(
    RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_REQUEST, {
        payerId,
        year
    })

export const loadAnnualExecutedSuccess = (annualRevenues: AnnualTransaction[]) => action(
    RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_SUCCESS,
    { annualRevenues }
)

export const loadAnnualExecutedFailure = (error: ErrorEvent) => action(
    RevenuesActionsTypes.LOAD_ANNUAL_EXECUTED_FAILURE,
    { error }
)
