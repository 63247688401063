import React, { Component } from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'

import PrevisionTable, { IColumn as ItemColumn } from './prevision.table'
import Currency from '../currency/currency'
import { TypeOfTransaction } from '../../store/application/models/transactions/transaction'
import { LogicalStrategy } from '../../services/auth'
import { VerifyScopes } from '../verify.scopes/verify.scopes'
import { Margin } from '../../containers/costing/costing.by.institution'
import { InitialPrevisionValidator } from '../../store/application/validators/initial.prevision'
import { Field, Form, Formik } from 'formik'
import { PrevisionTransactionTypes } from '../../store/application/utils/prevision.transaction.types'

interface IColumn {
    1: ItemColumn[]
    2: ItemColumn[]
}

export interface ITotal {
    initial_expected: number | undefined
    expected: number | undefined
    real: number | undefined
}

interface IProps {

    readonly initialValue?: number

    readonly year: number

    readonly loading?: boolean

    readonly previsions: any

    readonly columns: IColumn

    readonly totals: ITotal

    readonly create: boolean

    readonly disabledSave: boolean

    readonly typeOfResource: TypeOfTransaction

    readonly transactionType: PrevisionTransactionTypes | undefined

    onChange(field: string, value: number): void

    cancel(): void

    submit(previsions: any): void

    changeInitialPrevision?(initialValue: number): void

    onClickAdd(date: string): void

    onClickUpdate(date: string): void

    onClickRemove(date: string): void
}

class Prevision extends Component<IProps> {

    public render() {

        const {
            previsions,
            columns,
            totals,
            onChange,
            loading,
            create,
            submit,
            year,
            initialValue,
            changeInitialPrevision,
            typeOfResource,
            onClickAdd,
            onClickUpdate,
            onClickRemove,
            transactionType
        } = this.props

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                        {!create && <h4 style={{ margin: 0, padding: 0 }}>{year}</h4>}
                        {
                            (create && !loading) && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'left'
                                    }}>

                                    <h5 className="center">Adicionar previsão para {year}</h5>

                                    <p className="center">* Preencha os valores desejados, logo após pressione
                                        salvar.</p>

                                    <Formik
                                        initialValues={{
                                            initial_prevision: initialValue,
                                            transaction_type: transactionType
                                        }}
                                        enableReinitialize={true}
                                        validationSchema={InitialPrevisionValidator.validationScheme}
                                        onSubmit={() => submit(!initialValue ? previsions : {})}>
                                        {({ isValid }) => (
                                            <Form>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'left',
                                                        justifyContent: 'left'
                                                    }}>
                                                    <Margin top={0} bottom={0} left={0} right={10}>
                                                        <label className="label-bold" htmlFor="initial">
                                                            Previsto Inicial
                                                        </label>
                                                        <Field
                                                            name="initial_prevision"
                                                            id="initial_prevision"
                                                            type="customField">
                                                            {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                                <span className="p-inputgroup">
                                                                    <span className="p-inputgroup-addon">R$: </span>
                                                                    <Currency
                                                                        id="initial_prevision"
                                                                        name="initial_prevision"
                                                                        value={field.value}
                                                                        onChange={(event, value: number) => {
                                                                            setFieldValue('initial_prevision', value)
                                                                            if (changeInitialPrevision) {
                                                                                changeInitialPrevision(value)
                                                                            }
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFieldTouched('initial_prevision', true, true)
                                                                        }}
                                                                    />
                                                            </span>
                                                            )}
                                                        </Field>
                                                    </Margin>
                                                    <Margin
                                                        top={0}
                                                        bottom={0}
                                                        left={10}
                                                        right={0}
                                                        className="d-flex align-items-center">
                                                        <VerifyScopes
                                                            scopes={['pr:c', 'pr:u']}
                                                            logicalStrategy={LogicalStrategy.OR}>
                                                            <Button
                                                                className="p-button-primary right"
                                                                label="Salvar"
                                                                icon="pi pi-save"
                                                                disabled={!isValid}
                                                                type="submit"/>
                                                        </VerifyScopes>
                                                    </Margin>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            )

                        }
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                        <PrevisionTable
                            initialValue={initialValue && initialValue > 0 ? initialValue / 12 : initialValue}
                            create={create}
                            header="1° Trimestre"
                            previsions={[previsions]}
                            columns={columns['1']}
                            loading={loading}
                            onChange={onChange}
                            onClickAdd={onClickAdd}
                            onClickUpdate={onClickUpdate}
                            onClickRemove={onClickRemove}
                        />

                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                        <PrevisionTable
                            initialValue={initialValue && initialValue > 0 ? initialValue / 12 : initialValue}
                            create={create}
                            header="2° Trimestre"
                            previsions={[previsions]}
                            columns={columns['2']}
                            loading={loading}
                            onChange={onChange}
                            onClickAdd={onClickAdd}
                            onClickUpdate={onClickUpdate}
                            onClickRemove={onClickRemove}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                        <PrevisionTable
                            initialValue={initialValue && initialValue > 0 ? initialValue / 12 : initialValue}
                            create={create}
                            header="3° Trimestre"
                            previsions={[previsions]}
                            columns={columns['3']}
                            loading={loading}
                            onChange={onChange}
                            onClickAdd={onClickAdd}
                            onClickUpdate={onClickUpdate}
                            onClickRemove={onClickRemove}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                        <PrevisionTable
                            initialValue={initialValue && initialValue > 0 ? initialValue / 12 : initialValue}
                            create={create}
                            header="4° Trimestre"
                            previsions={[previsions]}
                            columns={columns['4']}
                            loading={loading}
                            onChange={onChange}
                            onClickAdd={onClickAdd}
                            onClickUpdate={onClickUpdate}
                            onClickRemove={onClickRemove}
                        />
                    </div>
                    {
                        !create && <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 p-15">
                            <DataTable value={[totals]} loading={loading}>
                                <Column
                                    field="initial_expected"
                                    header="Previsto Inicial"
                                    className="center"
                                    body={(rowData: any) =>
                                        <Currency
                                            disabledColor={true}
                                            value={rowData.initial_expected}
                                            readOnly={true}/>
                                    }/>
                                <Column
                                    field="expected"
                                    header="Previsto Atualizado"
                                    className="center"
                                    body={(rowData: any) =>
                                        <Currency
                                            disabledColor={true}
                                            value={rowData.expected}
                                            readOnly={true}/>
                                    }/>
                                <Column
                                    field="real"
                                    header={
                                        typeOfResource === TypeOfTransaction.OUTPUT ?
                                            'Total Realizado'
                                            : 'Total Arrecadado'
                                    }
                                    className="center"
                                    body={(rowData: any) =>
                                        <Currency
                                            disabledColor={true}
                                            value={rowData.real}
                                            readOnly={true}/>
                                    }/>
                            </DataTable>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default Prevision
