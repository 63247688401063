import { JsonUtils } from '../../utils/json.util'
import { MonthlyFinancialAgent } from '../financial.agents/monthly.financial.agent'
import { Transaction } from '../transactions/transaction'

export default abstract class MonthlySummary<F extends MonthlyFinancialAgent<T>, T extends Transaction> {
    private static hasValues(transactions: any[] | undefined): boolean {
        if (transactions?.length) {
            return transactions?.some(transaction => transaction.value)
        }
        return false
    }

    private _total_transactions_value: number | undefined
    private _financial_agents: F[] | undefined

    get total_transactions_value(): number | undefined {
        return this._total_transactions_value
    }

    set total_transactions_value(value: number | undefined) {
        this._total_transactions_value = value
    }

    get financial_agents(): F[] | undefined {
        return this._financial_agents
    }

    set financial_agents(value: F[] | undefined) {
        this._financial_agents = value
    }

    public addTransaction(transaction: T | any, error?: boolean): void {
        const found = this.financial_agents
            ?.find((financialAgent) => {
                return (financialAgent.id === transaction.financial_agent_id) ||
                    (financialAgent.id === transaction.institution_id) ||
                    (financialAgent.id === transaction.payee_id) ||
                    (financialAgent.id === transaction.payer_id)
            })
        if (found) {
            found.transactions?.push({ ...transaction.toJSON(), error })
        }
    }

    public updateTransaction(transaction: T | any, error?: boolean): void {
        const financialAgentFound = this.financial_agents
            ?.find((financialAgent) => {
                return (financialAgent.id === transaction.financial_agent_id) ||
                    (financialAgent.id === transaction.institution_id) ||
                    (financialAgent.id === transaction.payee_id) ||
                    (financialAgent.id === transaction.payer_id)
            })
        if (financialAgentFound) {
            financialAgentFound
                .transactions
                ?.forEach((mTrans) => {
                    if (mTrans?.id === transaction.id) {
                        mTrans.value = transaction.value
                    }
                })
        }
    }

    public fromJSON(json: any): MonthlySummary<F, T> {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.total_transactions_value !== undefined) {
            this._total_transactions_value = json.total_transactions_value
        }
        if (json.financial_agents !== undefined) {
            this._financial_agents = json.financial_agents
        }

        return this
    }

    public toJSON(): any {
        return {
            total_transactions_value: this.total_transactions_value ? this.total_transactions_value : undefined,
            financial_agents: this.financial_agents ? this.financial_agents.map(financialAgent => financialAgent.toJSON()) : undefined
        }
    }

    public concatFromJSON(json: any): MonthlySummary<F, T> {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }
        if (json.total_transactions_value !== undefined) {
            this._total_transactions_value += json.total_transactions_value
        }
        if (json.financial_agents !== undefined) {
            this._financial_agents = this._financial_agents?.concat(json.financial_agents)
        }

        return this
    }

    public filterResources(): any {
        const result: MonthlySummary<F, T> = Object.assign({}, this)

        result.financial_agents = this.financial_agents
            ?.filter((financialAgent) => {
                return MonthlySummary.hasValues(financialAgent.transactions)
            })

        return result
    }
}
