import { all, apply, put, takeLatest } from 'redux-saga/effects'

import {
    changeCreateDialog,
    createFailure,
    createSuccess,
    findFailure,
    loadFailure,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { IActionType, IAxiosResponse } from '../root.types'
import { Toast } from '../../../services/toast'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, LiberationActionTypes } from './types'
import liberationService from '../../../services/liberation'
import { Liberation } from '../../application/models/liberation/liberation'
import { LiberationSummary } from '../../application/models/liberation/liberation.summary'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    const { data, location } = action.payload
    try {
        const response: Liberation = yield apply(
            liberationService,
            liberationService.create,
            [data]
        )
        yield put<any>(createSuccess(response, location))
        toastService.show('success', 'Liberação cadastrada com sucesso', '')
    } catch (err) {
        yield put(createFailure())
    }
}

function* find(action: IActionType<IActionFind>) {
    const { id } = action.payload
    try {
        const response: Liberation = yield apply(
            liberationService,
            liberationService.getById,
            [id]
        )
        yield put<any>(changeCreateDialog(true, response))
    } catch (err) {
        yield put(findFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { date, paginator, type } = action.payload
        let response: IAxiosResponse<LiberationSummary> = {
            data: new LiberationSummary(),
            headers: undefined
        }
        switch (type) {
            case 'daily':
                response = yield apply(
                    liberationService,
                    liberationService.getDailySummary,
                    [date, paginator]
                )
                break
            case 'monthly':
                response = yield apply(
                    liberationService,
                    liberationService.getMonthlySummary,
                    [date, paginator]
                )
                break
            case 'annual':
                response = yield apply(
                    liberationService,
                    liberationService.getAnnualSummary,
                    [date, paginator]
                )
                break
        }
        yield put(loadSuccess(response))
    } catch (err) {
        yield put(loadFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { ids, date } = action.payload
        for (const id of ids) {
            yield apply(liberationService, liberationService.remove, [id])
        }
        yield put<any>(removeSuccess(date))
        toastService.show('success', 'Liberação removida com sucesso', '')
    } catch (err) {
        yield put(removeFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { data, location } = action.payload
    try {
        const response: Liberation = yield apply(
            liberationService,
            liberationService.update,
            [data]
        )
        yield put<any>(updateSuccess(response, location))
        toastService.show('success', 'Liberação atualizada com sucesso', '')
    } catch (err) {
        yield put(updateFailure())
    }
}

export default function* liberationSaga() {
    return yield all([
        takeLatest(LiberationActionTypes.CREATE_REQUEST, create),
        takeLatest(LiberationActionTypes.FIND_REQUEST, find),
        takeLatest(LiberationActionTypes.LOAD_REQUEST, getAll),
        takeLatest(LiberationActionTypes.REMOVE_REQUEST, remove),
        takeLatest(LiberationActionTypes.UPDATE_REQUEST, update)
    ])
}
