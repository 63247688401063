import { DailyDisbursementSummaryItem } from './daily.disbursement.summary.item'
import { JsonUtils } from '../../utils/json.util'
import { DisbursementReportItem } from './disbursement.report.item'

/**
 * Implementation of the Disbursement Report entity.
 * @param {number} [previous_balance] Previous balance.
 * @param {number} [bb97] Total value of Bank of Brazil finality 97.
 * @param {number} [bb96] Total value of Bradesco finality 96.
 * @param {number} [fundeb95] Total value of Fundeb finality 95.
 * @param {number} [fundeb94] Total value of Fundeb finality 94.
 * @param {number} [fundeb93] Total value of Fundeb finality 93.
 * @param {number} [fundeb92] Total value of Fundeb finality 92.
 * @param {DisbursementReportItem[]} [mr] Report items of MR category group by institution.
 * @param {DisbursementReportItem[]} [gd] Report items of GD category group by institution.
 * @param {DisbursementReportItem[]} [groups] Groups reports items.
 */
export class DisbursementReport {
    private _previous_balance: number | undefined
    private _bb97: number | undefined
    private _bd96: number | undefined
    private _fundeb95: DisbursementReportItem[] | undefined
    private _fundeb94: DisbursementReportItem[] | undefined
    private _fundeb93: DisbursementReportItem[] | undefined
    private _fundeb92: DisbursementReportItem[] | undefined
    private _mr: DisbursementReportItem[] | undefined
    private _gd: DisbursementReportItem[] | undefined
    private _groups: DisbursementReportItem[] | undefined
    private _institutions?: DailyDisbursementSummaryItem[] | undefined

    get previous_balance(): number | undefined {
        return this._previous_balance
    }

    set previous_balance(value: number | undefined) {
        this._previous_balance = value
    }

    get bb97(): number | undefined {
        return this._bb97
    }

    set bb97(value: number | undefined) {
        this._bb97 = value
    }

    get bd96(): number | undefined {
        return this._bd96
    }

    set bd96(value: number | undefined) {
        this._bd96 = value
    }

    get fundeb95(): DisbursementReportItem[] | undefined {
        return this._fundeb95
    }

    set fundeb95(value: DisbursementReportItem[] | undefined) {
        this._fundeb95 = value
    }

    get fundeb94(): DisbursementReportItem[] | undefined {
        return this._fundeb94
    }

    set fundeb94(value: DisbursementReportItem[] | undefined) {
        this._fundeb94 = value
    }

    get fundeb93(): DisbursementReportItem[] | undefined {
        return this._fundeb93
    }

    set fundeb93(value: DisbursementReportItem[] | undefined) {
        this._fundeb93 = value
    }

    get fundeb92(): DisbursementReportItem[] | undefined {
        return this._fundeb92
    }

    set fundeb92(value: DisbursementReportItem[] | undefined) {
        this._fundeb92 = value
    }

    get mr(): DisbursementReportItem[] | undefined {
        return this._mr
    }

    set mr(value: DisbursementReportItem[] | undefined) {
        this._mr = value
    }

    get gd(): DisbursementReportItem[] | undefined {
        return this._gd
    }

    set gd(value: DisbursementReportItem[] | undefined) {
        this._gd = value
    }

    get institutions(): DailyDisbursementSummaryItem[] | undefined {
        return this._institutions
    }

    set institutions(value: DailyDisbursementSummaryItem[] | undefined) {
        this._institutions = value
    }

    get groups(): DisbursementReportItem[] | undefined {
        return this._groups
    }

    set groups(value: DisbursementReportItem[] | undefined) {
        this._groups = value
    }

    public fromJSON(json: any): DisbursementReport {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.previous_balance) {
            this.previous_balance = json.previous_balance
        }

        if (json.bb97) {
            this.bb97 = json.bb97
        }

        if (json.bd96) {
            this.bd96 = json.bd96
        }

        if (json.fundeb95 instanceof Array && json.fundeb95.length) {
            this.fundeb95 = json.fundeb95.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.fundeb94 instanceof Array && json.fundeb94.length) {
            this.fundeb94 = json.fundeb94.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.fundeb93 instanceof Array && json.fundeb93.length) {
            this.fundeb93 = json.fundeb93.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.fundeb92 instanceof Array && json.fundeb92.length) {
            this.fundeb92 = json.fundeb92.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.mr instanceof Array && json.mr.length) {
            this.mr = json.mr.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.gd instanceof Array && json.gd.length) {
            this.gd = json.gd.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.groups instanceof Array && json.groups.length) {
            this.groups = json.groups.map((reportItem: any) => new DisbursementReportItem().fromJSON(reportItem))
        }

        if (json.institutions instanceof Array && json.institutions.length) {
            this.institutions = json
                ?.institutions
                ?.map((institutionItem: any) => new DailyDisbursementSummaryItem().fromJSON(institutionItem)) || []
        }

        return this
    }

    public toJSON(): any {
        return {
            previous_balance: this.previous_balance || undefined,
            bb97: this.bb97 || undefined,
            bd96: this.bd96 || undefined,
            fundeb95: this.fundeb95?.length ?
                this.fundeb95.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            fundeb94: this.fundeb94?.length ?
                this.fundeb94.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            fundeb93: this.fundeb93?.length ?
                this.fundeb93.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            fundeb92: this.fundeb92?.length ?
                this.fundeb92.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            mr: this.mr?.length ?
                this.mr.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            gd: this.gd?.length ?
                this.gd.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            groups: this.groups?.length ?
                this.groups.map((reportItem: DisbursementReportItem) => reportItem.toJSON())
                : [],
            institutions: this.institutions?.length ?
                this.institutions.map((item: DailyDisbursementSummaryItem) => item.toJSON())
                : []
        }
    }
}
