import { action } from 'typesafe-actions'
import { AuthTypes, IAccessToken, IAuth } from './types'

/* Actions for Change */
export const changeIconPassword = (icon: string) => action(AuthTypes.CHANGE_ICON_PASSWORD, { icon })

export const resetError = () => action(AuthTypes.RESET_ERROR, {})

/* Actions for Login */
export const loginRequest = (data: IAuth) => action(AuthTypes.LOGIN_REQUEST, { data })

export const loginSuccess = (data: IAccessToken) => action(AuthTypes.LOGIN_SUCCESS, { data })

export const loginFailure = (error: ErrorEvent) => action(AuthTypes.LOGIN_FAILURE, { error })

/* Actions for Login by Cert */
export const loginByCert = () => action(AuthTypes.LOGIN_BY_CERT, {})

export const loginByCertSuccess = (data: IAccessToken) => action(AuthTypes.LOGIN_BY_CERT_SUCCESS, { data })

export const loginByCertFailure = (error: ErrorEvent) => action(AuthTypes.LOGIN_BY_CERT_FAILURE, { error })
