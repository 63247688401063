import { lazy } from 'react'

const AnnualEventing = lazy(() => import('../containers/events/annual'))
const MonthlyEventing = lazy(() => import('../containers/events/monthly'))

export const EVENTING_ROUTES = [
    {
        path: '/app/events/monthly',
        component: MonthlyEventing,
        scopes: ['ms:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/events/annual',
        component: AnnualEventing,
        scopes: ['as:r'],
        exact: true,
        private: true
    },

]
