import AnnualSummary from '../../summary/annual.summary'
import { AnnualInstitution } from '../../financial.agents/institution/annual.institution'
import { IAnnualDebts } from '../../../../ducks/debt/types'
import Debt, { DebtType } from './debt'
import { AnnualTransaction } from '../annual.transaction'
import Prevision from '../../prevision/prevision'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'


export default class DebtAnnualSummary extends AnnualSummary<AnnualInstitution> {
    private _total_direct_debts: number | undefined
    private _total_indirect_debts: number | undefined
    private _total_jda: number | undefined

    get total_direct_debts(): number | undefined {
        return this._total_direct_debts
    }

    set total_direct_debts(value: number | undefined) {
        this._total_direct_debts = value
    }

    get total_indirect_debts(): number | undefined {
        return this._total_indirect_debts
    }

    set total_indirect_debts(value: number | undefined) {
        this._total_indirect_debts = value
    }

    get total_jda(): number | undefined {
        return this._total_jda
    }

    set total_jda(value: number | undefined) {
        this._total_jda = value
    }

    public fromJSON(json: any): DebtAnnualSummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_direct_debts !== undefined) {
            this._total_direct_debts = json.total_direct_debts
        }
        if (json.total_indirect_debts !== undefined) {
            this._total_indirect_debts = json.total_indirect_debts
        }
        if (json.total_jda !== undefined) {
            this._total_jda = json.total_jda
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_direct_debts: this.total_direct_debts ? this.total_direct_debts : undefined,
            total_indirect_debts: this.total_indirect_debts ? this.total_indirect_debts : undefined,
            total_jda: this.total_jda ? this.total_jda : undefined
        }
    }

    public concatFromJSON(json: any): DebtAnnualSummary {

        if (json?.financial_agents?.length) {
            const concatFinancialAgents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
            json.financial_agents = json.financial_agents?.concat(concatFinancialAgents)
        }

        super.concatFromJSON(json)

        if (json.total_direct_debts !== undefined) {
            this._total_direct_debts += json.total_direct_debts
        }
        if (json.total_indirect_debts !== undefined) {
            this._total_indirect_debts += json.total_indirect_debts
        }
        if (json.total_jda !== undefined) {
            this._total_jda += json.total_jda
        }

        return this
    }

    public fromTwelveMonthly(year: string | undefined, annualDebt: IAnnualDebts): DebtAnnualSummary {
        const months = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december'
        ]
        this.total_transactions_value = annualDebt.total
        this.total_direct_debts = annualDebt.totalDirects
        this.total_indirect_debts = annualDebt.totalIndirects
        this.total_jda = annualDebt.totalJudicialDecisionsAndAgreements
        this.financial_agents = []
        months.forEach((month: string, index: number) => {
            if (annualDebt[month]) {
                annualDebt[month]
                    .financial_agents
                    ?.forEach((financialAgent: MonthlyInstitution<Debt>) => {

                        const transactions: AnnualTransaction[] = []
                        let totalPrevision = 0

                        Object.values(DebtType)
                            .forEach((debtType: string) => {

                                const descriptions = financialAgent?.transactions?.length ?
                                    financialAgent.transactions
                                        .filter((transaction: Debt) => transaction.debt_type === debtType)
                                        .map((transaction: Debt) => transaction.description)
                                    : []

                                const setDescriptions = new Set(descriptions)
                                const arrayDescriptions = Array.from(setDescriptions.values())

                                arrayDescriptions.forEach((description: string | undefined) => {
                                    const annualTransaction: AnnualTransaction = new AnnualTransaction()
                                    const valuesForDescription = financialAgent?.transactions?.length ?
                                        financialAgent.transactions
                                            .filter((transaction: Debt) => {
                                                return transaction.debt_type === debtType &&
                                                    transaction.description === description
                                            })
                                            .map((transaction: Debt) => transaction.value)
                                        : []
                                    /** Sum all values */
                                    const totalDescription = valuesForDescription?.length ?
                                        valuesForDescription
                                            .reduce((prev: number | undefined, current: number | undefined) => {
                                                return (prev ? prev : 0) + (current ? current : 0)
                                            })
                                        : undefined

                                    const previsionDescription: number = financialAgent?.previsions?.length ?
                                        financialAgent
                                            ?.previsions
                                            ?.map((previsionElement: Prevision) => previsionElement?.expected_value)
                                            ?.reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                                                return (prevValue || 0) + (currentValue || 0)
                                            }, 0) || 0
                                        : 0

                                    annualTransaction.date = `${year}-${String(`0${index + 1}`).slice(-2)}`
                                    annualTransaction.total_value = totalDescription
                                    annualTransaction.prevision = previsionDescription
                                    annualTransaction.description = description
                                    annualTransaction.debt_type = debtType as DebtType

                                    transactions.push(annualTransaction)
                                    totalPrevision += previsionDescription
                                })
                            })

                        const financialAgentIncluded = this.financial_agents?.find((financialAgentElement: AnnualInstitution) => {
                            return financialAgentElement.id === financialAgent.id
                        })

                        if (!financialAgentIncluded) {
                            const newAnnualInstitution: AnnualInstitution = new AnnualInstitution()
                                .fromJSON({
                                    ...financialAgent.institution?.toJSON(),
                                    previsions: totalPrevision,
                                    transactions
                                })
                            this.financial_agents = !this.financial_agents?.length ?
                                [newAnnualInstitution]
                                : this.financial_agents?.concat(newAnnualInstitution)
                        } else {
                            financialAgentIncluded.transactions = financialAgentIncluded.transactions?.concat(transactions)
                            financialAgentIncluded.previsions = (financialAgentIncluded?.previsions || 0) + totalPrevision
                        }
                    })
            }
        })

        return this
    }
}
