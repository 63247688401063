import React, { Component } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import Spinner from '../spinner/spinner'
import { IApplicationState } from '../../store'
import * as LayoutActions from '../../store/ducks/layout/actions'
import './style.scss'

interface IProps {
    readonly updateAvailable: boolean
    readonly registration: ServiceWorkerRegistration | undefined

    changeUpdateAvailable(
        updateAvailable: boolean,
        registration: ServiceWorkerRegistration | undefined
    ): void
}

interface IState {
    readonly loading: boolean
}

class UpdateAvailableComponent extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.onUpdate = this.onUpdate.bind(this)
        this.state = { loading: false }
    }

    public render() {
        const { updateAvailable, changeUpdateAvailable } = this.props
        const { loading } = this.state
        return <React.Fragment>
            {
                updateAvailable && (
                    <div className="update-available-overlay">
                        <div className="update-available-content fade-in-down">
                            <div className="update-available-content-icon">
                                <i className="pi pi-info-circle"/>
                            </div>
                            <div className="update-available-content-summary">
                                <p>Uma nova versão do aplicativo está disponível!</p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        title="Clique aqui para atualizar agora."
                                        onClick={this.onUpdate}
                                        className="update-available-content-summary-button">
                                        Atualizar agora
                                        <i className="pi pi-refresh p-5"/>
                                    </button>
                                </div>
                            </div>
                            <div
                                title="Clique aqui para fechar"
                                className="update-available-content-close-button"
                                onClick={() => changeUpdateAvailable(false, undefined)}>
                                <i className="pi pi-times"/>
                            </div>
                        </div>
                    </div>
                )
            }
            {(loading && updateAvailable) && <Spinner/>}
        </React.Fragment>
    }

    private onUpdate(): void {
        const { registration } = this.props

        const registrationWaiting = registration?.waiting

        if (registrationWaiting) {
            this.setState({ loading: true })

            registrationWaiting?.postMessage({ type: 'SKIP_WAITING' })

            registrationWaiting?.addEventListener('statechange', (e: any) => {
                if (e?.target?.state === 'activated') {
                    window.location.reload()
                }
            })
        }

    }

}

const mapStateToProps = (state: IApplicationState) => ({
    updateAvailable: state.layout.updateAvailable,
    registration: state.layout.registration
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(LayoutActions, dispatch)

const UpdateAvailable = connect(mapStateToProps, mapDispatchToProps)(UpdateAvailableComponent)

export default UpdateAvailable
