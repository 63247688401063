import { action } from 'typesafe-actions'
import { PayerActionsTypes } from './types'
import Payer from '../../application/models/financial.agents/payer/payer'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'

/* Actions for Reset State */
export const resetPayer = () => action(PayerActionsTypes.RESET_PAYER, {})

/* Actions for Change */
export const changePayer = (payer: Payer) => action(PayerActionsTypes.CHANGE_PAYER, { payer })

export const changePaginator = (paginator: IPaginator) => [
    action(PayerActionsTypes.CHANGE_PAGINATOR, { paginator }),
    loadPayers(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(PayerActionsTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string) => action(
    PayerActionsTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove
    }
)

/* Actions for Change Dialog Create */
export const changeDialogCreate = (dialog: boolean) => action(PayerActionsTypes.CHANGE_DIALOG_CREATE, { dialog })


/* Actions for Create Payers */
export const createPayer = (payer: Payer) => action(PayerActionsTypes.CREATE_REQUEST, { payer })

export const createPayerSuccess = (payer: Payer) => [
    action(PayerActionsTypes.CREATE_SUCCESS, { payer }),
    resetPayer(),
    loadPayers()
]

export const createPayerFailure = (error: ErrorEvent) => action(
    PayerActionsTypes.CREATE_FAILURE, { error }
)

/* Actions for Load Payers */
export const loadPayers = (paginator?: IPaginator) => action(PayerActionsTypes.LOAD_REQUEST, { paginator })

export const loadPayersSuccess = (response: IAxiosResponse<Payer[]>) => action(
    PayerActionsTypes.LOAD_SUCCESS, {
        payers: response.data,
        headers: response.headers
    }
)

export const loadPayersFailure = (error: ErrorEvent) => action(PayerActionsTypes.LOAD_FAILURE, { error })

/* Actions for Find Payer */
export const findPayer = (payerId: string) => action(PayerActionsTypes.FIND_REQUEST, { payerId })

export const findPayerSuccess = (response: IAxiosResponse<Payer[]>) => action(
    PayerActionsTypes.FIND_SUCCESS, {
        payer: response.data,
        headers: response.headers
    }
)

export const findPayerFailure = (error: ErrorEvent) => action(PayerActionsTypes.FIND_FAILURE, { error })

/* Actions for Update Payer */
export const updatePayer = (payer: Payer) => action(PayerActionsTypes.UPDATE_REQUEST, { payer })

export const updatePayerSuccess = (payer: Payer) => [
    action(PayerActionsTypes.UPDATE_SUCCESS, { payer }),
    resetPayer(),
    loadPayers()
]

export const updatePayerFailure = (error: ErrorEvent, payer: Payer) => action(
    PayerActionsTypes.UPDATE_FAILURE, {
        error,
        payer
    }
)

/* Actions for Remove Payer */
export const removePayer = (idForRemove: string) => action(
    PayerActionsTypes.REMOVE_REQUEST, { idForRemove }
)

export const removePayerSuccess = () => action(PayerActionsTypes.REMOVE_SUCCESS, {})

export const removePayerFailure = (error: ErrorEvent) => action(PayerActionsTypes.REMOVE_FAILURE, { error })