import { action } from 'typesafe-actions'
import { DisbursementReportConfigActionTypes } from './types'
import { DisbursementReportConfig } from '../../application/models/disbursement/disbursement.report.config'
import { IAxiosResponse, IPaginator } from '../root.types'

/* Actions for Reset State */
export const reset = () => action(DisbursementReportConfigActionTypes.RESET)

/* Actions for Load Disbursement Report Config */
export const changePaginator = (paginator: IPaginator) => [
    action(DisbursementReportConfigActionTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

/* Actions for Create Disbursement Report Config */

export const changeCreateModal = (dialog: boolean, data: DisbursementReportConfig) => action(
    DisbursementReportConfigActionTypes.CHANGE_CREATE_DIALOG, { dialog, data }
)

export const createRequest = (data: DisbursementReportConfig) => action(
    DisbursementReportConfigActionTypes.CREATE_REQUEST,
    { data }
)

export const createSuccess = (data: DisbursementReportConfig) => action(
    DisbursementReportConfigActionTypes.CREATE_SUCCESS,
    { data }
)

export const createFailure = () => action(DisbursementReportConfigActionTypes.CREATE_FAILURE)

/* Actions for Load Disbursement Report Config */
export const loadRequest = (paginator: IPaginator) => action(
    DisbursementReportConfigActionTypes.LOAD_REQUEST,
    { paginator }
)

export const loadSuccess = (response: IAxiosResponse<DisbursementReportConfig[]>) => action(
    DisbursementReportConfigActionTypes.LOAD_SUCCESS,
    { data: response.data, headers: response.headers }
)

export const loadFailure = () => action(DisbursementReportConfigActionTypes.LOAD_FAILURE)

/* Actions for Remove Disbursement Report Config */

export const changeRemoveModal = (dialog: boolean, id: string) => action(
    DisbursementReportConfigActionTypes.CHANGE_REMOVE_DIALOG, { dialog, id }
)

export const removeRequest = (id: string) => action(
    DisbursementReportConfigActionTypes.REMOVE_REQUEST,
    { id }
)

export const removeSuccess = (id: string) => action(
    DisbursementReportConfigActionTypes.REMOVE_SUCCESS,
    { id }
)

export const removeFailure = () => action(DisbursementReportConfigActionTypes.REMOVE_FAILURE)

/* Actions for Update Disbursement Report Config */
export const updateRequest = (data: DisbursementReportConfig) => action(
    DisbursementReportConfigActionTypes.UPDATE_REQUEST,
    { data }
)

export const updateSuccess = (data: DisbursementReportConfig) => action(
    DisbursementReportConfigActionTypes.UPDATE_SUCCESS,
    { data }
)

export const updateFailure = () => action(DisbursementReportConfigActionTypes.UPDATE_FAILURE)
