import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Disbursement Report item entity.
 * @param {string} [code] Code of institution.
 * @param {string} [name] Name of institution.
 * @param {number} [value] Report item value.
 */
export class DisbursementReportItem {
    private _code: string | undefined
    private _name: string | undefined
    private _value: number | undefined

    get code(): string | undefined {
        return this._code
    }

    set code(value: string | undefined) {
        this._code = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    /**
     * Method use for receive an item from JSON, and pass for the atribbutes this value.
     * @function
     * @param {json} json Format the item is JSON
     * @returns {DisbursementReportItem}
     */
    public fromJSON(json: any): DisbursementReportItem {
        if (!json) return this
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.code !== undefined) this.code = json.code
        if (json.name !== undefined) this.name = json.name
        if (json.value !== undefined) this.value = json.value

        return this
    }

    /**
     * Method use for return an Object {@link DisbursementReportItem} to JSON.
     * @function
     * @returns {any}
     */
    public toJSON(): any {
        return {
            code: this.code,
            name: this.name,
            value: this.value
        }
    }
}
