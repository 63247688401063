import { FinancialAgent } from '../financial.agent'

export enum TypeInstitution {
    INDIRECT_ADMINISTRATION = 'indirect_administration',
    DIRECT_ADMINISTRATION = 'direct_administration',
}

export enum CostingCategory {
    PRIORITY_COST_UNITS = 'priority_cost_units',
    OTHER_UNITS = 'other_units',
}

export default class Institution extends FinancialAgent {
    private _code?: string
    private _institution_type: TypeInstitution
    private _cost_category: CostingCategory

    constructor() {
        super()
        this._code = ''
        this._institution_type = '' as TypeInstitution
        this._cost_category = '' as CostingCategory
    }

    get code(): string | undefined {
        return this._code
    }

    set code(value: string | undefined) {
        this._code = value
    }

    get institution_type(): TypeInstitution {
        return this._institution_type
    }

    set institution_type(value: TypeInstitution) {
        this._institution_type = value
    }

    get cost_category(): CostingCategory {
        return this._cost_category
    }

    set cost_category(value: CostingCategory) {
        this._cost_category = value
    }

    public fromJSON(json: any): Institution {

        super.fromJSON(json)

        if (json.code !== undefined) {
            this._code = json.code
        }

        if (json.institution_type !== undefined) {
            this._institution_type = json.institution_type
        }

        if (json.cost_category !== undefined) {
            this._cost_category = json.cost_category
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            code: this.code ? this.code : '',
            institution_type: this.institution_type ? this.institution_type : undefined,
            cost_category: this.cost_category ? this.cost_category : undefined
        }
    }
}
