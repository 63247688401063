import { ApiService } from './api'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import Balance from '../store/application/models/balance/balance'

class BalanceService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(balance: Balance): Promise<IAxiosResponse<Balance>> {
        return httpClient.post(`${this.apiVersion}/balanceagents/${balance.balanceagent_id}/balances`, balance.toJSON())
    }

    public getById(balanceAgentId: string, balanceId: string): Promise<IAxiosResponse<Balance>> {
        return httpClient.get(`${this.apiVersion}/balanceagents/${balanceAgentId}/balances/${balanceId}`)
    }

    public getAll(balanceAgentId: string, paginator?: IPaginator): Promise<IAxiosResponse<Balance[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/balanceagents/${balanceAgentId}/balances`, { params })
    }

    public update(balance: Balance): Promise<IAxiosResponse<Balance>> {
        const body = balance.toJSON()
        delete body.date
        return httpClient
          .patch(`${this.apiVersion}/balanceagents/${balance.balanceagent_id}/balances/${balance.id}`, body)
    }

    public remove(balanceAgentId: string, balanceId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/balanceagents/${balanceAgentId}/balances/${balanceId}`)
    }
}

export default new BalanceService()
