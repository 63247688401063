import { Reducer } from 'redux'
import { DisbursementReportBalanceActionTypes, IDisbursementReportBalanceState } from './types'
import { DisbursementReportBalance } from '../../application/models/disbursement/disbursement.report.balance'

export const INITIAL_STATE: IDisbursementReportBalanceState = {
    create: {
        data: new DisbursementReportBalance(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: [],
        loading: false,
        error: false,
        success: false
    },
    remove: {
        id: '',
        dialog: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IDisbursementReportBalanceState> = (state: IDisbursementReportBalanceState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case DisbursementReportBalanceActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data }
            }

        case DisbursementReportBalanceActionTypes.CREATE_SUCCESS:
            const { data: newData } = action.payload
            const resultData: DisbursementReportBalance[] = state.list.data.concat([newData])
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: newData
                },
                list: {
                    ...state.list,
                    data: resultData
                }
            }

        case DisbursementReportBalanceActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case DisbursementReportBalanceActionTypes.RESET:
            return INITIAL_STATE

        case DisbursementReportBalanceActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case DisbursementReportBalanceActionTypes.LOAD_SUCCESS:
            const { data: listData } = action.payload

            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: false,
                    success: true,
                    data: listData
                }
            }

        case DisbursementReportBalanceActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: []
                }
            }

        case DisbursementReportBalanceActionTypes.CHANGE_REMOVE_DIALOG:
            const { ids: removeId, dialog: removeDialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog: removeDialog, id: removeId }
            }

        case DisbursementReportBalanceActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case DisbursementReportBalanceActionTypes.REMOVE_SUCCESS:
            const { id: removedId } = action.payload
            const resultRemoveData: DisbursementReportBalance[] = state.list.data
                ?.filter((item: DisbursementReportBalance) => removedId !== `${item?.id}`) || []
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    id: ''
                },
                list: {
                    ...state.list,
                    data: resultRemoveData
                }
            }

        case DisbursementReportBalanceActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case DisbursementReportBalanceActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case DisbursementReportBalanceActionTypes.UPDATE_SUCCESS:
            const { data: updatedData } = action.payload
            const resultUpdateData: DisbursementReportBalance[] = state.list.data
                ?.map((item: DisbursementReportBalance) => {
                    if (item?.id === updatedData?.id) {
                        return updatedData
                    }
                    return item
                }) || []
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: updatedData
                },
                list: {
                    ...state.list,
                    data: resultUpdateData
                }
            }

        case DisbursementReportBalanceActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state;
    }
}

export default reducer
