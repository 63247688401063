import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import '../container.style.scss'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Field, Form, Formik } from 'formik'
import { Helmet } from 'react-helmet'

import Institution, {
    CostingCategory,
    TypeInstitution
} from '../../store/application/models/financial.agents/institution/institution'
import { Toast } from '../../services/toast'
import { IApplicationState } from '../../store'
import * as InstitutionActions from '../../store/ducks/institutions/actions'
import Spinner from '../../components/spinner/spinner'
import { Margin } from '../costing/costing.by.institution'
import CreatedAt from '../../components/created.at/created.at'
import { FormErrorMessage } from '../../components/form.error.message/form.error.message'
import { InstitutionValidator } from '../../store/application/validators/institution'
import { LogicalStrategy } from '../../services/auth'
import { VerifyScopes } from '../../components/verify.scopes/verify.scopes'
import { Dialog } from 'primereact/dialog'

interface IState {
    readonly institution: Institution
    readonly loading: boolean
    readonly error: boolean
    readonly success: boolean
    readonly dialog: boolean
}

interface IDispatchProps extends RouteComponentProps<any> {
    resetCreateInstitution(): void

    createInstitution(institution: Institution): void

    updateRequest(institution: Institution): void

    changeInstitution(institution: Institution): void

    findRequest(institutionId: string): void

    changeDialog(dialog: boolean): void
}

type Props = IState & IDispatchProps

export const translateInstitutionType = {
    [TypeInstitution.DIRECT_ADMINISTRATION]: 'Administração Direta',
    [TypeInstitution.INDIRECT_ADMINISTRATION]: 'Administração Indireta'
}

export const translateCostingCategory = {
    [CostingCategory.PRIORITY_COST_UNITS]: 'Unidade com Custeio Prioritários',
    [CostingCategory.OTHER_UNITS]: 'Demais Unidades'
}

const institutionsTypes = [
    {
        label: 'Administração Direta',
        value: TypeInstitution.DIRECT_ADMINISTRATION,
        type: TypeInstitution.DIRECT_ADMINISTRATION
    },
    {
        label: 'Administração Indireta',
        value: TypeInstitution.INDIRECT_ADMINISTRATION,
        type: TypeInstitution.INDIRECT_ADMINISTRATION
    }
]

const costingCategoryType = [
    {
        label: 'Unidade com Custeio Prioritários',
        value: CostingCategory.PRIORITY_COST_UNITS
    },
    { label: 'Demais Unidades', value: CostingCategory.OTHER_UNITS }
]

class Create extends Component<Props> {
    private spinnerMessage: string
    private toastService: Toast

    constructor(props: Props) {
        super(props)
        this.spinnerMessage = 'Carregando, aguarde...'
        this.toastService = Toast.getInstance()
        const {
            findRequest,
            match: { params }
        } = this.props
        if (params && params.institutionId) {
            findRequest(params.institutionId)
        }
    }

    public handleSubmit = async (values) => {
        const institution = new Institution().fromJSON({ ...values })
        const { createInstitution, updateRequest } = this.props
        if (institution.id) {
            this.spinnerMessage = 'Atualizando, aguarde...'
            updateRequest(institution)
        } else {
            this.spinnerMessage = 'Adicionando, aguarde...'
            createInstitution(institution)
        }
    }

    public componentWillUnmount(): void {
        this.props.resetCreateInstitution()
    }

    public render() {
        const {
            institution,
            loading,
            dialog,
            changeDialog,
            resetCreateInstitution
        } = this.props

        return (
            <React.Fragment>
                {
                    dialog && <Helmet>
                        <title>[Fc-e] - Adicionar Órgão</title>
                    </Helmet>
                }

                {loading && <Spinner message={this.spinnerMessage}/>}

                <Formik
                    initialValues={institution.toJSON()}
                    enableReinitialize={true}
                    validationSchema={InstitutionValidator.validationScheme}
                    onSubmit={this.handleSubmit}>
                    {({ isValid, resetForm, values }) => (
                        <React.Fragment>
                            <Form>
                                <Dialog
                                    header={institution?.id ? 'Atualizar Órgão ou Fundo' : 'Adicionar Órgão ou Fundo'}
                                    footer={
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                label="Fechar"
                                                className="p-button-secondary left"
                                                type="button"
                                                icon="pi pi-times"
                                                onClick={() => {
                                                    resetForm()
                                                    changeDialog(false)
                                                    resetCreateInstitution()
                                                }}
                                            />
                                            <VerifyScopes
                                                scopes={['fa:c', 'fa:u']}
                                                logicalStrategy={LogicalStrategy.OR}>
                                                <Button
                                                    label="Salvar"
                                                    type="submit"
                                                    className="p-button-primary right"
                                                    icon="pi pi-save"
                                                    disabled={!isValid}/>
                                            </VerifyScopes>
                                        </div>
                                    }
                                    visible={dialog}
                                    closeOnEscape={true}
                                    modal={true}
                                    className="dialog-overflow-enabled"
                                    onHide={() => {
                                        resetForm()
                                        changeDialog(false)
                                        resetCreateInstitution()
                                    }}
                                >
                                    <div className="row p-15">

                                        <h5 className="center">Dados Órgão ou Fundo</h5>

                                        {
                                            institution?.created_at && <div className="row">
                                                <div
                                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                    style={{ position: 'relative' }}>
                                                    <CreatedAt createdAt={institution?.created_at}/>
                                                </div>
                                            </div>
                                        }

                                        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-2 p-15">
                                            <Field name="code" id="code"
                                                   type="customField">
                                                {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                    <span className="p-float-label">
                                                                    <InputText
                                                                        id="code"
                                                                        name="code"
                                                                        keyfilter="num"
                                                                        value={field.value}
                                                                        onChange={(event: any) => {
                                                                            setFieldValue('code', event.target.value)
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFieldTouched('code', true, true)
                                                                        }}
                                                                    />
                                                                    <label htmlFor="code">Código</label>
                                                                </span>
                                                )}
                                            </Field>
                                            <FormErrorMessage name="code"/>
                                        </div>

                                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-10 p-15">
                                            <Field name="name" id="name" type="customField">
                                                {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                    <span className="p-float-label">
                                                                    <InputText
                                                                        id="name"
                                                                        name="name"
                                                                        value={field.value}
                                                                        onChange={(event: any) => {
                                                                            setFieldValue('name', event.target.value)
                                                                        }}
                                                                        onBlur={() => {
                                                                            setFieldTouched('name', true, true)
                                                                        }}
                                                                    />
                                                                    <label htmlFor="name">Nome</label>
                                                                </span>
                                                )}
                                            </Field>
                                            <FormErrorMessage name="name"/>
                                        </div>

                                        <div
                                            className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-15">
                                            <label className="label-bold">Tipo</label>
                                            <Margin top={20} bottom={0} left={0} right={0}>
                                                <Field name="institution_type" id="institution_type"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <Dropdown
                                                            name="institution_type"
                                                            value={field.value}
                                                            options={institutionsTypes}
                                                            className="input-container"
                                                            placeholder="Selecione um Tipo"
                                                            onChange={(events: any) => {
                                                                setFieldValue('institution_type', events.target.value)
                                                            }}
                                                            onBlur={() => setFieldTouched('institution_type', true, true)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="institution_type"/>
                                            </Margin>

                                        </div>


                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-15">
                                            <label className="label-bold">Categoria</label>
                                            <Margin top={20} bottom={0} left={0} right={0}>
                                                <Field name="cost_category" id="cost_category"
                                                       type="customField">
                                                    {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                        <Dropdown
                                                            name="cost_category"
                                                            className="input-container"
                                                            options={costingCategoryType}
                                                            value={field.value}
                                                            disabled={values.institution_type !== TypeInstitution.DIRECT_ADMINISTRATION}
                                                            onChange={(events: any) => {
                                                                setFieldValue('cost_category', events.target.value)
                                                            }}
                                                            placeholder="Selecione uma Categoria"
                                                            onBlur={() => setFieldTouched('cost_category', true, true)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormErrorMessage name="cost_category"/>
                                            </Margin>
                                        </div>

                                    </div>

                                    <div className="p-15"/>

                                </Dialog>

                            </Form>
                        </React.Fragment>
                    )}
                </Formik>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    institution: state.institution.create.institution,
    error: state.institution.create.error,
    success: state.institution.create.success,
    loading: state.institution.create.loading,
    dialog: state.institution.create.dialog
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(InstitutionActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create))
