import Investments from '../../application/models/transactions/investments/investments'
import { IComponentState, IPaginator } from '../root.types'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import InvestmentsAnnualSummary from '../../application/models/transactions/investments/investments.annual.summary'
import { TransactionLocation } from '../costing/types'

export enum InvestmentsActionsTypes {
    RESET_INVESTMENTS = '@investments/RESET_INVESTMENTS',
    RESET_MONTHLY_PAGINATOR = '@investments/RESET_MONTHLY_PAGINATOR',
    RESET_ANNUAL_PAGINATOR = '@investments/RESET_ANNUAL_PAGINATOR',

    CHANGE_INVESTMENTS = '@investments/CHANGE_INVESTMENTS',
    CHANGE_DIALOG = '@investments/CHANGE_DIALOG',
    CHANGE_DATE = '@investments/CHANGE_DATE',
    CHANGE_PAGINATOR = '@investments/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@investments/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@investments/CHANGE_REMOVE_MODAL',
    CHANGE_MONTHLY_PAGINATOR = '@investments/CHANGE_MONTHLY_PAGINATOR',
    CHANGE_ANNUAL_PAGINATOR = '@investments/CHANGE_ANNUAL_PAGINATOR',

    CREATE_REQUEST = '@investments/CREATE_REQUEST',
    CREATE_SUCCESS = '@investments/CREATE_SUCCESS',
    CREATE_FAILURE = '@investments/CREATE_FAILURE',

    LOAD_REQUEST = '@investments/LOAD_REQUEST',
    LOAD_SUCCESS = '@investments/LOAD_SUCCESS',
    LOAD_FAILURE = '@investments/LOAD_FAILURE',

    FIND_REQUEST = '@investments/FIND_REQUEST',
    FIND_SUCCESS = '@investments/FIND_SUCCESS',
    FIND_FAILURE = '@investments/FIND_FAILURE',

    UPDATE_REQUEST = '@investments/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@investments/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@investments/UPDATE_FAILURE',

    REMOVE_REQUEST = '@investments/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@investments/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@investments/REMOVE_FAILURE',

    FIND_INSTITUTION_REQUEST = '@investments/FIND_INSTITUTION_REQUEST',
    FIND_INSTITUTION_SUCCESS = '@investments/FIND_INSTITUTION_SUCCESS',
    FIND_INSTITUTION_FAILURE = '@investments/FIND_INSTITUTION_FAILURE',

    LOAD_MONTHLY_REQUEST = '@investments/LOAD_MONTHLY_REQUEST',
    LOAD_MONTHLY_SUCCESS_RESET = '@investments/LOAD_MONTHLY_SUCCESS_RESET',
    LOAD_MONTHLY_SUCCESS_CONCAT = '@investments/LOAD_MONTHLY_SUCCESS_CONCAT',
    LOAD_MONTHLY_FAILURE = '@investments/LOAD_MONTHLY_FAILURE',

    LOAD_ANNUAL_REQUEST = '@investments/LOAD_ANNUAL_REQUEST',
    LOAD_ANNUAL_SUCCESS_RESET = '@investments/LOAD_ANNUAL_SUCCESS_RESET',
    LOAD_ANNUAL_SUCCESS_CONCAT = '@investments/LOAD_ANNUAL_SUCCESS_CONCAT',
    LOAD_ANNUAL_FAILURE = '@investments/LOAD_ANNUAL_FAILURE',

    LOAD_ANNUAL_DASH = '@investments/LOAD_ANNUAL_DASH',
    LOAD_ANNUAL_DASH_SUCCESS = '@investments/LOAD_ANNUAL_DASH_SUCCESS',
    LOAD_ANNUAL_DASH_FAILURE = '@investments/LOAD_ANNUAL_DASH_FAILURE'
}

/* Action types*/

export interface IActionCreate {
    readonly investments: Investments
    readonly location: TransactionLocation
}

export interface IActionLoad {
    readonly institutionId: string
    readonly paginator?: IPaginator
}

export interface IActionFind {
    readonly institutionId: string
    readonly investmentsId: string
}

export interface IActionRemove {
    readonly institutionId: string
    readonly investmentsId: string
    readonly paginator: IPaginator
    readonly date?: string
    readonly location: TransactionLocation
}

/* State types*/

interface ICreateState extends IComponentState {
    readonly investments: Investments
    readonly data: ErrorEvent
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly investments: Investments[]
    readonly data: ErrorEvent
    readonly paginator: IPaginator
    readonly institution: any | undefined
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly institutionId: string
    readonly visibilityModal: boolean
}

export interface IMonthlyState extends IComponentState {
    readonly monthlyInvestments: InvestmentsMonthlySummary
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

export interface IAnnualInvestments {
    readonly january?: InvestmentsMonthlySummary
    readonly february?: InvestmentsMonthlySummary
    readonly march?: InvestmentsMonthlySummary
    readonly april?: InvestmentsMonthlySummary
    readonly may?: InvestmentsMonthlySummary
    readonly june?: InvestmentsMonthlySummary
    readonly july?: InvestmentsMonthlySummary
    readonly august?: InvestmentsMonthlySummary
    readonly september?: InvestmentsMonthlySummary
    readonly october?: InvestmentsMonthlySummary
    readonly november?: InvestmentsMonthlySummary
    readonly december?: InvestmentsMonthlySummary
    readonly total?: number,
    readonly totalCovenant?: number,
    readonly totalDirectInvestments?: number
    readonly totalFinanceCharges?: number
    readonly totalFunds?: number
    readonly totalPrograms?: number
}

interface IAnnualInvestmentState extends IComponentState {
    readonly investmentsAnnual: IAnnualInvestments
    readonly data: ErrorEvent
    readonly lastSync: Date | undefined
    readonly paginator: IPaginator
}

interface IDashAnnualInvestmentState extends IComponentState {
    readonly investmentsAnnual: InvestmentsAnnualSummary
}

/* State Type */
export interface IInvestmentsState {
    readonly createInvestments: ICreateState
    readonly listInvestments: IListState
    readonly removeInvestments: IRemoveState
    readonly monthlyInvestments: IMonthlyState
    readonly annualInvestments: IAnnualInvestmentState
    readonly dashboard: IDashAnnualInvestmentState
}

