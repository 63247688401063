import * as Yup from 'yup'
import { TransactionValidator } from './transaction'

export class InstitutionTransactionValidator extends TransactionValidator {
    private static _institution_id: Yup.StringSchema<string | null> = Yup.string()
        .required('Selecione um Órgão')
        .nullable()

    static get validationScheme(): Yup.ObjectSchema<object> {
        return TransactionValidator
            .validationScheme
            .concat(
                Yup.object().shape({ institution_id: this.institution_id })
            )
    }

    static get institution_id(): Yup.StringSchema<string | null> {
        return this._institution_id
    }
}


