import { action } from 'typesafe-actions'

import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'
import { DebtActionTypes, IAnnualDebts } from './types'
import Debt from '../../application/models/transactions/debt/debt'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import DebtAnnualSummary from '../../application/models/transactions/debt/debt.annual.summary'
import { TransactionLocation } from '../costing/types'

/* Actions for Reset State */
export const resetDebt = () => action(DebtActionTypes.RESET, {})

/* Actions for Change Debt */
export const changeDebt = (debt: Debt) => action(DebtActionTypes.CHANGE_DEBT, { debt })

export const changeDialog = (dialog: boolean) => action(DebtActionTypes.CHANGE_DIALOG, { dialog })

export const changePaginator = (institutionId: string, paginator: IPaginator) => [
    action(DebtActionTypes.CHANGE_PAGINATOR, { paginator }),
    load(institutionId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    DebtActionTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, institutionId: string) => action(
    DebtActionTypes.CHANGE_REMOVE_MODAL, { visibilityModal, idForRemove, institutionId }
)

/* Actions for Create Debt */
export const create = (debt: Debt, location: TransactionLocation, dateOfInstallments?: string[]) => action(
    DebtActionTypes.CREATE_REQUEST, {
        debt,
        location,
        dateOfInstallments
    })

export const createSuccess = (debt: Debt) => action(
    DebtActionTypes.CREATE_SUCCESS,
    { debt }
)

export const createFailure = (error: ErrorEvent, debt: Debt) => action(
    DebtActionTypes.CREATE_FAILURE,
    { error, debt }
)

/* Actions for Load Debts */
export const load = (institutionId: string, paginator?: IPaginator) => action(
    DebtActionTypes.LOAD_REQUEST, { institutionId, paginator }
)

export const loadSuccess = (response: IAxiosResponse<Debt[]>) => action(
    DebtActionTypes.LOAD_SUCCESS,
    { debts: response.data, headers: response.headers }
)

export const loadFailure = (error: ErrorEvent) => action(DebtActionTypes.LOAD_FAILURE, { error })

/* Actions for Find Debt */
export const find = (institutionId: string, debtId: string) => action(
    DebtActionTypes.FIND_REQUEST, { institutionId, debtId }
)

export const findSuccess = (response: IAxiosResponse<Debt[]>) => action(
    DebtActionTypes.FIND_SUCCESS,
    { debt: response.data, headers: response.headers }
)

export const findFailure = (error: ErrorEvent) => action(DebtActionTypes.FIND_FAILURE, { error })

/* Actions for Remove Debt */
export const remove = (institutionId: string, debtId: string, location: TransactionLocation, paginator: IPaginator, date?: string) => action(
    DebtActionTypes.REMOVE_REQUEST, {
        institutionId,
        debtId,
        location,
        paginator,
        date
    }
)

export const removeSuccess = () => action(DebtActionTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(DebtActionTypes.REMOVE_FAILURE, { error })

/* Actions for Update Debt */
export const update = (debt: Debt, location: TransactionLocation) => action(
    DebtActionTypes.UPDATE_REQUEST,
    { debt, location }
)

export const updateSuccess = (debt: Debt) => action(DebtActionTypes.UPDATE_SUCCESS, { debt })

export const updateFailure = (error: ErrorEvent, debt: Debt) => action(
    DebtActionTypes.UPDATE_FAILURE,
    { error, debt }
)

/* Actions for get Institution */
export const findInstitution = (institutionId: string) => action(
    DebtActionTypes.FIND_INSTITUTION,
    { institutionId }
)

export const findInstitutionSuccess = (response: IAxiosResponse<Institution>) => action(
    DebtActionTypes.FIND_INSTITUTION_SUCCESS,
    { institution: response.data, headers: response.headers }
)

export const findInstitutionFailure = (error: ErrorEvent) => action(
    DebtActionTypes.FIND_INSTITUTION_FAILURE,
    { error }
)

/* Actions for Load Monthly Debt */
export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(DebtActionTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(DebtActionTypes.RESET_MONTHLY_PAGINATOR)

export const loadMonthly = (date?: string, paginator?: IPaginator) => action(
    DebtActionTypes.LOAD_MONTHLY, { date, paginator }
)

export const loadMonthlySuccessReset = (response: IAxiosResponse<DebtMonthlySummary>) => action(
    DebtActionTypes.LOAD_MONTHLY_SUCCESS_RESET,
    { monthlyDebt: response.data, headers: response.headers }
)

export const loadMonthlySuccessConcat = (response: IAxiosResponse<DebtMonthlySummary>) => action(
    DebtActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT,
    { monthlyDebt: response.data, headers: response.headers }
)

export const loadMonthlyFailure = (error: ErrorEvent) => action(
    DebtActionTypes.LOAD_MONTHLY_FAILURE,
    { error }
)

/* Actions for Load Annual Debt */
export const changeAnnualPaginator = (year: string, paginator?: IPaginator) => [
    action(DebtActionTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(DebtActionTypes.RESET_ANNUAL_PAGINATOR)

export const loadAnnual = (year: string, paginator?: IPaginator) => action(
    DebtActionTypes.LOAD_ANNUAL, { year, paginator }
)

export const loadAnnualSuccessReset = (response: IAnnualDebts) => action(
    DebtActionTypes.LOAD_ANNUAL_SUCCESS_RESET,
    response
)

export const loadAnnualSuccessConcat = (response: IAnnualDebts) => action(
    DebtActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT,
    response
)

export const loadAnnualFailure = (error: ErrorEvent) => action(
    DebtActionTypes.LOAD_ANNUAL_FAILURE,
    { error }
)

export const loadAnnualDash = (year: string) => action(
    DebtActionTypes.LOAD_ANNUAL_DASH, { year }
)

export const loadAnnualDashSuccess = (response: IAxiosResponse<DebtAnnualSummary>) => action(
    DebtActionTypes.LOAD_ANNUAL_DASH_SUCCESS,
    {
        annualResource: response.data
    }
)

export const loadAnnualDashFailure = (error: ErrorEvent) => action(
    DebtActionTypes.LOAD_ANNUAL_DASH_FAILURE,
    { error }
)
