import { lazy } from 'react'

const AnnualBalance = lazy(() => import('../containers/balance/annual'))
const MonthlyBalance = lazy(() => import('../containers/balance/monthly'))
const BalanceByAgent = lazy(() => import('../containers/balance/balance.by.agent'))

export const BALANCES_ROUTES = [
    {
        path: '/app/balances/annual',
        component: AnnualBalance,
        scopes: ['as:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/balances/monthly',
        component: MonthlyBalance,
        scopes: ['ms:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/balanceAgents/:balanceAgentId/balances',
        component: BalanceByAgent,
        scopes: ['bl:ra'],
        exact: true,
        private: true
    }
]
