import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType } from '../root.types'
import { DisbursementIOReportActionTypes, IActionLoad } from './types'
import { DisbursementIOReportItem } from '../../application/models/disbursement/disbursement.io.report.item'
import disbursementIOReportService from '../../../services/disbursement.io.report'
import { loadInputFailure, loadInputSuccess, loadOutputFailure, loadOutputSuccess } from './actions'
import { DisbursementTransactionType } from '../../application/utils/disbursement.transaction.type'

function* getReport(action: IActionType<IActionLoad>) {
    const { startDate, endDate, reportType, transactionCategory, institutionsIds } = action.payload
    try {
        const input: DisbursementIOReportItem[] = yield apply(
            disbursementIOReportService,
            disbursementIOReportService.getByPeriod,
            [startDate, endDate, DisbursementTransactionType.INPUT, reportType, transactionCategory, institutionsIds]
        )
        yield put(loadInputSuccess(input))
    } catch (err) {
        yield put(loadInputFailure())

    }
    try {
        const output: DisbursementIOReportItem[] = yield apply(
            disbursementIOReportService,
            disbursementIOReportService.getByPeriod,
            [startDate, endDate, DisbursementTransactionType.OUTPUT, reportType, transactionCategory, institutionsIds]
        )
        yield put(loadOutputSuccess(output))
    } catch (err) {
        yield put(loadOutputFailure())
    }
}

export default function* disbursementReportIOSaga() {
    return yield all([
        takeLatest(DisbursementIOReportActionTypes.LOAD_REQUEST, getReport)
    ])
}
