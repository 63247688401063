import { JsonUtils } from '../../utils/json.util'
import Institution from '../financial.agents/institution/institution'

/**
 * Implementation of the Disbursement Balance Config entity.
 * @param {string} [id] Identifier of resource.
 * @param {string} [created_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is saved.
 * @param {string} [updated_at] Date automatically generated in UTC format according to ISO 8601 at the time the
 * resource is updated.
 * @param {string} [start_date] Date of start configuration report item.
 * @param {string} [end_date] Date of end configuration report item.
 * @param {Institution[]} [institutions] Identifiers of institutions that must be included in the configuration.
 */
export class DisbursementBalanceConfig {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _start_date: string | undefined
    private _end_date: string | undefined
    private _institutions: Institution[] | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get start_date(): string | undefined {
        return this._start_date
    }

    set start_date(value: string | undefined) {
        this._start_date = value
    }

    get end_date(): string | undefined {
        return this._end_date
    }

    set end_date(value: string | undefined) {
        this._end_date = value
    }

    get institutions(): Institution[] | undefined {
        return this._institutions
    }

    set institutions(value: Institution[] | undefined) {
        this._institutions = value
    }

    public fromJSON(json: any): DisbursementBalanceConfig {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }

        if (json.id) {
            this.id = json.id
        }

        if (json.created_at) {
            this.created_at = json.created_at
        }

        if (json.updated_at) {
            this.updated_at = json.updated_at
        }

        if (json.start_date) {
            this.start_date = json.start_date
        }

        if (json.end_date) {
            this.end_date = json.end_date
        }

        if (json?.institutions?.length) {
            this.institutions = json
                .institutions
                .map((institution: Institution) => new Institution().fromJSON(institution))
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            created_at: this.created_at || undefined,
            updated_at: this.updated_at || undefined,
            start_date: this.start_date || undefined,
            end_date: this.end_date || undefined,
            institutions: this.institutions
                ?.map((institution: Institution) => institution.toJSON()) || []
        }
    }
}
