import { ApiService } from './api'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import Budget from '../store/application/models/budget/budget'
import Payee from '../store/application/models/financial.agents/payee/payee'

class BudgetService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(budget: Budget): Promise<IAxiosResponse<Budget>> {
        const url = `${this.apiVersion}/payees/${budget.payee_id}/budgets`
        return httpClient.post(url, budget.toJSON())
    }

    public getById(payeeId: string, budgetId: string): Promise<IAxiosResponse<Budget>> {
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/budgets/${budgetId}`)
    }

    public getAll(payeeId: string, paginator?: IPaginator): Promise<IAxiosResponse<Budget[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/budgets`, { params })
    }

    public update(budget: Budget): Promise<IAxiosResponse<Budget>> {
        const body = budget.toJSON()
        delete body.exercise_id
        const url = `${this.apiVersion}/payees/${budget.payee_id}/budgets/${budget.id}`
        return httpClient.patch(url, body)
    }

    public remove(payeeId: string, budgetId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payees/${payeeId}/budgets/${budgetId}`)
    }

    public async getManyByExercise(payees: Payee[], exerciseId: string): Promise<Map<string, Budget>> {
        const paginator: IPaginator = {
            first: 0,
            rows: 1,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: 'exercise_id',
                value: exerciseId
            }
        }

        const result: Map<string, Budget> = new Map<string, Budget>()

        for (const payee of payees) {
            const payeeId = payee?.id || ''
            const response: IAxiosResponse<Budget[]> = await this.getAll(payeeId, paginator)
            if (response?.data?.length) {
                result.set(payeeId, response.data[0])
            }
        }

        return result
    }
}

export default new BudgetService()
