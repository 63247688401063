import { LocaleSettings } from 'primereact/calendar'
import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const CHECK_DATE = process.env.REACT_APP_CHECK_DATE

export const LOCALE: LocaleSettings = {
    firstDayOfWeek: 0,
    dayNames: ['DOMINGO', 'SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
}

/**
 * Class with statistical methods to aid data manipulation
 */
class UtilDatePicker {
    /**
     *  Returns the month prior to the one informed by parameter
     * @param month, Selected month
     */
    public static prevMonth(month: string): string {
        return moment(month)
            .subtract(1, 'M')
            .toDate()
            .toISOString()
            .substr(0, 7)
    }

    /**
     * Returns the next month for the parameter informed
     * @param month, Selected month
     */
    public static nextMonth(month: string): string {
        return moment(month)
            .add(1, 'M')
            .toDate()
            .toISOString()
            .substr(0, 7)
    }

    /**
     * Transforms date object to string in dd/mm/yyy format
     * @param date, Date to be transformed
     */
    public static dateToString(date: any): string {
        if (!date) {
            return ''
        }

        date = date.toISOString().split('T')[0]

        const year = date.substr(0, 4)
        const mount = date.substr(5, 2)
        const day = date.substr(8, 2)
        date = `${day}/${mount}/${year}`

        return date
    }

    /**
     * Transform string object in dd/mm/yyyy format to date object
     * @param date, Date to be transformed
     */
    public static stringToDate(date: string): Date {
        const temp = date.split('/')
        return new Date(Number(temp[2]), Number(temp[1]) - 1, Number(temp[0]))
    }

    /**
     * Return the current month in yyyy-MM format
     * @param year, Selected year
     */
    public static getCurrentMonth(year?: number): string {
        const date = new Date().toLocaleDateString('pt-BR').split('/')
        return `${year ? year : date[2]}-${date[1]}`
    }

    /**
     * Transforms string from dd/mm/yyyy format to yyyy-mm-dd
     * @param date, Date to be formatted
     */
    public static usaFormatDate(date: string | undefined): string {
        return date ? this.formatDate(date, '/', '-') : ''
    }

    /**
     * Transforms string from yyyy-mm-dd format to dd/mm/yyyy
     * @param date, Date to be formatted
     */
    public static brFormatDate(date: string | undefined): string {
        return date ? this.formatDate(date, '-', '/') : ''
    }

    /**
     * Transforms Date object format to Day, dd Month of Year at hh:mm
     * @param date, Date to be formatted
     */
    public static brFormatDateWithTimeStamp(date: string | undefined): string {
        return moment(date).format('LLLL')
    }

    /**
     * Transforms string from mm/yyyy format to yyyy-mm
     * @param date, Date to be formatted
     */
    public static usaFormatMonth(date: string | undefined): string {
        return date ? this.formatMonth(date, '/', '-') : ''
    }

    /**
     * Transforms string from yyyy-mm-dd format to dd/mm/yyyy
     * @param date, Date to be formatted
     */
    public static brFormatMonth(date: string | undefined): string {
        return date ? this.formatMonth(date, '-', '/') : ''
    }

    /**
     * Checks if the date entered has already occurred
     * @param date, Date to be checked
     */
    public static hasOccurred(date: string | undefined): boolean {
        if (CHECK_DATE === 'false') {
            return false
        }
        const unitOfTime = date?.length && date?.length > 7 ? 'days' : 'months'
        try {
            const format = unitOfTime === 'days' ? 'yyyy-MM-DD' : 'yyyy-MM'
            const today = moment().format(format)
            const dateToMoment = moment(date, format)
            const diff = moment(today, format).diff(dateToMoment, unitOfTime)
            return diff > 0
        } catch (e) {
            return true
        }
    }

    /**
     * Check if the date entered is a future date
     * @param date, Date to be checked
     */
    public static hasFuture(date: string | undefined): boolean {
        if (CHECK_DATE === 'false') {
            return false
        }
        const unitOfTime = date?.length && date?.length > 7 ? 'days' : 'months'
        try {
            const format = unitOfTime === 'days' ? 'yyyy-MM-DD' : 'yyyy-MM'
            const today = moment().format(format)
            const dateToMoment = moment(date, format)
            const diff = dateToMoment.diff(moment(today, format), unitOfTime)
            return diff > 0
        } catch (e) {
            return true
        }
    }

    /**
     * Validates date in dd/mm/yyyy format
     * @param date, Date in dd/mm/yyyy format
     */
    public static isValidDate(date: any): boolean {
        const bits = date ? date.split('/') : ''
        const d = new Date(bits[2], bits[1] - 1, bits[0])
        return d && (d.getMonth() + 1) === Number(bits[1])
    }

    /**
     * Checks if a date in yyyy-mm-dd format falls on a weekend
     * @param day, Selected date
     */
    public static hasWeekEnd(day: string): boolean {
        const numberOfDay = new Date(`${day}T03:00:00.000Z`).getDay()
        return numberOfDay === 0 || numberOfDay === 6
    }

    /**
     * Generate previous day in week (monday - friday)
     * @param {Date} date Date selected.
     * @private
     */
    public static getPrevWeekDay(date: Date): Date {
        let result: Date
        let day: number
        let index: number = 1
        do {
            result = moment(date).subtract(index, 'day').toDate()
            day = result.getDay()
            index++
        } while (day === 0 || day === 6)
        return result
    }

    /**
     * Generate next day in week (monday - friday)
     * @param {Date} date Date selected
     * @private
     */
    public static getNextWeekDay(date: Date): Date {
        let result: Date
        let day: number
        let index: number = 1
        do {
            result = moment(date).add(index, 'day').toDate()
            day = result.getDay()
            index++
        } while (day === 0 || day === 6)
        return result
    }

    /**
     *  Formats the date according to the from and to parameters
     * @param date, Date to be formatted
     * @param from, String that specifies the origin of the format
     * @param to, String that specifies the target of the resulting format
     */
    private static formatDate(date: string, from: string, to: string): string {
        if (!date) {
            return date
        }
        const temp = date.split(from)
        if (temp.length === 1) {
            return temp[0]
        }
        return `${temp[2]}${to}${temp[1]}${to}${temp[0]}`
    }

    /* Formats the date according to the from and to parameters */
    private static formatMonth(date: string, from: string, to: string): string {
        if (!date) {
            return date
        }
        const temp = date.split(from)
        return `${temp[1]}${to}${temp[0]}`
    }
}

export default UtilDatePicker
