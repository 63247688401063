import httpClient from './config.axios'
import Event from '../store/application/models/transactions/event/event'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import { ApiService } from './api'

class EventService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public create(event: Event): Promise<IAxiosResponse<Event>> {
        return httpClient.post(`${this.apiVersion}/institutions/${event.institution_id}/events`, event.toJSON())
    }

    public getById(institutionId: string, eventId: string): Promise<IAxiosResponse<Event>> {
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/events/${eventId}`)
    }

    public getAll(institutionId: string, paginator?: IPaginator): Promise<IAxiosResponse<Event[]>> {
        const params: URLSearchParams = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/institutions/${institutionId}/events`, { params })
    }

    public update(event: Event): Promise<IAxiosResponse<Event>> {
        const body = event.toJSON()
        delete body.date
        const url = `${this.apiVersion}/institutions/${event.institution_id}/events/${event.id}`
        return httpClient.patch(url, body)
    }

    public remove(institutionId: string, eventId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/institutions/${institutionId}/events/${eventId}`)
    }
}

export default new EventService()
