import { IPaginator } from '../costing/types'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import EventMonthlySummary from '../../application/models/transactions/event/event.monthly.summary'
import InvestmentsMonthlySummary from '../../application/models/transactions/investments/investments.monthly.summary'
import ExpenseMonthlySummary from '../../application/models/transactions/expense/expense.monthly.summary'
import RevenueMonthlySummary from '../../application/models/transactions/revenue/revenue.monthly.summary'
import BalanceSummary from '../../application/models/balance/balance.summary'
import { IComponentState } from '../root.types'

/**
 * Action types
 */
export enum CashFlowActionTypes {

    CHANGE_MONTH = '@cashflow/CHANGE_MONTH',

    LOAD_REQUEST = '@cashflow/LOAD_REQUEST',

    LOAD_COSTING_SUCCESS = '@cashflow/LOAD_COSTING_SUCCESS',
    LOAD_COSTING_FAILURE = '@cashflow/LOAD_COSTING_FAILURE',

    LOAD_DEBT_SUCCESS = '@cashflow/LOAD_DEBT_SUCCESS',
    LOAD_DEBT_FAILURE = '@cashflow/LOAD_DEBT_FAILURE',

    LOAD_EVENT_SUCCESS = '@cashflow/LOAD_EVENT_SUCCESS',
    LOAD_EVENT_FAILURE = '@cashflow/LOAD_EVENT_FAILURE',

    LOAD_INVESTMENT_SUCCESS = '@cashflow/LOAD_INVESTMENT_SUCCESS',
    LOAD_INVESTMENT_FAILURE = '@cashflow/LOAD_INVESTMENT_FAILURE',

    LOAD_EXPENSE_SUCCESS = '@cashflow/LOAD_EXPENSE_SUCCESS',
    LOAD_EXPENSE_FAILURE = '@cashflow/LOAD_EXPENSE_FAILURE',

    LOAD_REVENUE_SUCCESS = '@cashflow/LOAD_REVENUE_SUCCESS',
    LOAD_REVENUE_FAILURE = '@cashflow/LOAD_REVENUE_FAILURE',

    LOAD_BALANCE_SUCCESS = '@cashflow/LOAD_BALANCE_SUCCESS',
    LOAD_BALANCE_FAILURE = '@cashflow/LOAD_BALANCE_FAILURE'
}

export interface IActionLoad {
    readonly date: string
    readonly paginator?: IPaginator
}


export interface IActionLoadResponse {
    readonly monthlyCosting?: CostMonthlySummary
    readonly monthlyDebt?: DebtMonthlySummary
    readonly monthlyEvent?: EventMonthlySummary
    readonly monthlyInvestment?: InvestmentsMonthlySummary
    readonly monthlyExpense?: ExpenseMonthlySummary
    readonly monthlyRevenue?: RevenueMonthlySummary
    readonly monthlyBalance?: BalanceSummary
}

export interface ICostingMonthly extends IComponentState {
    readonly monthlyCosting?: CostMonthlySummary
}

export interface IDebtMonthly extends IComponentState {
    readonly monthlyDebt?: DebtMonthlySummary
}

export interface IEventMonthly extends IComponentState {
    readonly monthlyEvent?: EventMonthlySummary
}

export interface IInvestmentMonthly extends IComponentState {
    readonly monthlyInvestment?: InvestmentsMonthlySummary
}

export interface IExpenseMonthly extends IComponentState {
    readonly monthlyExpense?: ExpenseMonthlySummary
}

export interface IRevenueMonthly extends IComponentState {
    readonly monthlyRevenue?: RevenueMonthlySummary
}

export interface IBalanceMonthly extends IComponentState {
    readonly monthlyBalance?: BalanceSummary
}


/**
 * State type
 */
export interface ICashFlowState {
    readonly month: string
    readonly paginator: IPaginator
    readonly costing: ICostingMonthly
    readonly debt: IDebtMonthly
    readonly event: IEventMonthly
    readonly investment: IInvestmentMonthly
    readonly expense: IExpenseMonthly
    readonly revenue: IRevenueMonthly
    readonly balance: IBalanceMonthly
}
