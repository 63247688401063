import Institution, { CostingCategory, TypeInstitution } from './institution'
import { AnnualFinancialAgent } from '../annual.financial.agent'


export class AnnualInstitution extends AnnualFinancialAgent {
    private _institution: Institution | undefined

    get id(): string | undefined {
        return this._institution?.id
    }

    get institution_type(): TypeInstitution | undefined {
        return this._institution?.institution_type
    }

    get name(): string | undefined {
        return this._institution?.name
    }

    get cost_category(): CostingCategory | undefined {
        return this._institution?.cost_category
    }

    public fromJSON(json: any): AnnualInstitution {

        super.fromJSON(json)

        if (json) {
            this._institution = new Institution().fromJSON(json)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...this._institution?.toJSON(),
            ...super.toJSON()
        }
    }
}
