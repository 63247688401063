import { all, apply, put, select, takeLatest } from 'redux-saga/effects'
import { BalanceAgentTypes, IActionCreate, IActionFind, IActionLoad, IActionRemove } from './types'

import {
    createBalanceAgentFailure,
    createBalanceAgentSuccess,
    findBalanceAgentFailure,
    findBalanceAgentSuccess,
    loadBalanceAgentFailure,
    loadBalanceAgents,
    loadBalanceAgentSuccess,
    removeBalanceAgentFailure,
    removeBalanceAgentSuccess,
    updateBalanceAgentFailure,
    updateBalanceAgentSuccess
} from './actions'
import { Toast } from '../../../services/toast'
import balanceAgentService from '../../../services/balance.agent'
import { IActionType, IPaginator } from '../root.types'
import { IApplicationState } from '../../index'

const toastService = Toast.getInstance()

function* create(action: IActionType<IActionCreate>) {
    try {
        const { balanceAgent } = action.payload
        const response = yield apply(
            balanceAgentService,
            balanceAgentService.create, [balanceAgent])
        yield put<any>(createBalanceAgentSuccess(response))
        toastService.show('success', 'Dados criado com sucesso', '')
    } catch (err) {
        yield put(createBalanceAgentFailure(err))
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    const { paginator } = action.payload
    const listPaginator: IPaginator = yield select((state: IApplicationState) => state.balanceAgent.listBalanceAgents.paginator)
    try {
        const response = yield apply(
            balanceAgentService,
            balanceAgentService.getAll, [paginator || listPaginator]
        )
        yield put(loadBalanceAgentSuccess(response))
    } catch (err) {
        yield put(loadBalanceAgentFailure(err))
    }
}

function* getById(action: IActionType<IActionFind>) {

    try {
        const { balanceAgentId } = action.payload
        const response = yield apply(
            balanceAgentService,
            balanceAgentService.getById, [balanceAgentId]
        )
        yield put(findBalanceAgentSuccess(response))
    } catch (err) {
        yield put(findBalanceAgentFailure(err))
    }
}

function* update(action: IActionType<IActionCreate>) {
    const { balanceAgent } = action.payload
    try {
        const response = yield apply(
            balanceAgentService,
            balanceAgentService.update, [balanceAgent]
        )
        yield put<any>(updateBalanceAgentSuccess(response))
        toastService.show('success', 'Dados atualizado com sucesso', '')
    } catch (err) {
        yield put(updateBalanceAgentFailure(err))
    }
}

function* remove(action: IActionType<IActionRemove>) {
    const { idForRemove } = action.payload
    try {
        yield apply(
            balanceAgentService,
            balanceAgentService.remove, [idForRemove]
        )
        yield put(removeBalanceAgentSuccess())
        yield put(loadBalanceAgents())
        toastService.show('success', 'Dados removido com sucesso', '')
    } catch (err) {
        yield put(removeBalanceAgentFailure(err))
    }
}

export default function* balanceAgentSagas() {
    return yield all([
        takeLatest(BalanceAgentTypes.CREATE_REQUEST, create),
        takeLatest(BalanceAgentTypes.LOAD_REQUEST, getAll),
        takeLatest(BalanceAgentTypes.FIND_REQUEST, getById),
        takeLatest(BalanceAgentTypes.UPDATE_REQUEST, update),
        takeLatest(BalanceAgentTypes.REMOVE_REQUEST, remove)
    ])
}
