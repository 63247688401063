import { JsonUtils } from '../../utils/json.util'

/**
 * Implementation of the Conference Revenue Item entity.
 *
 */
export class ConferenceItem {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _date: string | undefined
    private _value: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): string | undefined {
        return this._value
    }

    set value(value: string | undefined) {
        this._value = value
    }

    public fromJSON(json: any): ConferenceItem {
        if (!json) {
            return this
        }
        if (typeof json === 'string' && JsonUtils.isJsonString(json)) {
            json = JSON.parse(json)
        }
        if (json.id !== undefined) {
            this.id = json.id
        }
        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }
        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }
        if (json.date !== undefined) {
            this.date = json.date
        }
        if (json.value !== undefined) {
            const valueDot: string = `${json.value?.replaceAll('.', '{.}')}`
            const valueComma: string = `${valueDot?.replaceAll(',', '{,}')}`
            this.value = valueComma
                ?.replaceAll('{.}', ',')
                ?.replaceAll('{,}', '.')
        }


        return this
    }

    public toJSON(): any {
        return {
            id: this.id,
            created_at: this.created_at,
            updated_at: this.updated_at,
            date: this.date,
            value: this.value
        }
    }
}
