import { action } from 'typesafe-actions'
import { BalanceAgentTypes } from './types'
import BalanceAgent from '../../application/models/balance/balance.agent'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'

/* Actions for Reset State */
export const resetBalanceAgent = () => action(BalanceAgentTypes.RESET_BALANCE_AGENT, {})

/* Actions for Change */
export const changeBalanceAgent = (balanceAgent: BalanceAgent) => action(BalanceAgentTypes.CHANGE_BALANCE_AGENT, { balanceAgent })

export const changePaginator = (paginator: IPaginator) => [
    action(BalanceAgentTypes.CHANGE_PAGINATOR, { paginator }),
    loadBalanceAgents(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(BalanceAgentTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string) => action(
    BalanceAgentTypes.CHANGE_REMOVE_MODAL, {
        visibilityModal,
        idForRemove
    }
)

/* Actions for Change Dialog Create */
export const changeDialogCreate = (dialog: boolean) => action(BalanceAgentTypes.CHANGE_DIALOG_CREATE, { dialog })

/* Actions for Create Bank Account */
export const createBalanceAgent = (balanceAgent: BalanceAgent) => action(BalanceAgentTypes.CREATE_REQUEST, { balanceAgent })

export const createBalanceAgentSuccess = (balanceAgent: BalanceAgent) => [
    action(BalanceAgentTypes.CREATE_SUCCESS, { balanceAgent }),
    loadBalanceAgents()
]

export const createBalanceAgentFailure = (error: ErrorEvent) => action(BalanceAgentTypes.CREATE_FAILURE, { error })

/* Actions for Load Bank Account */
export const loadBalanceAgents = (paginator?: IPaginator) => action(BalanceAgentTypes.LOAD_REQUEST, { paginator })

export const loadBalanceAgentSuccess = (response: IAxiosResponse<BalanceAgent[]>) => action(
    BalanceAgentTypes.LOAD_SUCCESS, {
        balanceAgents: response.data,
        headers: response.headers
    }
)

export const loadBalanceAgentFailure = (error: ErrorEvent) => action(BalanceAgentTypes.LOAD_FAILURE, { error })

/* Actions for Find Bank Account */
export const findBalanceAgent = (bankAccountId: string) => action(BalanceAgentTypes.FIND_REQUEST, { balanceAgentId: bankAccountId })

export const findBalanceAgentSuccess = (response: IAxiosResponse<BalanceAgent[]>) => action(
    BalanceAgentTypes.FIND_SUCCESS, {
        balanceAgents: response.data,
        headers: response.headers
    }
)

export const findBalanceAgentFailure = (error: ErrorEvent) => action(BalanceAgentTypes.FIND_FAILURE, { error })

/* Actions for Update Bank Account */
export const updateBalanceAgent = (balanceAgent: BalanceAgent) => action(BalanceAgentTypes.UPDATE_REQUEST, { balanceAgent })

export const updateBalanceAgentSuccess = (balanceAgent: BalanceAgent) => [
    action(BalanceAgentTypes.UPDATE_SUCCESS, { balanceAgent }),
    loadBalanceAgents()
]

export const updateBalanceAgentFailure = (error: ErrorEvent) => action(BalanceAgentTypes.UPDATE_FAILURE, { error })

/* Actions for Remove Bank Account */
export const removeBalanceAgent = (idForRemove: string) => action(BalanceAgentTypes.REMOVE_REQUEST, { idForRemove })

export const removeBalanceAgentSuccess = () => action(BalanceAgentTypes.REMOVE_SUCCESS, {})

export const removeBalanceAgentFailure = (error: ErrorEvent) => action(BalanceAgentTypes.REMOVE_FAILURE, { error })
