import { action } from 'typesafe-actions'

import { CashFlowActionTypes, IActionLoadResponse } from './types'
import { INITIAL_STATE } from './reducer'

export const changeMonth = (month: string) => [
    action(CashFlowActionTypes.CHANGE_MONTH, { month }),
    load(month)
]

export const load = (date: string) => {
    const { paginator } = INITIAL_STATE
    return action(CashFlowActionTypes.LOAD_REQUEST, {
        date,
        paginator
    })
}

/* Load Monthly Costing */
export const loadCostingSuccess = (response: IActionLoadResponse) => {
    const { monthlyCosting } = response
    return action(CashFlowActionTypes.LOAD_COSTING_SUCCESS, { monthlyCosting })
}

export const loadCostingFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_COSTING_FAILURE, { error })

/* Load Monthly Debt */
export const loadDebtSuccess = (response: IActionLoadResponse) => {
    const { monthlyDebt } = response
    return action(CashFlowActionTypes.LOAD_DEBT_SUCCESS, { monthlyDebt })
}

export const loadDebtFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_DEBT_FAILURE, { error })

/* Load Monthly Event */
export const loadEventSuccess = (response: IActionLoadResponse) => {
    const { monthlyEvent } = response
    return action(CashFlowActionTypes.LOAD_EVENT_SUCCESS, { monthlyEvent })
}

export const loadEventFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_EVENT_FAILURE, { error })

/* Load Monthly Investment */
export const loadInvestmentSuccess = (response: IActionLoadResponse) => {
    const { monthlyInvestment } = response
    return action(CashFlowActionTypes.LOAD_INVESTMENT_SUCCESS, { monthlyInvestment })
}

export const loadInvestmentFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_INVESTMENT_FAILURE, { error })

/* Load Monthly Expense */
export const loadExpenseSuccess = (response: IActionLoadResponse) => {
    const { monthlyExpense } = response
    return action(CashFlowActionTypes.LOAD_EXPENSE_SUCCESS, { monthlyExpense })
}

export const loadExpenseFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_EXPENSE_FAILURE, { error })

/* Load Monthly Revenue */
export const loadRevenueSuccess = (response: IActionLoadResponse) => {
    const { monthlyRevenue } = response
    return action(CashFlowActionTypes.LOAD_REVENUE_SUCCESS, { monthlyRevenue })
}

export const loadRevenueFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_REVENUE_FAILURE, { error })

/* Load Monthly Balance */
export const loadBalanceSuccess = (response: IActionLoadResponse) => {
    const { monthlyBalance } = response
    return action(CashFlowActionTypes.LOAD_BALANCE_SUCCESS, { monthlyBalance })
}

export const loadBalanceFailure = (error: ErrorEvent) => action(CashFlowActionTypes.LOAD_BALANCE_FAILURE, { error })
