import { Reducer } from 'redux'
import { BudgetTypes, IBudgetState } from './types'
import Budget from '../../application/models/budget/budget'
import Payee from '../../application/models/financial.agents/payee/payee'

export const INITIAL_STATE: IBudgetState = {
    create: {
        budget: new Budget(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        budgets: [],
        data: new ErrorEvent(''),
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        payee: new Payee()
    },
    remove: {
        payeeId: '',
        budgetId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<IBudgetState> = (state: IBudgetState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case BudgetTypes.RESET: {
            return {
                ...state,
                create: INITIAL_STATE.create
            }
        }

        case BudgetTypes.RESET_ALL: {
            return INITIAL_STATE
        }

        case BudgetTypes.CHANGE_BUDGET:
            const { budget: changeBudget } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: false,
                    success: false,
                    budget: new Budget().fromJSON(changeBudget)
                }
            }

        case BudgetTypes.CHANGE_DIALOG_CREATE:
            const { dialog } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    dialog
                }
            }

        case BudgetTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    paginator
                }
            }

        case BudgetTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    paginator: {
                        ...state.list.paginator,
                        search
                    }
                }
            }

        case BudgetTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, budgetId } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    visibilityModal,
                    budgetId
                }
            }

        case BudgetTypes.CREATE_REQUEST :
            const { budget: newRequestBudget } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    budget: newRequestBudget
                }
            }

        case BudgetTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    budget: new Budget(),
                    success: false,
                    loading: false
                }
            }

        case BudgetTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: createError
                }
            }

        case BudgetTypes.LOAD_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true
                }
            }

        case BudgetTypes.LOAD_SUCCESS:
            const { budgets, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    budgets: budgets?.length ? budgets.map(budget => new Budget().fromJSON(budget)) : [],
                    paginator: {
                        ...state.list.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case BudgetTypes.LOAD_FAILURE:
            const { error: loadError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: false,
                    error: true,
                    data: loadError
                }
            }

        case BudgetTypes.FIND_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case BudgetTypes.FIND_SUCCESS:
            const { budget: budgetFound } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    budget: new Budget().fromJSON(budgetFound),
                    loading: false
                }
            }

        case BudgetTypes.FIND_FAILURE:
            const { error: findError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: findError
                }
            }

        case BudgetTypes.UPDATE_REQUEST:
            const { budget: updateBudget } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    budget: updateBudget
                }
            }

        case BudgetTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    dialog: true
                }
            }

        case BudgetTypes.UPDATE_FAILURE:
            const { error: updateError } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: true,
                    data: updateError
                }
            }

        case BudgetTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true,
                    visibilityModal: false
                }
            }

        case BudgetTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    budgetId: ''
                }
            }

        case BudgetTypes.REMOVE_FAILURE:
            const { error: removeError } = action.payload
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    success: false,
                    visibilityModal: true,
                    error: true,
                    data: removeError
                }
            }

        case BudgetTypes.FIND_PAYEE_REQUEST:
            return {
                ...state,
                list: {
                    ...state.list,
                    payee: undefined
                }
            }

        case BudgetTypes.FIND_PAYEE_SUCCESS:
            const { payee } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    payee
                },
                remove: {
                    ...state.remove,
                    payeeId: payee.id
                }
            }

        case BudgetTypes.FIND_PAYEE_FAILURE:
            const { error: findPayeeError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    error: true,
                    data: findPayeeError
                },
                remove: {
                    ...state.remove,
                    payeeId: ''
                }
            }

        default:
            return state
    }
}

export default reducer
