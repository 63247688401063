import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Field, Form, Formik } from 'formik'
import '../container.style.scss'

import * as PrevisionActions from '../../store/ducks/prevision/actions'
import { IApplicationState } from '../../store'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Toast } from '../../services/toast'
import Spinner from '../../components/spinner/spinner'
import CreatedAt from '../../components/created.at/created.at'
import { FormErrorMessage } from '../../components/form.error.message/form.error.message'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { VerifyScopes } from '../../components/verify.scopes/verify.scopes'
import { LogicalStrategy } from '../../services/auth'
import { ICreateState } from '../../store/ducks/prevision/types'
import Prevision from '../../store/application/models/prevision/prevision'
import { IPaginator } from '../../store/ducks/root.types'
import { PrevisionValidator } from '../../store/application/validators/prevision'
import UtilDatePicker from '../../components/date.picker/utils'
import { InputText } from 'primereact/inputtext'
import Currency from '../../components/currency/currency'
import { PrevisionTransactionTypes } from '../../store/application/utils/prevision.transaction.types'
import { Margin } from '../costing/costing.by.institution'

interface IState extends ICreateState {
    readonly financialAgentId: string
    readonly transactionType: PrevisionTransactionTypes
    readonly paginator: IPaginator
}

interface IDispatchProps extends RouteComponentProps<any> {
    create(
        financialAgentId: string,
        transactionType: PrevisionTransactionTypes,
        previsions: Prevision[],
        paginator?: IPaginator): void

    update(prevision: Prevision, transactionType: PrevisionTransactionTypes, paginator: IPaginator): void

    changeDialogCreate(dialog: boolean): void
}

type Props = IState & IDispatchProps

class CreatePrevision extends Component<Props> {

    private spinnerMessage: string
    private toastService: Toast

    constructor(props: Props) {
        super(props);

        this.spinnerMessage = ''
        this.toastService = Toast.getInstance()
    }

    public handleSubmit = async (values) => {
        const result = new Prevision().fromJSON({ ...values, date: UtilDatePicker.usaFormatMonth(values.date) })
        if (result.id) {
            this.spinnerMessage = 'Atualizando previsão...'
            const { update, transactionType, paginator } = this.props
            update(result, transactionType || result.transaction_type, paginator)
        } else {
            const { create, financialAgentId, transactionType, paginator } = this.props
            this.spinnerMessage = 'Adicionando previsão...'
            create(
                result.financialagent_id || financialAgentId,
                transactionType,
                [result],
                paginator
            )
        }
    }

    public render() {

        const {
            prevision,
            dialog,
            changeDialogCreate,
            transactionType,
            financialAgentId,
            loading
        } = this.props

        return (
            <React.Fragment>
                {this.props.loading && <Spinner message={this.spinnerMessage}/>}
                <Formik
                    initialValues={{
                        ...prevision.toJSON(),
                        date: UtilDatePicker.brFormatMonth(prevision.date),
                        transaction_type: prevision?.transaction_type || transactionType,
                        financialagent_id: prevision?.financialagent_id || financialAgentId
                    }}
                    enableReinitialize={true}
                    validationSchema={PrevisionValidator.validationScheme}
                    onSubmit={this.handleSubmit}
                    isInitialValid={false}>
                    {({ isValid, resetForm }) => (
                        <Form>
                            <Dialog
                                header={prevision?.id ? 'Atualizar previsão' : 'Adicionar previsão'}
                                footer={
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            label="Fechar"
                                            className="p-button-secondary left"
                                            type="button"
                                            icon="pi pi-times"
                                            onClick={() => {
                                                resetForm()
                                                changeDialogCreate(false)
                                            }}
                                        />
                                        <VerifyScopes
                                            scopes={['pr:c', 'pr:u']}
                                            logicalStrategy={LogicalStrategy.OR}>
                                            <Button
                                                label="Salvar"
                                                type="submit"
                                                className="p-button-primary right"
                                                icon="pi pi-save"
                                                disabled={!isValid}/>
                                        </VerifyScopes>
                                    </div>
                                }
                                visible={dialog && !loading}
                                modal={true}
                                className="dialog max-width-700"
                                onHide={() => {
                                    resetForm()
                                    changeDialogCreate(false)
                                }}
                            >
                                <div style={{ padding: '20px' }}>
                                    <h5 className="center">Dados da Previsão</h5>

                                    {
                                        prevision?.created_at && <Margin top={0} bottom={20} left={0} right={0}>
                                            <div className="row">
                                                <div
                                                    className="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                    style={{ position: 'relative' }}>
                                                    <CreatedAt createdAt={prevision?.created_at}/>
                                                </div>
                                            </div>
                                        </Margin>
                                    }

                                    <div className="row">

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-tb-5">
                                            <label className="label-bold" htmlFor="value">Valor</label>
                                            <Field name="expected_value" id="expected_value"
                                                   type="customField">
                                                {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                    <span className="p-inputgroup">
                                                        <span className="p-inputgroup-addon">R$: </span>
                                                        <Currency
                                                            id="expected_value"
                                                            name="expected_value"
                                                            value={field.value}
                                                            onChange={(e: any, value: number) => {
                                                                setFieldValue('expected_value', value)
                                                            }}
                                                            onBlur={() => {
                                                                setFieldTouched('expected_value', true, true)
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                            </Field>
                                            <FormErrorMessage name="expected_value"/>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 m-tb-5">
                                            <label className="label-bold" htmlFor="date">
                                                Mês
                                            </label>
                                            <Field name="date" id="date"
                                                   type="customField">
                                                {({ field, form: { setFieldValue, setFieldTouched } }) => (
                                                    <InputText
                                                        id="date"
                                                        name="date"
                                                        value={field.value}
                                                        onChange={(event: any) => {
                                                            setFieldValue('date', event.target.value)
                                                        }}
                                                        onBlur={() => {
                                                            setFieldTouched('date', true, true)
                                                        }}
                                                        readOnly={true}
                                                    />
                                                )}
                                            </Field>
                                            <FormErrorMessage name="date"/>
                                        </div>

                                    </div>


                                </div>

                            </Dialog>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    prevision: state.prevision.create.prevision,
    error: state.prevision.create.error,
    success: state.prevision.create.success,
    loading: state.prevision.create.loading,
    dialog: state.prevision.create.dialog,
    transactionType: state.prevision.transactionType,
    financialAgentId: state.prevision.financialAgentId,
    paginator: state.prevision.paginator
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(PrevisionActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePrevision))
