import Expense from './expense'
import { MonthlyPayee } from '../../financial.agents/payee/monthly.payee'
import MonthlySummary from '../../summary/monthly.summary'

export default class ExpenseMonthlySummary extends MonthlySummary<MonthlyPayee, Expense> {
    private _total_unmanageable: number | undefined
    private _total_personnel_expenses: number | undefined
    private _total_administration_charges: number | undefined

    get total_unmanageable(): number | undefined {
        return this._total_unmanageable
    }

    set total_unmanageable(value: number | undefined) {
        this._total_unmanageable = value
    }

    get total_personnel_expenses(): number | undefined {
        return this._total_personnel_expenses
    }

    set total_personnel_expenses(value: number | undefined) {
        this._total_personnel_expenses = value
    }

    get total_administration_charges(): number | undefined {
        return this._total_administration_charges
    }

    set total_administration_charges(value: number | undefined) {
        this._total_administration_charges = value
    }

    public fromJSON(json: any): ExpenseMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyPayee().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_unmanageable !== undefined) {
            this._total_unmanageable = json.total_unmanageable
        }
        if (json.total_personnel_expenses !== undefined) {
            this._total_personnel_expenses = json.total_personnel_expenses
        }
        if (json.total_administration_charges !== undefined) {
            this._total_administration_charges = json.total_administration_charges
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_unmanageable: this.total_unmanageable ? this.total_unmanageable : undefined,
            total_personnel_expenses: this.total_personnel_expenses ? this.total_personnel_expenses : undefined,
            total_administration_charges: this.total_administration_charges ?
                this.total_administration_charges : undefined
        }
    }

    public concatFromJSON(json: any): ExpenseMonthlySummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new MonthlyPayee().fromJSON(financialAgent))
        }

        super.concatFromJSON(json)

        if (json.total_unmanageable !== undefined) {
            this._total_unmanageable += json.total_unmanageable
        }
        if (json.total_personnel_expenses !== undefined) {
            this._total_personnel_expenses += json.total_personnel_expenses
        }
        if (json.total_administration_charges !== undefined) {
            this._total_administration_charges += json.total_administration_charges
        }

        return this
    }
}
