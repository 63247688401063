import { lazy } from 'react'

const Scenario = lazy(() => import('../containers/scenario/scenario'))
const ScenarioManagement = lazy(() => import('../containers/scenario/scenario.management'))

export const SCENARIOS_ROUTES = [
    {
        path: '/app/scenarios/management',
        component: ScenarioManagement,
        exact: true,
        private: true,
        scopes: ['sc:ra']
    },
    {
        path: '/app/scenarios/:percentage',
        component: Scenario,
        exact: true,
        private: true,
        scopes: ['sc:ra']
    }
]
