import { IComponentState, IPaginator } from '../root.types'
import Payer from '../../application/models/financial.agents/payer/payer'

export enum PayerActionsTypes {
    RESET_PAYER = '@payers/RESET_PAYER',

    CHANGE_PAYER = '@payers/CHANGE_PAYER',
    CHANGE_PAGINATOR = '@payers/CHANGE_PAGINATOR',
    CHANGE_SEARCH_PAGINATOR = '@payers/CHANGE_SEARCH_PAGINATOR',
    CHANGE_REMOVE_MODAL = '@payers/CHANGE_REMOVE_MODAL',
    CHANGE_DIALOG_CREATE = '@payers/CHANGE_DIALOG_CREATE',

    CREATE_REQUEST = '@payers/CREATE_REQUEST',
    CREATE_SUCCESS = '@payers/CREATE_SUCCESS',
    CREATE_FAILURE = '@payers/CREATE_FAILURE',

    LOAD_REQUEST = '@payers/LOAD_REQUEST',
    LOAD_SUCCESS = '@payers/LOAD_SUCCESS',
    LOAD_FAILURE = '@payers/LOAD_FAILURE',

    FIND_REQUEST = '@payers/FIND_REQUEST',
    FIND_SUCCESS = '@payers/FIND_SUCCESS',
    FIND_FAILURE = '@payers/FIND_FAILURE',

    UPDATE_REQUEST = '@payers/UPDATE_REQUEST',
    UPDATE_SUCCESS = '@payers/UPDATE_SUCCESS',
    UPDATE_FAILURE = '@payers/UPDATE_FAILURE',

    REMOVE_REQUEST = '@payers/REMOVE_REQUEST',
    REMOVE_SUCCESS = '@payers/REMOVE_SUCCESS',
    REMOVE_FAILURE = '@payers/REMOVE_FAILURE',
}

/*
* Interface Actions
* */
export interface IActionCreate {
    payer: Payer
}

export interface IActionLoad {
    payerId: string
    paginator?: IPaginator
}

export interface IActionFind {
    payerId: string
}

export interface IActionRemove {
    idForRemove: string
    paginator: IPaginator
}

/*
* Interface Component State
* */
interface ICreateState extends IComponentState {
    readonly payer: Payer
    readonly dialog: boolean
}

interface IListState extends IComponentState {
    readonly payers: Payer[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly idForRemove: string
    readonly removeVisibilityModal: boolean
}

/* State Type */
export interface IPayersState {
    readonly createPayer: ICreateState
    readonly listPayers: IListState
    readonly removePayer: IRemoveState
}

