import { IAxiosResponse } from '../store/ducks/root.types'
import { IPaginator } from '../store/ducks/costing/types'
import { ApiService } from './api'
import httpClient from './config.axios'
import Expense from '../store/application/models/transactions/expense/expense'

class ExpenseService extends ApiService {

    constructor(private apiVersion: string = 'v1') {
        super()
    }

    public async create(expense: Expense): Promise<IAxiosResponse<Expense>> {
        return httpClient.post(`${this.apiVersion}/payees/${expense.payee_id}/expenses`, expense.toJSON())
    }

    public async getById(payeeId: string, expenseId: string): Promise<IAxiosResponse<Expense>> {
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/expenses/${expenseId}`)
    }

    public async getAll(payeeId: string, paginator?: IPaginator): Promise<IAxiosResponse<Expense[]>> {
        const params = this.buildDefaultParams(paginator)
        return httpClient.get(`${this.apiVersion}/payees/${payeeId}/expenses`, { params })
    }

    public async update(expense: Expense): Promise<IAxiosResponse<Expense>> {
        const body = expense.toJSON()
        delete body.date
        const url = `${this.apiVersion}/payees/${expense.payee_id}/expenses/${expense.id}`
        return httpClient.patch(url, body)
    }

    public async remove(payeeId: string, expenseId: string): Promise<IAxiosResponse<void>> {
        return httpClient.delete(`${this.apiVersion}/payees/${payeeId}/expenses/${expenseId}`)
    }
}

export default new ExpenseService()

