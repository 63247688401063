/**
 * Action types
 */
import { UserTypes } from '../../application/models/auth/user'

export enum AuthTypes {
    CHANGE_USERNAME = '@auth/CHANGE_USERNAME',
    CHANGE_PASSWORD = '@auth/CHANGE_PASSWORD',
    CHANGE_ICON_PASSWORD = '@auth/CHANGE_ICON_PASSWORD',
    RESET_ERROR = '@auth/RESET_ERROR',

    LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
    LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
    LOGIN_FAILURE = '@auth/LOGIN_FAILURE',

    LOGIN_BY_CERT = '@auth/LOGIN_BY_CERT',
    LOGIN_BY_CERT_SUCCESS = '@auth/LOGIN_BY_CERT_SUCCESS',
    LOGIN_BY_CERT_FAILURE = '@auth/LOGIN_BY_CERT_FAILURE'
}

/**
 * Data types
 */
export interface IAccessToken {
    sub: string
    sub_type: UserTypes
    iss: string
    exp: number
    iat: number
    scope: string
}

export interface IAuth {
    username: string
    password: string
}

/**
 * State type
 */
export interface IAuthState {
    readonly data: IAccessToken
    readonly loading: boolean
    readonly error: boolean,
    readonly passwordIcon: string
}
