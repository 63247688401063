import AnnualSummary from '../../summary/annual.summary'
import { AnnualInstitution } from '../../financial.agents/institution/annual.institution'
import { MonthlyInstitution } from '../../financial.agents/institution/monthly.institution'
import { AnnualTransaction } from '../annual.transaction'
import Prevision from '../../prevision/prevision'
import { IAnnualEvent } from '../../../../ducks/events/types'
import Event from './event'

export default class EventAnnualSummary extends AnnualSummary<AnnualInstitution> {
    private _total_realized: number | undefined
    private _total_unrealized: number | undefined

    get total_realized(): number | undefined {
        return this._total_realized
    }

    set total_realized(value: number | undefined) {
        this._total_realized = value
    }

    get total_unrealized(): number | undefined {
        return this._total_unrealized
    }

    set total_unrealized(value: number | undefined) {
        this._total_unrealized = value
    }

    public fromJSON(json: any): EventAnnualSummary {

        if (json?.financial_agents?.length) {
            json.financial_agents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
        }

        super.fromJSON(json)

        if (json.total_realized !== undefined) {
            this._total_realized = json.total_realized
        }

        if (json.total_unrealized !== undefined) {
            this._total_unrealized = json.total_unrealized
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_realized: this.total_realized ? this.total_realized : undefined,
            total_unrealized: this.total_unrealized ? this.total_unrealized : undefined
        }
    }

    public concatFromJSON(json: any): EventAnnualSummary {

        if (json?.financial_agents?.length) {
            const concatFinancialAgents = json.financial_agents
                .map(financialAgent => new AnnualInstitution().fromJSON(financialAgent))
            json.financial_agents = json.financial_agents?.concat(concatFinancialAgents)
        }

        super.concatFromJSON(json)

        if (json.total_realized !== undefined) {
            this._total_realized += json.total_realized
        }

        if (json.total_unrealized !== undefined) {
            this._total_unrealized += json.total_unrealized
        }

        return this
    }

    public fromTwelveMonthly(year: string | undefined, annualEvent: IAnnualEvent): EventAnnualSummary {
        const months = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december'
        ]
        this.total_transactions_value = annualEvent.total
        this.total_realized = annualEvent.totalRealized
        this.total_unrealized = annualEvent.totalUnrealized
        this.financial_agents = []

        months.forEach((month: string, index: number) => {
            if (annualEvent[month]) {
                annualEvent[month]
                    .financial_agents
                    ?.forEach((financialAgent: MonthlyInstitution<Event>) => {
                        const transactions: AnnualTransaction[] = []
                        let totalPrevision = 0

                        const descriptions = financialAgent?.transactions?.length ?
                            financialAgent.transactions
                                .map((transaction: Event) => transaction.description)
                            : []

                        const setDescriptions = new Set(descriptions)
                        const arrayDescriptions = Array.from(setDescriptions.values())

                        arrayDescriptions.forEach((description: string | undefined) => {
                            const annualTransaction: AnnualTransaction = new AnnualTransaction()
                            const valuesForDescription = financialAgent?.transactions?.length ?
                                financialAgent.transactions
                                    .filter((transaction: Event) => {
                                        return transaction.description === description
                                    })
                                    .map((transaction: Event) => transaction.value)
                                : []
                            /** Sum all values */
                            const totalDescription = valuesForDescription?.length ?
                                valuesForDescription
                                    .reduce((prev: number | undefined, current: number | undefined) => {
                                        return (prev ? prev : 0) + (current ? current : 0)
                                    })
                                : undefined

                            const previsionDescription: number = financialAgent?.previsions?.length ?
                                financialAgent
                                    ?.previsions
                                    ?.map((previsionElement: Prevision) => previsionElement?.expected_value)
                                    ?.reduce((prevValue: number | undefined, currentValue: number | undefined) => {
                                        return (prevValue || 0) + (currentValue || 0)
                                    }, 0) || 0
                                : 0

                            annualTransaction.date = `${year}-${String(`0${index + 1}`).slice(-2)}`
                            annualTransaction.total_value = totalDescription
                            annualTransaction.prevision = previsionDescription
                            annualTransaction.description = description

                            transactions.push(annualTransaction)
                            totalPrevision += previsionDescription
                        })

                        const financialAgentIncluded = this.financial_agents?.find((financialAgentElement: AnnualInstitution) => {
                            return financialAgentElement.id === financialAgent.id
                        })

                        if (!financialAgentIncluded) {
                            const newAnnualInstitution: AnnualInstitution = new AnnualInstitution()
                                .fromJSON({
                                    ...financialAgent.institution?.toJSON(),
                                    previsions: totalPrevision,
                                    transactions
                                })
                            this.financial_agents = !this.financial_agents?.length ?
                                [newAnnualInstitution]
                                : this.financial_agents?.concat(newAnnualInstitution)
                        } else {
                            financialAgentIncluded.transactions = financialAgentIncluded.transactions?.concat(transactions)
                            financialAgentIncluded.previsions = (financialAgentIncluded?.previsions || 0) + totalPrevision
                        }
                    })
            }
        })

        return this
    }
}
