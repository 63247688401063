import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { loadSuggestionsFailure, loadSuggestionsSuccess } from './actions'
import { IActionType } from '../root.types'
import institutionService from '../../../services/institutions'
import { IActionLoad, ISuggestionResponse, SuggestionActionTypes } from './types'

function* getSuggestions(action: IActionType<IActionLoad>) {
    const { institutionId, transactionType } = action.payload
    try {
        const response: ISuggestionResponse = yield apply(
            institutionService,
            institutionService.getSuggestionsById, [institutionId, transactionType]
        )
        yield put(loadSuggestionsSuccess(response))
    } catch (err) {
        yield put(loadSuggestionsFailure(err))
    }
}

export default function* suggestionSaga() {
    return yield all([
        takeLatest(SuggestionActionTypes.LOAD_SUGGESTIONS, getSuggestions)
    ])
}
