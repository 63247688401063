import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import httpClient from './config.axios'
import BalanceSummary from '../store/application/models/balance/balance.summary'

export class BalanceSummaryService {

    constructor(private apiVersion: string = 'v1') {
    }

    public async getDaily(date: string, paginator?: IPaginator): Promise<IAxiosResponse<BalanceSummary>> {
        const params = this.buildParams(paginator)
        return httpClient.get(`${this.apiVersion}/balances/date/${date}/dailysummary`, { params })
    }

    public async getMonthly(date: string, paginator?: IPaginator): Promise<IAxiosResponse<BalanceSummary>> {
        const params = this.buildParams(paginator)

        return httpClient.get(`${this.apiVersion}/balances/date/${date}/monthlysummary`, { params })
    }

    public async getAnnual(year: string, paginator?: IPaginator): Promise<IAxiosResponse<BalanceSummary>> {
        const params = this.buildParams(paginator)
        return httpClient
            .get(`${this.apiVersion}/balances/date/${year}/annualsummary`, { params })
    }

    private buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()

        if (paginator) {
            if (paginator.page !== undefined && paginator.page !== null) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (paginator?.search?.key && paginator?.search?.value) {
                params.append(`${paginator?.search?.key}`, `${paginator?.search?.value}`)
            }

            if (paginator?.sort?.key && paginator?.sort?.value) {
                params.append(`sort`, `${paginator?.sort?.key}`)
            }
        }

        if (!paginator?.sort?.key && !paginator?.sort?.value) {
            params.append('sort', 'name')
        }

        return params
    }

}

export default new BalanceSummaryService()
