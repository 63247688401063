import * as React from 'react'
import * as ReactDOM from 'react-dom'

import 'primereact/resources/themes/nova-light/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

import './assets/css/bootstrap.css'
import './assets/css/materialize.css'
import './assets/css/font-awesome.css'
import './assets/css/cssCharts.css'

import './index.scss'
import App from './app'
import store from './store'
import reportWebVitals from './report.web.vitals'
import * as serviceWorker from './serviceWorker'
import { LayoutTypes } from './store/ducks/layout/types'

ReactDOM.render(<App/>, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) =>
        store.dispatch({
            type: LayoutTypes.UPDATE_AVAILABLE,
            payload: {
                updateAvailable: true,
                registration
            }
        })
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
