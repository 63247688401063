import { IComponentState } from '../root.types'
import { TransactionTypes } from '../../application/models/prevision/prevision'

/**
 * Action types
 */
export enum SuggestionActionTypes {
    RESET = '@suggestion/RESET',
    LOAD_SUGGESTIONS = '@suggestion/LOAD_SUGGESTIONS',
    LOAD_SUGGESTIONS_SUCCESS = '@suggestion/LOAD_SUGGESTIONS_SUCCESS',
    LOAD_SUGGESTIONS_FAILURE = '@suggestion/LOAD_SUGGESTIONS_FAILURE'
}

export interface IActionLoad {
    institutionId: string
    transactionType: TransactionTypes
}

export interface ISuggestionResponse {
    suggestions: string[]
    typeRespectively: string[]
}

/**
 * State type
 */
export interface ISuggestionState extends IComponentState {
    readonly suggestions: string[]
    /*Only used for Debt and Investment type prevision */
    readonly typeRespectively: string[]
}
