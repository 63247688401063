import authService, { LogicalStrategy } from '../../../services/auth'

export class VerifyUsersUtil {

    public static verify(expectedUsers?: string[], logicalStrategy = LogicalStrategy.AND): boolean {

        if (expectedUsers) {
            try {
                const { sub_type } = authService.decodeToken()
                const users = [`${sub_type}`]
                if (expectedUsers.length === 0) {
                    return true
                }
                return logicalStrategy === LogicalStrategy.AND ?
                    expectedUsers.every((itemScope: string) => users.includes(itemScope))
                    : expectedUsers.some((itemScope: string) => users.includes(itemScope))
            } catch (e) {
                return false
            }
        }

        return true

    }
}
