import { Reducer } from 'redux'

import {
    DebtActionTypes,
    IActionCreate,
    IActionFind,
    IActionLoad,
    IActionRemove,
    IDebtState
} from './types'
import Debt from '../../application/models/transactions/debt/debt'
import { IActionAnnual, IActionMonthly, IActionType } from '../root.types'
import DebtMonthlySummary from '../../application/models/transactions/debt/debt.monthly.summary'
import DebtAnnualSummary from '../../application/models/transactions/debt/debt.annual.summary'
import { IActionFindInstitution } from '../costing/types'

export const INITIAL_STATE: IDebtState = {
    create: {
        resource: new Debt(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        resources: [],
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        institution: undefined
    },
    remove: {
        idForRemove: '',
        institutionId: '',
        visibilityModal: false,
        loading: false,
        error: false,
        success: false
    },
    monthly: {
        monthlyResource: new DebtMonthlySummary(),
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    annual: {
        annualResource: {
            january: undefined,
            february: undefined,
            march: undefined,
            april: undefined,
            may: undefined,
            june: undefined,
            july: undefined,
            august: undefined,
            september: undefined,
            october: undefined,
            november: undefined,
            december: undefined,
            total: undefined,
            totalDirects: undefined,
            totalIndirects: undefined,
            totalJudicialDecisionsAndAgreements: undefined
        },
        data: new ErrorEvent(''),
        loading: false,
        error: false,
        success: false,
        lastSync: undefined,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    dashboard: {
        annualResource: new DebtAnnualSummary(),
        loading: false,
        error: false,
        success: false
    }
}

type Actions =
    IActionCreate
    & IActionLoad
    & IActionFind
    & IActionRemove
    & IActionFindInstitution
    & IActionMonthly
    & IActionAnnual

const reducer: Reducer<IDebtState> = (state: IDebtState = INITIAL_STATE, action: IActionType<Actions> | any) => {
    switch (action.type) {
        case DebtActionTypes.CHANGE_DEBT:
            const { debt } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: false,
                    success: false,
                    resource: new Debt().fromJSON(debt)
                }
            }

        case DebtActionTypes.CHANGE_DIALOG:
            const { dialog } = action.payload
            return { ...state, create: { ...state.create, dialog } }

        case DebtActionTypes.CHANGE_PAGINATOR:
            const { paginator } = action.payload
            return { ...state, list: { ...state.list, paginator } }

        case DebtActionTypes.CHANGE_SEARCH_PAGINATOR:
            const { search } = action.payload
            return { ...state, list: { ...state.list, paginator: { ...state.list.paginator, search } } }

        case DebtActionTypes.CHANGE_MONTHLY_PAGINATOR:
            const { paginator: monthlyPaginator } = action.payload
            return {
                ...state,
                monthly: { ...state.monthly, paginator: monthlyPaginator }
            }

        case DebtActionTypes.RESET_MONTHLY_PAGINATOR:
            return {
                ...state,
                monthly: { ...state.monthly, paginator: INITIAL_STATE.monthly.paginator }
            }

        case DebtActionTypes.CHANGE_ANNUAL_PAGINATOR:
            const { paginator: annualPaginator } = action.payload
            return {
                ...state,
                annual: { ...state.annual, paginator: { ...state.annual.paginator, ...annualPaginator } }
            }

        case DebtActionTypes.RESET_ANNUAL_PAGINATOR:
            return {
                ...state,
                annual: { ...state.annual, paginator: INITIAL_STATE.annual.paginator }
            }

        case DebtActionTypes.CHANGE_REMOVE_MODAL:
            const { visibilityModal, idForRemove, institutionId } = action.payload
            return {
                ...state,
                remove: { ...state.remove, visibilityModal, idForRemove, institutionId }
            }

        case DebtActionTypes.CREATE_REQUEST:
            const { debt: newRequestDebt } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, resource: newRequestDebt },
                monthly: { ...state.monthly, loading: true }
            }

        case DebtActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    resource: new Debt().fromJSON({ institution_id: state.create.resource.institution_id }),
                    success: true,
                    loading: false
                }
            }

        case DebtActionTypes.CREATE_FAILURE:
            const { error: createError } = action.payload
            const monthlyDebtCreateError = state?.monthly?.monthlyResource ? state.monthly.monthlyResource : new DebtMonthlySummary()
            monthlyDebtCreateError.addTransaction(new Debt().fromJSON(action?.payload?.debt), createError)
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: createError },
                monthly: {
                    ...state.monthly,
                    loading: false,
                    monthlyResource: monthlyDebtCreateError
                }
            }

        case DebtActionTypes.RESET:
            return { ...state, create: INITIAL_STATE.create }

        case DebtActionTypes.LOAD_REQUEST:
            return { ...state, list: { ...state.list, loading: true } }

        case DebtActionTypes.LOAD_SUCCESS:
            const { debts, headers } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    resources: debts.map((debtJson: any) => new Debt().fromJSON(debtJson)),
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                }
            }

        case DebtActionTypes.LOAD_FAILURE:
            const { error } = action.payload
            return { ...state, list: { ...state.list, loading: false, error: true, data: error } }

        case DebtActionTypes.FIND_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true }
            }

        case DebtActionTypes.FIND_SUCCESS:
            const { debt: debtFound } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    resource: new Debt().fromJSON(debtFound),
                    loading: false
                }
            }

        case DebtActionTypes.FIND_FAILURE:
            const { error: errorFound } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: errorFound }
            }

        case DebtActionTypes.REMOVE_REQUEST:
            return { ...state, remove: { ...state.remove, loading: true, visibilityModal: false } }

        case DebtActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    visibilityModal: false,
                    success: true,
                    idForRemove: ''
                }
            }

        case DebtActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: { ...state.remove, loading: false, error: true, success: false, visibilityModal: true }
            }

        case DebtActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: { ...state.create, loading: true },
                monthly: { ...state.monthly, loading: true }
            }

        case DebtActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: { ...state.create, loading: false }
            }

        case DebtActionTypes.UPDATE_FAILURE:
            const { error: updateError, debt: debtError } = action.payload
            const monthlyDebtUpdateError = state?.monthly?.monthlyResource ? state.monthly.monthlyResource : new DebtMonthlySummary()
            monthlyDebtUpdateError.updateTransaction(new Debt().fromJSON(debtError), updateError)
            return {
                ...state,
                create: { ...state.create, loading: false, error: true, success: false, data: updateError },
                monthly: { ...state.monthly, loading: false, monthlyResource: monthlyDebtUpdateError }
            }

        case DebtActionTypes.FIND_INSTITUTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    institution: undefined
                }
            }

        case DebtActionTypes.FIND_INSTITUTION_SUCCESS:
            const { institution } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    institution
                }
            }

        case DebtActionTypes.FIND_INSTITUTION_FAILURE:
            const { error: institutionError } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    error: institutionError
                }
            }

        case DebtActionTypes.LOAD_MONTHLY:

            return { ...state, monthly: { ...state.monthly, loading: true } }

        case DebtActionTypes.LOAD_MONTHLY_SUCCESS_RESET:
            const { headers: monthlyHeaders, monthlyDebt } = action.payload
            const result = new DebtMonthlySummary().fromJSON(monthlyDebt)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyResource: result,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyHeaders['x-total-count'], 10)
                    }
                }
            }

        case DebtActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT:
            const { headers: monthlyConcatHeaders, monthlyDebt: concatMonthlyDebt } = action.payload
            const concatResult = state.monthly.monthlyResource
                .concatFromJSON(concatMonthlyDebt)
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    loading: false,
                    success: true,
                    error: false,
                    monthlyResource: concatResult,
                    lastSync: new Date(),
                    paginator: {
                        ...state.monthly.paginator, totalRecords: parseInt(monthlyConcatHeaders['x-total-count'], 10)
                    }
                }
            }

        case DebtActionTypes.LOAD_MONTHLY_FAILURE:
            const { error: monthlyError } = action.payload
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    monthlyResource: INITIAL_STATE.monthly.monthlyResource,
                    loading: false,
                    error: true,
                    data: monthlyError
                }
            }

        case DebtActionTypes.LOAD_ANNUAL:
            return { ...state, annual: { ...state.annual, loading: true } }

        case DebtActionTypes.LOAD_ANNUAL_SUCCESS_RESET:
            const {
                january: januaryDebtReset,
                february: februaryDebtReset,
                march: marchDebtReset,
                april: aprilDebtReset,
                may: mayDebtReset,
                june: juneDebtReset,
                july: julyDebtReset,
                august: augustDebtReset,
                september: septemberDebtReset,
                october: octoberDebtReset,
                november: novemberDebtReset,
                december: decemberDebtReset,
                totalDirects: directsReset,
                totalIndirects: indirectsReset,
                totalJudicialDecisionsAndAgreements: judicialDecisionsAndAgreementsReset,
                total: totalReset,
                xTotalCount: xTotalCountReset
            } = action.payload

            return {
                ...state,
                annual: {
                    ...state.annual,
                    annualResource: {
                        january: januaryDebtReset ? new DebtMonthlySummary().fromJSON(januaryDebtReset) : undefined,
                        february: februaryDebtReset ? new DebtMonthlySummary().fromJSON(februaryDebtReset) : undefined,
                        march: marchDebtReset ? new DebtMonthlySummary().fromJSON(marchDebtReset) : undefined,
                        april: aprilDebtReset ? new DebtMonthlySummary().fromJSON(aprilDebtReset) : undefined,
                        may: mayDebtReset ? new DebtMonthlySummary().fromJSON(mayDebtReset) : undefined,
                        june: juneDebtReset ? new DebtMonthlySummary().fromJSON(juneDebtReset) : undefined,
                        july: julyDebtReset ? new DebtMonthlySummary().fromJSON(julyDebtReset) : undefined,
                        august: augustDebtReset ? new DebtMonthlySummary().fromJSON(augustDebtReset) : undefined,
                        september: septemberDebtReset ? new DebtMonthlySummary().fromJSON(septemberDebtReset) : undefined,
                        october: octoberDebtReset ? new DebtMonthlySummary().fromJSON(octoberDebtReset) : undefined,
                        november: novemberDebtReset ? new DebtMonthlySummary().fromJSON(novemberDebtReset) : undefined,
                        december: decemberDebtReset ? new DebtMonthlySummary().fromJSON(decemberDebtReset) : undefined,
                        totalDirects: directsReset,
                        totalIndirects: indirectsReset,
                        totalJudicialDecisionsAndAgreements: judicialDecisionsAndAgreementsReset,
                        total: totalReset
                    },
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator,
                        totalRecords: xTotalCountReset
                    }
                }
            }

        case DebtActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT:
            const {
                january: januaryDebtConcat,
                february: februaryDebtConcat,
                march: marchDebtConcat,
                april: aprilDebtConcat,
                may: mayDebtConcat,
                june: juneDebtConcat,
                july: julyDebtConcat,
                august: augustDebtConcat,
                september: septemberDebtConcat,
                october: octoberDebtConcat,
                november: novemberDebtConcat,
                december: decemberDebtConcat,
                totalDirects: directsConcat,
                totalIndirects: indirectsConcat,
                totalJudicialDecisionsAndAgreements: judicialDecisionsAndAgreementsConcat,
                total: totalConcat,
                xTotalCount: xTotalCountConcat
            } = action.payload

            return {
                ...state,
                annual: {
                    ...state.annual,
                    annualResource: {
                        january: state.annual.annualResource.january?.concatFromJSON(januaryDebtConcat),
                        february: state.annual.annualResource.february?.concatFromJSON(februaryDebtConcat),
                        march: state.annual.annualResource.march?.concatFromJSON(marchDebtConcat),
                        april: state.annual.annualResource.april?.concatFromJSON(aprilDebtConcat),
                        may: state.annual.annualResource.may?.concatFromJSON(mayDebtConcat),
                        june: state.annual.annualResource.june?.concatFromJSON(juneDebtConcat),
                        july: state.annual.annualResource.july?.concatFromJSON(julyDebtConcat),
                        august: state.annual.annualResource.august?.concatFromJSON(augustDebtConcat),
                        september: state.annual.annualResource.september?.concatFromJSON(septemberDebtConcat),
                        october: state.annual.annualResource.october?.concatFromJSON(octoberDebtConcat),
                        november: state.annual.annualResource.november?.concatFromJSON(novemberDebtConcat),
                        december: state.annual.annualResource.december?.concatFromJSON(decemberDebtConcat),
                        totalDirects: state.annual.annualResource.totalDirects + directsConcat,
                        totalIndirects: state.annual.annualResource.totalIndirects + indirectsConcat,
                        totalJudicialDecisionsAndAgreements:
                            state.annual.annualResource.totalJudicialDecisionsAndAgreements +
                            judicialDecisionsAndAgreementsConcat,
                        total: state.annual.annualResource.total + totalConcat
                    },
                    loading: false,
                    success: true,
                    error: false,
                    lastSync: new Date(),
                    paginator: {
                        ...state.annual.paginator,
                        totalRecords: xTotalCountConcat
                    }
                }
            }

        case DebtActionTypes.LOAD_ANNUAL_FAILURE:
            const { error: annualError } = action.payload
            return {
                ...state,
                annual: {
                    ...state.annual,
                    annualResource: INITIAL_STATE.annual.annualResource,
                    loading: false,
                    error: true,
                    data: annualError
                }
            }

        case DebtActionTypes.LOAD_ANNUAL_DASH:
            return { ...state, dashboard: { ...state.dashboard, loading: true } }

        case DebtActionTypes.LOAD_ANNUAL_DASH_SUCCESS:
            const { annualResource: dashAnnualResource } = action.payload

            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    annualResource: new DebtAnnualSummary().fromJSON(dashAnnualResource),
                    loading: false,
                    success: true,
                    error: false
                }
            }

        case DebtActionTypes.LOAD_ANNUAL_DASH_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    annualResource: INITIAL_STATE.dashboard.annualResource,
                    loading: false,
                    error: true
                }
            }

        default:
            return state
    }
}

export default reducer
