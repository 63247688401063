import './select.scss'
import institutionService from '../../services/institutions'
import { IAxiosResponse, IPaginator } from '../../store/ducks/root.types'
import { translateCostingCategory, translateInstitutionType } from '../../containers/institutions/create'
import { SelectFinancialAgent } from './select.financial.agent'
import Institution, { TypeInstitution } from '../../store/application/models/financial.agents/institution/institution'

interface IProps {
    readonly name: string

    readonly value: string | string[]

    readonly filterPlaceholder?: string

    readonly placeholder?: string

    readonly disabled?: boolean

    readonly match?: any

    readonly multiple?: boolean

    readonly selectCategory?: TypeInstitution | ''

    setFieldValue(field: string, value: any): void

    setFieldTouched(field: string, value1: boolean, value2: boolean): void
}

interface IState {
    readonly elements: any[]
}

class SelectInstitution extends SelectFinancialAgent {
    private timeGetAll: any
    private timeGetByIds: any

    constructor(props) {
        super(props)
        /* Initial State */
        this.getAll = this.getAll.bind(this)
        this.getById = this.getById.bind(this)
        this.getByMultiplesIds = this.getByMultiplesIds.bind(this)
        this.selectAllByCategory = this.selectAllByCategory.bind(this)
    }

    public componentDidMount() {
        const { match: { params }, value } = this.props
        if ((!params.institutionId && !value) || (value instanceof Array && value?.length === 0)) {
            clearTimeout(this.timeGetAll)
            this.timeGetAll = setTimeout(this.getAll, 100)
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const { elements } = this.state
        const { value, selectCategory } = this.props
        const { selectCategory: prevSelectCategory } = prevProps
        if (prevProps?.value !== value && typeof value === 'string') {
            clearTimeout(this.timeGetAll)
            this.getById(value).then()
        } else if (value instanceof Array) {
            const notFound: any[] = value
                ?.map((institutionId: string) => {
                    const found: Institution | undefined = elements
                        ?.find((element: any) => element?.value === institutionId && element.className !== 'loading')
                    return {
                        status: !!found,
                        value: institutionId
                    }
                })
                ?.filter((item: any) => !item?.status)
            if (notFound?.length) {
                const institutionsIds: string[] = notFound?.map((item: any) => `${item?.value}`)
                clearTimeout(this.timeGetByIds)
                this.timeGetByIds = setTimeout(() => this.getByMultiplesIds(institutionsIds).then(), 100)
            }
        }
        if (selectCategory !== prevSelectCategory) {
            this.selectAllByCategory(selectCategory)
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        clearTimeout(this.timeGetAll)
        clearTimeout(this.timeGetByIds)
    }

    /* Method to invoke the request to the backend */
    protected async getAll(paginator?: IPaginator | any): Promise<void> {
        const { match: { params }, value, multiple } = this.props
        const institutionId: string = params?.institutionId || value
        const added: any[] = []
        this.resetState()
        try {
            const resultPaginator: IPaginator = multiple ?
                { ...paginator, rows: Number.MAX_SAFE_INTEGER }
                : paginator
            const response: IAxiosResponse<Institution[]> = await institutionService.getAll(resultPaginator)
            const institutions = response.data
            if (institutions?.length) {
                const elementsDirect: any[] = institutions?.length ?
                    response?.data
                        ?.filter((institution: Institution) => {
                            return institution.institution_type === TypeInstitution.DIRECT_ADMINISTRATION
                        })
                        ?.sort((prev, current) => {
                            const prevType = prev.cost_category || ''
                            const currentType = current.cost_category || ''
                            return (prevType > currentType) ? -1 : 1
                        })
                        .map((institution: Institution) => {
                            let type
                            if (!added.includes(institution.cost_category)) {
                                added.push(institution.cost_category)
                                type = `Administração Direta / ${translateCostingCategory[institution.cost_category]}`
                            }
                            return {
                                label: `(${institution.code}) ${institution.name}`,
                                value: institution.id,
                                type,
                                institution_type: institution.institution_type,
                                className: 'active'
                            }
                        })
                    : [
                        { label: 'Nenhum registro encontrado!', value: null, className: 'disabled' }
                    ]
                const elementsIndirect: any[] = institutions?.length ?
                    response?.data
                        ?.filter((institution: Institution) => {
                            return institution.institution_type === TypeInstitution.INDIRECT_ADMINISTRATION
                        })
                        .map((institution: Institution) => {
                            let type
                            if (!added.includes(institution.institution_type)) {
                                added.push(institution.institution_type)
                                type = translateInstitutionType[institution.institution_type]
                            }
                            return {
                                label: `(${institution.code}) ${institution.name}`,
                                value: institution.id,
                                type,
                                institution_type: institution.institution_type,
                                className: 'active'
                            }
                        })
                    : [
                        { label: 'Nenhum registro encontrado', value: null, className: 'disabled' }
                    ]
                const resultInstitutions: Institution[] = elementsDirect.concat(elementsIndirect)
                const elementsState: any[] = this.state.elements
                    ?.filter((item: any) => item.label !== 'Aguarde, carregando...')
                this.setState({
                    elements: institutionId ? elementsState.concat(resultInstitutions) : resultInstitutions
                })
            } else {
                this.setState({
                    elements: [{ label: 'Nenhum registro encontrado!', value: null, className: 'disabled' }]
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    /* Method to invoke the request to the backend */
    protected async getById(institutionId: string): Promise<void> {
        const { elements } = this.state
        const find = elements?.find(element => {
            return element.value === institutionId
        })
        if (!find) {
            try {
                const institution = await institutionService.getById(institutionId)
                if (institution) {
                    this.setState({
                        elements: this.state.elements.concat({
                            label: `(${institution.code}) ${institution.name}`,
                            value: institution.id,
                            institution_type: institution.institution_type,
                            className: 'active'
                        })
                    })
                }
            } catch (e) {
                console.error(e)
            }
        }
    }

    /* Method to invoke the request to the backend */
    protected async getByMultiplesIds(institutionIds: string[]): Promise<void> {
        const resultIds: string[] = institutionIds?.map((institutionId: any) => `${institutionId}`) || []
        try {
            const institutions: Institution[] = await institutionService.getByIds(resultIds)
            if (institutions?.length) {
                const newElements: any[] = institutions.map((institutionItem: Institution) => {
                    return {
                        label: `(${institutionItem.code}) ${institutionItem.name}`,
                        value: institutionItem.id,
                        institution_type: institutionItem.institution_type,
                        className: 'active'
                    }
                })
                const elements: any[] = this.state
                    ?.elements
                    ?.filter((elementItem: any) => !institutionIds.includes(elementItem?.value)) || []
                this.setState({ elements: elements.concat(newElements) })
            }
        } catch (e) {
            console.error(e)
        }
    }

    private selectAllByCategory(category?: TypeInstitution | ''): void {
        const { setFieldValue, name } = this.props
        const { elements } = this.state
        if (!category) {
            setFieldValue(name, [])
        } else {
            const institutions: any[] = elements.filter((item: any) => item?.institution_type === category)
            setFieldValue(name, institutions.map((item: any) => item?.value))
        }
    }
}

export default SelectInstitution
