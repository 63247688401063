import { action } from 'typesafe-actions'
import { CostingActionTypes, IPaginator, TransactionLocation } from './types'
import Costing from '../../application/models/transactions/costing/costing'
import { IAxiosResponse, ISearch } from '../root.types'
import Institution from '../../application/models/financial.agents/institution/institution'
import CostMonthlySummary from '../../application/models/transactions/costing/cost.monthly.summary'

/* Actions for Reset State */
export const resetCosting = () => action(CostingActionTypes.RESET_COSTING, {})

/* Actions for Change */
export const changeCosting = (costing: Costing) => action(CostingActionTypes.CHANGE_COSTING, { costing })

export const changeDialog = (dialog: boolean) => action(CostingActionTypes.CHANGE_DIALOG, { dialog })

export const changeDate = (date: Date) => action(CostingActionTypes.CHANGE_DATE, { date })

export const changeMonth = (month: string) => [
    action(CostingActionTypes.CHANGE_MONTH, { month }),
    loadMonthly(month)
]

export const changePaginator = (institutionId: string, paginator: IPaginator) => [
    action(CostingActionTypes.CHANGE_PAGINATOR, { paginator }),
    load(institutionId, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    CostingActionTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveModal = (visibilityModal: boolean, idForRemove: string, institutionId: string) => action(
    CostingActionTypes.CHANGE_REMOVE_MODAL, { visibilityModal, idForRemove, institutionId }
)

/* Actions for Create Costing */
export const create = (costing: Costing, location: TransactionLocation) => action(
    CostingActionTypes.CREATE_REQUEST,
    { costing, location }
)

export const createSuccess = (costing: Costing) => action(
    CostingActionTypes.CREATE_SUCCESS,
    { costing }
)

export const createFailure = (error: ErrorEvent, costing: Costing) => action(CostingActionTypes.CREATE_FAILURE, {
    error,
    costing
})

/* Actions for Load Costing */
export const load = (institutionId: string, paginator?: IPaginator) => action(
    CostingActionTypes.LOAD_REQUEST, { institutionId, paginator }
)

export const loadCostingSuccess = (response: IAxiosResponse<Costing[]>) => action(
    CostingActionTypes.LOAD_SUCCESS,
    { costings: response.data, headers: response.headers }
)

export const loadCostingFailure = (error: ErrorEvent) => action(CostingActionTypes.LOAD_FAILURE, { error })

/* Actions for Find Costing */
export const find = (institutionId: string, costingId: string) => action(
    CostingActionTypes.FIND_REQUEST, { institutionId, costingId }
)

export const findSuccess = (response: IAxiosResponse<Costing[]>) => action(
    CostingActionTypes.FIND_SUCCESS,
    { costing: response.data, headers: response.headers }
)

export const findFailure = (error: ErrorEvent) => action(CostingActionTypes.FIND_FAILURE, { error })

/* Actions for Remove Costing */
export const remove = (
    institutionId: string,
    costingId: string,
    location: TransactionLocation,
    paginator: IPaginator,
    date?: string) => action(
    CostingActionTypes.REMOVE_REQUEST, {
        institutionId,
        costingId,
        paginator,
        date,
        location
    }
)

export const removeSuccess = () => action(CostingActionTypes.REMOVE_SUCCESS, {})

export const removeFailure = (error: ErrorEvent) => action(CostingActionTypes.REMOVE_FAILURE, { error })

/* Actions for Update Costing */
export const update = (costing: Costing, location: TransactionLocation) => action(
    CostingActionTypes.UPDATE_REQUEST,
    { costing, location }
)

export const updateSuccess = (costing: Costing) => action(CostingActionTypes.UPDATE_SUCCESS, { costing })

export const updateFailure = (error: ErrorEvent, costing: Costing) => action(CostingActionTypes.UPDATE_FAILURE, {
    error,
    costing
})

/* Actions for get Institution */
export const findInstitution = (institutionId: string) => action(
    CostingActionTypes.FIND_INSTITUTION,
    { institutionId }
)

export const findInstitutionSuccess = (response: IAxiosResponse<Institution>) => action(
    CostingActionTypes.FIND_INSTITUTION_SUCCESS,
    { institution: response.data, headers: response.headers }
)

export const findInstitutionFailure = (error: ErrorEvent) => action(
    CostingActionTypes.FIND_INSTITUTION_FAILURE,
    { error }
)

/* Actions for Load Monthly Costing */
export const changeMonthlyPaginator = (date: string, paginator: IPaginator) => [
    action(CostingActionTypes.CHANGE_MONTHLY_PAGINATOR, { paginator }),
    loadMonthly(date, paginator)
]

export const resetMonthlyPaginator = () => action(CostingActionTypes.RESET_MONTHLY_PAGINATOR)

export const loadMonthly = (date?: string, paginator?: IPaginator) => action(
    CostingActionTypes.LOAD_MONTHLY, { date, paginator }
)

export const loadMonthlySuccessReset = (response: IAxiosResponse<CostMonthlySummary>) => action(
    CostingActionTypes.LOAD_MONTHLY_SUCCESS_RESET,
    { monthlyCosting: response.data, headers: response.headers }
)

export const loadMonthlySuccessConcat = (response: IAxiosResponse<CostMonthlySummary>) => action(
    CostingActionTypes.LOAD_MONTHLY_SUCCESS_CONCAT,
    { monthlyCosting: response.data, headers: response.headers }
)

export const loadMonthlyFailure = (error: ErrorEvent) => action(
    CostingActionTypes.LOAD_MONTHLY_FAILURE,
    { error }
)

/* Actions for Load Annual Costing */
export const changeAnnualPaginator = (year: string, paginator?: IPaginator) => [
    action(CostingActionTypes.CHANGE_ANNUAL_PAGINATOR, { paginator }),
    loadAnnual(year, paginator)
]

export const resetAnnualPaginator = () => action(CostingActionTypes.RESET_ANNUAL_PAGINATOR)


export const loadAnnual = (year: string, paginator?: IPaginator) => action(
    CostingActionTypes.LOAD_ANNUAL, { year, paginator }
)

export const loadAnnualSuccessReset = (response: IAxiosResponse<CostMonthlySummary>) => action(
    CostingActionTypes.LOAD_ANNUAL_SUCCESS_RESET,
    { annualCosting: response.data, headers: response.headers }
)

export const loadAnnualSuccessConcat = (response: IAxiosResponse<CostMonthlySummary>) => action(
    CostingActionTypes.LOAD_ANNUAL_SUCCESS_CONCAT,
    { annualCosting: response.data, headers: response.headers }
)

export const loadAnnualFailure = (error: ErrorEvent) => action(
    CostingActionTypes.LOAD_ANNUAL_FAILURE,
    { error }
)
