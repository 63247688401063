import { lazy } from 'react'

const AnnualCosting = lazy(() => import('../containers/costing/annual'))
const MonthlyCosting = lazy(() => import('../containers/costing/monthly'))


export const COSTING_ROUTES = [
    {
        path: '/app/costing/annual',
        component: AnnualCosting,
        scopes: ['as:r'],
        exact: true,
        private: true
    },
    {
        path: '/app/costing/monthly',
        component: MonthlyCosting,
        scopes: ['ms:r'],
        exact: true,
        private: true
    },

]
