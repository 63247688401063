import { Reducer } from 'redux'
import { LiberationActionTypes, ILiberationState } from './types'
import { Liberation } from '../../application/models/liberation/liberation'
import { LiberationSummary } from '../../application/models/liberation/liberation.summary'

export const INITIAL_STATE: ILiberationState = {
    create: {
        data: new Liberation(),
        loading: false,
        error: false,
        success: false,
        dialog: false
    },
    list: {
        data: new LiberationSummary(),
        loading: false,
        error: false,
        success: false,
        sync: false,
        paginator: {
            first: 0,
            rows: 100,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        error: false,
        success: false
    }
}

const reducer: Reducer<ILiberationState> = (state: ILiberationState = INITIAL_STATE, action: any) => {
    switch (action.type) {

        case LiberationActionTypes.CHANGE_CREATE_DIALOG:
            const { dialog: dialogChanged, data: dataSelected } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    success: true,
                    loading: false,
                    data: dataSelected,
                    dialog: dialogChanged
                }
            }

        case LiberationActionTypes.CHANGE_REMOVE_MODAL:
            const { ids, dialog } = action.payload
            return {
                ...state,
                remove: { ...state.remove, dialog, ids }
            }

        case LiberationActionTypes.CREATE_REQUEST:
            const { data } = action.payload
            return {
                ...state,
                create: { ...state.create, loading: true, data, success: false }
            }

        case LiberationActionTypes.CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    dialog: false,
                    success: true,
                    loading: false,
                    data: new Liberation()
                }
            }

        case LiberationActionTypes.CREATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        case LiberationActionTypes.RESET:
            return INITIAL_STATE

        case LiberationActionTypes.LOAD_REQUEST:
            const { paginator: listPaginator } = action.payload
            return { ...state, list: { ...state.list, loading: true, paginator: listPaginator } }

        case LiberationActionTypes.LOAD_SUCCESS:
            const { data: listData, headers: listHeaders } = action.payload
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    success: true,
                    error: false,
                    data: listData,
                    paginator: {
                        ...state.list.paginator, totalRecords: parseInt(listHeaders['x-total-count'], 10)
                    }
                }
            }

        case LiberationActionTypes.LOAD_FAILURE:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: true,
                    data: new LiberationSummary()
                }
            }

        case LiberationActionTypes.FIND_REQUEST:
            return { ...state, create: { ...state.create, loading: true } }

        case LiberationActionTypes.FIND_SUCCESS:
            const { data: foundData } = action.payload
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: true,
                    error: false,
                    data: foundData
                }
            }

        case LiberationActionTypes.FIND_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true
                }
            }

        case LiberationActionTypes.REMOVE_REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }

        case LiberationActionTypes.REMOVE_SUCCESS:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    dialog: false,
                    success: true,
                    ids: []
                }
            }

        case LiberationActionTypes.REMOVE_FAILURE:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false,
                    error: true,
                    success: false,
                    dialog: true
                }
            }

        case LiberationActionTypes.UPDATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }

        case LiberationActionTypes.UPDATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    data: new Liberation()
                }
            }

        case LiberationActionTypes.UPDATE_FAILURE:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: true,
                    success: false
                }
            }

        default:
            return state
    }
}

export default reducer
