import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import '../container.style.scss'
import Spinner from '../../components/spinner/spinner'
import { IPaginator, TransactionLocation } from '../../store/ducks/costing/types'
import { IApplicationState } from '../../store'
import * as CostingActions from '../../store/ducks/costing/actions'
import { Margin } from './costing.by.institution'

interface IState {
    /* Redux Props */
    readonly removeVisibilityModal: boolean,
    readonly removeLoading: boolean,
    readonly removeError: boolean,
    readonly removeSuccess: boolean
    readonly removeId: string
    readonly institutionId: string
    /* Others Props */
    readonly paginator: IPaginator
    readonly date?: string
    readonly costingLocation: TransactionLocation
}

interface IDispatchProps extends RouteComponentProps<any> {
    changeRemoveModal(visibilityModal: boolean, idForRemove: string, institutionId: string): void

    remove(
        institutionId: string,
        costingId: string,
        location: TransactionLocation,
        paginator: IPaginator,
        date?: string): void
}

type IProps = IState & IDispatchProps


class RemoveCosting extends Component<IProps> {

    public render() {
        const {
            paginator,
            removeVisibilityModal,
            removeLoading,
            removeId,
            changeRemoveModal,
            remove,
            date,
            costingLocation,
            institutionId
        } = this.props

        const footer = (
            <div className="d-flex justify-content-around">
                <Button className="p-button-primary" label="Sim" icon="pi pi-check"
                        onClick={() => remove(institutionId, removeId, costingLocation, paginator, date)}/>

                <Button className="p-button-secondary" label="Não" icon="pi pi-times"
                        onClick={() => changeRemoveModal(!removeVisibilityModal, '', '')}/>
            </div>
        )

        return <React.Fragment>

            {removeLoading && <Spinner message="Removendo custeio..."/>}

            <Dialog
                header="Remover Custeio"
                footer={footer}
                visible={removeVisibilityModal}
                closeOnEscape={true}
                className="dialog max-width-700"
                modal={true} onHide={() => changeRemoveModal(false, '', '')}>
                <Margin top={10} bottom={10} left={10} right={10}>
                    Tem certeza que deseja excluir definitivamente o custeio selecionado?
                </Margin>
            </Dialog>

        </React.Fragment>

    }

}

const mapStateToProps = (state: IApplicationState) => ({
    removeVisibilityModal: state.costing.remove.visibilityModal,
    removeLoading: state.costing.remove.loading,
    removeError: state.costing.remove.error,
    removeSuccess: state.costing.remove.success,
    removeId: state.costing.remove.idForRemove,
    institutionId: state.costing.remove.institutionId
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(CostingActions, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemoveCosting))

